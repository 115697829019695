export const minimal_distance = 30;

export const distances = {
    Palma 		: {
        Palma		: minimal_distance,
        Norte		: 55,
        Sur			: 30,
        Levante		: 50,
        Este	    : 50,
        Centro      : 30,
        Tramontana	: 35
    },
    Norte		: {
        Palma		: 55,
        Norte		: minimal_distance,
        Sur			: 55,
        Levante		: 30,
        Este	    : 30,
        Centro      : 30,
        Tramontana	: 35
    },
    Sur			: {
        Palma		: 30,
        Norte		: 55,
        Sur			: minimal_distance,
        Levante		: 55,
        Este	    : 55,
        Centro      : 30,
        Tramontana	: 35
    },
    Levante		: {
        Palma		: 50,
        Norte		: 30,
        Sur			: 55,
        Levante		: minimal_distance,
        Este	    : minimal_distance,
        Centro      : 30,
        Tramontana	: 60
    },
    Este		: {
        Palma		: 50,
        Norte		: 30,
        Sur			: 55,
        Levante		: minimal_distance,
        Este	    : minimal_distance,
        Centro      : 30,
        Tramontana	: 60
    },
    Tramontana	: {
        Palma		: 35,
        Norte		: 35,
        Sur			: 35,
        Levante		: 60,
        Este	    : 60,
        Centro      : 30,
        Tramontana	: minimal_distance
    },
    Centro	: {
        Palma		: 30,
        Norte		: 30,
        Sur			: 30,
        Levante		: 30,
        Este	    : 30,
        Centro      : minimal_distance,
        Tramontana	: 30
    },
};