import { 	Component, 
			OnInit, 
			ViewEncapsulation, 
			Output, 
			EventEmitter, 				
			Input,						
			ViewChild									} from '@angular/core';
import { CommonsService 				} from 'src/app/demo/service/commons.service';

interface Calendar {
	value		: any,
	date		: string,
	last		: string,
	offset		: number,
	minOffset	: number,
	maxOffset	: number
};
@Component({
  selector		: 'app-super-table',
  templateUrl	: './super-table.component.html',
  styleUrls		: ['./super-table.component.scss'],
  encapsulation	: ViewEncapsulation.None
})

export class SuperTableComponent implements OnInit {
	
	@ViewChild("dt") 		dataTable		: any;	
	@Input()				info        	: any   			= [];
	@Input()				cols        	: any   			= [];
	@Input()				buttons        	: any   			= [];
	@Input()				filters        	: any   			= [];
	@Input()				options			: any				= {};
	@Input()				form			: any				= {};
	@Input()				results			: any				= [];
    @Output() 				emitter			: EventEmitter<any> = new EventEmitter();

	pageInfo	: any = { dataLoaded: false };
	calendar	: Calendar 	= <Calendar>{ last: '', value: new Date(), offset: 4, minOffset: 0, maxOffset: 30 };	
	
  	constructor(
		private commons	: CommonsService,        
	) {		
	}

  	ngOnInit() {}
	  
	// Get data updates
	ngOnChanges(data) 			{ 
		[ "info", "cols", "buttons", "filters", "options", "form" ].forEach(item=>{
			if ( undefined===data[item] || undefined==data[item].currentValue || null==data[item].currentValue) { return false; }
			const values = data[item].currentValue;
			switch(item){
				case "info"		:	break;
				case "cols"		:	this.generateMenuCols();	
									break;
				case "buttons"	: 	this.pageInfo.buttons = values; break;
				case "filters"	:	this.initFilters();
									this.initMenu();
									break;
				case "options"	:	console.log("DATA Options", values);	
									this.pageInfo.scrollHeight = values.scrollHeight || '70vh';
									break;
				case "form"		:	console.log("DATA Form", 	values);
									console.log(data);
									this.pageInfo.dataLoaded = true;
									break;
			}
		});		
	}

	async initMenu(){
		let me					= this;
		this.pageInfo.menu		= [];
		this.filters.forEach(async filter=>{
			let item 		= {
				label		: await this.commons.getTranslate(filter.label),
				icon		: filter.icon?('fa fa-'+filter.icon):'',
				style		: { padding: '5px 10px' },
				items		: []
			};
			filter.items.forEach(async current=> {
				let element	= {
					label		: 	await this.commons.getTranslate(current.label),
					icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
					command		: 	($event) => { me.doMenuAction(current.value,filter); }
				}
				item["items"].push(element);
			});
			this.pageInfo.menu.push(item);
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		$filter.selected = $filter.selected.some(item=>item===$item)
							?$filter.selected.filter(item=>item!==$item)
							:[...$filter.selected,$item];
		this.checkFilter($filter);		// Execute filtering
		this.initMenu();				// Regenerate menu to show selection
	}

	/**
	 * Init all filters and exec initial check
	 */
	async initFilters(){
		this.pageInfo.filters = await this.commons.translateRecursively(this.filters,{ label: "label", children: "items" });
		( this.pageInfo.entities || [] ).forEach(entity=>{
			this[entity].filters = this[entity].filters || [];
			this[entity].filters.forEach(filter=>this.checkFilter(filter));
		});
	}

	checkFilter(filter)			{	switch(filter.type){
										case "multiple":	this.checkMultipleFilter(filter); 	break;
										default:			this.checkSimpleFilter(filter);		break;
									}
	}

    checkMultipleFilter(filter)	{
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){ return false; }

		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){ return false; }

		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this[$entity].data			|| [];
		let filters = this[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "shared":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		this[$entity].filteredData	= data;
		this[$entity].count 		= this[$entity].filteredData ? this[$entity].filteredData.length : 0;
		// this.paginateLocal($entity, null);
	}

	filterPending(filter){}

// -----------------------------------------------------------------------------------
// ACTION METHODS
// -----------------------------------------------------------------------------------

	doAction(type,items){	
		switch(type){
			case "cancelRow"	: 	this.cancelRow(items);					break;
			case "saveRow"		:	this.emitter.emit({type : type, item : items}); break;
			default				:	this.emitter.emit({ type: type	});		break;
		}
	}		

	search(item,$event){
		//if(undefined==item.entityList){ return false; }
		//this.results =  this.commons.getEntity(item.entityList).filter(item => item.includes($event.query));
		this.emitter.emit({ type: 'search', entity: item.entityList });
	}

	checkField( $type, $item, $data ){
		switch($type){					
		}
	}

	checkFieldError($item,$data){
		switch($item.field){
			default							: return true;
		}
	}

	async calendarChange(propagation:boolean=true)		{
		this.pageInfo.calendar.value.setHours(12);
		this.pageInfo.calendar.date	= this.pageInfo.calendar.value.toISOString().split('T')[0];
		if(this.pageInfo.calendar.date!==this.pageInfo.calendar.last){
			this.pageInfo.calendar.last = this.pageInfo.calendar.date;
			if(propagation){ this.emitter.emit({ action: 'reload', values: { date: this.pageInfo.calendar.date }}); }
		}
	}

// -----------------------------------------------------------------------------------
// SUPERFORM METHODS
// -----------------------------------------------------------------------------------

	superFormAction($event){
		console.log('SUPER FORM',$event);
		switch($event.type){
			case 'save'		:	this.emitter.emit({type : 'saveRow', item : $event.item}); break;
			case 'cancel'	:	this.emitter.emit({type : 'cancel', item : $event.item}); break;	
			default			:	return;
		}
	}

// -----------------------------------------------------------------------------------
// TABLE METHODS
// -----------------------------------------------------------------------------------

	generateMenuCols()								{ 
		this.pageInfo.selectedColumns = this.cols.filter(item => !item.disabled); 
	}

	onSort() 										{}

	getRendererType($col) 							{
		switch ($col.renderer) {
			case 'custom'			: return (type, col, items)	=> $col.rendererFn			(this, type, col, items);
			case 'phone'			: return (type, col, items)	=> this.phoneRenderer		(this, type, col, items);
			case 'check'			: return (type, col, items)	=> this.checkRenderer		(this, type, col, items);
			// case 'area_inner'   : return (type, col, items) => this.innerAreaRenderer	(this, type, col, items);
            // case 'company'    	: return (type, col, items) => this.companyRenderer		(this, type, col, items);
            // case 'treatment'    : return (type, col, items) => this.treatmentRenderer	(this, type, col, items);
            // case 'customer'     : return (type, col, items) => this.customerRenderer	(this, type, col, items, 'retail');
            // case 'customer_full': return (type, col, items) => this.customerRenderer	(this, type, col, items, 'full');
            // case 'price'        : return (type, col, items) => this.priceRenderer		(this, type, col, items);
            // case 'arrival'      : return (type, col, items) => this.arrivalRenderer		(this, type, col, items);
            // case 'departure'    : return (type, col, items) => this.departureRenderer	(this, type, col, items);
            // case 'pax'          : return (type, col, items) => this.paxRenderer			(this, type, col, items);
            // case 'flight'       : return (type, col, items) => this.flightRenderer		(this, type, col, items);
		}		
		return (type, col, items) => this.defaultRenderer(this, type, col, items);
    }

    getRenderer($type, $col, $items) 				{	return $col.renderer
															// ? $col.renderer($type, $col, $items)
															? this.getRendererType($col)($type, $col, $items)
															: this.defaultRenderer($type, $col, $items);
													}

	defaultRenderer($type, $col, $items, $me=this)	{	switch ($type) {
															case 'header'	: return $me.defaultStyleRenderer($col);
															case 'style'	: return $me.defaultStyleRenderer($col);
															// case 'content'	: return $col.i18n?this.commons.getTranslate($items[$col.field]):$items[$col.field];
															case 'content'	: return $items[$col.field];
														}
													}
													
    defaultStyleRenderer($col) 						{	return {
												            'width'			: $col.width ? $col.width : '',
												            'text-align'	: $col.align ? $col.align : ''
												        };
													}	

	// GENERIC RENDERERS			-----------------------------------------------------------------------------------

	checkRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return { ...this.defaultStyleRenderer($col), 'color': $items[$col.field]?'green':'crimson' };			
		}
	}											

	phoneRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return { ...this.defaultStyleRenderer($col), 'color': 'green' };
			case 'content'	: return $items[$col.field];
			case 'expander'	: return {
                'color'			: 'red',
				'font-weight'	: 700
            };			
		}
	}

	// END GENERIC RENDERERS		-----------------------------------------------------------------------------------

	cancelRow($item)								{ 	this.collapseRow($item);	}
	expandRow($item)								{ 	this.toggleDT($item); 		}
	collapseRow($item)								{ 	this.toggleDT($item);		}
	toggleRow($item)								{ 	if(this.options.rowExpander){ this.toggleDT($item); }}

	toggleDT($item)									{	if(undefined===$item){ return false; }
														if(undefined==this.dataTable.expandedRowKeys[$item.id]){
															this.dataTable.expandedRowKeys 			 	= [];
															this.dataTable.expandedRowKeys[$item.id] 	= 1;
															this.emitter.emit({ type: 'selectedRow', item: $item });
														} else {
															this.dataTable.expandedRowKeys 				= [];
															this.emitter.emit({ type: 'unselectedRow', item: $item });
														}
	}	
}
