export const filters	= [	
	{ 	
		name	: 'price_range',
		type	: 'dropdown_price',
		position: 'left',
		label	: '_TYPE',
		items	: [
			{ label: "_ITEM_1", 				value: "item1" },
			{ label: "_ITEM_2", 				value: "item2" }
		],
		selected: undefined
	},
	{ 	
		name	: 'price_range',
		type	: 'dropdown_price',
		position: 'left',
		label	: '_TYPE',
		items	: [
			{ label: "_ITEM_1", 				value: "item1" },
			{ label: "_ITEM_2", 				value: "item2" }
		],
		selected: undefined
	},
	{ 	
		name	: 'price_range',
		type	: 'dropdown_price',
		position: 'left',
		label	: '_TYPE',
		items	: [
			{ label: "_ITEM_1", 				value: "item1" },
			{ label: "_ITEM_2", 				value: "item2" }
		],
		selected: undefined
	},
	{ 	
		name	: 'price_range',
		type	: 'dropdown_price',
		position: 'left',
		label	: '_TYPE',
		items	: [
			{ label: "_ITEM_1", 				value: "item1" },
			{ label: "_ITEM_2", 				value: "item2" }
		],
		selected: undefined
	},
];