import { CommonsService } from './../commons.service';
import { HttpClient               	} from '@angular/common/http';
import { Injectable               	} from '@angular/core';
import { AngularFirestore,
         AngularFirestoreModule   	} from '@angular/fire/firestore';
import { AngularFireAuth          	} from '@angular/fire/auth';
import { AngularFireStorage 		} from '@angular/fire/storage';
import { first, take, map, mergeMap	} from 'rxjs/operators';
import { Observable, forkJoin 		} from 'rxjs';
import { combineLatest 				} from 'rxjs';

@Injectable()
export class CompanyService {

	info		: any 	= {};
	callbacks	: any 	= {};
	subscriptors: any 	= { bookings: {} };
	dmcRef		: any;
	dmcDestRef	: any;
	bookings	: any[]	= [];
	transfers	: any[]	= [];
	flights		: any[]	= [];
		
  	constructor(  
		private afs     	: AngularFirestore,
		private afAuth  	: AngularFireAuth,
		private commons 	: CommonsService,
		public 	afStorage	: AngularFireStorage
    ){}


	async createCompany(info){
		console.log('Creating Company', info);

		// Create at Firebase
		let added	=	await Promise.resolve(this.afs.collection('dmcs').add(info));
		await Promise.resolve(this.afs.collection('dmcs').doc(added.id).update({id : added.id, ref : (await added.get()).ref}));

		// Create at DB with Firebase reference
		
		console.log('Company created');
		let infoCompany	=	await added.get();
		return {ref : infoCompany.ref, ...infoCompany.data()}
	}

	async editCompany($info){
		let filteredInfo = Object.keys($info).reduce((o,field)=>{
			if(null!=$info[field]){
				o[field] = $info[field];
			}
			return o;
		},{});
		await Promise.resolve(this.afs.collection('dmcs').doc($info.id).update(filteredInfo));
		// TODO: Update company on DB using the info.id
	}

	async getCompaniesByReseller($resellerId){
		console.log("resellerId",$resellerId);
		let companies:any = [];
		if(null==$resellerId){ return companies; }

		companies	=	await Promise.resolve(this.afs.collection(	
							'dmcs', 
							(ref => ref.where('createdBy','==',$resellerId))		// Get only createds by reseller
						).ref.get());

		return companies.docs.filter(docs => docs.data().createdBy == $resellerId)
								.map(doc => {return { ...doc.data(), ref : doc.ref}});
	}

	async addDestination(dmcId, infoDestination){
		try{
			let infoAdded = await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('destinations').add(infoDestination));
			await Promise.resolve(infoAdded.update({id : infoAdded.id}));
			return { success : true, destinationId : infoAdded.id};
		}catch(e){
			console.log(e);
			return { success : false, error : e};
		}
	}

	async assignDestinationToNewUsers(dmcId,listUsers,destinationId){
		if(!listUsers || listUsers.length == 0){ return; }
		let staffPath	=	this.afs.collection('dmcs').doc(dmcId).collection('staff'); // code 999 is just temporally. Need to ask which profile it has the user created
		await Promise.all(listUsers.map(async userId => await staffPath.doc(userId).update({destination : [{profileId : 999, destinationId : destinationId}]})))
	}
	
	async persistProviders(refCompany,providers){
		try{
			await Promise.all(providers.map(async provider => 
				{
					if(!provider.id){
						let providerAdded = await Promise.resolve(this.afs.doc(refCompany).collection('providers').add(provider));
						await Promise.resolve(this.afs.doc(refCompany).collection('providers').doc(providerAdded.id).update({id : providerAdded.id, ref : (await providerAdded.get()).ref}))		
					}else{
						let existProvider	=	await Promise.resolve(this.afs.doc(refCompany).collection('providers').doc(provider.id).ref.get())
						if(existProvider.exists){
							await Promise.resolve(this.afs.doc(refCompany).collection('providers').doc(provider.id).update(provider));
						}else{ // FIX DUPLICATED CODE
							let providerAdded = await Promise.resolve(this.afs.doc(refCompany).collection('providers').add(provider));
							await Promise.resolve(this.afs.doc(refCompany).collection('providers').doc(providerAdded.id).update({id : providerAdded.id, ref : (await providerAdded.get()).ref}))		
						}
					}
				} 
			));
			return { success : true}
		}catch(e){
			console.error(e);
			return { success : false}
		}
	}
	
	async getDmc(id){
		let info	=	await Promise.resolve(this.afs.collection('dmcs').doc(id).ref.get());
		return info.data();
	}
	async getDestinationsFromDmc(id){
		let info	=	await Promise.resolve(this.afs.collection('dmcs').doc(id).collection('destinations').ref.get());
		if(info){
			return info.docs.map(doc => {return {ref : doc.ref, ...doc.data()}});
		}else{
			return [];
		}
	}
	async deleteDestination(refDestination){
		try{
			await Promise.resolve(this.afs.doc(refDestination).delete());
			return { success : true}
		}catch(e){
			return { success : false, error : e}
		}
	}
	async deleteDmc(refDmc){
		try{
			await Promise.resolve(this.afs.doc(refDmc).delete());
			return { success : true };
		}catch(e){
			return { success : false, error : e };
		}
	}
	async getStaffUser(idUser, idDmc){
		let user	=	 await Promise.resolve(this.afs.collection('dmcs').doc(idDmc).collection('staff').doc(idUser).ref.get());
		return { ...user.data(), ref : user.ref	};
	}

	/**
	 * Get all the modules
	 */
	async getModules(){
		const modules	=	 await Promise.resolve(this.afs.collection('modules').doc('modules').ref.get())
		return modules.data();
	}

	/**
	 * Return the modules hired by a dmc
	 * @param idDmc 
	 */
	async getModulesFromCompany(idDmc){
		const modules	=	await Promise.resolve(this.afs.collection('dmcs').doc(idDmc).ref.get());
		return modules.data().modules || []; 
	}

	subscribeDestination(dmcId, destinationId){		
		return this.afs.collection('dmcs')
						.doc(dmcId)
						.collection('destinations')
						.doc(destinationId)
						.snapshotChanges();	
	}
}