export let servicesCols = [
    {
        field		: 'transporter_status',
        header		: '_STATUS',
        width		: '100px',
        align       : 'center',
        disabled	: false,
        translate   : true
    },
    {
        field		: 'customer',
        header		: '_CUSTOMER',
        width		: '250px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'reference',
        header		: '_REFERENCE',
        width		: '150px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'pax',
        header		: 'Pax',
        width		: '50px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'name',
        header		: '_GROUP',
        width		: '150px',
        align       : 'center',
        disabled	: true
    },
    {
        field		: 'date',
        header		: '_DATE',
        width		: '100px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'time',
        header		: '_HOUR',
        width		: '80px',
        align       : 'center',
        disabled	: false
    },
    ,{
        field		: 'shared',
        header		: '_TYPE',
        width		: '80px',
        align       : 'center',
        disabled	: false
    },
    ,{
        field		: 'direction',
        header		: '_DIRECTION',
        width		: '80px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'location',
        header		: '_LOCATION',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    {
        field		: 'area',
        header		: '_AREA',
        width		: '200px',
        align       : 'center',
        disabled	: false
    },
    // {
    //     field		: 'zone',
    //     header		: '_ZONE',
    //     width		: '150px',
    //     align       : 'center',
    //     disabled	: false
    // }

    {
        type        : 'buttons',
        header      : "_ACTIONS",
        width       : '250px',
        align       : 'center',
        options     : [
            { name: "accept_service",	label: "_ACCEPT_SERVICE", 	color: 'forestgreen'    },
            { name: "reject_service",  	label: "_REJECT_SERVICE", 	color: 'crimson'        },
            // { name: "show_service",    	label: "_SHOW_SERVICE", 	color: 'gray'       	}
        ]
    },
];
