import { Component, Input} from '@angular/core';

@Component({
	selector    : 'app-spinner',
	styleUrls	: ['./app-spinner.component.scss'],
	templateUrl : 'app-spinner.component.html'  
})
export class AppSpinnerComponent {

	@Input()	title			:	any;
	@Input() 	spinnerHeight	:	any = "100px";
	pageInfo	:	any = {
		loadingImg	:	'assets/demo/images/general/loading.gif'
	}
	constructor(){}

}