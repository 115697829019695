import { HttpClient } from '@angular/common/http';
import { CommonsService } from './../../demo/service/commons.service';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-admin-translate',
  templateUrl: './app-admin-translate.component.html',  
  styleUrls   : ['./app-admin-translate.component.scss']
})
export class AppAdminTranslateComponent implements OnInit{

	pageInfo : any = {};

	constructor(	private commonsService 	:	CommonsService,
					private http			:	HttpClient	){}
	
	async ngOnInit(){
		this.pageInfo	=	{
			langsPath		:	'assets/i18n/',
			langs			:	['es','en'],
			itemsPerRow 	:	50,
			filterOptions	:	[{label:this.commonsService.getTranslate('_DISPLAY_ALL'),	value : 'displayAll'},
								{label:this.commonsService.getTranslate('_DISPLAY_EMPTY'),	value : 'displayNull'}],	
		}
		
		
		
		this.pageInfo.listLangs	=	await this.loadLangs();
		console.log('LIST LANGS', this.pageInfo.listLangs);
		this.initForms();
	}

	async loadLangs(){
		let langs : any = await Promise.all(this.pageInfo.langs.map(async lang =>{
			let loadLang	=	await this.http.get(this.pageInfo.langsPath+lang+'.json').toPromise();	
			let lengthLang	=	Object.keys(loadLang).length;	
			return {
				lang			:	lang,
				length			:	lengthLang,
				items			:	loadLang,
			}
		}))
		langs			=	langs.sort((a,b) => b.length - a.length ); // get the lang most completed
		let fullKeys	=	Object.keys((langs[0].items));	
		langs.forEach((lang, index) =>{
			lang.length 		= 	Object.keys(lang.items).length;
			if(index == 0){
				lang.paginateInfo	=	{first : 0, last : lang.length, pages : Math.ceil(lang.length/this.pageInfo.itemsPerRow)+1, currentPage : 1}
				return; 
			}
			
			let actualKeysLang	=	Object.keys(lang.items);
			let missingKeys		=	fullKeys.filter(key =>{ let foundItem = actualKeysLang.find(item => item == key);return !foundItem})
			missingKeys.forEach(key =>{	lang.items[key]	=	null;});
			
			lang.paginateInfo	=	{first : 0, last : lang.length, pages : Math.ceil(lang.length/this.pageInfo.itemsPerRow)+1, currentPage : 1}
		});

		return langs
	}

	initForms(){
		this.pageInfo.langsForm	= this.pageInfo.listLangs.map(lang =>{
			return {
				ready		: false,
				entities	: {},		
				info		: lang.items,
				value		: lang.lang,
				filterSelected	:	'displayAll',
				form		: 
					// { cols		: 3 },
					[
					{
						cols		:	12,
						// cols			:	12,
						showTitle	: 	true,
						title			:	lang.lang,
						icon			: 	"fa fa-flag",
						items			:	Object.keys(lang.items).slice(0,this.pageInfo.itemsPerRow).map(key =>{ return this.mountItemForm(key)})																		
					},
				],		
				buttons	: {
					items:	[
						{ name: 'save', 	label: '_SAVE',		action: 'save',  icon: 'fa fa-fw fa-save'},
						{ name: 'remove', 	label: '_DELETE',	action: 'delete',  icon: 'fa fa-fw fa-remove'},
					]
				}
			}
		})	
		console.log(this.pageInfo.langsForm);
	}

	mountItemForm(key){		return { 	mandatory 	:	false,
										field		:	key,
										label		:	key,
										editable	:	true,
										options		:	{
											translateLabel : false
										}
									}
	}

	getInfoLang(lang){
		return this.pageInfo.listLangs.find(item =>item.lang == lang);
	}
	paginate(lang, type){
		let currentLang			=	this.pageInfo.listLangs.find(item => item.lang == lang);
		let formLang			=	this.pageInfo.langsForm.find(item => item.value == lang);
		if(type == 'next'){
			currentLang.paginateInfo.first 			+= this.pageInfo.itemsPerRow;
			currentLang.paginateInfo.currentPage 	+= 1;
			formLang.form[0].items 	= 	Object.keys(currentLang.items).slice(currentLang.paginateInfo.first, currentLang.paginateInfo.first + this.pageInfo.itemsPerRow).map(key =>{ return this.mountItemForm(key)})
			
		}else{
			formLang.form[0].items 	= 	Object.keys(currentLang.items).slice(currentLang.paginateInfo.first - this.pageInfo.itemsPerRow, currentLang.paginateInfo.first).map(key =>{ return this.mountItemForm(key)})
			currentLang.paginateInfo.first 			-= this.pageInfo.itemsPerRow;
			currentLang.paginateInfo.currentPage 	-= 1;
		}
	}

	onFilterItems($event,form){
		console.log($event);
		console.log(form);
	}
}