
export const airports = [
    {
        label: 'Airports', 
        items: [
            {label: 'PMI Airport', value : {name : 'PMI Airport	', type : 'airport', id: 53, coords   :   {lat: 39.5521152815252, lng: 2.736645344962426}}},
        ]
    },
];

export const fullAreasMallorca = [
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,7 km",
          "value": 39674
        },
        "duration": {
          "text": "30 min",
          "value": 1776
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,3 km",
          "value": 46319
        },
        "duration": {
          "text": "40 min",
          "value": 2423
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "83,1 km",
          "value": 83094
        },
        "duration": {
          "text": "59 min",
          "value": 3551
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,2 km",
          "value": 31150
        },
        "duration": {
          "text": "34 min",
          "value": 2013
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,4 km",
          "value": 37440
        },
        "duration": {
          "text": "45 min",
          "value": 2727
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,8 km",
          "value": 69760
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4175
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,1 km",
          "value": 31140
        },
        "duration": {
          "text": "23 min",
          "value": 1376
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,4 km",
          "value": 17405
        },
        "duration": {
          "text": "15 min",
          "value": 928
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,6 km",
          "value": 51631
        },
        "duration": {
          "text": "40 min",
          "value": 2412
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,4 km",
          "value": 70405
        },
        "duration": {
          "text": "48 min",
          "value": 2888
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,9 km",
          "value": 16921
        },
        "duration": {
          "text": "16 min",
          "value": 949
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,8 km",
          "value": 66754
        },
        "duration": {
          "text": "56 min",
          "value": 3367
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,9 km",
          "value": 41941
        },
        "duration": {
          "text": "42 min",
          "value": 2540
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,0 km",
          "value": 35971
        },
        "duration": {
          "text": "27 min",
          "value": 1595
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,9 km",
          "value": 31918
        },
        "duration": {
          "text": "25 min",
          "value": 1527
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,6 km",
          "value": 70593
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3681
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,5 km",
          "value": 35492
        },
        "duration": {
          "text": "43 min",
          "value": 2568
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,8 km",
          "value": 58790
        },
        "duration": {
          "text": "45 min",
          "value": 2697
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,6 km",
          "value": 77609
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4559
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52112
        },
        "duration": {
          "text": "57 min",
          "value": 3433
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,6 km",
          "value": 72603
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3671
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,4 km",
          "value": 25421
        },
        "duration": {
          "text": "18 min",
          "value": 1103
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,0 km",
          "value": 39958
        },
        "duration": {
          "text": "34 min",
          "value": 2047
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,8 km",
          "value": 31774
        },
        "duration": {
          "text": "26 min",
          "value": 1570
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26762
        },
        "duration": {
          "text": "26 min",
          "value": 1566
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,0 km",
          "value": 76960
        },
        "duration": {
          "text": "54 min",
          "value": 3232
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,6 km",
          "value": 45585
        },
        "duration": {
          "text": "48 min",
          "value": 2895
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,4 km",
          "value": 31438
        },
        "duration": {
          "text": "36 min",
          "value": 2156
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,6 km",
          "value": 24620
        },
        "duration": {
          "text": "27 min",
          "value": 1600
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,7 km",
          "value": 43678
        },
        "duration": {
          "text": "30 min",
          "value": 1787
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46895
        },
        "duration": {
          "text": "36 min",
          "value": 2180
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,5 km",
          "value": 17486
        },
        "duration": {
          "text": "18 min",
          "value": 1086
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,4 km",
          "value": 52363
        },
        "duration": {
          "text": "35 min",
          "value": 2091
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,5 km",
          "value": 34514
        },
        "duration": {
          "text": "39 min",
          "value": 2316
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,3 km",
          "value": 11325
        },
        "duration": {
          "text": "13 min",
          "value": 772
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,4 km",
          "value": 55395
        },
        "duration": {
          "text": "46 min",
          "value": 2737
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,6 km",
          "value": 66616
        },
        "duration": {
          "text": "51 min",
          "value": 3066
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,4 km",
          "value": 13369
        },
        "duration": {
          "text": "13 min",
          "value": 761
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43327
        },
        "duration": {
          "text": "35 min",
          "value": 2121
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,6 km",
          "value": 43588
        },
        "duration": {
          "text": "46 min",
          "value": 2763
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,4 km",
          "value": 44425
        },
        "duration": {
          "text": "32 min",
          "value": 1913
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22296
        },
        "duration": {
          "text": "24 min",
          "value": 1462
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,9 km",
          "value": 38873
        },
        "duration": {
          "text": "30 min",
          "value": 1806
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,7 km",
          "value": 77733
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3869
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,5 km",
          "value": 23533
        },
        "duration": {
          "text": "26 min",
          "value": 1585
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,7 km",
          "value": 34680
        },
        "duration": {
          "text": "28 min",
          "value": 1683
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "76,7 km",
          "value": 76655
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3959
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,2 km",
          "value": 35200
        },
        "duration": {
          "text": "26 min",
          "value": 1569
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,4 km",
          "value": 62355
        },
        "duration": {
          "text": "47 min",
          "value": 2845
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,3 km",
          "value": 44336
        },
        "duration": {
          "text": "44 min",
          "value": 2639
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,0 km",
          "value": 58983
        },
        "duration": {
          "text": "45 min",
          "value": 2719
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,4 km",
          "value": 40368
        },
        "duration": {
          "text": "30 min",
          "value": 1793
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,9 km",
          "value": 27931
        },
        "duration": {
          "text": "30 min",
          "value": 1785
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,5 km",
          "value": 57548
        },
        "duration": {
          "text": "47 min",
          "value": 2811
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,9 km",
          "value": 40921
        },
        "duration": {
          "text": "37 min",
          "value": 2208
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,7 km",
          "value": 69684
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4032
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,2 km",
          "value": 44214
        },
        "duration": {
          "text": "57 min",
          "value": 3435
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,1 km",
          "value": 10082
        },
        "duration": {
          "text": "13 min",
          "value": 761
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,3 km",
          "value": 26307
        },
        "duration": {
          "text": "23 min",
          "value": 1382
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,3 km",
          "value": 15330
        },
        "duration": {
          "text": "27 min",
          "value": 1637
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,9 km",
          "value": 44859
        },
        "duration": {
          "text": "36 min",
          "value": 2148
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,4 km",
          "value": 31384
        },
        "duration": {
          "text": "27 min",
          "value": 1592
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "65,5 km",
          "value": 65531
        },
        "duration": {
          "text": "50 min",
          "value": 3001
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,2 km",
          "value": 74185
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3845
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,7 km",
          "value": 9655
        },
        "duration": {
          "text": "13 min",
          "value": 786
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26078
        },
        "duration": {
          "text": "24 min",
          "value": 1466
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,0 km",
          "value": 45047
        },
        "duration": {
          "text": "49 min",
          "value": 2941
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,5 km",
          "value": 30536
        },
        "duration": {
          "text": "50 min",
          "value": 2992
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,2 km",
          "value": 33244
        },
        "duration": {
          "text": "33 min",
          "value": 1957
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52063
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3819
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "76,8 km",
          "value": 76814
        },
        "duration": {
          "text": "59 min",
          "value": 3521
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,1 km",
          "value": 47057
        },
        "duration": {
          "text": "49 min",
          "value": 2931
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,0 km",
          "value": 20018
        },
        "duration": {
          "text": "18 min",
          "value": 1086
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31697
        },
        "duration": {
          "text": "32 min",
          "value": 1934
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,1 km",
          "value": 8123
        },
        "duration": {
          "text": "15 min",
          "value": 906
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29826
        },
        "duration": {
          "text": "28 min",
          "value": 1661
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,4 km",
          "value": 51413
        },
        "duration": {
          "text": "42 min",
          "value": 2492
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,4 km",
          "value": 55356
        },
        "duration": {
          "text": "52 min",
          "value": 3090
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,9 km",
          "value": 15898
        },
        "duration": {
          "text": "31 min",
          "value": 1841
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,4 km",
          "value": 37400
        },
        "duration": {
          "text": "35 min",
          "value": 2119
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,1 km",
          "value": 18132
        },
        "duration": {
          "text": "17 min",
          "value": 1047
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,1 km",
          "value": 41055
        },
        "duration": {
          "text": "35 min",
          "value": 2119
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,4 km",
          "value": 40438
        },
        "duration": {
          "text": "34 min",
          "value": 2060
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26816
        },
        "duration": {
          "text": "23 min",
          "value": 1351
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,3 km",
          "value": 44285
        },
        "duration": {
          "text": "42 min",
          "value": 2511
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,6 km",
          "value": 42561
        },
        "duration": {
          "text": "33 min",
          "value": 2000
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43792
        },
        "duration": {
          "text": "43 min",
          "value": 2554
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,1 km",
          "value": 41070
        },
        "duration": {
          "text": "39 min",
          "value": 2326
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,0 km",
          "value": 30989
        },
        "duration": {
          "text": "26 min",
          "value": 1544
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,5 km",
          "value": 37487
        },
        "duration": {
          "text": "34 min",
          "value": 2060
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,2 km",
          "value": 62151
        },
        "duration": {
          "text": "55 min",
          "value": 3274
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,9 km",
          "value": 18878
        },
        "duration": {
          "text": "20 min",
          "value": 1173
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,9 km",
          "value": 41856
        },
        "duration": {
          "text": "41 min",
          "value": 2433
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,4 km",
          "value": 8400
        },
        "duration": {
          "text": "14 min",
          "value": 836
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "76,9 km",
          "value": 76948
        },
        "duration": {
          "text": "59 min",
          "value": 3552
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,4 km",
          "value": 17380
        },
        "duration": {
          "text": "28 min",
          "value": 1708
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,7 km",
          "value": 18722
        },
        "duration": {
          "text": "24 min",
          "value": 1445
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,1 km",
          "value": 77055
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3638
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,4 km",
          "value": 29360
        },
        "duration": {
          "text": "25 min",
          "value": 1508
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,8 km",
          "value": 36809
        },
        "duration": {
          "text": "35 min",
          "value": 2105
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,9 km",
          "value": 67863
        },
        "duration": {
          "text": "59 min",
          "value": 3518
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,4 km",
          "value": 33437
        },
        "duration": {
          "text": "33 min",
          "value": 1979
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,1 km",
          "value": 45093
        },
        "duration": {
          "text": "40 min",
          "value": 2423
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,3 km",
          "value": 29332
        },
        "duration": {
          "text": "29 min",
          "value": 1758
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,8 km",
          "value": 58752
        },
        "duration": {
          "text": "47 min",
          "value": 2796
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,0 km",
          "value": 27965
        },
        "duration": {
          "text": "30 min",
          "value": 1777
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,0 km",
          "value": 50983
        },
        "duration": {
          "text": "50 min",
          "value": 2976
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,7 km",
          "value": 50748
        },
        "duration": {
          "text": "58 min",
          "value": 3463
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,4 km",
          "value": 19413
        },
        "duration": {
          "text": "23 min",
          "value": 1383
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,0 km",
          "value": 31031
        },
        "duration": {
          "text": "34 min",
          "value": 2012
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,2 km",
          "value": 31151
        },
        "duration": {
          "text": "34 min",
          "value": 2041
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,1 km",
          "value": 46063
        },
        "duration": {
          "text": "36 min",
          "value": 2133
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,1 km",
          "value": 36108
        },
        "duration": {
          "text": "37 min",
          "value": 2222
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,3 km",
          "value": 27258
        },
        "duration": {
          "text": "25 min",
          "value": 1499
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,0 km",
          "value": 52985
        },
        "duration": {
          "text": "43 min",
          "value": 2551
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,9 km",
          "value": 23942
        },
        "duration": {
          "text": "24 min",
          "value": 1451
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,2 km",
          "value": 13188
        },
        "duration": {
          "text": "17 min",
          "value": 1040
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,6 km",
          "value": 52614
        },
        "duration": {
          "text": "51 min",
          "value": 3044
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,5 km",
          "value": 41459
        },
        "duration": {
          "text": "57 min",
          "value": 3402
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,8 km",
          "value": 39778
        },
        "duration": {
          "text": "33 min",
          "value": 1985
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,6 km",
          "value": 58597
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3847
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,1 km",
          "value": 33052
        },
        "duration": {
          "text": "31 min",
          "value": 1865
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52123
        },
        "duration": {
          "text": "55 min",
          "value": 3300
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,0 km",
          "value": 23981
        },
        "duration": {
          "text": "27 min",
          "value": 1612
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,8 km",
          "value": 10824
        },
        "duration": {
          "text": "13 min",
          "value": 802
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,8 km",
          "value": 24837
        },
        "duration": {
          "text": "31 min",
          "value": 1864
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,2 km",
          "value": 26208
        },
        "duration": {
          "text": "27 min",
          "value": 1612
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,0 km",
          "value": 16976
        },
        "duration": {
          "text": "23 min",
          "value": 1393
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,5 km",
          "value": 32476
        },
        "duration": {
          "text": "30 min",
          "value": 1776
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,2 km",
          "value": 29197
        },
        "duration": {
          "text": "43 min",
          "value": 2577
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,4 km",
          "value": 27377
        },
        "duration": {
          "text": "28 min",
          "value": 1702
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,9 km",
          "value": 26891
        },
        "duration": {
          "text": "23 min",
          "value": 1378
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,8 km",
          "value": 8781
        },
        "duration": {
          "text": "7 min",
          "value": 426
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,8 km",
          "value": 37821
        },
        "duration": {
          "text": "40 min",
          "value": 2378
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,1 km",
          "value": 21126
        },
        "duration": {
          "text": "17 min",
          "value": 999
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,2 km",
          "value": 30182
        },
        "duration": {
          "text": "31 min",
          "value": 1830
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,3 km",
          "value": 47285
        },
        "duration": {
          "text": "44 min",
          "value": 2630
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,9 km",
          "value": 15899
        },
        "duration": {
          "text": "14 min",
          "value": 869
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,3 km",
          "value": 42273
        },
        "duration": {
          "text": "39 min",
          "value": 2311
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,7 km",
          "value": 35713
        },
        "duration": {
          "text": "36 min",
          "value": 2174
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,9 km",
          "value": 17905
        },
        "duration": {
          "text": "14 min",
          "value": 823
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,3 km",
          "value": 39271
        },
        "duration": {
          "text": "33 min",
          "value": 1960
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,5 km",
          "value": 11516
        },
        "duration": {
          "text": "12 min",
          "value": 709
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,1 km",
          "value": 33121
        },
        "duration": {
          "text": "36 min",
          "value": 2175
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,8 km",
          "value": 19838
        },
        "duration": {
          "text": "23 min",
          "value": 1371
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,2 km",
          "value": 38237
        },
        "duration": {
          "text": "33 min",
          "value": 2001
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,3 km",
          "value": 28303
        },
        "duration": {
          "text": "35 min",
          "value": 2118
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,7 km",
          "value": 12679
        },
        "duration": {
          "text": "16 min",
          "value": 936
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37159
        },
        "duration": {
          "text": "35 min",
          "value": 2091
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,8 km",
          "value": 16828
        },
        "duration": {
          "text": "19 min",
          "value": 1126
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,9 km",
          "value": 41875
        },
        "duration": {
          "text": "41 min",
          "value": 2474
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,0 km",
          "value": 44983
        },
        "duration": {
          "text": "37 min",
          "value": 2204
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,0 km",
          "value": 41004
        },
        "duration": {
          "text": "35 min",
          "value": 2082
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "83,5 km",
          "value": 83474
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3672
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,1 km",
          "value": 58114
        },
        "duration": {
          "text": "50 min",
          "value": 2985
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,4 km",
          "value": 56392
        },
        "duration": {
          "text": "49 min",
          "value": 2920
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "84,0 km",
          "value": 84027
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4088
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "113 km",
          "value": 112790
        },
        "duration": {
          "text": "1h 39 min",
          "value": 5912
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,6 km",
          "value": 30605
        },
        "duration": {
          "text": "58 min",
          "value": 3463
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,7 km",
          "value": 54656
        },
        "duration": {
          "text": "46 min",
          "value": 2759
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,4 km",
          "value": 69412
        },
        "duration": {
          "text": "54 min",
          "value": 3261
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,0 km",
          "value": 46036
        },
        "duration": {
          "text": "48 min",
          "value": 2904
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,4 km",
          "value": 19436
        },
        "duration": {
          "text": "26 min",
          "value": 1531
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,5 km",
          "value": 74489
        },
        "duration": {
          "text": "58 min",
          "value": 3471
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,3 km",
          "value": 74289
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3649
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "109 km",
          "value": 109085
        },
        "duration": {
          "text": "1h 30 min",
          "value": 5391
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,7 km",
          "value": 52724
        },
        "duration": {
          "text": "45 min",
          "value": 2678
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,4 km",
          "value": 61406
        },
        "duration": {
          "text": "55 min",
          "value": 3312
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,3 km",
          "value": 56325
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3590
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,7 km",
          "value": 80688
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4837
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,5 km",
          "value": 43489
        },
        "duration": {
          "text": "42 min",
          "value": 2531
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,3 km",
          "value": 20325
        },
        "duration": {
          "text": "33 min",
          "value": 1995
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,6 km",
          "value": 85572
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4169
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,0 km",
          "value": 63032
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3933
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63124
        },
        "duration": {
          "text": "49 min",
          "value": 2966
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,7 km",
          "value": 64707
        },
        "duration": {
          "text": "58 min",
          "value": 3450
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,1 km",
          "value": 60080
        },
        "duration": {
          "text": "54 min",
          "value": 3240
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,9 km",
          "value": 72932
        },
        "duration": {
          "text": "59 min",
          "value": 3541
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,2 km",
          "value": 60171
        },
        "duration": {
          "text": "52 min",
          "value": 3140
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "88,6 km",
          "value": 88576
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4616
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,4 km",
          "value": 68426
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4012
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,5 km",
          "value": 80506
        },
        "duration": {
          "text": "1h 7 min",
          "value": 3999
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,3 km",
          "value": 42273
        },
        "duration": {
          "text": "38 min",
          "value": 2307
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,9 km",
          "value": 64881
        },
        "duration": {
          "text": "54 min",
          "value": 3266
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "83,5 km",
          "value": 83544
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3940
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,7 km",
          "value": 38726
        },
        "duration": {
          "text": "36 min",
          "value": 2182
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "87,4 km",
          "value": 87391
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4391
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,7 km",
          "value": 85666
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3880
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,7 km",
          "value": 73691
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3696
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,8 km",
          "value": 42771
        },
        "duration": {
          "text": "45 min",
          "value": 2680
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,1 km",
          "value": 74094
        },
        "duration": {
          "text": "57 min",
          "value": 3423
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,0 km",
          "value": 74005
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3663
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "95,4 km",
          "value": 95371
        },
        "duration": {
          "text": "1h 20 min",
          "value": 4800
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,9 km",
          "value": 51888
        },
        "duration": {
          "text": "45 min",
          "value": 2689
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,0 km",
          "value": 84962
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4313
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,4 km",
          "value": 48384
        },
        "duration": {
          "text": "45 min",
          "value": 2672
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,7 km",
          "value": 85706
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4200
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,5 km",
          "value": 67532
        },
        "duration": {
          "text": "59 min",
          "value": 3553
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,9 km",
          "value": 56945
        },
        "duration": {
          "text": "50 min",
          "value": 2979
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,8 km",
          "value": 85813
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4286
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,5 km",
          "value": 72466
        },
        "duration": {
          "text": "56 min",
          "value": 3388
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,8 km",
          "value": 52784
        },
        "duration": {
          "text": "52 min",
          "value": 3107
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "101 km",
          "value": 101083
        },
        "duration": {
          "text": "1h 24 min",
          "value": 5044
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,7 km",
          "value": 44715
        },
        "duration": {
          "text": "44 min",
          "value": 2628
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,0 km",
          "value": 30038
        },
        "duration": {
          "text": "32 min",
          "value": 1929
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,6 km",
          "value": 39582
        },
        "duration": {
          "text": "36 min",
          "value": 2157
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,6 km",
          "value": 27555
        },
        "duration": {
          "text": "29 min",
          "value": 1768
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "83,0 km",
          "value": 83002
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3932
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,6 km",
          "value": 36649
        },
        "duration": {
          "text": "44 min",
          "value": 2620
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,7 km",
          "value": 69668
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4556
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,8 km",
          "value": 27824
        },
        "duration": {
          "text": "30 min",
          "value": 1789
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,9 km",
          "value": 24876
        },
        "duration": {
          "text": "31 min",
          "value": 1850
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,5 km",
          "value": 51539
        },
        "duration": {
          "text": "47 min",
          "value": 2793
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,3 km",
          "value": 70313
        },
        "duration": {
          "text": "54 min",
          "value": 3269
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,2 km",
          "value": 28172
        },
        "duration": {
          "text": "35 min",
          "value": 2077
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,9 km",
          "value": 36917
        },
        "duration": {
          "text": "41 min",
          "value": 2466
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,2 km",
          "value": 41150
        },
        "duration": {
          "text": "41 min",
          "value": 2433
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,9 km",
          "value": 35879
        },
        "duration": {
          "text": "33 min",
          "value": 1976
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,6 km",
          "value": 18607
        },
        "duration": {
          "text": "21 min",
          "value": 1259
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,5 km",
          "value": 70501
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4062
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,6 km",
          "value": 43581
        },
        "duration": {
          "text": "55 min",
          "value": 3329
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,7 km",
          "value": 58698
        },
        "duration": {
          "text": "51 min",
          "value": 3078
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,5 km",
          "value": 77517
        },
        "duration": {
          "text": "1h 22 min",
          "value": 4940
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,8 km",
          "value": 28847
        },
        "duration": {
          "text": "37 min",
          "value": 2217
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,5 km",
          "value": 72511
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4052
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,3 km",
          "value": 23294
        },
        "duration": {
          "text": "24 min",
          "value": 1416
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,2 km",
          "value": 17193
        },
        "duration": {
          "text": "22 min",
          "value": 1313
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31682
        },
        "duration": {
          "text": "33 min",
          "value": 1951
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,3 km",
          "value": 18269
        },
        "duration": {
          "text": "21 min",
          "value": 1258
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,9 km",
          "value": 47923
        },
        "duration": {
          "text": "51 min",
          "value": 3036
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22321
        },
        "duration": {
          "text": "28 min",
          "value": 1678
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,3 km",
          "value": 31318
        },
        "duration": {
          "text": "42 min",
          "value": 2504
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,2 km",
          "value": 7150
        },
        "duration": {
          "text": "12 min",
          "value": 705
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,6 km",
          "value": 43586
        },
        "duration": {
          "text": "36 min",
          "value": 2168
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,4 km",
          "value": 23414
        },
        "duration": {
          "text": "24 min",
          "value": 1415
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,7 km",
          "value": 20691
        },
        "duration": {
          "text": "23 min",
          "value": 1386
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,2 km",
          "value": 41207
        },
        "duration": {
          "text": "39 min",
          "value": 2324
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "6,0 km",
          "value": 6001
        },
        "duration": {
          "text": "14 min",
          "value": 827
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,3 km",
          "value": 36274
        },
        "duration": {
          "text": "39 min",
          "value": 2369
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,5 km",
          "value": 24466
        },
        "duration": {
          "text": "28 min",
          "value": 1705
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,5 km",
          "value": 66524
        },
        "duration": {
          "text": "57 min",
          "value": 3447
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,0 km",
          "value": 21978
        },
        "duration": {
          "text": "27 min",
          "value": 1634
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,8 km",
          "value": 14840
        },
        "duration": {
          "text": "21 min",
          "value": 1242
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,1 km",
          "value": 29116
        },
        "duration": {
          "text": "31 min",
          "value": 1862
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,4 km",
          "value": 28379
        },
        "duration": {
          "text": "28 min",
          "value": 1693
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,0 km",
          "value": 15991
        },
        "duration": {
          "text": "20 min",
          "value": 1183
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,8 km",
          "value": 38781
        },
        "duration": {
          "text": "36 min",
          "value": 2187
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,9 km",
          "value": 47896
        },
        "duration": {
          "text": "49 min",
          "value": 2968
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,4 km",
          "value": 30425
        },
        "duration": {
          "text": "34 min",
          "value": 2045
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,7 km",
          "value": 23718
        },
        "duration": {
          "text": "27 min",
          "value": 1624
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,8 km",
          "value": 46818
        },
        "duration": {
          "text": "51 min",
          "value": 3058
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,6 km",
          "value": 11613
        },
        "duration": {
          "text": "13 min",
          "value": 794
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,3 km",
          "value": 62263
        },
        "duration": {
          "text": "54 min",
          "value": 3226
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,8 km",
          "value": 34828
        },
        "duration": {
          "text": "35 min",
          "value": 2106
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,9 km",
          "value": 58891
        },
        "duration": {
          "text": "52 min",
          "value": 3100
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,6 km",
          "value": 41551
        },
        "duration": {
          "text": "51 min",
          "value": 3077
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,0 km",
          "value": 68004
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4385
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,9 km",
          "value": 50894
        },
        "duration": {
          "text": "50 min",
          "value": 2985
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "108 km",
          "value": 108161
        },
        "duration": {
          "text": "1h 35 min",
          "value": 5670
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,5 km",
          "value": 40526
        },
        "duration": {
          "text": "50 min",
          "value": 3022
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "100 km",
          "value": 100157
        },
        "duration": {
          "text": "1h 46 min",
          "value": 6337
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,8 km",
          "value": 58751
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4006
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,7 km",
          "value": 48702
        },
        "duration": {
          "text": "58 min",
          "value": 3487
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,0 km",
          "value": 79961
        },
        "duration": {
          "text": "1h 24 min",
          "value": 5021
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "95,5 km",
          "value": 95472
        },
        "duration": {
          "text": "1h 23 min",
          "value": 5007
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,1 km",
          "value": 49063
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3637
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,6 km",
          "value": 52564
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3678
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,7 km",
          "value": 11736
        },
        "duration": {
          "text": "20 min",
          "value": 1191
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,5 km",
          "value": 52477
        },
        "duration": {
          "text": "58 min",
          "value": 3507
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,8 km",
          "value": 70779
        },
        "duration": {
          "text": "1h 30 min",
          "value": 5412
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "108 km",
          "value": 108006
        },
        "duration": {
          "text": "1h 52 min",
          "value": 6721
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,2 km",
          "value": 41200
        },
        "duration": {
          "text": "53 min",
          "value": 3172
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,8 km",
          "value": 48824
        },
        "duration": {
          "text": "56 min",
          "value": 3339
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,4 km",
          "value": 59386
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4200
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,6 km",
          "value": 61620
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4174
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,9 km",
          "value": 60930
        },
        "duration": {
          "text": "1h 18 min",
          "value": 4657
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,0 km",
          "value": 72008
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4396
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,1 km",
          "value": 36077
        },
        "duration": {
          "text": "45 min",
          "value": 2672
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,5 km",
          "value": 50519
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3734
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "91,7 km",
          "value": 91682
        },
        "duration": {
          "text": "1h 26 min",
          "value": 5185
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,1 km",
          "value": 39124
        },
        "duration": {
          "text": "43 min",
          "value": 2558
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,9 km",
          "value": 60925
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3582
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,2 km",
          "value": 67203
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4415
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,6 km",
          "value": 57588
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3872
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,5 km",
          "value": 45483
        },
        "duration": {
          "text": "51 min",
          "value": 3042
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,1 km",
          "value": 14131
        },
        "duration": {
          "text": "22 min",
          "value": 1290
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,9 km",
          "value": 44888
        },
        "duration": {
          "text": "59 min",
          "value": 3543
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,9 km",
          "value": 29851
        },
        "duration": {
          "text": "56 min",
          "value": 3376
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41430
        },
        "duration": {
          "text": "55 min",
          "value": 3317
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,2 km",
          "value": 56187
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3818
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,3 km",
          "value": 61264
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4029
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "104 km",
          "value": 104065
        },
        "duration": {
          "text": "1h 45 min",
          "value": 6282
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,7 km",
          "value": 24741
        },
        "duration": {
          "text": "45 min",
          "value": 2684
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,3 km",
          "value": 10281
        },
        "duration": {
          "text": "26 min",
          "value": 1580
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,9 km",
          "value": 49898
        },
        "duration": {
          "text": "59 min",
          "value": 3523
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46854
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3798
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,8 km",
          "value": 80752
        },
        "duration": {
          "text": "1h 27 min",
          "value": 5245
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,9 km",
          "value": 29925
        },
        "duration": {
          "text": "47 min",
          "value": 2818
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30902
        },
        "duration": {
          "text": "47 min",
          "value": 2812
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "65,9 km",
          "value": 65867
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4325
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "87,5 km",
          "value": 87547
        },
        "duration": {
          "text": "1h 30 min",
          "value": 5429
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,3 km",
          "value": 29291
        },
        "duration": {
          "text": "48 min",
          "value": 2884
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,7 km",
          "value": 43719
        },
        "duration": {
          "text": "59 min",
          "value": 3537
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,0 km",
          "value": 33031
        },
        "duration": {
          "text": "50 min",
          "value": 2971
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,3 km",
          "value": 10279
        },
        "duration": {
          "text": "13 min",
          "value": 796
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,5 km",
          "value": 31488
        },
        "duration": {
          "text": "31 min",
          "value": 1835
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,6 km",
          "value": 17595
        },
        "duration": {
          "text": "16 min",
          "value": 965
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,7 km",
          "value": 22672
        },
        "duration": {
          "text": "20 min",
          "value": 1176
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,7 km",
          "value": 10663
        },
        "duration": {
          "text": "16 min",
          "value": 971
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,2 km",
          "value": 30165
        },
        "duration": {
          "text": "30 min",
          "value": 1785
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,9 km",
          "value": 9874
        },
        "duration": {
          "text": "11 min",
          "value": 641
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,3 km",
          "value": 48334
        },
        "duration": {
          "text": "39 min",
          "value": 2320
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,1 km",
          "value": 15053
        },
        "duration": {
          "text": "15 min",
          "value": 875
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,9 km",
          "value": 34852
        },
        "duration": {
          "text": "36 min",
          "value": 2138
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22277
        },
        "duration": {
          "text": "19 min",
          "value": 1128
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31712
        },
        "duration": {
          "text": "33 min",
          "value": 1988
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,7 km",
          "value": 8665
        },
        "duration": {
          "text": "15 min",
          "value": 877
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,4 km",
          "value": 30358
        },
        "duration": {
          "text": "30 min",
          "value": 1807
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,5 km",
          "value": 69525
        },
        "duration": {
          "text": "53 min",
          "value": 3150
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,1 km",
          "value": 38062
        },
        "duration": {
          "text": "34 min",
          "value": 2011
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,2 km",
          "value": 53185
        },
        "duration": {
          "text": "49 min",
          "value": 2966
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,7 km",
          "value": 23731
        },
        "duration": {
          "text": "42 min",
          "value": 2540
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,9 km",
          "value": 11852
        },
        "duration": {
          "text": "12 min",
          "value": 702
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,4 km",
          "value": 63391
        },
        "duration": {
          "text": "47 min",
          "value": 2831
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,3 km",
          "value": 33326
        },
        "duration": {
          "text": "30 min",
          "value": 1779
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,0 km",
          "value": 53047
        },
        "duration": {
          "text": "44 min",
          "value": 2665
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,7 km",
          "value": 20715
        },
        "duration": {
          "text": "29 min",
          "value": 1721
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63086
        },
        "duration": {
          "text": "59 min",
          "value": 3558
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52109
        },
        "duration": {
          "text": "40 min",
          "value": 2400
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,2 km",
          "value": 26165
        },
        "duration": {
          "text": "45 min",
          "value": 2685
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,1 km",
          "value": 43125
        },
        "duration": {
          "text": "37 min",
          "value": 2199
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,2 km",
          "value": 39232
        },
        "duration": {
          "text": "54 min",
          "value": 3254
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,3 km",
          "value": 33342
        },
        "duration": {
          "text": "36 min",
          "value": 2178
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,1 km",
          "value": 49141
        },
        "duration": {
          "text": "45 min",
          "value": 2726
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,3 km",
          "value": 54301
        },
        "duration": {
          "text": "43 min",
          "value": 2607
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,5 km",
          "value": 20523
        },
        "duration": {
          "text": "24 min",
          "value": 1417
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,1 km",
          "value": 41101
        },
        "duration": {
          "text": "35 min",
          "value": 2115
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43766
        },
        "duration": {
          "text": "36 min",
          "value": 2175
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,4 km",
          "value": 33410
        },
        "duration": {
          "text": "36 min",
          "value": 2159
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,8 km",
          "value": 48780
        },
        "duration": {
          "text": "43 min",
          "value": 2567
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,5 km",
          "value": 50498
        },
        "duration": {
          "text": "37 min",
          "value": 2221
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,9 km",
          "value": 67880
        },
        "duration": {
          "text": "54 min",
          "value": 3254
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,1 km",
          "value": 30145
        },
        "duration": {
          "text": "32 min",
          "value": 1935
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,1 km",
          "value": 73080
        },
        "duration": {
          "text": "58 min",
          "value": 3455
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,1 km",
          "value": 32089
        },
        "duration": {
          "text": "31 min",
          "value": 1883
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,0 km",
          "value": 22034
        },
        "duration": {
          "text": "19 min",
          "value": 1132
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,8 km",
          "value": 22811
        },
        "duration": {
          "text": "21 min",
          "value": 1283
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30851
        },
        "duration": {
          "text": "30 min",
          "value": 1803
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,8 km",
          "value": 37788
        },
        "duration": {
          "text": "32 min",
          "value": 1936
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,2 km",
          "value": 34220
        },
        "duration": {
          "text": "31 min",
          "value": 1877
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26093
        },
        "duration": {
          "text": "22 min",
          "value": 1325
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,4 km",
          "value": 38443
        },
        "duration": {
          "text": "43 min",
          "value": 2578
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,6 km",
          "value": 54624
        },
        "duration": {
          "text": "54 min",
          "value": 3265
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,1 km",
          "value": 44081
        },
        "duration": {
          "text": "43 min",
          "value": 2588
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,8 km",
          "value": 19796
        },
        "duration": {
          "text": "20 min",
          "value": 1212
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,6 km",
          "value": 37644
        },
        "duration": {
          "text": "36 min",
          "value": 2171
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46622
        },
        "duration": {
          "text": "49 min",
          "value": 2918
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,8 km",
          "value": 52760
        },
        "duration": {
          "text": "55 min",
          "value": 3302
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "91,2 km",
          "value": 91194
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4454
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,0 km",
          "value": 67013
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4194
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,1 km",
          "value": 41131
        },
        "duration": {
          "text": "36 min",
          "value": 2153
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,0 km",
          "value": 8030
        },
        "duration": {
          "text": "7 min",
          "value": 405
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22289
        },
        "duration": {
          "text": "25 min",
          "value": 1497
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,5 km",
          "value": 53500
        },
        "duration": {
          "text": "53 min",
          "value": 3206
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,7 km",
          "value": 35715
        },
        "duration": {
          "text": "31 min",
          "value": 1838
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,8 km",
          "value": 8761
        },
        "duration": {
          "text": "14 min",
          "value": 823
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,5 km",
          "value": 61525
        },
        "duration": {
          "text": "53 min",
          "value": 3177
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,0 km",
          "value": 49025
        },
        "duration": {
          "text": "55 min",
          "value": 3306
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,9 km",
          "value": 33863
        },
        "duration": {
          "text": "36 min",
          "value": 2170
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,7 km",
          "value": 40658
        },
        "duration": {
          "text": "39 min",
          "value": 2354
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,7 km",
          "value": 45727
        },
        "duration": {
          "text": "51 min",
          "value": 3057
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,3 km",
          "value": 40337
        },
        "duration": {
          "text": "46 min",
          "value": 2750
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "86,1 km",
          "value": 86075
        },
        "duration": {
          "text": "1h 24 min",
          "value": 5041
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,5 km",
          "value": 39501
        },
        "duration": {
          "text": "46 min",
          "value": 2761
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,8 km",
          "value": 80777
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4573
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,7 km",
          "value": 48717
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3627
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,2 km",
          "value": 40159
        },
        "duration": {
          "text": "51 min",
          "value": 3038
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,3 km",
          "value": 74310
        },
        "duration": {
          "text": "1h 22 min",
          "value": 4948
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,3 km",
          "value": 50294
        },
        "duration": {
          "text": "43 min",
          "value": 2582
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,8 km",
          "value": 69782
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3798
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,3 km",
          "value": 18321
        },
        "duration": {
          "text": "24 min",
          "value": 1437
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,3 km",
          "value": 49279
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3623
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,7 km",
          "value": 54703
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4104
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,5 km",
          "value": 46511
        },
        "duration": {
          "text": "59 min",
          "value": 3553
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,3 km",
          "value": 77312
        },
        "duration": {
          "text": "1h 19 min",
          "value": 4759
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,7 km",
          "value": 49721
        },
        "duration": {
          "text": "55 min",
          "value": 3276
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,1 km",
          "value": 39086
        },
        "duration": {
          "text": "45 min",
          "value": 2718
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,8 km",
          "value": 22780
        },
        "duration": {
          "text": "31 min",
          "value": 1883
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,2 km",
          "value": 35176
        },
        "duration": {
          "text": "58 min",
          "value": 3488
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,2 km",
          "value": 62154
        },
        "duration": {
          "text": "56 min",
          "value": 3378
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,1 km",
          "value": 50099
        },
        "duration": {
          "text": "49 min",
          "value": 2952
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,7 km",
          "value": 32678
        },
        "duration": {
          "text": "32 min",
          "value": 1942
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,8 km",
          "value": 46752
        },
        "duration": {
          "text": "53 min",
          "value": 3174
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,6 km",
          "value": 27575
        },
        "duration": {
          "text": "36 min",
          "value": 2187
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "6,9 km",
          "value": 6852
        },
        "duration": {
          "text": "12 min",
          "value": 699
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,3 km",
          "value": 35300
        },
        "duration": {
          "text": "37 min",
          "value": 2240
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,0 km",
          "value": 16038
        },
        "duration": {
          "text": "24 min",
          "value": 1454
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41365
        },
        "duration": {
          "text": "42 min",
          "value": 2510
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,9 km",
          "value": 25907
        },
        "duration": {
          "text": "30 min",
          "value": 1796
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,0 km",
          "value": 63014
        },
        "duration": {
          "text": "47 min",
          "value": 2792
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,1 km",
          "value": 77146
        },
        "duration": {
          "text": "58 min",
          "value": 3471
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "81,8 km",
          "value": 81795
        },
        "duration": {
          "text": "1h 27 min",
          "value": 5244
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,0 km",
          "value": 36018
        },
        "duration": {
          "text": "40 min",
          "value": 2389
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,0 km",
          "value": 53039
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4289
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,6 km",
          "value": 35641
        },
        "duration": {
          "text": "37 min",
          "value": 2205
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,7 km",
          "value": 51742
        },
        "duration": {
          "text": "46 min",
          "value": 2747
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,7 km",
          "value": 66727
        },
        "duration": {
          "text": "57 min",
          "value": 3401
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,7 km",
          "value": 12704
        },
        "duration": {
          "text": "12 min",
          "value": 740
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,8 km",
          "value": 7850
        },
        "duration": {
          "text": "11 min",
          "value": 680
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,1 km",
          "value": 59061
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3591
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,5 km",
          "value": 17536
        },
        "duration": {
          "text": "18 min",
          "value": 1093
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,8 km",
          "value": 30838
        },
        "duration": {
          "text": "33 min",
          "value": 2005
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,6 km",
          "value": 39589
        },
        "duration": {
          "text": "35 min",
          "value": 2082
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,0 km",
          "value": 51006
        },
        "duration": {
          "text": "44 min",
          "value": 2633
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,9 km",
          "value": 40906
        },
        "duration": {
          "text": "51 min",
          "value": 3069
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,0 km",
          "value": 38040
        },
        "duration": {
          "text": "41 min",
          "value": 2449
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,8 km",
          "value": 47810
        },
        "duration": {
          "text": "44 min",
          "value": 2644
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,8 km",
          "value": 22805
        },
        "duration": {
          "text": "18 min",
          "value": 1058
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,9 km",
          "value": 78905
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3898
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,8 km",
          "value": 14775
        },
        "duration": {
          "text": "21 min",
          "value": 1233
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,3 km",
          "value": 64301
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4204
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "102 km",
          "value": 102023
        },
        "duration": {
          "text": "1h 39 min",
          "value": 5918
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "89,2 km",
          "value": 89187
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4859
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "101 km",
          "value": 100933
        },
        "duration": {
          "text": "1h 45 min",
          "value": 6280
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,1 km",
          "value": 51063
        },
        "duration": {
          "text": "59 min",
          "value": 3561
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,5 km",
          "value": 40455
        },
        "duration": {
          "text": "49 min",
          "value": 2966
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26124
        },
        "duration": {
          "text": "37 min",
          "value": 2236
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,5 km",
          "value": 37477
        },
        "duration": {
          "text": "47 min",
          "value": 2847
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,4 km",
          "value": 43371
        },
        "duration": {
          "text": "44 min",
          "value": 2668
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,5 km",
          "value": 70535
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3873
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,3 km",
          "value": 36334
        },
        "duration": {
          "text": "47 min",
          "value": 2847
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,1 km",
          "value": 43064
        },
        "duration": {
          "text": "47 min",
          "value": 2824
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,5 km",
          "value": 42458
        },
        "duration": {
          "text": "52 min",
          "value": 3093
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,1 km",
          "value": 14062
        },
        "duration": {
          "text": "24 min",
          "value": 1457
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,3 km",
          "value": 27345
        },
        "duration": {
          "text": "35 min",
          "value": 2097
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,2 km",
          "value": 56167
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4114
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,6 km",
          "value": 57623
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4128
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62465
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4270
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "90,7 km",
          "value": 90685
        },
        "duration": {
          "text": "1h 31 min",
          "value": 5454
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "90,4 km",
          "value": 90413
        },
        "duration": {
          "text": "1h 23 min",
          "value": 4956
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,4 km",
          "value": 33373
        },
        "duration": {
          "text": "39 min",
          "value": 2352
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,3 km",
          "value": 26283
        },
        "duration": {
          "text": "46 min",
          "value": 2768
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,5 km",
          "value": 41496
        },
        "duration": {
          "text": "54 min",
          "value": 3216
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,5 km",
          "value": 66465
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4278
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,5 km",
          "value": 39498
        },
        "duration": {
          "text": "54 min",
          "value": 3236
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,2 km",
          "value": 48180
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3870
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,8 km",
          "value": 59807
        },
        "duration": {
          "text": "1h 26 min",
          "value": 5179
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,4 km",
          "value": 11410
        },
        "duration": {
          "text": "28 min",
          "value": 1650
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,7 km",
          "value": 77748
        },
        "duration": {
          "text": "1h 20 min",
          "value": 4798
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43279
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3797
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,5 km",
          "value": 51481
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4008
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,7 km",
          "value": 59706
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4098
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,3 km",
          "value": 52347
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3769
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,2 km",
          "value": 55200
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4570
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,3 km",
          "value": 67280
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4556
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,1 km",
          "value": 57057
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3895
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,3 km",
          "value": 70318
        },
        "duration": {
          "text": "1h 15 min",
          "value": 4497
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,2 km",
          "value": 74165
        },
        "duration": {
          "text": "1h 22 min",
          "value": 4948
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,4 km",
          "value": 72440
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4437
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,6 km",
          "value": 18636
        },
        "duration": {
          "text": "46 min",
          "value": 2736
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,9 km",
          "value": 60869
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3981
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,1 km",
          "value": 61131
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4437
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,7 km",
          "value": 38662
        },
        "duration": {
          "text": "54 min",
          "value": 3247
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "71,7 km",
          "value": 71736
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4870
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,9 km",
          "value": 77882
        },
        "duration": {
          "text": "1h 20 min",
          "value": 4829
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,3 km",
          "value": 54306
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4111
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,0 km",
          "value": 77989
        },
        "duration": {
          "text": "1h 22 min",
          "value": 4915
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,2 km",
          "value": 59240
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3945
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "93,3 km",
          "value": 93259
        },
        "duration": {
          "text": "1h 35 min",
          "value": 5673
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,6 km",
          "value": 17631
        },
        "duration": {
          "text": "37 min",
          "value": 2225
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "71,1 km",
          "value": 71081
        },
        "duration": {
          "text": "1h 15 min",
          "value": 4527
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,0 km",
          "value": 23006
        },
        "duration": {
          "text": "25 min",
          "value": 1500
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,8 km",
          "value": 41780
        },
        "duration": {
          "text": "33 min",
          "value": 1976
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62452
        },
        "duration": {
          "text": "47 min",
          "value": 2829
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,0 km",
          "value": 59004
        },
        "duration": {
          "text": "58 min",
          "value": 3451
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "6,6 km",
          "value": 6577
        },
        "duration": {
          "text": "10 min",
          "value": 615
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,0 km",
          "value": 41968
        },
        "duration": {
          "text": "46 min",
          "value": 2769
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,4 km",
          "value": 27352
        },
        "duration": {
          "text": "41 min",
          "value": 2431
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,0 km",
          "value": 48984
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3647
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,4 km",
          "value": 49407
        },
        "duration": {
          "text": "52 min",
          "value": 3114
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,0 km",
          "value": 43978
        },
        "duration": {
          "text": "46 min",
          "value": 2759
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,1 km",
          "value": 17063
        },
        "duration": {
          "text": "25 min",
          "value": 1489
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "6,5 km",
          "value": 6503
        },
        "duration": {
          "text": "10 min",
          "value": 587
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,7 km",
          "value": 19682
        },
        "duration": {
          "text": "20 min",
          "value": 1216
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,9 km",
          "value": 45923
        },
        "duration": {
          "text": "45 min",
          "value": 2717
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,7 km",
          "value": 12740
        },
        "duration": {
          "text": "23 min",
          "value": 1378
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,0 km",
          "value": 27967
        },
        "duration": {
          "text": "29 min",
          "value": 1746
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,6 km",
          "value": 31622
        },
        "duration": {
          "text": "29 min",
          "value": 1746
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31727
        },
        "duration": {
          "text": "27 min",
          "value": 1644
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,7 km",
          "value": 23737
        },
        "duration": {
          "text": "20 min",
          "value": 1179
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,8 km",
          "value": 33849
        },
        "duration": {
          "text": "26 min",
          "value": 1584
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,3 km",
          "value": 34302
        },
        "duration": {
          "text": "37 min",
          "value": 2235
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,0 km",
          "value": 37991
        },
        "duration": {
          "text": "36 min",
          "value": 2154
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,1 km",
          "value": 28054
        },
        "duration": {
          "text": "28 min",
          "value": 1687
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,7 km",
          "value": 52718
        },
        "duration": {
          "text": "48 min",
          "value": 2901
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,9 km",
          "value": 10903
        },
        "duration": {
          "text": "17 min",
          "value": 1000
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,5 km",
          "value": 9479
        },
        "duration": {
          "text": "14 min",
          "value": 826
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,9 km",
          "value": 73869
        },
        "duration": {
          "text": "56 min",
          "value": 3380
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,2 km",
          "value": 14196
        },
        "duration": {
          "text": "19 min",
          "value": 1147
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,0 km",
          "value": 73976
        },
        "duration": {
          "text": "58 min",
          "value": 3466
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,9 km",
          "value": 19927
        },
        "duration": {
          "text": "19 min",
          "value": 1135
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,7 km",
          "value": 33730
        },
        "duration": {
          "text": "32 min",
          "value": 1933
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,4 km",
          "value": 58430
        },
        "duration": {
          "text": "52 min",
          "value": 3145
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,4 km",
          "value": 38377
        },
        "duration": {
          "text": "38 min",
          "value": 2271
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,8 km",
          "value": 56836
        },
        "duration": {
          "text": "41 min",
          "value": 2487
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,3 km",
          "value": 8317
        },
        "duration": {
          "text": "12 min",
          "value": 707
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,0 km",
          "value": 48024
        },
        "duration": {
          "text": "52 min",
          "value": 3110
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,4 km",
          "value": 22402
        },
        "duration": {
          "text": "20 min",
          "value": 1194
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,3 km",
          "value": 18349
        },
        "duration": {
          "text": "19 min",
          "value": 1126
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,0 km",
          "value": 57024
        },
        "duration": {
          "text": "55 min",
          "value": 3280
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,2 km",
          "value": 45221
        },
        "duration": {
          "text": "38 min",
          "value": 2296
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,0 km",
          "value": 64040
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4158
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,1 km",
          "value": 51111
        },
        "duration": {
          "text": "52 min",
          "value": 3147
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,0 km",
          "value": 59034
        },
        "duration": {
          "text": "55 min",
          "value": 3270
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,4 km",
          "value": 26389
        },
        "duration": {
          "text": "27 min",
          "value": 1646
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,2 km",
          "value": 18205
        },
        "duration": {
          "text": "19 min",
          "value": 1169
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,7 km",
          "value": 12690
        },
        "duration": {
          "text": "20 min",
          "value": 1217
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,6 km",
          "value": 47627
        },
        "duration": {
          "text": "46 min",
          "value": 2750
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,9 km",
          "value": 17869
        },
        "duration": {
          "text": "29 min",
          "value": 1755
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,9 km",
          "value": 19867
        },
        "duration": {
          "text": "28 min",
          "value": 1662
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,1 km",
          "value": 30109
        },
        "duration": {
          "text": "23 min",
          "value": 1386
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,1 km",
          "value": 14064
        },
        "duration": {
          "text": "20 min",
          "value": 1188
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,8 km",
          "value": 38794
        },
        "duration": {
          "text": "28 min",
          "value": 1690
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,6 km",
          "value": 36556
        },
        "duration": {
          "text": "36 min",
          "value": 2171
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,5 km",
          "value": 19494
        },
        "duration": {
          "text": "19 min",
          "value": 1115
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,8 km",
          "value": 41826
        },
        "duration": {
          "text": "39 min",
          "value": 2336
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "6,3 km",
          "value": 6251
        },
        "duration": {
          "text": "11 min",
          "value": 686
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29758
        },
        "duration": {
          "text": "29 min",
          "value": 1720
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,4 km",
          "value": 54422
        },
        "duration": {
          "text": "49 min",
          "value": 2934
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30856
        },
        "duration": {
          "text": "25 min",
          "value": 1511
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,3 km",
          "value": 25304
        },
        "duration": {
          "text": "23 min",
          "value": 1405
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,2 km",
          "value": 64164
        },
        "duration": {
          "text": "58 min",
          "value": 3468
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,9 km",
          "value": 9896
        },
        "duration": {
          "text": "19 min",
          "value": 1150
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,1 km",
          "value": 21111
        },
        "duration": {
          "text": "21 min",
          "value": 1282
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,6 km",
          "value": 21631
        },
        "duration": {
          "text": "19 min",
          "value": 1168
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,8 km",
          "value": 48786
        },
        "duration": {
          "text": "41 min",
          "value": 2444
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,4 km",
          "value": 50419
        },
        "duration": {
          "text": "53 min",
          "value": 3209
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,4 km",
          "value": 45414
        },
        "duration": {
          "text": "39 min",
          "value": 2318
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,1 km",
          "value": 40081
        },
        "duration": {
          "text": "38 min",
          "value": 2304
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,3 km",
          "value": 33276
        },
        "duration": {
          "text": "34 min",
          "value": 2032
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,3 km",
          "value": 59276
        },
        "duration": {
          "text": "50 min",
          "value": 2975
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,9 km",
          "value": 85926
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4452
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,4 km",
          "value": 21359
        },
        "duration": {
          "text": "23 min",
          "value": 1406
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,0 km",
          "value": 30041
        },
        "duration": {
          "text": "34 min",
          "value": 2040
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,4 km",
          "value": 21409
        },
        "duration": {
          "text": "34 min",
          "value": 2047
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,2 km",
          "value": 15195
        },
        "duration": {
          "text": "20 min",
          "value": 1207
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,0 km",
          "value": 34014
        },
        "duration": {
          "text": "51 min",
          "value": 3069
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,6 km",
          "value": 70559
        },
        "duration": {
          "text": "58 min",
          "value": 3495
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,7 km",
          "value": 22704
        },
        "duration": {
          "text": "31 min",
          "value": 1872
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,8 km",
          "value": 31759
        },
        "duration": {
          "text": "28 min",
          "value": 1693
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,7 km",
          "value": 28715
        },
        "duration": {
          "text": "33 min",
          "value": 1968
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,6 km",
          "value": 41567
        },
        "duration": {
          "text": "38 min",
          "value": 2268
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,2 km",
          "value": 45158
        },
        "duration": {
          "text": "41 min",
          "value": 2466
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,1 km",
          "value": 67097
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3697
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,1 km",
          "value": 37061
        },
        "duration": {
          "text": "46 min",
          "value": 2740
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,8 km",
          "value": 11786
        },
        "duration": {
          "text": "16 min",
          "value": 988
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,3 km",
          "value": 38319
        },
        "duration": {
          "text": "39 min",
          "value": 2355
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,2 km",
          "value": 52179
        },
        "duration": {
          "text": "44 min",
          "value": 2667
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,6 km",
          "value": 20561
        },
        "duration": {
          "text": "22 min",
          "value": 1325
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,0 km",
          "value": 56026
        },
        "duration": {
          "text": "52 min",
          "value": 3118
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,4 km",
          "value": 45437
        },
        "duration": {
          "text": "47 min",
          "value": 2798
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,3 km",
          "value": 28263
        },
        "duration": {
          "text": "36 min",
          "value": 2177
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,7 km",
          "value": 42730
        },
        "duration": {
          "text": "36 min",
          "value": 2151
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,0 km",
          "value": 42992
        },
        "duration": {
          "text": "43 min",
          "value": 2607
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,9 km",
          "value": 73892
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3881
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,6 km",
          "value": 53597
        },
        "duration": {
          "text": "51 min",
          "value": 3040
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,2 km",
          "value": 11152
        },
        "duration": {
          "text": "18 min",
          "value": 1053
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,7 km",
          "value": 70693
        },
        "duration": {
          "text": "59 min",
          "value": 3526
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,2 km",
          "value": 36167
        },
        "duration": {
          "text": "38 min",
          "value": 2281
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,6 km",
          "value": 25580
        },
        "duration": {
          "text": "28 min",
          "value": 1707
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,8 km",
          "value": 70800
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3612
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,5 km",
          "value": 12456
        },
        "duration": {
          "text": "17 min",
          "value": 1046
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,6 km",
          "value": 79604
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4125
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,4 km",
          "value": 15388
        },
        "duration": {
          "text": "20 min",
          "value": 1229
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,3 km",
          "value": 52343
        },
        "duration": {
          "text": "50 min",
          "value": 2987
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,9 km",
          "value": 61863
        },
        "duration": {
          "text": "45 min",
          "value": 2726
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,7 km",
          "value": 61663
        },
        "duration": {
          "text": "48 min",
          "value": 2904
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "96,5 km",
          "value": 96459
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4646
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,1 km",
          "value": 40098
        },
        "duration": {
          "text": "32 min",
          "value": 1933
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,7 km",
          "value": 43699
        },
        "duration": {
          "text": "47 min",
          "value": 2845
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,1 km",
          "value": 68062
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4092
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30863
        },
        "duration": {
          "text": "30 min",
          "value": 1786
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,6 km",
          "value": 32630
        },
        "duration": {
          "text": "41 min",
          "value": 2471
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,9 km",
          "value": 72946
        },
        "duration": {
          "text": "57 min",
          "value": 3424
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,4 km",
          "value": 50406
        },
        "duration": {
          "text": "53 min",
          "value": 3188
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52081
        },
        "duration": {
          "text": "45 min",
          "value": 2705
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,5 km",
          "value": 47454
        },
        "duration": {
          "text": "42 min",
          "value": 2495
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,3 km",
          "value": 60306
        },
        "duration": {
          "text": "47 min",
          "value": 2796
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,5 km",
          "value": 47545
        },
        "duration": {
          "text": "40 min",
          "value": 2395
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "76,0 km",
          "value": 75950
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3871
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,8 km",
          "value": 55800
        },
        "duration": {
          "text": "54 min",
          "value": 3267
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,6 km",
          "value": 29647
        },
        "duration": {
          "text": "26 min",
          "value": 1562
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,3 km",
          "value": 52255
        },
        "duration": {
          "text": "42 min",
          "value": 2521
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,9 km",
          "value": 70918
        },
        "duration": {
          "text": "53 min",
          "value": 3195
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26100
        },
        "duration": {
          "text": "24 min",
          "value": 1437
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,8 km",
          "value": 74765
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3646
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,0 km",
          "value": 73040
        },
        "duration": {
          "text": "52 min",
          "value": 3135
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,1 km",
          "value": 61065
        },
        "duration": {
          "text": "49 min",
          "value": 2951
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,5 km",
          "value": 61468
        },
        "duration": {
          "text": "45 min",
          "value": 2678
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,4 km",
          "value": 61379
        },
        "duration": {
          "text": "49 min",
          "value": 2918
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "82,7 km",
          "value": 82745
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4055
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,3 km",
          "value": 39262
        },
        "duration": {
          "text": "32 min",
          "value": 1944
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,3 km",
          "value": 72336
        },
        "duration": {
          "text": "59 min",
          "value": 3568
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,8 km",
          "value": 35758
        },
        "duration": {
          "text": "32 min",
          "value": 1927
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,9 km",
          "value": 54906
        },
        "duration": {
          "text": "47 min",
          "value": 2808
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,3 km",
          "value": 44319
        },
        "duration": {
          "text": "37 min",
          "value": 2234
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,2 km",
          "value": 73187
        },
        "duration": {
          "text": "59 min",
          "value": 3541
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,8 km",
          "value": 59840
        },
        "duration": {
          "text": "44 min",
          "value": 2643
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,2 km",
          "value": 40158
        },
        "duration": {
          "text": "39 min",
          "value": 2362
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "88,5 km",
          "value": 88457
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4299
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,3 km",
          "value": 66279
        },
        "duration": {
          "text": "53 min",
          "value": 3153
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,6 km",
          "value": 57647
        },
        "duration": {
          "text": "52 min",
          "value": 3123
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,5 km",
          "value": 49476
        },
        "duration": {
          "text": "52 min",
          "value": 3093
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,9 km",
          "value": 26864
        },
        "duration": {
          "text": "23 min",
          "value": 1351
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,5 km",
          "value": 61487
        },
        "duration": {
          "text": "57 min",
          "value": 3437
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22330
        },
        "duration": {
          "text": "39 min",
          "value": 2313
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,7 km",
          "value": 49684
        },
        "duration": {
          "text": "41 min",
          "value": 2453
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,5 km",
          "value": 68503
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4315
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,6 km",
          "value": 55573
        },
        "duration": {
          "text": "55 min",
          "value": 3304
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,5 km",
          "value": 63497
        },
        "duration": {
          "text": "57 min",
          "value": 3427
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,3 km",
          "value": 16314
        },
        "duration": {
          "text": "14 min",
          "value": 859
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,7 km",
          "value": 22668
        },
        "duration": {
          "text": "22 min",
          "value": 1326
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,6 km",
          "value": 17647
        },
        "duration": {
          "text": "22 min",
          "value": 1303
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,9 km",
          "value": 67853
        },
        "duration": {
          "text": "50 min",
          "value": 2988
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43256
        },
        "duration": {
          "text": "49 min",
          "value": 2967
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,9 km",
          "value": 14933
        },
        "duration": {
          "text": "29 min",
          "value": 1730
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,3 km",
          "value": 22290
        },
        "duration": {
          "text": "28 min",
          "value": 1672
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,6 km",
          "value": 34572
        },
        "duration": {
          "text": "26 min",
          "value": 1543
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,5 km",
          "value": 15516
        },
        "duration": {
          "text": "20 min",
          "value": 1171
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43256
        },
        "duration": {
          "text": "31 min",
          "value": 1847
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,2 km",
          "value": 32185
        },
        "duration": {
          "text": "40 min",
          "value": 2388
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,4 km",
          "value": 19438
        },
        "duration": {
          "text": "20 min",
          "value": 1205
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,3 km",
          "value": 46288
        },
        "duration": {
          "text": "42 min",
          "value": 2493
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,5 km",
          "value": 57509
        },
        "duration": {
          "text": "47 min",
          "value": 2823
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,7 km",
          "value": 7703
        },
        "duration": {
          "text": "11 min",
          "value": 669
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,1 km",
          "value": 50051
        },
        "duration": {
          "text": "53 min",
          "value": 3151
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,3 km",
          "value": 35318
        },
        "duration": {
          "text": "28 min",
          "value": 1669
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,1 km",
          "value": 23138
        },
        "duration": {
          "text": "29 min",
          "value": 1731
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29766
        },
        "duration": {
          "text": "26 min",
          "value": 1562
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,6 km",
          "value": 68626
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3625
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,5 km",
          "value": 8495
        },
        "duration": {
          "text": "15 min",
          "value": 923
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,6 km",
          "value": 25574
        },
        "duration": {
          "text": "24 min",
          "value": 1439
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,5 km",
          "value": 67548
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3715
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,2 km",
          "value": 53249
        },
        "duration": {
          "text": "43 min",
          "value": 2601
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,9 km",
          "value": 51871
        },
        "duration": {
          "text": "53 min",
          "value": 3192
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,9 km",
          "value": 49877
        },
        "duration": {
          "text": "41 min",
          "value": 2475
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,7 km",
          "value": 35711
        },
        "duration": {
          "text": "42 min",
          "value": 2521
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,2 km",
          "value": 60199
        },
        "duration": {
          "text": "46 min",
          "value": 2785
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,7 km",
          "value": 38684
        },
        "duration": {
          "text": "40 min",
          "value": 2383
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,1 km",
          "value": 68062
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3837
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,4 km",
          "value": 64369
        },
        "duration": {
          "text": "1h 15 min",
          "value": 4502
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,2 km",
          "value": 55226
        },
        "duration": {
          "text": "46 min",
          "value": 2778
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,0 km",
          "value": 74045
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4640
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,6 km",
          "value": 17551
        },
        "duration": {
          "text": "19 min",
          "value": 1145
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,7 km",
          "value": 73674
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4108
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,3 km",
          "value": 29329
        },
        "duration": {
          "text": "35 min",
          "value": 2092
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,5 km",
          "value": 52470
        },
        "duration": {
          "text": "52 min",
          "value": 3124
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,2 km",
          "value": 41223
        },
        "duration": {
          "text": "42 min",
          "value": 2491
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,7 km",
          "value": 21661
        },
        "duration": {
          "text": "27 min",
          "value": 1611
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,1 km",
          "value": 32052
        },
        "duration": {
          "text": "35 min",
          "value": 2084
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,1 km",
          "value": 52106
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3677
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,9 km",
          "value": 38937
        },
        "duration": {
          "text": "42 min",
          "value": 2509
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,7 km",
          "value": 49748
        },
        "duration": {
          "text": "40 min",
          "value": 2414
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,5 km",
          "value": 52478
        },
        "duration": {
          "text": "53 min",
          "value": 3190
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,8 km",
          "value": 39835
        },
        "duration": {
          "text": "35 min",
          "value": 2091
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,8 km",
          "value": 34756
        },
        "duration": {
          "text": "41 min",
          "value": 2462
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,7 km",
          "value": 68681
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3570
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,5 km",
          "value": 13458
        },
        "duration": {
          "text": "15 min",
          "value": 901
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,7 km",
          "value": 57721
        },
        "duration": {
          "text": "52 min",
          "value": 3104
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,1 km",
          "value": 57109
        },
        "duration": {
          "text": "52 min",
          "value": 3114
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26805
        },
        "duration": {
          "text": "28 min",
          "value": 1651
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,9 km",
          "value": 40910
        },
        "duration": {
          "text": "45 min",
          "value": 2674
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,8 km",
          "value": 47778
        },
        "duration": {
          "text": "50 min",
          "value": 2987
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,9 km",
          "value": 55859
        },
        "duration": {
          "text": "46 min",
          "value": 2779
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,0 km",
          "value": 16000
        },
        "duration": {
          "text": "15 min",
          "value": 907
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,2 km",
          "value": 51213
        },
        "duration": {
          "text": "54 min",
          "value": 3218
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,6 km",
          "value": 35574
        },
        "duration": {
          "text": "39 min",
          "value": 2369
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,8 km",
          "value": 10837
        },
        "duration": {
          "text": "14 min",
          "value": 815
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32401
        },
        "duration": {
          "text": "33 min",
          "value": 1963
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,4 km",
          "value": 63426
        },
        "duration": {
          "text": "55 min",
          "value": 3282
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,5 km",
          "value": 56452
        },
        "duration": {
          "text": "48 min",
          "value": 2875
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,5 km",
          "value": 24485
        },
        "duration": {
          "text": "27 min",
          "value": 1638
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "71,6 km",
          "value": 71574
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3666
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,3 km",
          "value": 54302
        },
        "duration": {
          "text": "49 min",
          "value": 2949
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "104 km",
          "value": 104030
        },
        "duration": {
          "text": "1h 32 min",
          "value": 5513
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,3 km",
          "value": 79276
        },
        "duration": {
          "text": "1h 24 min",
          "value": 5019
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "110 km",
          "value": 110013
        },
        "duration": {
          "text": "1h 45 min",
          "value": 6316
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,2 km",
          "value": 43207
        },
        "duration": {
          "text": "46 min",
          "value": 2767
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "108 km",
          "value": 108206
        },
        "duration": {
          "text": "1h 36 min",
          "value": 5742
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,9 km",
          "value": 52882
        },
        "duration": {
          "text": "53 min",
          "value": 3154
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,3 km",
          "value": 51317
        },
        "duration": {
          "text": "50 min",
          "value": 3001
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,4 km",
          "value": 67377
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3641
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,5 km",
          "value": 44513
        },
        "duration": {
          "text": "46 min",
          "value": 2781
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,6 km",
          "value": 63627
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3769
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29813
        },
        "duration": {
          "text": "30 min",
          "value": 1794
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,8 km",
          "value": 42846
        },
        "duration": {
          "text": "40 min",
          "value": 2396
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,3 km",
          "value": 79281
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3858
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,4 km",
          "value": 45378
        },
        "duration": {
          "text": "38 min",
          "value": 2263
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,1 km",
          "value": 40135
        },
        "duration": {
          "text": "41 min",
          "value": 2487
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,5 km",
          "value": 72542
        },
        "duration": {
          "text": "58 min",
          "value": 3468
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,7 km",
          "value": 38665
        },
        "duration": {
          "text": "39 min",
          "value": 2349
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,6 km",
          "value": 54577
        },
        "duration": {
          "text": "59 min",
          "value": 3549
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,1 km",
          "value": 45071
        },
        "duration": {
          "text": "40 min",
          "value": 2419
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "93,7 km",
          "value": 93689
        },
        "duration": {
          "text": "1h 20 min",
          "value": 4780
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,5 km",
          "value": 46516
        },
        "duration": {
          "text": "48 min",
          "value": 2908
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,5 km",
          "value": 15534
        },
        "duration": {
          "text": "15 min",
          "value": 883
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,9 km",
          "value": 62932
        },
        "duration": {
          "text": "53 min",
          "value": 3177
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,4 km",
          "value": 31403
        },
        "duration": {
          "text": "32 min",
          "value": 1912
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,5 km",
          "value": 74476
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3877
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,9 km",
          "value": 53925
        },
        "duration": {
          "text": "56 min",
          "value": 3370
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,1 km",
          "value": 66120
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3735
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,4 km",
          "value": 59413
        },
        "duration": {
          "text": "55 min",
          "value": 3314
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,5 km",
          "value": 64472
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3865
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,3 km",
          "value": 47308
        },
        "duration": {
          "text": "41 min",
          "value": 2484
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "98,0 km",
          "value": 97958
        },
        "duration": {
          "text": "1h 22 min",
          "value": 4916
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "92,4 km",
          "value": 92420
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4551
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,4 km",
          "value": 35380
        },
        "duration": {
          "text": "32 min",
          "value": 1947
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,7 km",
          "value": 20738
        },
        "duration": {
          "text": "20 min",
          "value": 1185
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,3 km",
          "value": 41251
        },
        "duration": {
          "text": "46 min",
          "value": 2766
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,2 km",
          "value": 32242
        },
        "duration": {
          "text": "45 min",
          "value": 2677
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,4 km",
          "value": 29448
        },
        "duration": {
          "text": "30 min",
          "value": 1782
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,3 km",
          "value": 48267
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3644
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43261
        },
        "duration": {
          "text": "46 min",
          "value": 2756
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,7 km",
          "value": 14678
        },
        "duration": {
          "text": "13 min",
          "value": 805
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,2 km",
          "value": 19197
        },
        "duration": {
          "text": "27 min",
          "value": 1616
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,5 km",
          "value": 11528
        },
        "duration": {
          "text": "17 min",
          "value": 1049
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,5 km",
          "value": 24486
        },
        "duration": {
          "text": "23 min",
          "value": 1380
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,6 km",
          "value": 47617
        },
        "duration": {
          "text": "39 min",
          "value": 2317
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,0 km",
          "value": 50016
        },
        "duration": {
          "text": "47 min",
          "value": 2809
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,8 km",
          "value": 17765
        },
        "duration": {
          "text": "31 min",
          "value": 1840
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,1 km",
          "value": 32060
        },
        "duration": {
          "text": "31 min",
          "value": 1838
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,3 km",
          "value": 14336
        },
        "duration": {
          "text": "15 min",
          "value": 872
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,1 km",
          "value": 35098
        },
        "duration": {
          "text": "30 min",
          "value": 1779
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,0 km",
          "value": 23020
        },
        "duration": {
          "text": "20 min",
          "value": 1176
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,9 km",
          "value": 38945
        },
        "duration": {
          "text": "37 min",
          "value": 2230
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37221
        },
        "duration": {
          "text": "29 min",
          "value": 1719
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,7 km",
          "value": 33719
        },
        "duration": {
          "text": "39 min",
          "value": 2359
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,3 km",
          "value": 37274
        },
        "duration": {
          "text": "36 min",
          "value": 2151
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,6 km",
          "value": 25649
        },
        "duration": {
          "text": "21 min",
          "value": 1263
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,1 km",
          "value": 32147
        },
        "duration": {
          "text": "30 min",
          "value": 1779
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,8 km",
          "value": 56811
        },
        "duration": {
          "text": "50 min",
          "value": 2993
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,1 km",
          "value": 15082
        },
        "duration": {
          "text": "17 min",
          "value": 998
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,5 km",
          "value": 36516
        },
        "duration": {
          "text": "36 min",
          "value": 2152
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,2 km",
          "value": 73152
        },
        "duration": {
          "text": "56 min",
          "value": 3377
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,1 km",
          "value": 19086
        },
        "duration": {
          "text": "23 min",
          "value": 1393
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,0 km",
          "value": 9966
        },
        "duration": {
          "text": "17 min",
          "value": 1031
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,3 km",
          "value": 73259
        },
        "duration": {
          "text": "58 min",
          "value": 3463
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,0 km",
          "value": 24020
        },
        "duration": {
          "text": "20 min",
          "value": 1227
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,0 km",
          "value": 33013
        },
        "duration": {
          "text": "32 min",
          "value": 1930
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62523
        },
        "duration": {
          "text": "54 min",
          "value": 3237
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,6 km",
          "value": 29641
        },
        "duration": {
          "text": "30 min",
          "value": 1804
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,5 km",
          "value": 42470
        },
        "duration": {
          "text": "39 min",
          "value": 2363
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,6 km",
          "value": 29638
        },
        "duration": {
          "text": "44 min",
          "value": 2647
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37222
        },
        "duration": {
          "text": "39 min",
          "value": 2322
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,0 km",
          "value": 56041
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4184
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,3 km",
          "value": 37347
        },
        "duration": {
          "text": "43 min",
          "value": 2567
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,0 km",
          "value": 51035
        },
        "duration": {
          "text": "55 min",
          "value": 3296
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,4 km",
          "value": 11358
        },
        "duration": {
          "text": "13 min",
          "value": 769
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,3 km",
          "value": 7342
        },
        "duration": {
          "text": "13 min",
          "value": 788
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,5 km",
          "value": 16484
        },
        "duration": {
          "text": "22 min",
          "value": 1292
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,6 km",
          "value": 12560
        },
        "duration": {
          "text": "17 min",
          "value": 999
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,7 km",
          "value": 29684
        },
        "duration": {
          "text": "40 min",
          "value": 2391
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,4 km",
          "value": 17375
        },
        "duration": {
          "text": "30 min",
          "value": 1822
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,9 km",
          "value": 15907
        },
        "duration": {
          "text": "20 min",
          "value": 1199
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,1 km",
          "value": 22110
        },
        "duration": {
          "text": "24 min",
          "value": 1412
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,6 km",
          "value": 19562
        },
        "duration": {
          "text": "20 min",
          "value": 1199
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,8 km",
          "value": 25835
        },
        "duration": {
          "text": "30 min",
          "value": 1781
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26824
        },
        "duration": {
          "text": "27 min",
          "value": 1601
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,8 km",
          "value": 22792
        },
        "duration": {
          "text": "27 min",
          "value": 1591
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,0 km",
          "value": 33950
        },
        "duration": {
          "text": "29 min",
          "value": 1715
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,6 km",
          "value": 24564
        },
        "duration": {
          "text": "30 min",
          "value": 1777
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,0 km",
          "value": 45047
        },
        "duration": {
          "text": "45 min",
          "value": 2692
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,4 km",
          "value": 22378
        },
        "duration": {
          "text": "21 min",
          "value": 1259
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,0 km",
          "value": 15994
        },
        "duration": {
          "text": "19 min",
          "value": 1140
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,0 km",
          "value": 13995
        },
        "duration": {
          "text": "16 min",
          "value": 970
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,2 km",
          "value": 24160
        },
        "duration": {
          "text": "27 min",
          "value": 1637
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,8 km",
          "value": 18750
        },
        "duration": {
          "text": "23 min",
          "value": 1367
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46902
        },
        "duration": {
          "text": "48 min",
          "value": 2909
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,5 km",
          "value": 16482
        },
        "duration": {
          "text": "23 min",
          "value": 1363
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "5,7 km",
          "value": 5660
        },
        "duration": {
          "text": "10 min",
          "value": 574
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,8 km",
          "value": 45824
        },
        "duration": {
          "text": "50 min",
          "value": 2999
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,9 km",
          "value": 7867
        },
        "duration": {
          "text": "10 min",
          "value": 588
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,8 km",
          "value": 40787
        },
        "duration": {
          "text": "41 min",
          "value": 2470
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,4 km",
          "value": 46370
        },
        "duration": {
          "text": "43 min",
          "value": 2598
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,4 km",
          "value": 37415
        },
        "duration": {
          "text": "39 min",
          "value": 2344
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,3 km",
          "value": 26317
        },
        "duration": {
          "text": "29 min",
          "value": 1724
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,4 km",
          "value": 36427
        },
        "duration": {
          "text": "55 min",
          "value": 3274
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,5 km",
          "value": 23509
        },
        "duration": {
          "text": "31 min",
          "value": 1857
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,6 km",
          "value": 32565
        },
        "duration": {
          "text": "50 min",
          "value": 3008
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,7 km",
          "value": 79669
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4388
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,9 km",
          "value": 19899
        },
        "duration": {
          "text": "32 min",
          "value": 1892
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,7 km",
          "value": 50737
        },
        "duration": {
          "text": "51 min",
          "value": 3037
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,3 km",
          "value": 52320
        },
        "duration": {
          "text": "59 min",
          "value": 3522
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,7 km",
          "value": 47693
        },
        "duration": {
          "text": "55 min",
          "value": 3312
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,5 km",
          "value": 60545
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3612
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,3 km",
          "value": 54268
        },
        "duration": {
          "text": "56 min",
          "value": 3359
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,0 km",
          "value": 56039
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4084
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,1 km",
          "value": 68119
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4070
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,8 km",
          "value": 30764
        },
        "duration": {
          "text": "39 min",
          "value": 2332
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,0 km",
          "value": 58978
        },
        "duration": {
          "text": "58 min",
          "value": 3485
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "71,2 km",
          "value": 71157
        },
        "duration": {
          "text": "1h 7 min",
          "value": 4011
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,8 km",
          "value": 32823
        },
        "duration": {
          "text": "40 min",
          "value": 2402
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "75,0 km",
          "value": 75004
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4462
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,3 km",
          "value": 73279
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3951
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,8 km",
          "value": 67788
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3915
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,9 km",
          "value": 35871
        },
        "duration": {
          "text": "49 min",
          "value": 2931
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,7 km",
          "value": 61708
        },
        "duration": {
          "text": "58 min",
          "value": 3495
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,0 km",
          "value": 61970
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3951
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "92,9 km",
          "value": 92870
        },
        "duration": {
          "text": "1h 27 min",
          "value": 5225
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,6 km",
          "value": 72575
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4384
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,1 km",
          "value": 30130
        },
        "duration": {
          "text": "40 min",
          "value": 2398
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,8 km",
          "value": 79803
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4419
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,1 km",
          "value": 55145
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3625
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,6 km",
          "value": 44558
        },
        "duration": {
          "text": "51 min",
          "value": 3051
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,9 km",
          "value": 79910
        },
        "duration": {
          "text": "1h 15 min",
          "value": 4505
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,1 km",
          "value": 60079
        },
        "duration": {
          "text": "58 min",
          "value": 3459
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,7 km",
          "value": 9652
        },
        "duration": {
          "text": "18 min",
          "value": 1067
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "98,6 km",
          "value": 98582
        },
        "duration": {
          "text": "1h 31 min",
          "value": 5469
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,7 km",
          "value": 11704
        },
        "duration": {
          "text": "20 min",
          "value": 1186
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,0 km",
          "value": 73002
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4117
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,5 km",
          "value": 67536
        },
        "duration": {
          "text": "1h 31 min",
          "value": 5489
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,3 km",
          "value": 62335
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4537
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,5 km",
          "value": 16528
        },
        "duration": {
          "text": "23 min",
          "value": 1390
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,0 km",
          "value": 21968
        },
        "duration": {
          "text": "33 min",
          "value": 1953
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,6 km",
          "value": 37612
        },
        "duration": {
          "text": "51 min",
          "value": 3036
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,9 km",
          "value": 21935
        },
        "duration": {
          "text": "36 min",
          "value": 2153
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,8 km",
          "value": 31775
        },
        "duration": {
          "text": "42 min",
          "value": 2528
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,6 km",
          "value": 74643
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4254
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,9 km",
          "value": 58851
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4140
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,6 km",
          "value": 18647
        },
        "duration": {
          "text": "35 min",
          "value": 2106
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,9 km",
          "value": 40895
        },
        "duration": {
          "text": "53 min",
          "value": 3169
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41361
        },
        "duration": {
          "text": "47 min",
          "value": 2809
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,6 km",
          "value": 44550
        },
        "duration": {
          "text": "53 min",
          "value": 3169
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,0 km",
          "value": 50046
        },
        "duration": {
          "text": "52 min",
          "value": 3113
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,8 km",
          "value": 47780
        },
        "duration": {
          "text": "59 min",
          "value": 3561
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,0 km",
          "value": 26035
        },
        "duration": {
          "text": "33 min",
          "value": 1980
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,0 km",
          "value": 53050
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3726
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,3 km",
          "value": 64299
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4088
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,9 km",
          "value": 27860
        },
        "duration": {
          "text": "42 min",
          "value": 2503
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,0 km",
          "value": 40982
        },
        "duration": {
          "text": "52 min",
          "value": 3110
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "65,6 km",
          "value": 65646
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4324
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,1 km",
          "value": 42108
        },
        "duration": {
          "text": "49 min",
          "value": 2934
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43805
        },
        "duration": {
          "text": "55 min",
          "value": 3300
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,6 km",
          "value": 36556
        },
        "duration": {
          "text": "47 min",
          "value": 2828
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "75,4 km",
          "value": 75388
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4858
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,0 km",
          "value": 14008
        },
        "duration": {
          "text": "23 min",
          "value": 1379
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,6 km",
          "value": 29631
        },
        "duration": {
          "text": "43 min",
          "value": 2553
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,9 km",
          "value": 32855
        },
        "duration": {
          "text": "43 min",
          "value": 2558
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26797
        },
        "duration": {
          "text": "37 min",
          "value": 2196
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "71,4 km",
          "value": 71358
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4568
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,9 km",
          "value": 48910
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3649
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,3 km",
          "value": 51305
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3694
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,3 km",
          "value": 19259
        },
        "duration": {
          "text": "33 min",
          "value": 1979
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,8 km",
          "value": 66778
        },
        "duration": {
          "text": "56 min",
          "value": 3351
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,3 km",
          "value": 32309
        },
        "duration": {
          "text": "39 min",
          "value": 2350
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,9 km",
          "value": 38928
        },
        "duration": {
          "text": "35 min",
          "value": 2076
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,5 km",
          "value": 40511
        },
        "duration": {
          "text": "43 min",
          "value": 2561
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,9 km",
          "value": 35884
        },
        "duration": {
          "text": "39 min",
          "value": 2351
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,7 km",
          "value": 48736
        },
        "duration": {
          "text": "44 min",
          "value": 2651
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41377
        },
        "duration": {
          "text": "39 min",
          "value": 2322
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,2 km",
          "value": 44230
        },
        "duration": {
          "text": "52 min",
          "value": 3123
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,3 km",
          "value": 56310
        },
        "duration": {
          "text": "52 min",
          "value": 3109
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,0 km",
          "value": 18955
        },
        "duration": {
          "text": "23 min",
          "value": 1371
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,1 km",
          "value": 46087
        },
        "duration": {
          "text": "41 min",
          "value": 2448
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,3 km",
          "value": 59348
        },
        "duration": {
          "text": "51 min",
          "value": 3050
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,9 km",
          "value": 19932
        },
        "duration": {
          "text": "23 min",
          "value": 1365
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,2 km",
          "value": 63195
        },
        "duration": {
          "text": "58 min",
          "value": 3501
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,5 km",
          "value": 61470
        },
        "duration": {
          "text": "50 min",
          "value": 2990
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,9 km",
          "value": 54897
        },
        "duration": {
          "text": "48 min",
          "value": 2878
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,3 km",
          "value": 16279
        },
        "duration": {
          "text": "25 min",
          "value": 1480
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,9 km",
          "value": 49899
        },
        "duration": {
          "text": "42 min",
          "value": 2534
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,2 km",
          "value": 50161
        },
        "duration": {
          "text": "50 min",
          "value": 2990
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "76,6 km",
          "value": 76577
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3982
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,7 km",
          "value": 27692
        },
        "duration": {
          "text": "30 min",
          "value": 1800
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,8 km",
          "value": 60766
        },
        "duration": {
          "text": "57 min",
          "value": 3423
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,9 km",
          "value": 66912
        },
        "duration": {
          "text": "56 min",
          "value": 3382
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,3 km",
          "value": 43336
        },
        "duration": {
          "text": "44 min",
          "value": 2664
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,7 km",
          "value": 32749
        },
        "duration": {
          "text": "35 min",
          "value": 2090
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,0 km",
          "value": 67019
        },
        "duration": {
          "text": "58 min",
          "value": 3468
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,3 km",
          "value": 48270
        },
        "duration": {
          "text": "42 min",
          "value": 2498
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,1 km",
          "value": 22061
        },
        "duration": {
          "text": "25 min",
          "value": 1524
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "82,3 km",
          "value": 82289
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4226
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,0 km",
          "value": 11963
        },
        "duration": {
          "text": "16 min",
          "value": 930
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,1 km",
          "value": 60111
        },
        "duration": {
          "text": "51 min",
          "value": 3080
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,6 km",
          "value": 85597
        },
        "duration": {
          "text": "1h 25 min",
          "value": 5104
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,1 km",
          "value": 51128
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4103
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,7 km",
          "value": 57747
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3829
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,3 km",
          "value": 59330
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4314
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,6 km",
          "value": 67555
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4404
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,2 km",
          "value": 60196
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4075
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "88,6 km",
          "value": 88601
        },
        "duration": {
          "text": "1h 33 min",
          "value": 5551
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,0 km",
          "value": 63049
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4876
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "75,1 km",
          "value": 75129
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4862
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,8 km",
          "value": 37774
        },
        "duration": {
          "text": "52 min",
          "value": 3124
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,9 km",
          "value": 64906
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4201
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,2 km",
          "value": 78167
        },
        "duration": {
          "text": "1h 20 min",
          "value": 4803
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,8 km",
          "value": 38751
        },
        "duration": {
          "text": "52 min",
          "value": 3118
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "82,0 km",
          "value": 82014
        },
        "duration": {
          "text": "1h 28 min",
          "value": 5254
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "80,3 km",
          "value": 80289
        },
        "duration": {
          "text": "1h 19 min",
          "value": 4743
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,7 km",
          "value": 73716
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4631
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,5 km",
          "value": 26485
        },
        "duration": {
          "text": "51 min",
          "value": 3042
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,7 km",
          "value": 68718
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4287
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,0 km",
          "value": 68980
        },
        "duration": {
          "text": "1h 19 min",
          "value": 4743
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "95,4 km",
          "value": 95396
        },
        "duration": {
          "text": "1h 36 min",
          "value": 5735
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,6 km",
          "value": 79585
        },
        "duration": {
          "text": "1h 26 min",
          "value": 5176
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,1 km",
          "value": 37140
        },
        "duration": {
          "text": "53 min",
          "value": 3190
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,7 km",
          "value": 85731
        },
        "duration": {
          "text": "1h 26 min",
          "value": 5135
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,2 km",
          "value": 62155
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4417
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,6 km",
          "value": 51568
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3843
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,8 km",
          "value": 85838
        },
        "duration": {
          "text": "1h 27 min",
          "value": 5221
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,1 km",
          "value": 67089
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4251
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,9 km",
          "value": 40880
        },
        "duration": {
          "text": "55 min",
          "value": 3277
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "101 km",
          "value": 101108
        },
        "duration": {
          "text": "1h 40 min",
          "value": 5979
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,5 km",
          "value": 25480
        },
        "duration": {
          "text": "42 min",
          "value": 2531
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,9 km",
          "value": 78930
        },
        "duration": {
          "text": "1h 21 min",
          "value": 4833
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "84,8 km",
          "value": 84790
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4611
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,3 km",
          "value": 41332
        },
        "duration": {
          "text": "46 min",
          "value": 2740
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,0 km",
          "value": 30968
        },
        "duration": {
          "text": "39 min",
          "value": 2329
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,5 km",
          "value": 38474
        },
        "duration": {
          "text": "44 min",
          "value": 2643
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,8 km",
          "value": 32777
        },
        "duration": {
          "text": "35 min",
          "value": 2114
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,3 km",
          "value": 19264
        },
        "duration": {
          "text": "24 min",
          "value": 1466
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,4 km",
          "value": 49357
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3829
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,2 km",
          "value": 30245
        },
        "duration": {
          "text": "37 min",
          "value": 2240
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,9 km",
          "value": 60864
        },
        "duration": {
          "text": "49 min",
          "value": 2917
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,3 km",
          "value": 23343
        },
        "duration": {
          "text": "27 min",
          "value": 1603
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43786
        },
        "duration": {
          "text": "49 min",
          "value": 2921
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,0 km",
          "value": 50951
        },
        "duration": {
          "text": "43 min",
          "value": 2594
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26064
        },
        "duration": {
          "text": "37 min",
          "value": 2193
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "65,9 km",
          "value": 65932
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3722
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,0 km",
          "value": 15979
        },
        "duration": {
          "text": "19 min",
          "value": 1167
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,8 km",
          "value": 68837
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3607
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,1 km",
          "value": 45073
        },
        "duration": {
          "text": "53 min",
          "value": 3169
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,8 km",
          "value": 20782
        },
        "duration": {
          "text": "25 min",
          "value": 1481
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,1 km",
          "value": 28122
        },
        "duration": {
          "text": "34 min",
          "value": 2056
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,6 km",
          "value": 42583
        },
        "duration": {
          "text": "42 min",
          "value": 2505
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,9 km",
          "value": 50905
        },
        "duration": {
          "text": "53 min",
          "value": 3167
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,1 km",
          "value": 16053
        },
        "duration": {
          "text": "18 min",
          "value": 1101
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,5 km",
          "value": 48464
        },
        "duration": {
          "text": "56 min",
          "value": 3370
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,6 km",
          "value": 39582
        },
        "duration": {
          "text": "44 min",
          "value": 2649
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,4 km",
          "value": 27396
        },
        "duration": {
          "text": "29 min",
          "value": 1732
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,7 km",
          "value": 29652
        },
        "duration": {
          "text": "35 min",
          "value": 2115
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,5 km",
          "value": 74542
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3785
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,8 km",
          "value": 33834
        },
        "duration": {
          "text": "38 min",
          "value": 2300
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,6 km",
          "value": 67568
        },
        "duration": {
          "text": "56 min",
          "value": 3378
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,4 km",
          "value": 14412
        },
        "duration": {
          "text": "20 min",
          "value": 1221
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,8 km",
          "value": 52812
        },
        "duration": {
          "text": "49 min",
          "value": 2956
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,4 km",
          "value": 54395
        },
        "duration": {
          "text": "57 min",
          "value": 3441
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,8 km",
          "value": 49768
        },
        "duration": {
          "text": "54 min",
          "value": 3231
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,6 km",
          "value": 62620
        },
        "duration": {
          "text": "59 min",
          "value": 3531
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,8 km",
          "value": 59779
        },
        "duration": {
          "text": "59 min",
          "value": 3558
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "88,2 km",
          "value": 88150
        },
        "duration": {
          "text": "1h 23 min",
          "value": 4960
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,2 km",
          "value": 70194
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3989
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,8 km",
          "value": 32839
        },
        "duration": {
          "text": "38 min",
          "value": 2251
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,4 km",
          "value": 59372
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3618
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,2 km",
          "value": 73232
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3930
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,6 km",
          "value": 41614
        },
        "duration": {
          "text": "43 min",
          "value": 2588
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,1 km",
          "value": 77079
        },
        "duration": {
          "text": "1h 13 min",
          "value": 4381
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,6 km",
          "value": 42563
        },
        "duration": {
          "text": "56 min",
          "value": 3361
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,3 km",
          "value": 73299
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4114
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,3 km",
          "value": 45257
        },
        "duration": {
          "text": "53 min",
          "value": 3208
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,8 km",
          "value": 63783
        },
        "duration": {
          "text": "57 min",
          "value": 3414
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,0 km",
          "value": 64045
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3870
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "94,9 km",
          "value": 94945
        },
        "duration": {
          "text": "1h 26 min",
          "value": 5144
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,6 km",
          "value": 41576
        },
        "duration": {
          "text": "45 min",
          "value": 2680
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,6 km",
          "value": 74650
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4303
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,2 km",
          "value": 32205
        },
        "duration": {
          "text": "39 min",
          "value": 2317
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,3 km",
          "value": 85314
        },
        "duration": {
          "text": "1h 17 min",
          "value": 4618
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,5 km",
          "value": 30537
        },
        "duration": {
          "text": "46 min",
          "value": 2761
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46633
        },
        "duration": {
          "text": "50 min",
          "value": 2970
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "85,4 km",
          "value": 85421
        },
        "duration": {
          "text": "1h 18 min",
          "value": 4704
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,3 km",
          "value": 10269
        },
        "duration": {
          "text": "13 min",
          "value": 807
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "101 km",
          "value": 100657
        },
        "duration": {
          "text": "1h 30 min",
          "value": 5388
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32382
        },
        "duration": {
          "text": "38 min",
          "value": 2260
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,4 km",
          "value": 73396
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4250
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,4 km",
          "value": 19435
        },
        "duration": {
          "text": "21 min",
          "value": 1285
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,6 km",
          "value": 10562
        },
        "duration": {
          "text": "14 min",
          "value": 862
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,8 km",
          "value": 9807
        },
        "duration": {
          "text": "10 min",
          "value": 575
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,5 km",
          "value": 56466
        },
        "duration": {
          "text": "42 min",
          "value": 2503
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,5 km",
          "value": 38479
        },
        "duration": {
          "text": "39 min",
          "value": 2356
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,1 km",
          "value": 12107
        },
        "duration": {
          "text": "22 min",
          "value": 1319
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,1 km",
          "value": 18108
        },
        "duration": {
          "text": "21 min",
          "value": 1243
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,2 km",
          "value": 23184
        },
        "duration": {
          "text": "18 min",
          "value": 1058
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,2 km",
          "value": 24178
        },
        "duration": {
          "text": "23 min",
          "value": 1385
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,2 km",
          "value": 18191
        },
        "duration": {
          "text": "18 min",
          "value": 1085
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,9 km",
          "value": 31869
        },
        "duration": {
          "text": "23 min",
          "value": 1362
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,4 km",
          "value": 27408
        },
        "duration": {
          "text": "30 min",
          "value": 1777
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,9 km",
          "value": 27929
        },
        "duration": {
          "text": "22 min",
          "value": 1331
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,1 km",
          "value": 46122
        },
        "duration": {
          "text": "39 min",
          "value": 2337
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,6 km",
          "value": 14611
        },
        "duration": {
          "text": "15 min",
          "value": 913
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,6 km",
          "value": 20610
        },
        "duration": {
          "text": "22 min",
          "value": 1326
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,3 km",
          "value": 45274
        },
        "duration": {
          "text": "42 min",
          "value": 2540
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,9 km",
          "value": 23931
        },
        "duration": {
          "text": "20 min",
          "value": 1183
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,8 km",
          "value": 21837
        },
        "duration": {
          "text": "22 min",
          "value": 1347
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,4 km",
          "value": 18379
        },
        "duration": {
          "text": "18 min",
          "value": 1077
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,0 km",
          "value": 55016
        },
        "duration": {
          "text": "51 min",
          "value": 3074
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,8 km",
          "value": 8800
        },
        "duration": {
          "text": "13 min",
          "value": 790
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,2 km",
          "value": 12233
        },
        "duration": {
          "text": "15 min",
          "value": 893
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,9 km",
          "value": 53938
        },
        "duration": {
          "text": "53 min",
          "value": 3164
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,5 km",
          "value": 12483
        },
        "duration": {
          "text": "13 min",
          "value": 774
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,9 km",
          "value": 41861
        },
        "duration": {
          "text": "35 min",
          "value": 2116
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,5 km",
          "value": 51524
        },
        "duration": {
          "text": "48 min",
          "value": 2909
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,5 km",
          "value": 38489
        },
        "duration": {
          "text": "33 min",
          "value": 1990
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30933
        },
        "duration": {
          "text": "32 min",
          "value": 1910
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,5 km",
          "value": 22504
        },
        "duration": {
          "text": "30 min",
          "value": 1822
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,2 km",
          "value": 16232
        },
        "duration": {
          "text": "20 min",
          "value": 1185
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32424
        },
        "duration": {
          "text": "37 min",
          "value": 2232
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,1 km",
          "value": 25104
        },
        "duration": {
          "text": "38 min",
          "value": 2278
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,4 km",
          "value": 17401
        },
        "duration": {
          "text": "21 min",
          "value": 1275
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,1 km",
          "value": 26119
        },
        "duration": {
          "text": "26 min",
          "value": 1582
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,6 km",
          "value": 9639
        },
        "duration": {
          "text": "16 min",
          "value": 957
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,8 km",
          "value": 27845
        },
        "duration": {
          "text": "33 min",
          "value": 1951
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,3 km",
          "value": 27266
        },
        "duration": {
          "text": "25 min",
          "value": 1506
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,4 km",
          "value": 21353
        },
        "duration": {
          "text": "28 min",
          "value": 1653
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,7 km",
          "value": 41679
        },
        "duration": {
          "text": "36 min",
          "value": 2171
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,3 km",
          "value": 20342
        },
        "duration": {
          "text": "24 min",
          "value": 1438
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,1 km",
          "value": 49056
        },
        "duration": {
          "text": "48 min",
          "value": 2862
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,1 km",
          "value": 30107
        },
        "duration": {
          "text": "29 min",
          "value": 1715
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,9 km",
          "value": 12909
        },
        "duration": {
          "text": "16 min",
          "value": 951
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,2 km",
          "value": 39219
        },
        "duration": {
          "text": "40 min",
          "value": 2416
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,4 km",
          "value": 14437
        },
        "duration": {
          "text": "15 min",
          "value": 875
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,1 km",
          "value": 23145
        },
        "duration": {
          "text": "29 min",
          "value": 1748
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,8 km",
          "value": 22759
        },
        "duration": {
          "text": "26 min",
          "value": 1537
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,7 km",
          "value": 42680
        },
        "duration": {
          "text": "43 min",
          "value": 2570
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,2 km",
          "value": 24211
        },
        "duration": {
          "text": "30 min",
          "value": 1819
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,8 km",
          "value": 9777
        },
        "duration": {
          "text": "13 min",
          "value": 806
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,6 km",
          "value": 41602
        },
        "duration": {
          "text": "44 min",
          "value": 2660
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,8 km",
          "value": 44796
        },
        "duration": {
          "text": "44 min",
          "value": 2640
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,9 km",
          "value": 44931
        },
        "duration": {
          "text": "44 min",
          "value": 2660
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41424
        },
        "duration": {
          "text": "42 min",
          "value": 2514
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,0 km",
          "value": 24015
        },
        "duration": {
          "text": "26 min",
          "value": 1571
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,3 km",
          "value": 20320
        },
        "duration": {
          "text": "24 min",
          "value": 1430
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,5 km",
          "value": 53469
        },
        "duration": {
          "text": "46 min",
          "value": 2775
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46561
        },
        "duration": {
          "text": "49 min",
          "value": 2931
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,3 km",
          "value": 7312
        },
        "duration": {
          "text": "19 min",
          "value": 1155
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,6 km",
          "value": 28605
        },
        "duration": {
          "text": "33 min",
          "value": 1960
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,2 km",
          "value": 20188
        },
        "duration": {
          "text": "22 min",
          "value": 1330
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,3 km",
          "value": 32260
        },
        "duration": {
          "text": "33 min",
          "value": 1960
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32365
        },
        "duration": {
          "text": "31 min",
          "value": 1858
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,9 km",
          "value": 28872
        },
        "duration": {
          "text": "27 min",
          "value": 1634
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,5 km",
          "value": 35490
        },
        "duration": {
          "text": "39 min",
          "value": 2352
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,5 km",
          "value": 34487
        },
        "duration": {
          "text": "30 min",
          "value": 1798
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,8 km",
          "value": 40760
        },
        "duration": {
          "text": "42 min",
          "value": 2517
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,1 km",
          "value": 43126
        },
        "duration": {
          "text": "43 min",
          "value": 2609
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,9 km",
          "value": 22915
        },
        "duration": {
          "text": "22 min",
          "value": 1342
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,7 km",
          "value": 28692
        },
        "duration": {
          "text": "32 min",
          "value": 1901
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,4 km",
          "value": 53356
        },
        "duration": {
          "text": "52 min",
          "value": 3115
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32350
        },
        "duration": {
          "text": "37 min",
          "value": 2202
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,6 km",
          "value": 14614
        },
        "duration": {
          "text": "21 min",
          "value": 1281
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63098
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3649
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,8 km",
          "value": 8794
        },
        "duration": {
          "text": "17 min",
          "value": 1022
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,5 km",
          "value": 15545
        },
        "duration": {
          "text": "20 min",
          "value": 1209
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,0 km",
          "value": 62020
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3739
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,6 km",
          "value": 20565
        },
        "duration": {
          "text": "22 min",
          "value": 1349
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,9 km",
          "value": 38865
        },
        "duration": {
          "text": "40 min",
          "value": 2388
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,1 km",
          "value": 59068
        },
        "duration": {
          "text": "56 min",
          "value": 3359
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,5 km",
          "value": 35493
        },
        "duration": {
          "text": "38 min",
          "value": 2262
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,0 km",
          "value": 39015
        },
        "duration": {
          "text": "41 min",
          "value": 2485
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,0 km",
          "value": 46015
        },
        "duration": {
          "text": "49 min",
          "value": 2944
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,6 km",
          "value": 33592
        },
        "duration": {
          "text": "37 min",
          "value": 2203
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,9 km",
          "value": 21919
        },
        "duration": {
          "text": "32 min",
          "value": 1910
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,7 km",
          "value": 9744
        },
        "duration": {
          "text": "14 min",
          "value": 863
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,0 km",
          "value": 32997
        },
        "duration": {
          "text": "27 min",
          "value": 1649
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,5 km",
          "value": 21506
        },
        "duration": {
          "text": "22 min",
          "value": 1323
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,2 km",
          "value": 18174
        },
        "duration": {
          "text": "19 min",
          "value": 1169
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,7 km",
          "value": 41682
        },
        "duration": {
          "text": "33 min",
          "value": 1953
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,5 km",
          "value": 22521
        },
        "duration": {
          "text": "27 min",
          "value": 1624
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,0 km",
          "value": 30006
        },
        "duration": {
          "text": "31 min",
          "value": 1880
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,9 km",
          "value": 55935
        },
        "duration": {
          "text": "49 min",
          "value": 2928
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,3 km",
          "value": 11261
        },
        "duration": {
          "text": "18 min",
          "value": 1055
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,9 km",
          "value": 17938
        },
        "duration": {
          "text": "21 min",
          "value": 1264
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,4 km",
          "value": 40387
        },
        "duration": {
          "text": "40 min",
          "value": 2387
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,1 km",
          "value": 27089
        },
        "duration": {
          "text": "26 min",
          "value": 1573
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,5 km",
          "value": 13474
        },
        "duration": {
          "text": "16 min",
          "value": 967
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,2 km",
          "value": 28192
        },
        "duration": {
          "text": "28 min",
          "value": 1668
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,3 km",
          "value": 52344
        },
        "duration": {
          "text": "50 min",
          "value": 3012
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,6 km",
          "value": 18612
        },
        "duration": {
          "text": "23 min",
          "value": 1381
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,0 km",
          "value": 22046
        },
        "duration": {
          "text": "25 min",
          "value": 1484
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,3 km",
          "value": 51266
        },
        "duration": {
          "text": "52 min",
          "value": 3102
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,8 km",
          "value": 9811
        },
        "duration": {
          "text": "12 min",
          "value": 712
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,7 km",
          "value": 51674
        },
        "duration": {
          "text": "45 min",
          "value": 2707
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,2 km",
          "value": 43161
        },
        "duration": {
          "text": "42 min",
          "value": 2529
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,3 km",
          "value": 48302
        },
        "duration": {
          "text": "43 min",
          "value": 2581
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,0 km",
          "value": 26047
        },
        "duration": {
          "text": "29 min",
          "value": 1757
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46935
        },
        "duration": {
          "text": "50 min",
          "value": 2983
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,0 km",
          "value": 62028
        },
        "duration": {
          "text": "59 min",
          "value": 3543
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,5 km",
          "value": 42537
        },
        "duration": {
          "text": "50 min",
          "value": 2986
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,9 km",
          "value": 35875
        },
        "duration": {
          "text": "31 min",
          "value": 1838
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,7 km",
          "value": 24667
        },
        "duration": {
          "text": "27 min",
          "value": 1602
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,0 km",
          "value": 25961
        },
        "duration": {
          "text": "25 min",
          "value": 1514
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,1 km",
          "value": 41078
        },
        "duration": {
          "text": "50 min",
          "value": 3006
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,3 km",
          "value": 79268
        },
        "duration": {
          "text": "57 min",
          "value": 3411
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,8 km",
          "value": 19780
        },
        "duration": {
          "text": "24 min",
          "value": 1445
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43848
        },
        "duration": {
          "text": "42 min",
          "value": 2528
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,7 km",
          "value": 67696
        },
        "duration": {
          "text": "49 min",
          "value": 2954
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,7 km",
          "value": 30685
        },
        "duration": {
          "text": "34 min",
          "value": 2044
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,2 km",
          "value": 50167
        },
        "duration": {
          "text": "52 min",
          "value": 3136
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,4 km",
          "value": 26406
        },
        "duration": {
          "text": "33 min",
          "value": 1964
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,4 km",
          "value": 51378
        },
        "duration": {
          "text": "58 min",
          "value": 3465
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,0 km",
          "value": 41986
        },
        "duration": {
          "text": "37 min",
          "value": 2203
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,6 km",
          "value": 32568
        },
        "duration": {
          "text": "35 min",
          "value": 2075
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,1 km",
          "value": 61134
        },
        "duration": {
          "text": "51 min",
          "value": 3084
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,1 km",
          "value": 29129
        },
        "duration": {
          "text": "38 min",
          "value": 2269
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,4 km",
          "value": 31392
        },
        "duration": {
          "text": "35 min",
          "value": 2119
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,3 km",
          "value": 32312
        },
        "duration": {
          "text": "40 min",
          "value": 2416
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,6 km",
          "value": 49553
        },
        "duration": {
          "text": "45 min",
          "value": 2706
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,9 km",
          "value": 55879
        },
        "duration": {
          "text": "56 min",
          "value": 3380
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,6 km",
          "value": 42579
        },
        "duration": {
          "text": "38 min",
          "value": 2299
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,8 km",
          "value": 30807
        },
        "duration": {
          "text": "36 min",
          "value": 2182
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46644
        },
        "duration": {
          "text": "58 min",
          "value": 3506
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,5 km",
          "value": 22477
        },
        "duration": {
          "text": "28 min",
          "value": 1708
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,9 km",
          "value": 58912
        },
        "duration": {
          "text": "53 min",
          "value": 3170
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,0 km",
          "value": 25009
        },
        "duration": {
          "text": "26 min",
          "value": 1575
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,2 km",
          "value": 52173
        },
        "duration": {
          "text": "46 min",
          "value": 2780
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,3 km",
          "value": 18296
        },
        "duration": {
          "text": "28 min",
          "value": 1661
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,6 km",
          "value": 51601
        },
        "duration": {
          "text": "56 min",
          "value": 3372
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,7 km",
          "value": 24702
        },
        "duration": {
          "text": "29 min",
          "value": 1731
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,3 km",
          "value": 73320
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4092
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,3 km",
          "value": 37305
        },
        "duration": {
          "text": "44 min",
          "value": 2637
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,8 km",
          "value": 20762
        },
        "duration": {
          "text": "24 min",
          "value": 1465
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,2 km",
          "value": 14218
        },
        "duration": {
          "text": "21 min",
          "value": 1281
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,6 km",
          "value": 42563
        },
        "duration": {
          "text": "41 min",
          "value": 2489
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,5 km",
          "value": 30544
        },
        "duration": {
          "text": "35 min",
          "value": 2092
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "54,1 km",
          "value": 54107
        },
        "duration": {
          "text": "53 min",
          "value": 3189
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,6 km",
          "value": 35585
        },
        "duration": {
          "text": "43 min",
          "value": 2589
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,8 km",
          "value": 45751
        },
        "duration": {
          "text": "51 min",
          "value": 3047
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,0 km",
          "value": 39044
        },
        "duration": {
          "text": "44 min",
          "value": 2626
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,9 km",
          "value": 41883
        },
        "duration": {
          "text": "46 min",
          "value": 2745
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,9 km",
          "value": 26939
        },
        "duration": {
          "text": "30 min",
          "value": 1796
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "77,6 km",
          "value": 77589
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4228
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,9 km",
          "value": 21858
        },
        "duration": {
          "text": "24 min",
          "value": 1455
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "72,1 km",
          "value": 72051
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3863
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,0 km",
          "value": 15011
        },
        "duration": {
          "text": "21 min",
          "value": 1259
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,6 km",
          "value": 28607
        },
        "duration": {
          "text": "41 min",
          "value": 2471
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,0 km",
          "value": 26957
        },
        "duration": {
          "text": "32 min",
          "value": 1901
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,3 km",
          "value": 32262
        },
        "duration": {
          "text": "41 min",
          "value": 2471
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,3 km",
          "value": 30332
        },
        "duration": {
          "text": "40 min",
          "value": 2406
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,5 km",
          "value": 35492
        },
        "duration": {
          "text": "48 min",
          "value": 2863
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,2 km",
          "value": 36166
        },
        "duration": {
          "text": "39 min",
          "value": 2333
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,8 km",
          "value": 40762
        },
        "duration": {
          "text": "50 min",
          "value": 3028
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,9 km",
          "value": 49895
        },
        "duration": {
          "text": "53 min",
          "value": 3181
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,6 km",
          "value": 24594
        },
        "duration": {
          "text": "31 min",
          "value": 1877
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,7 km",
          "value": 28694
        },
        "duration": {
          "text": "40 min",
          "value": 2412
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,4 km",
          "value": 53358
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3626
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,7 km",
          "value": 27703
        },
        "duration": {
          "text": "34 min",
          "value": 2027
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,0 km",
          "value": 33978
        },
        "duration": {
          "text": "44 min",
          "value": 2668
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,2 km",
          "value": 22151
        },
        "duration": {
          "text": "32 min",
          "value": 1920
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63100
        },
        "duration": {
          "text": "1h 9 min",
          "value": 4160
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,3 km",
          "value": 8291
        },
        "duration": {
          "text": "18 min",
          "value": 1075
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,2 km",
          "value": 17224
        },
        "duration": {
          "text": "29 min",
          "value": 1745
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,0 km",
          "value": 62022
        },
        "duration": {
          "text": "1h 11 min",
          "value": 4250
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,6 km",
          "value": 20567
        },
        "duration": {
          "text": "31 min",
          "value": 1860
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,6 km",
          "value": 45634
        },
        "duration": {
          "text": "49 min",
          "value": 2959
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,1 km",
          "value": 59070
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3870
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,3 km",
          "value": 42262
        },
        "duration": {
          "text": "47 min",
          "value": 2833
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,0 km",
          "value": 39017
        },
        "duration": {
          "text": "50 min",
          "value": 2996
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,8 km",
          "value": 43789
        },
        "duration": {
          "text": "35 min",
          "value": 2122
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,6 km",
          "value": 23617
        },
        "duration": {
          "text": "23 min",
          "value": 1369
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,1 km",
          "value": 15099
        },
        "duration": {
          "text": "18 min",
          "value": 1104
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,4 km",
          "value": 41410
        },
        "duration": {
          "text": "38 min",
          "value": 2278
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,5 km",
          "value": 11453
        },
        "duration": {
          "text": "18 min",
          "value": 1053
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,7 km",
          "value": 30682
        },
        "duration": {
          "text": "35 min",
          "value": 2087
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,7 km",
          "value": 24670
        },
        "duration": {
          "text": "28 min",
          "value": 1658
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,4 km",
          "value": 16386
        },
        "duration": {
          "text": "23 min",
          "value": 1352
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,0 km",
          "value": 15044
        },
        "duration": {
          "text": "20 min",
          "value": 1195
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,3 km",
          "value": 29319
        },
        "duration": {
          "text": "30 min",
          "value": 1816
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,6 km",
          "value": 28582
        },
        "duration": {
          "text": "27 min",
          "value": 1647
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,4 km",
          "value": 10399
        },
        "duration": {
          "text": "15 min",
          "value": 901
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,0 km",
          "value": 38984
        },
        "duration": {
          "text": "36 min",
          "value": 2141
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,1 km",
          "value": 48100
        },
        "duration": {
          "text": "49 min",
          "value": 2921
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,6 km",
          "value": 30628
        },
        "duration": {
          "text": "33 min",
          "value": 1999
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,9 km",
          "value": 23921
        },
        "duration": {
          "text": "26 min",
          "value": 1578
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,0 km",
          "value": 47022
        },
        "duration": {
          "text": "50 min",
          "value": 3011
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,8 km",
          "value": 11816
        },
        "duration": {
          "text": "12 min",
          "value": 748
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62466
        },
        "duration": {
          "text": "53 min",
          "value": 3180
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,0 km",
          "value": 35031
        },
        "duration": {
          "text": "34 min",
          "value": 2060
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,1 km",
          "value": 59094
        },
        "duration": {
          "text": "51 min",
          "value": 3054
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,0 km",
          "value": 14979
        },
        "duration": {
          "text": "20 min",
          "value": 1186
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,7 km",
          "value": 29664
        },
        "duration": {
          "text": "28 min",
          "value": 1689
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,5 km",
          "value": 43524
        },
        "duration": {
          "text": "33 min",
          "value": 2001
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,7 km",
          "value": 7742
        },
        "duration": {
          "text": "9 min",
          "value": 563
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,4 km",
          "value": 47371
        },
        "duration": {
          "text": "41 min",
          "value": 2452
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,6 km",
          "value": 45646
        },
        "duration": {
          "text": "32 min",
          "value": 1941
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,8 km",
          "value": 36782
        },
        "duration": {
          "text": "36 min",
          "value": 2132
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,4 km",
          "value": 25400
        },
        "duration": {
          "text": "28 min",
          "value": 1665
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,1 km",
          "value": 34075
        },
        "duration": {
          "text": "25 min",
          "value": 1485
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,3 km",
          "value": 34337
        },
        "duration": {
          "text": "32 min",
          "value": 1941
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "65,2 km",
          "value": 65237
        },
        "duration": {
          "text": "54 min",
          "value": 3215
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,9 km",
          "value": 11868
        },
        "duration": {
          "text": "13 min",
          "value": 751
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,9 km",
          "value": 44942
        },
        "duration": {
          "text": "40 min",
          "value": 2374
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,3 km",
          "value": 61279
        },
        "duration": {
          "text": "48 min",
          "value": 2891
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,5 km",
          "value": 27512
        },
        "duration": {
          "text": "27 min",
          "value": 1615
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,9 km",
          "value": 16925
        },
        "duration": {
          "text": "17 min",
          "value": 1041
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,4 km",
          "value": 61386
        },
        "duration": {
          "text": "50 min",
          "value": 2977
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,4 km",
          "value": 32446
        },
        "duration": {
          "text": "24 min",
          "value": 1449
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,4 km",
          "value": 22411
        },
        "duration": {
          "text": "24 min",
          "value": 1441
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,9 km",
          "value": 70949
        },
        "duration": {
          "text": "58 min",
          "value": 3459
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,0 km",
          "value": 19039
        },
        "duration": {
          "text": "22 min",
          "value": 1315
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,7 km",
          "value": 43688
        },
        "duration": {
          "text": "39 min",
          "value": 2321
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,4 km",
          "value": 36370
        },
        "duration": {
          "text": "33 min",
          "value": 2003
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,1 km",
          "value": 28064
        },
        "duration": {
          "text": "22 min",
          "value": 1291
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,9 km",
          "value": 17915
        },
        "duration": {
          "text": "27 min",
          "value": 1590
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,9 km",
          "value": 48884
        },
        "duration": {
          "text": "39 min",
          "value": 2358
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,5 km",
          "value": 8500
        },
        "duration": {
          "text": "9 min",
          "value": 557
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,2 km",
          "value": 49211
        },
        "duration": {
          "text": "43 min",
          "value": 2603
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,3 km",
          "value": 37312
        },
        "duration": {
          "text": "32 min",
          "value": 1902
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,6 km",
          "value": 7607
        },
        "duration": {
          "text": "8 min",
          "value": 501
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,1 km",
          "value": 32107
        },
        "duration": {
          "text": "28 min",
          "value": 1693
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,5 km",
          "value": 18454
        },
        "duration": {
          "text": "17 min",
          "value": 1000
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31670
        },
        "duration": {
          "text": "30 min",
          "value": 1800
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,8 km",
          "value": 26776
        },
        "duration": {
          "text": "28 min",
          "value": 1662
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,8 km",
          "value": 30838
        },
        "duration": {
          "text": "28 min",
          "value": 1689
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,4 km",
          "value": 31416
        },
        "duration": {
          "text": "33 min",
          "value": 2006
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,4 km",
          "value": 16384
        },
        "duration": {
          "text": "20 min",
          "value": 1198
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29760
        },
        "duration": {
          "text": "30 min",
          "value": 1779
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,6 km",
          "value": 12604
        },
        "duration": {
          "text": "13 min",
          "value": 751
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,8 km",
          "value": 48813
        },
        "duration": {
          "text": "46 min",
          "value": 2765
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,8 km",
          "value": 37819
        },
        "duration": {
          "text": "32 min",
          "value": 1937
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,9 km",
          "value": 47942
        },
        "duration": {
          "text": "40 min",
          "value": 2374
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,6 km",
          "value": 15641
        },
        "duration": {
          "text": "13 min",
          "value": 791
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,5 km",
          "value": 52481
        },
        "duration": {
          "text": "39 min",
          "value": 2357
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,1 km",
          "value": 25054
        },
        "duration": {
          "text": "29 min",
          "value": 1745
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,1 km",
          "value": 23134
        },
        "duration": {
          "text": "23 min",
          "value": 1405
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,3 km",
          "value": 38271
        },
        "duration": {
          "text": "39 min",
          "value": 2350
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,7 km",
          "value": 66734
        },
        "duration": {
          "text": "56 min",
          "value": 3332
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,8 km",
          "value": 8788
        },
        "duration": {
          "text": "10 min",
          "value": 622
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,6 km",
          "value": 28645
        },
        "duration": {
          "text": "31 min",
          "value": 1887
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,9 km",
          "value": 37926
        },
        "duration": {
          "text": "39 min",
          "value": 2332
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,5 km",
          "value": 44543
        },
        "duration": {
          "text": "36 min",
          "value": 2179
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,8 km",
          "value": 12835
        },
        "duration": {
          "text": "15 min",
          "value": 891
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,0 km",
          "value": 38991
        },
        "duration": {
          "text": "35 min",
          "value": 2072
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,7 km",
          "value": 61701
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3613
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,7 km",
          "value": 26731
        },
        "duration": {
          "text": "30 min",
          "value": 1805
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,2 km",
          "value": 30164
        },
        "duration": {
          "text": "32 min",
          "value": 1908
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,6 km",
          "value": 60623
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3703
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,1 km",
          "value": 19143
        },
        "duration": {
          "text": "23 min",
          "value": 1394
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62473
        },
        "duration": {
          "text": "52 min",
          "value": 3111
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,7 km",
          "value": 38674
        },
        "duration": {
          "text": "37 min",
          "value": 2208
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,1 km",
          "value": 59101
        },
        "duration": {
          "text": "50 min",
          "value": 2985
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,6 km",
          "value": 28580
        },
        "duration": {
          "text": "31 min",
          "value": 1878
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,0 km",
          "value": 44958
        },
        "duration": {
          "text": "45 min",
          "value": 2678
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,5 km",
          "value": 53541
        },
        "duration": {
          "text": "38 min",
          "value": 2271
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,6 km",
          "value": 35596
        },
        "duration": {
          "text": "31 min",
          "value": 1853
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,2 km",
          "value": 22208
        },
        "duration": {
          "text": "27 min",
          "value": 1601
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,0 km",
          "value": 41969
        },
        "duration": {
          "text": "30 min",
          "value": 1814
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,6 km",
          "value": 37602
        },
        "duration": {
          "text": "30 min",
          "value": 1807
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,0 km",
          "value": 58968
        },
        "duration": {
          "text": "49 min",
          "value": 2944
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,8 km",
          "value": 13825
        },
        "duration": {
          "text": "16 min",
          "value": 952
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,8 km",
          "value": 52836
        },
        "duration": {
          "text": "45 min",
          "value": 2704
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,3 km",
          "value": 16258
        },
        "duration": {
          "text": "18 min",
          "value": 1063
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,4 km",
          "value": 35406
        },
        "duration": {
          "text": "32 min",
          "value": 1945
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,8 km",
          "value": 24819
        },
        "duration": {
          "text": "23 min",
          "value": 1370
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,1 km",
          "value": 51113
        },
        "duration": {
          "text": "45 min",
          "value": 2719
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,5 km",
          "value": 30457
        },
        "duration": {
          "text": "29 min",
          "value": 1724
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,8 km",
          "value": 30819
        },
        "duration": {
          "text": "30 min",
          "value": 1824
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,7 km",
          "value": 64680
        },
        "duration": {
          "text": "53 min",
          "value": 3188
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,9 km",
          "value": 20938
        },
        "duration": {
          "text": "23 min",
          "value": 1372
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,5 km",
          "value": 42502
        },
        "duration": {
          "text": "34 min",
          "value": 2042
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,5 km",
          "value": 40519
        },
        "duration": {
          "text": "46 min",
          "value": 2737
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,4 km",
          "value": 22350
        },
        "duration": {
          "text": "29 min",
          "value": 1719
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "70,8 km",
          "value": 70768
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3814
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,2 km",
          "value": 26223
        },
        "duration": {
          "text": "33 min",
          "value": 2002
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,2 km",
          "value": 12226
        },
        "duration": {
          "text": "22 min",
          "value": 1321
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,2 km",
          "value": 25192
        },
        "duration": {
          "text": "30 min",
          "value": 1801
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,6 km",
          "value": 32623
        },
        "duration": {
          "text": "34 min",
          "value": 2060
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "20,2 km",
          "value": 20236
        },
        "duration": {
          "text": "26 min",
          "value": 1551
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,0 km",
          "value": 43025
        },
        "duration": {
          "text": "43 min",
          "value": 2554
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,8 km",
          "value": 45780
        },
        "duration": {
          "text": "50 min",
          "value": 2982
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,7 km",
          "value": 34669
        },
        "duration": {
          "text": "40 min",
          "value": 2412
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,0 km",
          "value": 27962
        },
        "duration": {
          "text": "33 min",
          "value": 1991
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,7 km",
          "value": 44702
        },
        "duration": {
          "text": "51 min",
          "value": 3072
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,9 km",
          "value": 15857
        },
        "duration": {
          "text": "19 min",
          "value": 1161
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,5 km",
          "value": 66507
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3593
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,9 km",
          "value": 30904
        },
        "duration": {
          "text": "34 min",
          "value": 2045
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63135
        },
        "duration": {
          "text": "58 min",
          "value": 3467
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,7 km",
          "value": 12659
        },
        "duration": {
          "text": "21 min",
          "value": 1247
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,6 km",
          "value": 57555
        },
        "duration": {
          "text": "49 min",
          "value": 2954
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,8 km",
          "value": 68776
        },
        "duration": {
          "text": "55 min",
          "value": 3284
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "15,5 km",
          "value": 15533
        },
        "duration": {
          "text": "16 min",
          "value": 963
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,5 km",
          "value": 45487
        },
        "duration": {
          "text": "39 min",
          "value": 2338
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,7 km",
          "value": 52658
        },
        "duration": {
          "text": "57 min",
          "value": 3447
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46584
        },
        "duration": {
          "text": "36 min",
          "value": 2130
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,7 km",
          "value": 31655
        },
        "duration": {
          "text": "35 min",
          "value": 2106
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "41,0 km",
          "value": 41033
        },
        "duration": {
          "text": "34 min",
          "value": 2023
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "79,9 km",
          "value": 79893
        },
        "duration": {
          "text": "1h 8 min",
          "value": 4086
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,7 km",
          "value": 25693
        },
        "duration": {
          "text": "30 min",
          "value": 1802
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,8 km",
          "value": 36840
        },
        "duration": {
          "text": "32 min",
          "value": 1900
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,8 km",
          "value": 78815
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4176
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,4 km",
          "value": 37360
        },
        "duration": {
          "text": "30 min",
          "value": 1786
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,5 km",
          "value": 64515
        },
        "duration": {
          "text": "51 min",
          "value": 3062
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,4 km",
          "value": 53406
        },
        "duration": {
          "text": "55 min",
          "value": 3323
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,1 km",
          "value": 61143
        },
        "duration": {
          "text": "49 min",
          "value": 2936
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,2 km",
          "value": 44228
        },
        "duration": {
          "text": "48 min",
          "value": 2896
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,8 km",
          "value": 56823
        },
        "duration": {
          "text": "52 min",
          "value": 3092
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,8 km",
          "value": 45767
        },
        "duration": {
          "text": "41 min",
          "value": 2459
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,4 km",
          "value": 14437
        },
        "duration": {
          "text": "14 min",
          "value": 868
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,5 km",
          "value": 31477
        },
        "duration": {
          "text": "30 min",
          "value": 1809
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,3 km",
          "value": 26302
        },
        "duration": {
          "text": "25 min",
          "value": 1516
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,4 km",
          "value": 35410
        },
        "duration": {
          "text": "37 min",
          "value": 2204
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,6 km",
          "value": 34624
        },
        "duration": {
          "text": "36 min",
          "value": 2178
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,5 km",
          "value": 24520
        },
        "duration": {
          "text": "23 min",
          "value": 1384
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,9 km",
          "value": 39871
        },
        "duration": {
          "text": "43 min",
          "value": 2563
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,2 km",
          "value": 24232
        },
        "duration": {
          "text": "29 min",
          "value": 1714
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,4 km",
          "value": 23442
        },
        "duration": {
          "text": "25 min",
          "value": 1474
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,1 km",
          "value": 21059
        },
        "duration": {
          "text": "22 min",
          "value": 1308
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,5 km",
          "value": 62528
        },
        "duration": {
          "text": "55 min",
          "value": 3270
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37189
        },
        "duration": {
          "text": "34 min",
          "value": 2053
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,6 km",
          "value": 55554
        },
        "duration": {
          "text": "48 min",
          "value": 2863
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "12,1 km",
          "value": 12117
        },
        "duration": {
          "text": "14 min",
          "value": 855
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,5 km",
          "value": 57460
        },
        "duration": {
          "text": "47 min",
          "value": 2833
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,4 km",
          "value": 57372
        },
        "duration": {
          "text": "51 min",
          "value": 3072
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,7 km",
          "value": 78738
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4209
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,3 km",
          "value": 35254
        },
        "duration": {
          "text": "35 min",
          "value": 2099
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,3 km",
          "value": 68328
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3723
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,8 km",
          "value": 31750
        },
        "duration": {
          "text": "35 min",
          "value": 2082
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,1 km",
          "value": 69072
        },
        "duration": {
          "text": "1h 0 min",
          "value": 3610
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,9 km",
          "value": 50898
        },
        "duration": {
          "text": "49 min",
          "value": 2963
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,3 km",
          "value": 40311
        },
        "duration": {
          "text": "40 min",
          "value": 2389
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "69,2 km",
          "value": 69179
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3696
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,8 km",
          "value": 55832
        },
        "duration": {
          "text": "47 min",
          "value": 2798
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,1 km",
          "value": 35085
        },
        "duration": {
          "text": "41 min",
          "value": 2456
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "84,4 km",
          "value": 84450
        },
        "duration": {
          "text": "1h 14 min",
          "value": 4453
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,4 km",
          "value": 24395
        },
        "duration": {
          "text": "32 min",
          "value": 1944
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,3 km",
          "value": 62272
        },
        "duration": {
          "text": "55 min",
          "value": 3307
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,7 km",
          "value": 26697
        },
        "duration": {
          "text": "32 min",
          "value": 1892
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,1 km",
          "value": 44095
        },
        "duration": {
          "text": "46 min",
          "value": 2776
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,1 km",
          "value": 35119
        },
        "duration": {
          "text": "27 min",
          "value": 1643
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,2 km",
          "value": 17184
        },
        "duration": {
          "text": "22 min",
          "value": 1303
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,6 km",
          "value": 29567
        },
        "duration": {
          "text": "26 min",
          "value": 1536
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,1 km",
          "value": 61103
        },
        "duration": {
          "text": "1h 1 min",
          "value": 3640
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "14,2 km",
          "value": 14227
        },
        "duration": {
          "text": "22 min",
          "value": 1315
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "25,4 km",
          "value": 25375
        },
        "duration": {
          "text": "24 min",
          "value": 1413
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,0 km",
          "value": 60025
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3730
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,6 km",
          "value": 18570
        },
        "duration": {
          "text": "22 min",
          "value": 1340
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,0 km",
          "value": 53050
        },
        "duration": {
          "text": "43 min",
          "value": 2575
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,8 km",
          "value": 44843
        },
        "duration": {
          "text": "44 min",
          "value": 2652
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,7 km",
          "value": 49678
        },
        "duration": {
          "text": "41 min",
          "value": 2449
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,8 km",
          "value": 29757
        },
        "duration": {
          "text": "35 min",
          "value": 2093
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,6 km",
          "value": 27628
        },
        "duration": {
          "text": "26 min",
          "value": 1565
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,5 km",
          "value": 23517
        },
        "duration": {
          "text": "22 min",
          "value": 1306
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,9 km",
          "value": 23903
        },
        "duration": {
          "text": "28 min",
          "value": 1679
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,8 km",
          "value": 31838
        },
        "duration": {
          "text": "33 min",
          "value": 1968
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,8 km",
          "value": 37790
        },
        "duration": {
          "text": "35 min",
          "value": 2115
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,7 km",
          "value": 27738
        },
        "duration": {
          "text": "33 min",
          "value": 1957
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,9 km",
          "value": 18856
        },
        "duration": {
          "text": "21 min",
          "value": 1236
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,7 km",
          "value": 36712
        },
        "duration": {
          "text": "37 min",
          "value": 2205
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "8,9 km",
          "value": 8926
        },
        "duration": {
          "text": "12 min",
          "value": 702
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "53,9 km",
          "value": 53875
        },
        "duration": {
          "text": "51 min",
          "value": 3071
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,3 km",
          "value": 33340
        },
        "duration": {
          "text": "30 min",
          "value": 1809
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,3 km",
          "value": 57312
        },
        "duration": {
          "text": "47 min",
          "value": 2801
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "11,2 km",
          "value": 11162
        },
        "duration": {
          "text": "11 min",
          "value": 663
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,2 km",
          "value": 49232
        },
        "duration": {
          "text": "43 min",
          "value": 2603
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,4 km",
          "value": 29366
        },
        "duration": {
          "text": "30 min",
          "value": 1820
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,8 km",
          "value": 60776
        },
        "duration": {
          "text": "55 min",
          "value": 3303
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,8 km",
          "value": 44756
        },
        "duration": {
          "text": "52 min",
          "value": 3120
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,4 km",
          "value": 52420
        },
        "duration": {
          "text": "53 min",
          "value": 3161
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,7 km",
          "value": 45713
        },
        "duration": {
          "text": "46 min",
          "value": 2740
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,8 km",
          "value": 50772
        },
        "duration": {
          "text": "55 min",
          "value": 3291
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,6 km",
          "value": 33608
        },
        "duration": {
          "text": "32 min",
          "value": 1910
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "84,3 km",
          "value": 84258
        },
        "duration": {
          "text": "1h 12 min",
          "value": 4342
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,5 km",
          "value": 7545
        },
        "duration": {
          "text": "10 min",
          "value": 577
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "78,7 km",
          "value": 78720
        },
        "duration": {
          "text": "1h 6 min",
          "value": 3977
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,7 km",
          "value": 21680
        },
        "duration": {
          "text": "23 min",
          "value": 1373
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,9 km",
          "value": 33928
        },
        "duration": {
          "text": "35 min",
          "value": 2123
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "10,6 km",
          "value": 10605
        },
        "duration": {
          "text": "15 min",
          "value": 901
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,5 km",
          "value": 48474
        },
        "duration": {
          "text": "44 min",
          "value": 2649
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,5 km",
          "value": 29516
        },
        "duration": {
          "text": "31 min",
          "value": 1856
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,7 km",
          "value": 9749
        },
        "duration": {
          "text": "14 min",
          "value": 833
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,4 km",
          "value": 47396
        },
        "duration": {
          "text": "46 min",
          "value": 2739
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,6 km",
          "value": 17635
        },
        "duration": {
          "text": "18 min",
          "value": 1074
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,6 km",
          "value": 32642
        },
        "duration": {
          "text": "33 min",
          "value": 2004
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,2 km",
          "value": 55220
        },
        "duration": {
          "text": "48 min",
          "value": 2852
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,3 km",
          "value": 29270
        },
        "duration": {
          "text": "31 min",
          "value": 1878
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,0 km",
          "value": 33042
        },
        "duration": {
          "text": "28 min",
          "value": 1706
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,2 km",
          "value": 40238
        },
        "duration": {
          "text": "40 min",
          "value": 2409
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,1 km",
          "value": 57053
        },
        "duration": {
          "text": "57 min",
          "value": 3425
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,7 km",
          "value": 30658
        },
        "duration": {
          "text": "35 min",
          "value": 2122
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,4 km",
          "value": 29362
        },
        "duration": {
          "text": "34 min",
          "value": 2017
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,0 km",
          "value": 55975
        },
        "duration": {
          "text": "59 min",
          "value": 3515
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "17,4 km",
          "value": 17363
        },
        "duration": {
          "text": "20 min",
          "value": 1197
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,7 km",
          "value": 63720
        },
        "duration": {
          "text": "57 min",
          "value": 3448
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,1 km",
          "value": 30067
        },
        "duration": {
          "text": "28 min",
          "value": 1679
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,3 km",
          "value": 60348
        },
        "duration": {
          "text": "55 min",
          "value": 3322
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,9 km",
          "value": 23932
        },
        "duration": {
          "text": "28 min",
          "value": 1690
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,8 km",
          "value": 66754
        },
        "duration": {
          "text": "53 min",
          "value": 3197
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "23,5 km",
          "value": 23537
        },
        "duration": {
          "text": "29 min",
          "value": 1714
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,6 km",
          "value": 13632
        },
        "duration": {
          "text": "18 min",
          "value": 1084
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "66,9 km",
          "value": 66861
        },
        "duration": {
          "text": "55 min",
          "value": 3283
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,5 km",
          "value": 28471
        },
        "duration": {
          "text": "26 min",
          "value": 1548
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,8 km",
          "value": 21836
        },
        "duration": {
          "text": "25 min",
          "value": 1471
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,0 km",
          "value": 66974
        },
        "duration": {
          "text": "59 min",
          "value": 3558
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,5 km",
          "value": 18464
        },
        "duration": {
          "text": "22 min",
          "value": 1345
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,4 km",
          "value": 40395
        },
        "duration": {
          "text": "39 min",
          "value": 2364
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,2 km",
          "value": 62234
        },
        "duration": {
          "text": "1h 2 min",
          "value": 3723
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,6 km",
          "value": 46595
        },
        "duration": {
          "text": "48 min",
          "value": 2874
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "9,6 km",
          "value": 9644
        },
        "duration": {
          "text": "15 min",
          "value": 871
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,4 km",
          "value": 43422
        },
        "duration": {
          "text": "41 min",
          "value": 2468
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "74,9 km",
          "value": 74853
        },
        "duration": {
          "text": "1h 4 min",
          "value": 3834
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,6 km",
          "value": 44571
        },
        "duration": {
          "text": "50 min",
          "value": 2979
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,9 km",
          "value": 67879
        },
        "duration": {
          "text": "57 min",
          "value": 3427
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "29,4 km",
          "value": 29390
        },
        "duration": {
          "text": "36 min",
          "value": 2189
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "16,5 km",
          "value": 16475
        },
        "duration": {
          "text": "23 min",
          "value": 1366
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,2 km",
          "value": 61154
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3761
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,7 km",
          "value": 19699
        },
        "duration": {
          "text": "23 min",
          "value": 1371
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46882
        },
        "duration": {
          "text": "45 min",
          "value": 2680
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "58,2 km",
          "value": 58202
        },
        "duration": {
          "text": "56 min",
          "value": 3381
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,5 km",
          "value": 43510
        },
        "duration": {
          "text": "43 min",
          "value": 2554
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,1 km",
          "value": 38149
        },
        "duration": {
          "text": "42 min",
          "value": 2507
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,3 km",
          "value": 45294
        },
        "duration": {
          "text": "49 min",
          "value": 2912
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "13,0 km",
          "value": 12994
        },
        "duration": {
          "text": "17 min",
          "value": 1007
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37209
        },
        "duration": {
          "text": "39 min",
          "value": 2323
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,1 km",
          "value": 51073
        },
        "duration": {
          "text": "49 min",
          "value": 2968
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,8 km",
          "value": 33837
        },
        "duration": {
          "text": "37 min",
          "value": 2197
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,2 km",
          "value": 30157
        },
        "duration": {
          "text": "31 min",
          "value": 1879
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,3 km",
          "value": 42292
        },
        "duration": {
          "text": "43 min",
          "value": 2562
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "75,1 km",
          "value": 75141
        },
        "duration": {
          "text": "1h 5 min",
          "value": 3896
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "56,5 km",
          "value": 56513
        },
        "duration": {
          "text": "59 min",
          "value": 3517
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "68,2 km",
          "value": 68167
        },
        "duration": {
          "text": "58 min",
          "value": 3489
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,4 km",
          "value": 34376
        },
        "duration": {
          "text": "37 min",
          "value": 2237
        }
      }
    },
    {
      "origin": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "51,5 km",
          "value": 51518
        },
        "duration": {
          "text": "43 min",
          "value": 2600
        }
      }
    },
    {
      "origin": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,5 km",
          "value": 39478
        },
        "duration": {
          "text": "36 min",
          "value": 2188
        }
      }
    },
    {
      "origin": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "48,1 km",
          "value": 48146
        },
        "duration": {
          "text": "41 min",
          "value": 2474
        }
      }
    },
    {
      "origin": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,4 km",
          "value": 19425
        },
        "duration": {
          "text": "22 min",
          "value": 1314
        }
      }
    },
    {
      "origin": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "90,4 km",
          "value": 90388
        },
        "duration": {
          "text": "1h 16 min",
          "value": 4582
        }
      }
    },
    {
      "origin": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "22,1 km",
          "value": 22113
        },
        "duration": {
          "text": "24 min",
          "value": 1454
        }
      }
    },
    {
      "origin": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "63,1 km",
          "value": 63127
        },
        "duration": {
          "text": "57 min",
          "value": 3444
        }
      }
    },
    {
      "origin": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "84,4 km",
          "value": 84420
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4190
        }
      }
    },
    {
      "origin": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "27,4 km",
          "value": 27380
        },
        "duration": {
          "text": "26 min",
          "value": 1586
        }
      }
    },
    {
      "origin": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "64,0 km",
          "value": 64010
        },
        "duration": {
          "text": "58 min",
          "value": 3476
        }
      }
    },
    {
      "origin": {
        "name": "Alc\u00fadia",
        "id": 0,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,9 km",
          "value": 59921
        },
        "duration": {
          "text": "41 min",
          "value": 2485
        }
      }
    },
    {
      "origin": {
        "name": "Alar\u00f3",
        "id": 1,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,4 km",
          "value": 34374
        },
        "duration": {
          "text": "29 min",
          "value": 1745
        }
      }
    },
    {
      "origin": {
        "name": "Algaida",
        "id": 2,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,0 km",
          "value": 24025
        },
        "duration": {
          "text": "22 min",
          "value": 1299
        }
      }
    },
    {
      "origin": {
        "name": "Andratx",
        "id": 3,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,1 km",
          "value": 43132
        },
        "duration": {
          "text": "40 min",
          "value": 2393
        }
      }
    },
    {
      "origin": {
        "name": "Ariany",
        "id": 4,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "59,8 km",
          "value": 59829
        },
        "duration": {
          "text": "48 min",
          "value": 2866
        }
      }
    },
    {
      "origin": {
        "name": "Art\u00e0",
        "id": 5,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "73,4 km",
          "value": 73434
        },
        "duration": {
          "text": "1h 10 min",
          "value": 4173
        }
      }
    },
    {
      "origin": {
        "name": "Banyalbufar",
        "id": 6,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,3 km",
          "value": 35308
        },
        "duration": {
          "text": "50 min",
          "value": 3022
        }
      }
    },
    {
      "origin": {
        "name": "Binissalem",
        "id": 7,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,3 km",
          "value": 31295
        },
        "duration": {
          "text": "26 min",
          "value": 1573
        }
      }
    },
    {
      "origin": {
        "name": "B\u00fager",
        "id": 8,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,4 km",
          "value": 46352
        },
        "duration": {
          "text": "35 min",
          "value": 2084
        }
      }
    },
    {
      "origin": {
        "name": "Bunyola",
        "id": 9,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,1 km",
          "value": 28119
        },
        "duration": {
          "text": "29 min",
          "value": 1719
        }
      }
    },
    {
      "origin": {
        "name": "Calvi\u00e0",
        "id": 10,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,5 km",
          "value": 30506
        },
        "duration": {
          "text": "27 min",
          "value": 1648
        }
      }
    },
    {
      "origin": {
        "name": "Campanet",
        "id": 11,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,8 km",
          "value": 50814
        },
        "duration": {
          "text": "37 min",
          "value": 2241
        }
      }
    },
    {
      "origin": {
        "name": "Campos",
        "id": 12,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "35,4 km",
          "value": 35425
        },
        "duration": {
          "text": "31 min",
          "value": 1875
        }
      }
    },
    {
      "origin": {
        "name": "Capdepera",
        "id": 13,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "75,4 km",
          "value": 75441
        },
        "duration": {
          "text": "1h 3 min",
          "value": 3768
        }
      }
    },
    {
      "origin": {
        "name": "Consell",
        "id": 14,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "30,6 km",
          "value": 30578
        },
        "duration": {
          "text": "26 min",
          "value": 1570
        }
      }
    },
    {
      "origin": {
        "name": "Costitx",
        "id": 15,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "38,4 km",
          "value": 38352
        },
        "duration": {
          "text": "35 min",
          "value": 2110
        }
      }
    },
    {
      "origin": {
        "name": "Dei\u00e0",
        "id": 16,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "37,2 km",
          "value": 37229
        },
        "duration": {
          "text": "44 min",
          "value": 2612
        }
      }
    },
    {
      "origin": {
        "name": "Escorca",
        "id": 17,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "57,6 km",
          "value": 57604
        },
        "duration": {
          "text": "58 min",
          "value": 3507
        }
      }
    },
    {
      "origin": {
        "name": "Esporles",
        "id": 18,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,3 km",
          "value": 24338
        },
        "duration": {
          "text": "26 min",
          "value": 1575
        }
      }
    },
    {
      "origin": {
        "name": "Estellencs",
        "id": 19,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,2 km",
          "value": 43157
        },
        "duration": {
          "text": "55 min",
          "value": 3328
        }
      }
    },
    {
      "origin": {
        "name": "Felanitx",
        "id": 20,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,5 km",
          "value": 46541
        },
        "duration": {
          "text": "40 min",
          "value": 2378
        }
      }
    },
    {
      "origin": {
        "name": "Fornalutx",
        "id": 21,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "42,7 km",
          "value": 42740
        },
        "duration": {
          "text": "47 min",
          "value": 2811
        }
      }
    },
    {
      "origin": {
        "name": "Inca",
        "id": 22,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "39,4 km",
          "value": 39427
        },
        "duration": {
          "text": "29 min",
          "value": 1756
        }
      }
    },
    {
      "origin": {
        "name": "Lloret",
        "id": 23,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "33,6 km",
          "value": 33602
        },
        "duration": {
          "text": "34 min",
          "value": 2028
        }
      }
    },
    {
      "origin": {
        "name": "Lloseta",
        "id": 24,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "36,4 km",
          "value": 36430
        },
        "duration": {
          "text": "34 min",
          "value": 2028
        }
      }
    },
    {
      "origin": {
        "name": "Llub\u00ed",
        "id": 25,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,2 km",
          "value": 49240
        },
        "duration": {
          "text": "39 min",
          "value": 2347
        }
      }
    },
    {
      "origin": {
        "name": "Llucmajor",
        "id": 26,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "21,6 km",
          "value": 21551
        },
        "duration": {
          "text": "22 min",
          "value": 1298
        }
      }
    },
    {
      "origin": {
        "name": "Manacor",
        "id": 27,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "55,1 km",
          "value": 55072
        },
        "duration": {
          "text": "51 min",
          "value": 3080
        }
      }
    },
    {
      "origin": {
        "name": "Mancor de la Vall",
        "id": 28,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "43,2 km",
          "value": 43199
        },
        "duration": {
          "text": "43 min",
          "value": 2599
        }
      }
    },
    {
      "origin": {
        "name": "Maria de la Salut",
        "id": 29,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,0 km",
          "value": 60032
        },
        "duration": {
          "text": "47 min",
          "value": 2820
        }
      }
    },
    {
      "origin": {
        "name": "Marratx\u00ed",
        "id": 30,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "18,7 km",
          "value": 18705
        },
        "duration": {
          "text": "18 min",
          "value": 1084
        }
      }
    },
    {
      "origin": {
        "name": "Montuiri",
        "id": 31,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "31,0 km",
          "value": 30963
        },
        "duration": {
          "text": "27 min",
          "value": 1591
        }
      }
    },
    {
      "origin": {
        "name": "Muro",
        "id": 32,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "60,0 km",
          "value": 60039
        },
        "duration": {
          "text": "46 min",
          "value": 2751
        }
      }
    },
    {
      "origin": {
        "name": "Palma",
        "id": 33,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "7,9 km",
          "value": 7915
        },
        "duration": {
          "text": "14 min",
          "value": 844
        }
      }
    },
    {
      "origin": {
        "name": "Petra",
        "id": 34,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "52,7 km",
          "value": 52720
        },
        "duration": {
          "text": "51 min",
          "value": 3068
        }
      }
    },
    {
      "origin": {
        "name": "Pollen\u00e7a",
        "id": 35,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "62,1 km",
          "value": 62081
        },
        "duration": {
          "text": "45 min",
          "value": 2702
        }
      }
    },
    {
      "origin": {
        "name": "Porreres",
        "id": 36,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,5 km",
          "value": 34527
        },
        "duration": {
          "text": "31 min",
          "value": 1863
        }
      }
    },
    {
      "origin": {
        "name": "Puigpunyent",
        "id": 37,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "26,5 km",
          "value": 26498
        },
        "duration": {
          "text": "30 min",
          "value": 1803
        }
      }
    },
    {
      "origin": {
        "name": "Sa Pobla",
        "id": 38,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "50,6 km",
          "value": 50615
        },
        "duration": {
          "text": "37 min",
          "value": 2215
        }
      }
    },
    {
      "origin": {
        "name": "Sant Joan",
        "id": 39,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "40,3 km",
          "value": 40333
        },
        "duration": {
          "text": "34 min",
          "value": 2018
        }
      }
    },
    {
      "origin": {
        "name": "Sant Lloren\u00e7",
        "id": 40,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,7 km",
          "value": 61741
        },
        "duration": {
          "text": "53 min",
          "value": 3194
        }
      }
    },
    {
      "origin": {
        "name": "Santa Eug\u00e8nia",
        "id": 41,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "19,7 km",
          "value": 19717
        },
        "duration": {
          "text": "24 min",
          "value": 1467
        }
      }
    },
    {
      "origin": {
        "name": "Santa Margalida",
        "id": 42,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "61,3 km",
          "value": 61286
        },
        "duration": {
          "text": "51 min",
          "value": 3088
        }
      }
    },
    {
      "origin": {
        "name": "Santa Maria",
        "id": 43,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "24,2 km",
          "value": 24180
        },
        "duration": {
          "text": "23 min",
          "value": 1390
        }
      }
    },
    {
      "origin": {
        "name": "Santany\u00ed",
        "id": 44,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "46,9 km",
          "value": 46852
        },
        "duration": {
          "text": "40 min",
          "value": 2427
        }
      }
    },
    {
      "origin": {
        "name": "Selva",
        "id": 45,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "44,4 km",
          "value": 44448
        },
        "duration": {
          "text": "39 min",
          "value": 2320
        }
      }
    },
    {
      "origin": {
        "name": "Sencelles",
        "id": 46,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "34,8 km",
          "value": 34774
        },
        "duration": {
          "text": "33 min",
          "value": 1963
        }
      }
    },
    {
      "origin": {
        "name": "Ses Salines",
        "id": 47,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "47,1 km",
          "value": 47140
        },
        "duration": {
          "text": "41 min",
          "value": 2488
        }
      }
    },
    {
      "origin": {
        "name": "Sineu",
        "id": 48,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "49,1 km",
          "value": 49084
        },
        "duration": {
          "text": "37 min",
          "value": 2240
        }
      }
    },
    {
      "origin": {
        "name": "S\u00f3ller",
        "id": 49,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "32,5 km",
          "value": 32471
        },
        "duration": {
          "text": "33 min",
          "value": 2005
        }
      }
    },
    {
      "origin": {
        "name": "Son Servera",
        "id": 50,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "67,4 km",
          "value": 67441
        },
        "duration": {
          "text": "57 min",
          "value": 3407
        }
      }
    },
    {
      "origin": {
        "name": "Valldemossa",
        "id": 51,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "28,2 km",
          "value": 28237
        },
        "duration": {
          "text": "33 min",
          "value": 1971
        }
      }
    },
    {
      "origin": {
        "name": "Vilafranca",
        "id": 52,
        "zone": "_NO_ZONE"
      },
      "destination": {
        "name": "PMI Airport",
        "id": 53,
        "zone": "_NO_ZONE"
      },
      "data": {
        "distance": {
          "text": "45,5 km",
          "value": 45545
        },
        "duration": {
          "text": "39 min",
          "value": 2328
        }
      }
    }
  ];