import { CompanyService 			}	from './demo/service/database/company.service';
import { FirebaseService			}	from './demo/service/database/firebase.service';
import { StorageService 			}	from './demo/service/storageservice';
import { Component, 
		 Input, 
		 OnInit, 
		 AfterViewInit, 
		 ViewChild					}	from '@angular/core';
import { trigger, 
		 state, 
		 style, 
		 transition, 
		 animate					} from '@angular/animations';
import { Location					} from '@angular/common';
import { Router						} from '@angular/router';
import { MenuItem, ScrollPanel		} from 'primeng/primeng';
import { AppComponent				} from './app.component';

@Component({
    selector	: 'app-menu',
    templateUrl : './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset	: boolean;
    @Input() roles	: any;
    @Input() modules: any[]     =   [];
    userInfo        : any       =   {};
    model           : any[];

    @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

    constructor(
		public app			    : AppComponent, 
		public storage 		    : StorageService, 
        public firebaseCtrl     : FirebaseService,
        private companyService  : CompanyService,
	) {}

    async ngOnInit() {        
		console.log('ROLES FROM USER',      this.roles);
        console.log('MODULES FROM COMPANY', this.modules);        

        this.userInfo                   =   (this.storage.getItem('dmcSuite') ||{}).userInfo || null;
        this.model = [            
            {
                label     : '_DASHBOARD',           	
                icon      : 'fa fa-fw fa-tachometer',	
                routerLink: [ '/dashboard' ], 
                roles     : [ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ]
            },            
            {   
                roles   :   [ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN' ],
                label   :   '_TRANSFERS', 
                icon    :   'fa fa-fw fa-bus',
                modules :   [ 'transport_service' ],
                items   : [
                    {   label       :	'_SERVICES',     			
                        icon        :	'fa fa-fw fa-table', 	    
                        routerLink  :	['/services'],  
                        roles       :   ['ROLE_ADMIN','ROLE_DMC_ADMIN']
					}
                ]
            }
        ];
    }

    ngAfterViewInit() {
        // setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }
    changeLayout(layout) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
    }

    onMenuClick() {
        if (!this.app.isHorizontal()){ setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 450); }
        this.app.onMenuClick();
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
           
            <li  *ngIf="hasPermission(child) && hasModule(child)" [ngClass]="{'active-menuitem': isActive(i)}">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span [innerHTML]="child.label | translate"></span>
                    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="child?.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>
                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                    [queryParams]="child.queryParams ? child.queryParams : null"
                    [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span [innerHTML]="child.label | translate"></span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child?.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" [roles]="roles" [modules]="modules" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
			        trigger('children', [
			            state		('visible', 			style({ height: '*' })							),
			            state		('hidden', 				style({height: '0px'})							),
			            transition	('visible => hidden', 	animate('100ms cubic-bezier(0.86, 0, 0.07, 1)')	),
			            transition	('hidden => visible', 	animate('100ms cubic-bezier(0.86, 0, 0.07, 1)')	)
			        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item		: MenuItem;
    @Input() root		: boolean;
    @Input() visible	: boolean;
    @Input() roles      : any [] = [] || [];
	@Input() modules    : any [] = [] || [];
	
    _reset: boolean;
    _parentActive: boolean;
    activeIndex: number;
    hover: boolean;

    constructor(
		public app		: AppComponent, 
		public router	: Router, 
		public location	: Location, 
		public appMenu	: AppMenuComponent
	){}

    /**
     * Check if the current user has the permissions to enter
     * @param child item menu
     */
    hasPermission(child)    {	// const findAdmin =   this.roles.find(roles => roles == 'ROLE_ADMIN');
                            	// if(findAdmin)	{	return true;	}
                                if(!child.roles){	return false;	}
                                return child.roles.some(role => this.roles.find(myRole => myRole == role));                                
                            }

    /**
     *  Check if the company has the module and section hired
     * @param child item menu
     */
    hasModule(child)        {	const findAdmin     =   this.roles.find(roles => roles == 'ROLE_ADMIN');
						        if(findAdmin || !child.modules || !this.modules)   { return true;      } //if no module needed hired or admin profile, return true
						        const moduleHired   =   this.modules.find(module => child.modules.some(myModule => myModule == module.value));
						        console.log('MODULE HIRED', moduleHired);
						        if(!moduleHired)    {   return false;   };
						        if(!child.sections) {   return true     };
						        const moduleSectionHired    =  moduleHired.sections.some(section => child.sections.some(childSection => childSection == section.value));
						        return moduleSectionHired;
						    }
    getChilds(items)        {    return items;            								}
    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) 		{	this.app.menuHoverActive = !this.app.menuHoverActive;	}
        // avoid processing disabled items
        if (item.disabled) 	{	event.preventDefault();	return true;					}

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }

        // execute command
        if (item.command) 	{	item.command({originalEvent: event, item: item});		}

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            // setTimeout(() => {	this.appMenu.layoutMenuScrollerViewChild.moveBar();	}, 450 );
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
			this.app.resetMenu = this.app.menuMode === 'horizontal' ? true: false;
            if (this.app.isMobile() || this.app.menuMode === 'overlay' || this.app.menuMode === 'popup') {
                this.app.menuActive = false;
            }
            this.app.menuHoverActive = false;
        }
    }

    onMouseEnter(index: number) {
		if (this.root 					&&
			this.app.menuHoverActive 	&& 
			this.app.isHorizontal()		&&
			!this.app.isMobile() 		&& 
			!this.app.isTablet()
		) {	this.activeIndex = index;		}
    }

    isActive(index: number)	: boolean 			{	return this.activeIndex === index;			}
    @Input() 	get reset()	: boolean			{	return this._reset;							}
    			set reset(val: boolean) 		{	this._reset = val; if (this._reset && (this.app.menuMode === 'horizontal')) { this.activeIndex = null;	}}
    @Input() 	get parentActive(): boolean 	{	return this._parentActive;					}
    			set parentActive(val: boolean) 	{	this._parentActive = val; if (!this._parentActive) { this.activeIndex = null; }}
}
