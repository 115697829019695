export const pages = {		
    admin		:	{
        admin : {
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/services",
            loggedWithSetupNotDone	: "/services"											
        }
    },  
    transporter		:	{
        transporter : {
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/services",
            loggedWithSetupNotDone	: "/services"											
        }
    }
};