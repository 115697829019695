// area: "Playa de Fanabe"
// arrival_Date: "2024-02-19"
// bookings: ['LM-20039155']
// bookingsInfo: [{…}]
// date: "2024-02-19"
// departure_DateTime: "2024-02-19T11:50:00.000Z"
// departure_PickupDateTime: "2024-02-19T08:40:00.000Z"
// departure_PickupTime: "09:40"
// direction: "departure"
// end: 595
// end_timestamp: 1708332900
// generated_PickupTime: false
// hotels: [null]
// init: 580
// init_timestamp: 1708332000
// name: "GR_PR_2024-02-19_00014"
// pax: 2
// preassigned: false
// private: true
// provider: 1
// routeTime: 0
// solution: 1
// status: "_ACCEPTED"
// time: "2024-02-19 09:40"
// transporter: "Famara Bus TFS"
// transporter_status: "_ACCEPTED"
// type: "private"
// waitTime: 30
// zone: "SUR"
export const expandedArrivalForm = [
    {
        title   : "_GENERAL_INFO",
        type    : 'info',
        items   : [
            { field: 'customer', 			    label: '_CUSTOMER', 			editable: false, 	type: 'text' 																				},
            { field: 'name', 			        label: '_NAME', 			    editable: false, 	type: 'text' 																				},
            { field: 'direction', 			    label: '_DIRECTION', 			editable: false, 	type: 'text' 																				},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'orderref', 				label: '_ORDERREF', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'infants', 				label: '_INFANTS', 			    editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				},
            {   
                type    : 'info',
                label   : '_ARRIVAL_EXTRAS_INFO'
            },
            { field: 'arrival_bikes', 					    label: '_BIKES', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_surf', 					    label: '_SURF', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_golf', 					    label: '_GOLF', 						editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'arrival_extras_str', 					label: '_EXTRAS', 	            		editable: true,	    type: 'text',   editor: 'textarea' 														}           
        ]
    },
    {
        title   : "_ARRIVAL_INFO",
        type    : 'arrival',
        items   : [
            { field: 'arrival_From', 						label: '_AIRPORT_TO', 					editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 							},
            { field: 'arrival_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', renderer: 'flight' 		},        	
            { field: 'date',				                label: '_ARRIVAL_DATE', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'datetime'			},
            { field: 'arrival_Time_Calendar',				label: '_ARRIVAL_TIME', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'time'				},
            { field: 'arrival_To', 							label: '_PROVIDER_RESORT', 				editable: false, 	type: 'text'    },
            { field: 'arrival_resort_tourinia', 			label: '_TOURINIA_RESORT', 				editable: false, 	type: 'text'    },            
            { field: 'arrival_Zone', 						label: '_ZONE', 						editable: false, 	type: 'text'																		    },
            { field: 'arrival_AddressInfo', 				label: '_ADDRESS', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel' 								    },
            // { field: 'arrival_Location', 					label: '_ACCOMMODATION',				editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel', 	extra: ['field'] 			},
            // { field: 'arrival_Latitude', 			        label: '_LATITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'arrival_Longitude', 			    	label: '_LONGITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},           
            { field: 'arrival_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'													},
            { field: 'arrival_VehicleType', 				label: '_ARRIVAL_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            { field: 'arrival_Group', 				    	label: '_ARRIVAL_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'arrival_Vehicle', 					label: '_ARRIVAL_VEHICLE', 				editable: false, 	type: 'text' 								                                        	},
            { field: 'arrival_Driver', 					    label: '_ARRIVAL_DRIVER', 				editable: false, 	type: 'text' 	    							                                    	},        
            { field: 'arrival_DriverNote', 		            label: '_DRIVER_NOTE', 			        editable: true, 	type: 'text' 																			}            
        ]
    }
];

// area: "Playa de Fanabe"
// arrival_Date: "2024-02-19"
// bookings: ['LM-20039155']
// bookingsInfo: [{…}]
// date: "2024-02-19"
// departure_DateTime: "2024-02-19T11:50:00.000Z"
// departure_PickupDateTime: "2024-02-19T08:40:00.000Z"
// departure_PickupTime: "09:40"
// direction: "departure"
// end: 595
// end_timestamp: 1708332900
// generated_PickupTime: false
// hotels: [null]
// init: 580
// init_timestamp: 1708332000
// name: "GR_PR_2024-02-19_00014"
// pax: 2
// preassigned: false
// private: true
// provider: 1
// routeTime: 0
// solution: 1
// status: "_ACCEPTED"
// time: "2024-02-19 09:40"
// transporter: "Famara Bus TFS"
// transporter_status: "_ACCEPTED"
// type: "private"
// waitTime: 30
// zone: "SUR"
export const expandedDepartureForm = [
    {
        title: "_GENERAL_INFO",
        type: 'info',
        items: [
            { field: 'customer', 			    label: '_CUSTOMER', 			editable: false, 	type: 'text' 																				},
            { field: 'name', 			        label: '_NAME', 			    editable: false, 	type: 'text' 																				},
            { field: 'direction', 			    label: '_DIRECTION', 			editable: false, 	type: 'text' 																				},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'orderref', 				label: '_ORDERREF', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'infants', 				label: '_INFANTS', 			    editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				}
        ]
    },
    {
        title   : "_DEPARTURE_INFO",
        type    : 'departure',
        items   : [
            { field: 'departure_From', 						label: '_PROVIDER_RESORT',				editable: false, 	type: 'text', 	check: 'hotel', _entityList: 'areasList', _editor: 'autocomplete' 	    },
            { field: 'departure_resort_tourinia', 			label: '_TOURINIA_RESORT', 				editable: false, 	type: 'text'    },
            { field: 'departure_Zone', 						label: '_ZONE', 						editable: false,	type: 'text',   _entityList: 'zonesList', _editor: 'autocomplete' 						},
            { field: 'departure_AddressInfo', 				label: '_ADDRESS', 						editable: true,		type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_Location', 					label: '_ACCOMMODATION', 				editable: true, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            // { field: 'departure_Latitude', 			        label: '_LATITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            // { field: 'departure_Longitude', 			    label: '_LONGITUDE', 		            editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_To', 						label: '_AIRPORT_FROM',         		editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'flight' 									},            
            { field: 'departure_GatewayInfo', 				label: '_FLIGHT', 						editable: true, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		renderer: 'flight' 			},            
            { field: 'departure_Time_Calendar',				label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'time'				},
            { field: 'departure_Pickup_Place',		        label: '_DEPARTURE_PICKUP_PLACE', 		editable: true, 	type: 'text', 	_editor: 'datetime'			                                            },
            { field: 'departure_Date_Calendar',				label: '_DEPARTURE_DATE', 				editable: false, 	type: 'text',   autoFixIcon: 'wrench', check: 'flight', 		editor: 'datetime'		},
            { field: 'departure_PickupTime',		        label: '_DEPARTURE_PICKUP_TIME', 		editable: true, 	type: 'text', 			                                                                },
            {   
                type    : 'info',
                label   : '_DEPARTURE_EXTRAS_INFO'
            },
            { field: 'departure_bikes', 					label: '_BIKES', 			        	editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_surf', 					    label: '_SURF', 				        editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_golf', 					    label: '_GOLF', 				        editable: true,	    type: 'text',   editor: 'number' 														},
            { field: 'departure_extras_str', 				label: '_EXTRAS', 				        editable: true,	    type: 'text',   editor: 'textarea' 														},        
            { field: 'departure_Vehicles',					label: '_VEHICLES_QTY', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'departure_VehicleType', 				label: '_DEPARTURE_VEHICLE_TYPE', 		editable: false, 	type: 'text' 								                                        	},
            { field: 'departure_Group', 				    label: '_DEPARTURE_GROUP', 				editable: false, 	type: 'text'                                         									},
            { field: 'departure_Vehicle', 					label: '_DEPARTURE_VEHICLE', 			editable: false, 	type: 'text' 								                                        	},
            { field: 'departure_Driver', 				    label: '_DEPARTURE_DRIVER', 			editable: false, 	type: 'text' 	    							                                    	},
            { field: 'departure_DriverNote', 				label: '_DRIVER_NOTE', 					editable: true, 	type: 'text' 																			}            
        ]
    }
];