export let detailItems = [	
	{
		id			: 1,
		title		: "_WIZARD_VARIANT_STEP_1_TITLE",
		description	: "_WIZARD_VARIANT_STEP_1_DESC"
	},
	{
		id			: 2,
		title		: "_WIZARD_VARIANT_STEP_2_TITLE",
		description	: "_WIZARD_VARIANT_STEP_2_DESC"
	},
	{
		id			: 3,
		title		: "_WIZARD_VARIANT_STEP_3_TITLE",
		description	: "_WIZARD_VARIANT_STEP_3_DESC"
	},
	{
		id			: 4,
		title		: "_WIZARD_VARIANT_STEP_4_TITLE",
		description	: "_WIZARD_VARIANT_STEP_4_DESC"
	},
	{
		id			: 5,
		title		: "_WIZARD_VARIANT_STEP_5_TITLE",
		description	: "_WIZARD_VARIANT_STEP_5_DESC"
	},
	{
		id			: 6,
		title		: "_WIZARD_VARIANT_STEP_6_TITLE",
		description	: "_WIZARD_VARIANT_STEP_6_DESC"
	},
	{
		id			: 7,
		title		: "_WIZARD_VARIANT_STEP_7_TITLE",
		description	: "_WIZARD_VARIANT_STEP_7_DESC"
	},
];

export const entities = {						
	langList		: [ 
		{ id: 1, name: 'es', 							label: '_LANG_ES' 						},
		{ id: 2, name: 'uk', 							label: '_LANG_UK' 						}
	],
	providerList		: [ 
		{ id: 1, name: 'agregator', 					label: '_AGREGATOR' 						},
		{ id: 2, name: 'transporter', 					label: '_TRANSPORTER' 						}		
	],
	couponTypeList	: [ 
		{ id: 1, name: 'app', 							label: '_APP' 							},
		{ id: 2, name: 'paper', 						label: '_PAPER' 						}
	],
	cancelReasonList	: [ 
		{ id: 1, name: 'cancel_reason_1', 				label: '_CANCEL_REASON_1' 				},
		{ id: 2, name: 'cancel_reason_2', 				label: '_CANCEL_REASON_2' 				}
	],
	cancelDevolutionMethodList	: [ 
		{ id: 1, name: 'cancel_devolution_method_1', 	label: '_CANCEL_DEVOLUTION_METHOD_1' 	},
		{ id: 2, name: 'cancel_devolution_method_2', 	label: '_CANCEL_DEVOLUTION_METHOD_2' 	}
	],
	cancelConditionList	: [ 
		{ id: 1, name: 'cancel_condition_1', 			label: '_CANCEL_CONDITION_1' 			},
		{ id: 2, name: 'cancel_condition_2', 			label: '_CANCEL_CONDITION_2' 			}
	],				
	infoAdditionalList	: [ 
		{ id: 1, label: '_CHILDREN_TICKET' 		},
		{ id: 2, label: '_DRINK_AND_FOOD'		},
		{ id: 3, label: '_DIFICULTIES'			},
		{ id: 4, label: '_CLAUSURE'				}
	],
	infoStepList	: [ 
		{ id: 1, label: '_TOUR_STEP_VOUCHER_VALID_TYPE'	 		},
		{ id: 2, label: '_TOUR_STEP_VOUCHER_VALID_TIME'			}
	],
	infoEventList	: [
		{ id: 1, label: 'De la Catedral a bodegas Matheu 30 a 40 min'		},
		{ id: 2, label: 'De bodegas Matheu a las cuevas del Drach 45 min'	}
	],
	infoConfirmationList	: [
		{ id: 1, label: 'Recibirá un email de confirmación y un voucher justo después de realizar la reserva' },
		{ id: 2, label: 'En caso de no recibir el email por nuestra parte, por favor comprueba la carpeta de Spam o notifíquenoslo por email' }
	]				
};