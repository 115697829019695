import { StorageService 			} from 'src/app/demo/service/storageservice';
import { FirebaseService 			} from './../../../demo/service/database/firebase.service';
import { EntityService 				} from './../../../demo/service/entity.service';
import { Component,
         Input,
         Output,
         EventEmitter     			} from '@angular/core';
import { CommonsService 			} from 'src/app/demo/service/commons.service';
import { detailItems, entities 		} from './data/items';
import { TranslateService 			} from '@ngx-translate/core';

@Component({
  selector    	: 'app-provider-detail',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'
})
export class ProviderDetailComponent {

	@Input("provider") 	currentProvider	:string;
	@Input("info")  	info			:string;
	@Output()			emitter	= new EventEmitter<any>();

	pageInfo  			: any = {};
	userInfo			: any = {};

	constructor(
		private commons				: CommonsService,
		private entityService		: EntityService,
		private translate			: TranslateService,
		private firebaseCtrl		: FirebaseService,
		private storageCtrl			: StorageService
	){
		// this.init();
	}

	init()							{	this.userInfo	=	(this.storageCtrl.getItem('dmcSuite') || {}).userInfo || {};	}
	async loadEntities()			{
										await this.load("providerInfo");
										await this.load("langs");										
									}
	async ngOnInit()				{
		let me = this;
		this.pageInfo.detailInfo			= { ready : false, entities : {} };
		this.pageInfo.step					= 1;
		this.pageInfo.items					= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.detailInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		await this.loadEntities();
		this.pageInfo.detailInfo.ready		= 	true;									
		

		this.pageInfo.detailInfo.forms		= {};

		// INFO
		this.pageInfo.detailInfo.forms.info = [
			{
				cols		: 5,
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'provider', 	label:'_PROVIDER_INNER_NAME',		type: 'text',	editor: 'text',			editable: false			},
					{ mandatory: true, 	field	: 'zone', 		label:'_ZONE',					type: 'text', 	editor: 'autocomplete', entityList: 'zoneList' 	}
				]
			}
			,{
				cols		: 7,
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ label:'_STEP_INFO_PROVIDER_LEGEND',	editor: 'info' }
				]
			}
			,{
				cols		: 4,
				clear		: true,
				title		: "_WIZARD_INFO_MAIN",
				showTitle	: true,
				icon		: "fa fa-check",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'active', 					label:'_ACTIVE', 					type: 'text', 	editor: 'toggle',	info: true		},
					{ mandatory: false, field	: 'coupon', 					label:'_COUPONS', 					type: 'text', 	editor: 'toggle' 															},
					{ mandatory: true, 	field	: 'price_guaranteed',			label:'_PRICE_GUARANTEED', 			type: 'text', 	editor: 'toggle' 															},
					{ mandatory: true, 	field	: 'instant_confirmation',		label:'_INSTANT_CONFIRMATION', 		type: 'text', 	editor: 'toggle' 															},
					{ mandatory: true, 	field	: 'coupon_type', 				label:'_COUPON_TYPE',				type: 'text', 	editor: 'autocomplete', 	multiple: true, entityList: 'couponTypeList'	},
					{ mandatory: true, 	field	: 'groups', 					label:'_FOR_GROUPS',				type: 'text', 	editor: 'toggle' 															}
				]
			},
			{
				cols		: 4,
				title		: "_WIZARD_INFO_OPTIONS",
				showTitle	: true,
				icon		: "fa fa-ban",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'items', 						label:'_OPTIONS_ITEMS', 			type: 'text', 	editor: 'toggle' 												},
					{ mandatory: true, 	field	: 'expect', 					label:'_OPTIONS_EXPECT', 			type: 'text', 	editor: 'toggle' 												},
					{ mandatory: true, 	field	: 'itinerary', 					label:'_OPTIONS_ITINERARY', 		type: 'text', 	editor: 'toggle' 												},
					{ mandatory: true, 	field	: 'journey_times',				label:'_OPTIONS_JOURNEY_TIMES',		type: 'text', 	editor: 'toggle' 												}
				]
			},
			{
				cols		: 4,
				title		: "_WIZARD_INFO_CANCELATION_POLICY",
				showTitle	: true,
				icon		: "fa fa-ban",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'cancel_available', 			label:'_CANCEL_AVAILABLE', 			type: 'text', 	editor: 'toggle' 																		},
					{ mandatory: true, 	field	: 'cancel_reason', 				label:'_CANCEL_REASON', 			type: 'text', 	editor: 'autocomplete', 	multiple: true, entityList: 'cancelReasonList' 				},
					{ mandatory: true, 	field	: 'cancel_devolution_period',	label:'_CANCEL_DEVOLUTION_PERIOD', 	type: 'number',	editor: 'number'	 																	},
					{ mandatory: true, 	field	: 'cancel_devolution_method',	label:'_CANCEL_DEVOLUTION_METHOD', 	type: 'text',	editor: 'autocomplete', 	multiple: true, entityList: 'cancelDevolutionMethodList'	},
					{ mandatory: true, 	field	: 'cancel_conditions', 			label:'_CANCEL_CONDITIONS', 		type: 'text', 	editor: 'autocomplete', 	multiple: true, entityList: 'cancelConditionList'			}
				]
			}
		];

		const providerInfo = this.pageInfo.detailInfo.entities["providerInfo"];

		this.pageInfo.detailInfo.info	= {
			id			: providerInfo.id,			
			active		: providerInfo.active,
			name		: providerInfo.name	|| "",
			zone		: this.pageInfo.detailInfo.entities["zoneList"].find(item=>item.id==providerInfo.zone),
			coupon		: providerInfo.info.coupon				=="true"?true:false,
			groups		: providerInfo.info.groups				=="true"?true:false,
			info_steps	: ( providerInfo.info.info_steps || [] ).map(itemId=>this.pageInfo.detailInfo.entities["infoStepList"]
																.find(item=>item.id==itemId))
		};
		
		this.pageInfo.detailInfo.ready		= 	true;
		this.pageInfo.detailInfoOriginal	=	JSON.stringify({
			info		: this.pageInfo.detailInfo.info
		});
	}

    onUpload(event) {
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}

	action($type,$item){
		switch($type){
			case "close"	: 	this.saveOrClose();								break;
			case "set"		: 	this.pageInfo.step = $item.id;					break;
		}
	}

	async saveOrClose(){
		const currentInfo	= JSON.stringify({
			info		: this.pageInfo.detailInfo.info
		});

		if(currentInfo!=this.pageInfo.detailInfoOriginal){ await this.persistEntity(this.pageInfo.detailInfo); }
		this.emitter.emit({ 'action': 'close' });
	}

	/**
	 * Persist Entity ( DB and Firebase )
	 * @param $item
	 */
	async persistEntity($item)
	{
		let item = { info: {} };

		// Check mandatory fields
		if(($item.info.name||"") == ""	){ this.commons.generateToast("_ERROR","_WIZARD_ERROR_NAME",	"error"); return false; }		

		// Info general
		item.info			= $item.info;

		// Info list
		[ "info_additional", "info_steps", "journey_times", "confirmation" ].forEach(key=>{
			item.info[key]	= ($item.info[key]||[]).map(item=>item.id);	// Get only ID
		});

		// Info dropdowns simples
		[  "zone" ].forEach(key=>{
			item.info[key]	= ($item.info[key]||{}).id;		// Get only ID
		});

		// Info dropdowns multiples
		[  "coupon_type", "cancel_reason", "cancel_devolution_method", "cancel_conditions" ].forEach(key=>{
			item.info[key]	= ($item.info[key]||[]).map(item=>item.id);	// Get only ID
		});

		const vars 		= [ "info" ];
		const records	= [ vars.reduce((o,i)=>{ o[i]=item[i]; return o; },{ }) ];

		console.log("RECORDS",records);

		// API CALL
		this.pageInfo.spinner 	= true;
		return await this.entityService
			.postJSON( this.entityService.getUrl('provider_persist'), { type: "multiple", records: records })
			.then(response=>{
				this.pageInfo.spinner = false;
				if(response["success"]!=true){
					this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
					console.log("RESPONSE",response);
					return false;
				}
				// Create entry into FB to persist offers and other volatile stuff
				// this.firebaseCtrl.setProviderInfo(
				// 	this.userInfo.idDmc,
				// 	this.userInfo.currentDestination.id, 
				// 	response["data"].providerId, 
				// 	response["data"].id,{}
				// );
				this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");
				return true;
			}).catch(response=>{
				this.pageInfo.spinner = false;
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
			});
	}

	async load($entity){
		let response;
		switch($entity){
			case "providerInfo"	:	response = await Promise.resolve(this.entityService.getComplexRequest("providerInfo",{ id:this.info["id"] }));
									this.pageInfo.detailInfo.entities[$entity]	= response["data"];
									break;						
		}
	}
}
