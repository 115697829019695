import { CommonsService } from 'src/app/demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit} from '@angular/core';

@Component({
  selector    : 'app-info-message',
  templateUrl : './info-message.component.html',
  styleUrls   : ['./info-message.component.scss'] ,

})

export class InfoMessageComponent implements OnInit{
    
    constructor(private commons :   CommonsService){

    }

    @Input() message    :   string = '';
    @Input() type       :   string;

    ngOnInit(){
       
    }

    applyClass(){
        return this.type || 'info';
    }
}