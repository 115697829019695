import { SuperformService 		} from '../../../demo/service/super-form/superform.service';
import { EntityService 			} from '../../../demo/service/entity.service';
import { ConfirmationService 	} from 'primeng/primeng';
import { StorageService 		} from '../../../demo/service/storageservice';
import { CommonsService 		} from '../../../demo/service/commons.service';
import { OnInit, 
		 Component, 
		 Input, 
		 Output, 
		 EventEmitter, 
		 ViewChild 				} from "@angular/core";
import { TransportService 		} from 'src/app/demo/service/transports/transports.service';

@Component({
    selector	: 'app-external-transporters',
    templateUrl	: './app-external-transporters.component.html',  
    styleUrls   : ['./app-external-transporters.component.scss']
  })
  export class AppExternalTransporters implements OnInit{
	
	@Input()	fromWizard			:	boolean = false;
	@Input()	dmc					:	any
	@Input()	destination			:	any;
	@Input()	mode				:	any;
	@Input()	stations			:	any;
	@Input()	areas				:	any = [];
	@Input()	settings			:	any = {};
	@Input()	transporters		:	any = [];
	@Input()	types				:	any = [];
	@Input()	form				:	any = {};
	@Output()   emitter				:   EventEmitter<any>   = new EventEmitter();
                                                    
	@ViewChild('transporterFleetCmp')	transporterFleetCmp : any;
	pageInfo : any = {};
	userInfo : any = {};
	hovering : boolean	= false;
	
	constructor(private commons 		: CommonsService,
				private entityService	: EntityService,
				private transportCtrl	: TransportService,	
				private confirmCtrl		: ConfirmationService,
				private superFormCtrl	: SuperformService,
				private storage			: StorageService
	){}

	async ngOnInit() 	{	this.userInfo	=	( this.storage.getItem('dmcSuite') || {}).userInfo || {};
							this.pageInfo.loadingData	=	true;
							this.init();
							await this.loadEntities();	
							this.initForms();
							this.transporters.forEach(el => el.selected = false);
							if(this.transporters.length > 0){
								await this.toggleTransporter(this.transporters[0]);
							}else{
								await this.addTransporter();
							}
							this.pageInfo.loadingData	=	false;
						}

    async init()		{
		this.pageInfo.forms				= {};
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, value : 'transporters', label: this.commons.getTranslate('_INFORMATION'		),	icon : 'fa fa-folder-o', 											 	command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:1, value : 'fleet',		label: this.commons.getTranslate('_FLEET'			),	icon : 'fa fa-bus' 				, _conditional: 'notNew', 				command: ($event)=> { this.setTabItem('main',$event)} 	},					
					{ id:2, value : 'areas',		label: this.commons.getTranslate('_AREAS'			),	icon : 'fa fa-bus' 				, _conditional: 'notNew', 				command: ($event)=> { this.setTabItem('main',$event)} 	},					
					{ id:3, value : 'prices',		label: this.commons.getTranslate('_PRICES'			),	icon : 'fa fa-bus' 				, _conditional: 'notNew', 				command: ($event)=> { this.setTabItem('main',$event)} 	},					
					{ id:4, value : 'services',		label: this.commons.getTranslate('_SERVICES'		), 	icon : 'fa fa-pencil-square-o' 	, conditional: 'currentTransporter', 	command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:5, value :	'conciliation',	label: this.commons.getTranslate('_CONCILIATION'	), 	icon : 'fa fa-handshake-o' 		, conditional: 'currentTransporter', 	command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:6, value :	'invoicing',	label: this.commons.getTranslate('_INVOICING'		),	icon : 'fa fa-file-text-o' 		, conditional: 'currentTransporter', 	command: ($event)=> { this.setTabItem('main',$event)} 	}
				].map((item : any)=> { return {
					...item, 
					// visible : this.settings.tabs.some(value => value == item.value)
					visible : true
				}}),
				selected		: 0
			}
		};		
		this.pageInfo.entities	=	{};
        // this.pageInfo.colFilters 	=	await Promise.all(this.settings.colFilters.map(async item=>await this.commons.translateRecursively(item)));
		console.log('TABS',this.pageInfo.tabs);
	}

	initForms(){
		this.pageInfo.forms.transportForm 	= { 
			ready			: 	true,
			entities		: 	this.pageInfo.entities,
			displayButtons	:	true,
			buttons			: 	{
				items:	[
					{ name: 'save', 	label: '_SAVE_TRANSPORTER',		action: 'save',  	icon: 'fa fa-fw fa-save'},
					{ name: 'delete', 	label: '_DELETE_TRANSPORTER',	action: 'delete',  	icon: 'fa fa-fw fa-save'},
				]
			},	
			form			: 	[
				{
					cols		: 12,
					icon		: "fa fa-user",
					// title		: "_PROPERTY_PRICES_SELL",				
					// padding		: "1rem 1rem 1rem 1rem",
					items		: 	[
						{
							cols		: 6,
							offsetCols	: 0,
							showTitle	: true,
							title		: "_MAIN",
							icon		: "fa fa-check",
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ clean: true, flex: 10,  mandatory: true, 		field	: 'name', 					label:'_DMC_NAME',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: true, 		field	: 'type', 					label:'_DMC_TYPE',					type: 'text',	editor: 'multiselect',	entityList	: 'companyTypeList'	},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'address',		 		label:'_DMC_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'zipcode', 				label:'_DMC_ZIPCODE',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'municipality', 			label:'_DMC_MUNICIPALITY',			type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'region', 				label:'_DMC_REGION',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'country',				label:'_DMC_COUNTRY',				type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	},						
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'activity_name', 			label:'_DMC_ACTIVITY_NAME',			type: 'text',	editor: 'multiselect',	entityList	: 'activityList'	},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_section', 		label:'_DMC_ACTIVITY_SECTION',		type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_epigraph', 		label:'_DMC_ACTIVITY_PARAGRAPH',	type: 'text',	editor: 'text',			editable	: true				}
							]
						},
						{
							title		: "_COMPANY_CONTACT",
							cols		: 4,
							showTitle	: true,
							icon		: "fa fa-phone",
							_padding		: "1rem 1rem 1rem 1rem",
							_widthButtons	: '150px',
							items		: [
								{ clean: true, flex: 10,  mandatory: false, 	field	: 'phone', 					label:'_TRANSPORTER_PHONE',		type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 	field	: 'fax', 					label:'_FAX',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 	field	: 'web', 					label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 	field	: 'contact_name', 			label:'_CONTACT_NAME',			type: 'text',	editor: 'text',			editable	: true				},
								// { clean: true, flex: 10,  mandatory: true, 	field	: 'contact_role', 				label:'_ROLE',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_phone', 		label:'_CONTACT_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_email',		label:'_CONTACT_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_lang',		label:'_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		}
							]
						},
						{
							title		: "_LOGO_TRANSPORTER",
							cols		: 4,
							showTitle	: true,
							icon		: "fa fa-check",
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ clean: true, flex: 10,  mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
							]
						}
					]
				}		
			]
		};

		this.pageInfo.forms.transportForm1	=	{
			ready			: true,
			entities		: this.pageInfo.entities,	
			displayButtons	: false,
			buttons			: 	{
				items:	[
					{ name: 'save', 	label: '_SAVE_TRANSPORTER',		action: 'save',  	icon: 'fa fa-fw fa-save'},
					{ name: 'delete', 	label: '_DELETE_TRANSPORTER',	action: 'delete',  	icon: 'fa fa-fw fa-save'},
				]
			},	
			form:
			{
				cols		: 12,
				icon		: "fa fa-user",
				// title		: "_PROPERTY_PRICES_SELL",				
				// padding		: "1rem 1rem 1rem 1rem",
				items		: 	[
					{
						cols		: 6,
						offsetCols	: 0,
						showTitle	: true,
						title		: "_MAIN",
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ clean: true, flex: 10,  mandatory: true, 		field	: 'name', 					label:'_DMC_NAME',					type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: true, 		field	: 'type', 					label:'_DMC_TYPE',					type: 'text',	editor: 'multiselect',	entityList	: 'companyTypeList'	},
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'address',		 		label:'_DMC_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'zipcode', 				label:'_DMC_ZIPCODE',				type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'municipality', 			label:'_DMC_MUNICIPALITY',			type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'region', 				label:'_DMC_REGION',				type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'country',				label:'_DMC_COUNTRY',				type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	},						
							{ clean: true, flex: 10,  mandatoty: true, 		field	: 'activity_name', 			label:'_DMC_ACTIVITY_NAME',			type: 'text',	editor: 'multiselect',	entityList	: 'activityList'	},
							{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_section', 		label:'_DMC_ACTIVITY_SECTION',		type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_epigraph', 		label:'_DMC_ACTIVITY_PARAGRAPH',	type: 'text',	editor: 'text',			editable	: true				}
						]
					},
					{
						title		: "_COMPANY_CONTACT",
						cols		: 4,
						showTitle	: true,
						icon		: "fa fa-phone",
						_padding		: "1rem 1rem 1rem 1rem",
						_widthButtons	: '150px',
						items		: [
							{ clean: true, flex: 10,  mandatory: false, 	field	: 'phone', 					label:'_TRANSPORTER_PHONE',		type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 	field	: 'fax', 					label:'_FAX',					type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 	field	: 'web', 					label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 	field	: 'contact_name', 			label:'_CONTACT_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							// { clean: true, flex: 10,  mandatory: true, 	field	: 'contact_role', 				label:'_ROLE',					type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_phone', 		label:'_CONTACT_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_email',		label:'_CONTACT_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},
							{ clean: true, flex: 10,  mandatory: false, 		field	: 'contact_lang',		label:'_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		}
						]
					},
					{
						title		: "_LOGO_TRANSPORTER",
						cols		: 4,
						showTitle	: true,
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ clean: true, flex: 10,  mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
						]
					},	
				],
				buttons	:	[
					{ id: 1, name: 'save', 	label: '_SAVE', 	action: 'save', 		icon : 'fa fa-fw fa-save'	},
					{ id: 2, name: 'save', 	label: '_DELETE', 	action: 'delete',		icon : 'fa fa-fw fa-trash'	},
					{ id: 2, name: 'cancel', label: '_CANCEL', 	action: 'cancel',		icon : 'fa fa-fw fa-remove'}
				]
			}
		}
	}

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }

	getTabItems($entity,$type){
		// .map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional] && (this.pageInfo[item.conditional] || {}).isNew); return item; });
		switch($type){
			case "list"		: 	
				this.pageInfo.tabs[$entity].items.forEach(item =>{ 
					if(!item.conditional){ item.disabled = false; }
					switch(item.conditional){
						case "currentTransporter"	:	return true;
						// case 'currentTransporter'	:	return item.disabled = !this.pageInfo[item.conditional];
						case 'notNew'				:	return true;
						// case "notNew"				: 	return item.disabled = !this.pageInfo['currentTransporter'] || this.pageInfo['currentTransporter'].isNew
					}
				});
				return this.pageInfo.tabs[$entity].items;

			case "active"	: 	
				return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

    async loadEntities(){	
		await this.load("countryList");
		await this.load("langList");
		this.pageInfo.dataLoaded = true;	
	}

    async load($entity)	{
		this.pageInfo.entities[$entity]	= this.pageInfo.entities[$entity] || {};	
		let response;
        switch($entity){			
			case "countryList"		:	
				response								=	await Promise.resolve(this.entityService.getRequest("countries",{}));
				this.pageInfo.entities[$entity]			=   ( response["data"] || [] ).filter(el => el.name && el.name != '--unassigned--').map(item=>{
																item.name 	= item.name.toLowerCase();
																item.label		= item.name;
																return item;
															});	
				break;

			case "langList"			:	
				response								=   await Promise.resolve(this.entityService.getRequest("countries",{}));
				this.pageInfo.entities[$entity]			=	[{ id:0, name: 'es', 	label: '_SPANISH' 	},{ id:1, name: 'en', 	label: '_ENGLISH' 	}];
				break;

			case 'typeFleetTransporter'	:	
				response								=	await this.transportCtrl.getTypeFleetTransporter(this.pageInfo.currentTransporter);
				this.pageInfo.entities[$entity].data	=	[];
				if(response.success){
					this.pageInfo.entities[$entity].data=	response.data;					
				}
				break;		
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	async addTransporter(){
		this.pageInfo.tabs.main.selected=0;
		this.transporters.unshift({ isNew : true, id : this.commons.generateHash(+new Date())});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'delete', { visible : false });
		await this.toggleTransporter(this.transporters[0]);
	}
	
	async toggleTransporter(transporter){
		if(this.pageInfo.currentTransporter && this.pageInfo.currentTransporter.id == transporter.id){ return; }
		this.commons.toggleItem(transporter,this.transporters,'selected');

		if(!transporter.selected){	this.pageInfo.forms.transportForm.info	=	{};
									this.pageInfo.forms.transportForm.item	=	{};
									return;
								}
		this.pageInfo.currentTransporter 		=	transporter;
		this.pageInfo.forms.transportForm.info	=	transporter;
		this.pageInfo.forms.transportForm.item	=	transporter;
		// this.superFormCtrl.prepareToFrontend(this.pageInfo.forms.transportForm);
		transporter.isNew ? this.setFooterAsAdding() : this.setFooterAsAdded();

		if(!this.mode || this.mode == 'edit')	{ 	await this.load('typeFleetTransporter');}
		else									{	this.pageInfo.entities['typeFleetTransporter'] 		=	this.pageInfo.entities['typeFleetTransporter'] || {}
													this.pageInfo.entities['typeFleetTransporter'].data	=	transporter.fleet || []; }
		
		if(this.transporterFleetCmp){ // force to bind the first type transport and its own form
			setTimeout(() => {
				this.transporterFleetCmp.unselectTypeTransports();
				this.transporterFleetCmp.selectFirstTransport();	
			}, 250);
			
		}
		
	}

	setFooterAsAdded(){
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'delete', 	{ visible : true});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'save', 	{ label : '_UPDATE'});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'cancel', 	{ visible : false});
	}

	setFooterAsAdding(){
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'delete', 	{ visible : false});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'save',		{ label : '_SAVE'});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.transportForm,'cancel', 	{ visible : true});
	}

	async action($type,$event){
		switch($type){
			case "services"			: 	switch($event.action){
											case "provider_unselected"	:	this.pageInfo.currentProvider	= undefined;		break;
											case "provider_selected"	:	this.pageInfo.currentProvider	= $event["item"];	break;
											case "view_provider"		: 	if(undefined==this.pageInfo.currentProvider){
																				this.commons.generateToast("_ERROR","_PROVIDER_NOT_SELECTED","error");
																				return false;
																			}
																			this.pageInfo.status.providers 		= "providerDetail"; 	
																			break;
											case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
										}
										break;			

			case "conciliation"		: 	this.pageInfo.status.providers 		= "providerList"; break;

			case 'transporterList'	:	
				switch($event.type){
					case "selected"				:	let currentTransporter 					= this.transporters.find(el => el.id == $event.item.item.id);
													if(!currentTransporter){
														this.pageInfo.currentTransporter 					= null;
														this.pageInfo.entities['typeFleetTransporter'].data = [];
													}
													if(!this.pageInfo.currentTransporter || this.pageInfo.currentTransporter.id != currentTransporter.id){ 
														this.pageInfo.currentTransporter 	= $event.item.item;
														if(this.mode == 'edit')	{ 	await this.load('typeFleetTransporter');}
														else					{	this.pageInfo.entities['typeFleetTransporter'] 		=	this.pageInfo.entities['typeFleetTransporter'] || {}
																					this.pageInfo.entities['typeFleetTransporter'].data	=	$event.item.item.fleet || []; }
													}
													console.log(this.transporters);
													break;

					case "saveTransporter"		:	console.log('saveTransporter',$event.item.item);
													$event.item.item.isExternal = true;
													if(this.fromWizard){
														if(this.mode == 'edit')	{	
															await this.saveTransporter($event.item.item);						
														} else	{	
															this.emitter.emit({	action	: 'saveTransporter', 
																				data 	: { transporter : $event.item.item}});	
														}
													}
													else						{	
														await this.saveTransporter($event.item.item);	
													}
													this.pageInfo.tabs.main.selected	=	4;
													break;
					// MISSING DELETE TRANSPORTER
					case "saveFleet"			:	if(this.fromWizard){
														if(this.mode == 'edit') {	await this.saveTransporterFleet($event.item.type);}
														else					{	this.emitter.emit({action : 'saveFleet', data : {transporter : this.pageInfo.currentTransporter, fleet : $event.item.type}})}
													}else						{	await this.saveTransporterFleet($event.item.type)}
													break;
					case "deleteFleet"			:	if(this.fromWizard){
														if(this.mode == 'edit') {	await this.deleteTransporterFleet($event.item.type);}
														else					{	this.emitter.emit({action : 'deleteFleet', data : {transporter : this.pageInfo.currentTransporter, fleet : $event.item.type}})}
													}else						{	await this.deleteTransporterFleet($event.item.type);}
													break;
				}
				break;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			case "types"				:	return (this.types||[]).sort((a,b)=>a.name=="_GENERIC"?1:-1);
			case "transporters"			:	return this.transporters;			
			case 'typeFleetTransporter'	:	return this.pageInfo.entities[$entity].data || [];						
			default						:	return;
		}
	}

	async doAction($event){	
		switch($event.type){
			case "new"		:
			case "add"		:	this.addTransporter();
								break;

			case "button"	:	if(undefined==$event.item		){ return false; }
								if(undefined==$event.item.button){ return false; }	
								if(undefined==$event.item.info	){ return false; }								
								switch($event.item.button.name){
									case "save":	await this.saveTransporter($event.item.info); 
													break;
								}
								break;

			case 'save'		:	if($event.item.isNew){ delete $event.item.isNew; }
								$event.simplifed.isExternal = true;																			
								if(this.fromWizard){
									switch(this.mode){
										case "edit"	:	await this.saveTransporter($event.simplified); 
														break;

										default		:	this.emitter.emit({ 
															action: 'saveTransporter', 
															data : { transporter : $event.simplified }
														});break;
									}
								}else{						
									await this.saveTransporter($event.simplified);
								}
								
								this.setFooterAsAdded();
								this.pageInfo.tabs.main.selected	=	4;
			break;
			case 'delete'	:	this.confirmCtrl.confirm({
									message 	: this.commons.getTranslate('_DELETE_TRANSPORTER_QUESTION'),
									header		: this.commons.getTranslate('_CONFIRMATION'),
									icon		: 'pi pi-exclamation-triangle',
									acceptLabel	:	this.commons.getTranslate('_DELETE'),
									rejectLabel	:	this.commons.getTranslate('_CANCEL'),
									accept: async () => {
										if(this.fromWizard){
											if(this.mode == 'edit')	{	await this.deleteTransporter($event.item);
																		await this.reloadAfterDelete();
																		}
											else						{	this.emitter.emit({action : 'deleteTransporter', data : {transporter : $event.item }});}
										}else						{	await this.deleteTransporter($event.item);
																		await this.reloadAfterDelete()}
											
									}
								});
			break;
			case 'cancel'	:	let findTransporter = this.transporters.findIndex(el => el.isNew);
								if(findTransporter == -1){ return;}
								this.transporters[findTransporter]				=	{};
								this.transporters[findTransporter].isNew 		=	true;
								this.transporters[findTransporter].selected 	=	true;

								this.pageInfo.forms.transportForm.info		=	this.transporters[findTransporter];
								this.pageInfo.forms.transportForm.item		=	this.transporters[findTransporter];

								// this.transporters.splice(findTransporter,1);
								break;
		}						
		console.log($event);	
	}

	async reloadAfterDelete(){
		this.emitter.emit({ action: 'reloadTransporters'});
		// this.pageInfo.forms.transportForm.info	=	{};
		// this.pageInfo.forms.transportForm.item	=	{};
		// await this.addTransporter();	
	}
	/**------------------------------------------
	 * PERSIST INFO  METHODS 
	 * -----------------------------------------
	 */

	async saveTransporter(transporter){
		try {
			switch(this.mode){
				default		:
				case "edit"	:	await this.transportCtrl.saveTransporter({	
									transporter	: transporter, 
									dmc			: this.commons.pageInfo.dmcSelected,
									destination	: this.commons.pageInfo.destinationSelected
								});
								break;
			}	
			this.commons.generateToast("_TRANSPORTER","_SAVE_TRANSPORTER_OK","info");
		} catch(e){
			console.log("SaveTransporter",e);
			this.commons.generateToast("_TRANSPORTER","_SAVE_TRANSPORTER_ERROR","error");
		}
		this.pageInfo.currentTransporter = transporter;
	}

	async deleteTransporter(transporter){
		if(this.mode && this.mode=='edit'){
			await this.transportCtrl.deleteTransporter(transporter, this.dmc,this.destination);
		}else{
			await this.transportCtrl.deleteTransporter(transporter, this.userInfo.idDmc, this.userInfo.currentDestination.id);
		}
		this.pageInfo.currentTransporter = null;
	}

	async saveTransporterFleet(fleet){
		if(this.mode && this.mode == 'edit'){
			await this.transportCtrl.saveFleetExternalTransporter(	{transporter : this.pageInfo.currentTransporter, fleet : fleet},
																	this.dmc,
																	this.destination )
		}else{
			await this.transportCtrl.saveFleetExternalTransporter(	{transporter : this.pageInfo.currentTransporter, fleet : fleet},
																	this.userInfo.idDmc,
																	this.userInfo.currentDestination.id);
		}
	}

	async deleteTransporterFleet(fleet){
		if(this.mode && this.mode == 'edit'){
			await this.transportCtrl.deleteFleetExternalTransporter(	{transporter : this.pageInfo.currentTransporter, fleet : fleet},
																		this.dmc,
																		this.destination);
		}else{
			await this.transportCtrl.deleteFleetExternalTransporter(	{transporter : this.pageInfo.currentTransporter, fleet : fleet},
																		this.userInfo.idDmc,
																		this.userInfo.currentDestination.id);
		}
		
	}
}
