import { Directionality } from '@angular/cdk/bidi';
import { EventEmitter, 
		 Injectable 		} from '@angular/core';
import { CommonsService 	} from '../commons.service';
import { EntityService 		} from '../entity.service';

const astra_arrival_headers	= [
	"Type",
	"FlightDate",
	"FlightTime",
	"From",
	"FlightNumber",
	"To",
	"Reference",
	"#",
	"Transfer Point",
	"Accommodation",
	"Resort",
	"Passenger Name",
	"Pax",
	"Adults",
	"Children",
	"Infants",
	"Agent",
	"Driver Notes",
	"Reservation Note"
];

const astra_departure_headers = [
	"Type",
	"FlightDate",
	"TransferTime",
	"FlightTime",
	"From",
	"FlightNumber",
	"To",
	"Reference",
	"#",
	"Transfer Point",
	"Accommodation",
	"Resort",
	"Passenger Name",
	"Pax",
	"Adults",
	"Children",
	"Infants",
	"Agent",
	"Driver Notes",
	"Reservation Notes"
];

const astra_mappings_arrival= {
	"adults"				: "Adults",
	"location"				: "Accommodation",
	"infants"				: "Infants",
	"arrival_Date"			: "FlightDate",
	"arrival_GatewayInfo"	: "FlightNumber",
	"arrival_Time"			: "FlightTime",
	"arrival_GatewayFrom"	: "From",
	"arrival_GatewayTo"		: "To",
	"children"				: "Children",
	"customer"				: "Passenger Name",
	"pax"					: "Pax",
	"reference"				: "Reference",
	"notes"					: "Reservation Notes",
	"area"					: "Resort",
	"transfer_Point"		: "Transfer Point",
	"group"					: "group",
	"vehicle"				: "vehicle",
	"driver_notes"			: "Driver Notes",
	"agent"					: "Agent",
	"foo"					: "#"
};

const astra_mappings_departures = {
	"PickupTime"			: "TransferTime",
	"adults"				: "Adults",
	"location"				: "Accommodation",
	"infants"				: "Infants",
	"departure_Date"		: "FlightDate",
	"departure_GatewayInfo"	: "FlightNumber",
	"departure_Time"		: "FlightTime",
	"departure_GatewayFrom"	: "From",
	"departure_GatewayTo"	: "To",
	"children"				: "Children",
	"customer"				: "Passenger Name",
	"pax"					: "Pax",
	"reference"				: "Reference",
	"notes"					: "Reservation Notes",
	"area"					: "Resort",
	"transfer_Point"		: "Transfer Point",
	"group"					: "group",
	"vehicle"				: "vehicle",
	"driver_notes"			: "Driver Notes",
	"agent"					: "Agent",
	"foo"					: "#"
};

@Injectable({
  providedIn: 'root'
})
export class ImporterService {
	eventEmitter: EventEmitter<any>= new EventEmitter();
    entities: any = [ "bookings", "providers" ];
    pageInfo: any = { bookings: [], importTrace: []};
    
    constructor(
        private commons				: CommonsService,
        private entityService		: EntityService
    ){ }
  
    ngOnInit() 							{
      this.pageInfo.uploadedFiles	= [];							
    }
  
    onUpload($type,$info) 				{		
      	switch($info.type){
			case "audios"		:
			case "pictures"		:
			case "bookings"		:	
			case "services"		:
				for(let file of $info.event.files) {  
					this.processFile({ ...$info, file: file });
				}
				break;
			default				:	alert("OnUpload " + $info.type); break;
      	}
    }

	processFile($info)					{		
		let type = $info.format;
		switch(type){
			case "csv":		let fileReader		= new FileReader();										
							fileReader.onload 	= (() => { 
								switch($info.type){
									// case "bookings": this.processBookingsCSV(<string>fileReader.result); break;
									case "services": this.processServicesCSV($info.direction, <string>fileReader.result); break;
								};
							});

							fileReader.onerror = (error) => {
								this.pageInfo.importTrace.push({	type	:	"error",
																	label	:	this.commons.getTranslate("_FILE"),
																	content	: 	this.commons.getTranslate("_PROCESSING_FILE_ERROR")
																})
							}
							fileReader.readAsText($info.file);
							break;

			case "xlsx":	switch($info.type){
								// case "bookings": this.processBookingsExcel($info.file);								
							}
							break;
		}
	}

// ----------------------------------------------------------------------------
// SERVICES
// ----------------------------------------------------------------------------

	processServicesCSV($direction, $info)			{	
		let header,entries,data;
		let wrongDelimiter 	= false;
		let services		= [];
		let bookings		= [];
		let group, vehicle, driver;
		
		( $info.split('\n') || [] ).forEach(($line,$index)=>{
			if(wrongDelimiter){ return; }
			data = $line.split(";") || [];
			if(data.length<2){ 
				switch(data[0]){
					case ""	:	console.log("Última linea",$index);	break;
					default	:	wrongDelimiter = true;
								this.pageInfo.importTrace.push({ type: "error", label: "error", content: this.commons.getTranslate("_CSV_WRONG_DELIMITER") });
								break;
				}
			} else {							
				switch($direction){
					case "arrivals"		:	if($index<12){ break; }
											this.processCSVLine({
												data 		: data,
												direction	: $direction,
												header		: astra_arrival_headers,
												index		: $index,
												bookings	: bookings
											});	
											break;

					case "departures"	:	if($index<13){ break; }
											this.processCSVLine({
												data 		: data,
												direction	: $direction,
												header		: astra_departure_headers,
												index		: $index,
												bookings	: bookings
											});	
											break;
				}
			}
		});

		this.pageInfo.bookings = bookings;

		let $action = "persist";

		console.log("Bookings",this.pageInfo.bookings);
		
		switch($action){
			case "persist"	: 	let response = this.persistBookings(); 
								this.eventEmitter.emit({ type: "services", action: $action, response: response }); break;
								break;
			case "emit"		: 	this.eventEmitter.emit({ type: "services", action: $action, items: this.pageInfo.bookings }); break;
		}
	}

	processCSVLine($params){
		let data 		= $params["data"		];
		let direction	= $params["direction"	];
		let header		= $params["header"		];
		let index		= $params["index"		];
		let bookings	= $params["bookings"	];

		// let group, vehicle, driver;

		let result 	= this.getServiceJSON({ direction: direction, header: header, item: data, index: index });
		let item 	= result["item"];
		switch(result["type"]){
			case "none"		: 	break;
			case "group"	:	switch(direction){
									case "arrivals"		:	
										this.pageInfo.group 	= item["Type"];
										this.pageInfo.vehicle	= item["From"];
										this.pageInfo.driver  	= item["To"];
										break;
									case "departures"	:
										this.pageInfo.group 	= item["Type"];
										this.pageInfo.vehicle	= item["FlightTime"];
										this.pageInfo.driver 	= item["FlightNumber"];
								}
								break;
			case "booking"	:	item["group"] 	= this.pageInfo.group;
								item["vehicle"]	= this.pageInfo.vehicle;
								item["driver"]	= this.pageInfo.driver;
								bookings.push(item);
								break;
		}
	}

	async persistBookings(){
		let message = { succeded_qty: 0, errors_qty: 0, errors: [] };
		let response;
		
		// let bookings = (this.pageInfo.bookings||[]).filter(item=>item.agent=="WTR");
		let bookings 	= this.pageInfo.bookings;

		let responseAll = await Promise.all(bookings.map(booking=>this.persistBooking(booking)));

		// responseAll = await Promise.all(bookings.map(booking=>{
		// 	response = await this.persistBooking(booking);
		// 	if(response["success"]	)	{	message["succeded"]++;		
		// 	} else 						{	
		// 		message["errors_qty"]++;
		// 		message["errors"].push(response["error"]);
		// 	}
		// 	return message;
		// }));

		return responseAll;
	}

	/**
	 * Persist Booking
	 * 
	 * @param $item
	 */
	 async persistBooking($item) 		{	
		let params		= { 	
			dmc				: this.commons.userInfo.dmc.id,
			destination		: this.commons.userInfo.destination.id,
		};

		switch($item.provider){
			// Mark WTR bookings as privetes
			case 15			: $item.shared = "premium"; break;
		}

		// if($item.plate){
		// 	$item.vehicle = $item.plate;
		// 	delete $item.plate;
		// }
		
		// if($item.reference!="OTBT-17727257"){ return false; }
		let response 	= await this.entityService.postJSON(
			this.entityService.getUrl("booking_imported_update"),
			{ ...params, ...$item }
		)
		return response;
	}

	/**
	 * generate JSON from from CSV format
	 * @param $params 
	 */
	private getServiceJSON($params):any{
		let direction	= $params["direction"];
		let header 		= $params["header"];
		let item		= $params["item"];
		let index		= $params["index"];
		let type;
		let itemInfo 	= {};

		let result	= header.reduce((o,h,i)=>{
			o[h] = item[i];
			return o;
		},{});

		if		(result["Type"]==""								)	{ type = "none"		}
		else if	(result["Type"].toLowerCase().includes("group")	)	{ type = "group"	}
		else														{ type = "booking"	}

		switch(type){
			default			: itemInfo = result; break;
			case "booking"	:
				switch(direction){
					case "arrivals":				
						Object.keys(astra_mappings_arrival).forEach(field=>{
							itemInfo[field] = result[astra_mappings_arrival[field]];
						});
						itemInfo["shared"			]	= result["Type"].includes("SH")?'shuttle':"private";
						itemInfo["vehicles"			]	= 1;
						itemInfo["verified"			]	= "yes";
						itemInfo["direction"		]	= "arrival";
						itemInfo["arrival_Date"		]	= this.commons.pageInfo.moment(itemInfo["arrival_Date"],"DD/MM/YYYY").format("YYYY-MM-DD");
						break;
					case "departures":				
						Object.keys(astra_mappings_departures).forEach(field=>{
							itemInfo[field] = result[astra_mappings_departures[field]];
						});
						itemInfo["shared"			]	= result["Type"].includes("SH")?'shuttle':"private";			
						itemInfo["vehicles"			]	= 1;
						itemInfo["verified"			]	= "yes";
						itemInfo["direction"		]	= "departure";
						itemInfo["departure_Date"	]	= this.commons.pageInfo.moment(itemInfo["departure_Date"],"DD/MM/YYYY").format("YYYY-MM-DD");
						break;
				}
		}

		return { 
			type: type,
			// item: result
			item: itemInfo
		};
	}

	/**
	 * add Service
	 * 
	 * @param $header 
	 * @param $item 
	 * @param $index 
	 * @returns 
	 */
	async addService($header,$item,$index){	
		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length});												
		}
	
		let result = {};

		($header||[]).forEach((header,index)=>{
			result[header] = $item[index];
		});

		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length });
			return false;
		}										

		try {
			// let response = await Promise.resolve(this.persistBooking(result));
			// switch(response["action"]){
			// 	default			: this.pageInfo.importTrace.push({ type: "error", 	label: "error", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
			// 	case "insert"	: this.pageInfo.importTrace.push({ type: "insert", 	label: "insert", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
			// 	case "update"	: this.pageInfo.importTrace.push({ type: "update", 	label: "update", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;									
			// 	case "skip"		: this.pageInfo.importTrace.push({ type: "skip", 	label: "skip", 		content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;														
			// }
			return true;
		
		} catch(e){
			return false;
		}										
	}
}  
