import { Component, 
        Input, 
        Output, 
        ViewEncapsulation,		
		ViewChild,
		OnInit, 
		EventEmitter			} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { ConfirmationService 	} from 'primeng/api';

@Component({
	selector    		: 'app-company-modules',
	styleUrls			: ['./app-company-modules.component.scss'],
	encapsulation		: ViewEncapsulation.None,
	templateUrl 		: './app-company-modules.component.html'  
})
export class CompanyModulesComponent implements OnInit{
    
    @Input()    modules				 		:   any;
	@Input()	form						:	any;
	@Input()	modulesCompany				:	any;
	@ViewChild('formModules') formModules 	:	any;
	@Output()	emitter						= 	new EventEmitter<any>();	
	pageInfo								:	any	=	{};

	constructor(
		private commons 			:   CommonsService,
		private confirmationService	: 	ConfirmationService
	){}

    ngOnInit(): void 				{	this.pageInfo = {
											modulesCompany	:	this.modulesCompany || [],
											defaultImg		:	'https://img.icons8.com/windows/452/module.png'
										}
										this.initForms();
										this.toggleModule(this.modules[0]);
									}

	initForms()						{}

	toggleModule(module)			{	if(this.pageInfo.moduleSelected && this.pageInfo.moduleSelected.id == module.id ){ return false; }
										this.pageInfo.moduleSelected ? this.pageInfo.moduleSelected.sections.forEach(item => item.selected = false) : null;
										this.restartForm();
										this.commons.toggleItem(module,this.modules,'selected');
										this.pageInfo.moduleSelected			=	module.selected ? module : null;
										if(!this.pageInfo.moduleSelected){ return; }

										let companyModule						=	this.getCompanyModule();
										if(companyModule){
											this.pageInfo.moduleSelected.sections.forEach(section =>{
												companyModule.sections.find(compSection => compSection.id == section.id) ? section.selected = true : null;
											});
										}
										this.pageInfo.displayForm	=	false;
										this.form.info				=	companyModule ? companyModule['formInfo'] || {} : null;
										this.form.info == null ? this.form.item = {} : this.cloneForm();
										this.pageInfo.displayForm	=	true;

										this.checkItemsFreeTrial();	// check if enable or disable the elements
										this.checkItemsDiscount();
										this.pageInfo.displayErrorSection	=	false;
										
										console.log(this.pageInfo.modulesCompany);
									}

	getCurrentModule()				{	return this.pageInfo.moduleSelected ? this.pageInfo.moduleSelected : null;	}

	getSectionsModule()				{	return (this.pageInfo.moduleSelected && this.pageInfo.moduleSelected['sections'])	
												? this.pageInfo.moduleSelected['sections']
												: []; 
									}

	clickSection(selectedSection, fromCheckbox?){
										selectedSection.selected = undefined != fromCheckbox ? fromCheckbox : !selectedSection.selected;
										console.log(selectedSection);
										console.log(this.pageInfo.modulesCompany);	
									}
	
	checkIfSectionSelected(currentSection){
										let currentModule	=	this.getCompanyModule();
										return 	currentModule	
													? currentModule.sections.find(section => section.value == currentSection.value) || {}
													: {};
									}

	clickSectionFromCheckBox($event,section){	this.clickSection(section,$event);	}

	doAction($type, $info)			{	console.group("modules::doAction");
										console.log("Type",$type,"info",$info);
										switch($type){
											case "emitter"	:	switch($info.event.action){
																	default			:	return;
																	case 'save'		:	this.pageInfo.moduleSelected.formInfo	=	$info.event.item;	break;
																	case 'toggle'	:	this.handleToggleAction($info.event); 							break;	
																}
																break;
											case "save"		:	this.saveModule();		break;
											case "delete"	:	this.deleteModule();	break;
										}
									}

	handleToggleAction($event)		{
										let findedItem		=	this.getItemForm($event['extra']['field']);
										if(!findedItem){ return; }
										switch(findedItem.field){
											case 'free_trial_status'	:	
												console.log(this.form);
												let currentModule	=	this.getCompanyModule();
												console.log('currentModule',currentModule);
												if(currentModule){
													if(currentModule.formInfo.hire_init && $event['item'][$event['extra']['field']]){
														this.changePropertiesItemForm('free_trial_status',	{ 	disabled : true}) // check is just to put something on the value 
														this.changePropertiesItemForm('free_trial_days',	{	disabled : !$event['item'][$event['extra']['field']], invalidInfo : 'check'}) // check is just to put something on the value 
														this.changePropertiesItemForm('start_free_trial',	{	disabled : !$event['item'][$event['extra']['field']], invalidInfo : 'check'});
														return this.commons.generateToast('_INFO','_DELETE_MODULE_TO_CONTINUE','info');
													}else if(currentModule.formInfo.start_free_trial && !$event['item'][$event['extra']['field']]){
														return this.commons.generateToast('_INFO','_DELETE_MODULE_TO_CONTINUE','info');
													}
												}

												this.changePropertiesItemForm('free_trial_days',	{	disabled : !$event['item'][$event['extra']['field']], invalidInfo : 'check'}) // check is just to put something on the value 
												this.changePropertiesItemForm('start_free_trial',	{	disabled : !$event['item'][$event['extra']['field']], invalidInfo : 'check'});
												this.changePropertiesColForm('period_hire',			{	disabled :  $event['item'][$event['extra']['field']]}) 
												this.form.item					=	this.form.item || {};
												this.form.item.free_trial_days	=	$event['item'][$event['extra']['field']] ? 14 : null; // by default set to 14 days as maximum
												break;
											case 'discount_module'	:
												this.changePropertiesItemForm('discount_number',	{	disabled : !$event['item'][$event['extra']['field']], invalidInfo : 'check'});
											break;
										}	
									}

	getCompanyModule()				{	if(!this.pageInfo.moduleSelected){ return {}; }
										return this.pageInfo.modulesCompany.find(module => module.id == this.pageInfo.moduleSelected.id);
									}

	removeCurrentModule()			{	if(!this.pageInfo.moduleSelected){ return {}; }
										let indexModule	=	this.pageInfo.modulesCompany.findIndex(module => module.id == this.pageInfo.moduleSelected.id);
										indexModule > -1 ? this.pageInfo.modulesCompany.splice(indexModule,1) : null;
									}

	getInfoCompany(moduleId)		{
										if(!this.pageInfo.modulesCompany){ return null; }
										let findCompany	= this.pageInfo.modulesCompany.find(module => module.id == moduleId);
										return (!findCompany || !findCompany.formInfo)	
												? 	null	
												:	{
														type		:	findCompany.formInfo.free_trial_status ? '_FREE_TRIAL' 	: '_MODULE_HIRED',
														background	:	findCompany.formInfo.free_trial_status ? 'orange' 		: 'forestgreen'
													} 

									}

	/**
	 * Save the current module. 
	 * 1 section required minumom
	 * Desactivate period on TRIAL
	 * Desactivate TRIAL on period
	 */
	saveModule()					{	console.group("saveModule");
										let validatorForm	=	this.formModules.validate();
										if(!validatorForm)	{	return this.commons.generateToast('_ERROR','_VALIDATE_ITEMS','error');}
										
										// let currentModule	=	this.getCompanyModule();
										if(!this.pageInfo.moduleSelected || this.pageInfo.moduleSelected.sections.filter(el => el.selected).length == 0){ this.pageInfo.displayErrorSection = true; return this.commons.generateToast('_ERROR','_SELECT_SECTIONS','error');}
										
										this.pageInfo.moduleSelected.formInfo	=	this.formModules.info.item;

										const findedModule	=	this.pageInfo.modulesCompany.findIndex(el => el.id == this.pageInfo.moduleSelected.id);
										if(findedModule > -1 ){
											this.pageInfo.modulesCompany[findedModule].formInfo = this.formModules.info.item;
											this.pageInfo.modulesCompany[findedModule].price	= this.calculateCurrentModulePrice();
											this.pageInfo.modulesCompany[findedModule].sections = this.pageInfo.moduleSelected.sections.filter	(el			=> el.selected)
																																		.map	(section	=> { return {id : section.id, value : section.value, selected : true }})
										}else{
											this.pageInfo.modulesCompany.push({ id 			: this.pageInfo.moduleSelected.id,
																				value		: this.pageInfo.moduleSelected.value,
																				price		: this.calculateCurrentModulePrice(),
																				formInfo	: this.pageInfo.moduleSelected.formInfo, 
																				sections 	: this.pageInfo.moduleSelected.sections.filter	(el			=> el.selected)
																																	.map	(section	=> { return {id : section.id, value : section.value, selected : true }})})
										}
										this.pageInfo.displayErrorSection = false;
										
										this.commons.generateToast('_SUCCESS','_MODULE_SAVED','success');
										this.displayNextModule();
										console.groupEnd();
									}

	deleteModule()					{	this.confirmationService.confirm({	
											message 	:	this.commons.getTranslate('_REMOVE_MODULE_QUESTION'),
											header		:	this.commons.getTranslate('_CONFIRMATION'),
											icon		:	'pi pi-exclamation-triangle',
											acceptLabel	:	this.commons.getTranslate('_REMOVE'),
											rejectLabel	:	this.commons.getTranslate('_CANCEL'),
											accept		: 	async () => {
																this.removeCurrentModule();
																this.pageInfo.moduleSelected.sections.forEach(el => el.selected = false);
																this.checkItemsFreeTrial();	// check if enable or disable the elements
																this.checkItemsDiscount();
																this.restartForm(); 
																//guardar temporal modul actual i tornarlo a cridar
																let idCurrentModule	=	this.pageInfo.moduleSelected.id;
																this.toggleModule(this.pageInfo.moduleSelected);
																this.pageInfo.moduleSelected = this.modules.find(module => module.id == idCurrentModule);
																this.toggleModule(this.pageInfo.moduleSelected);
																console.log('Form is', this.form);										
															}
										});	
									}

	calculateCurrentModulePrice()	{	let priceModules	=	this.getSumPriceModule();
										let currentModule	=	this.modules.find(module => module.id == (this.pageInfo.moduleSelected || {}).id);
										if(!currentModule){ return null };
										return currentModule.pricing ? (priceModules + currentModule.pricing.base_price) : priceModules;
									}

	calculatePriceModule(moduleId)	{	let currentModule		=	this.modulesCompany.find(item => item.id == moduleId);
										if(!currentModule)		{ 	return null }
										
										let infoModule			=	this.modules.find(item => item.id == currentModule.id);
										if(!infoModule)			{	return	null};

										let sectionsWithPrice	=	currentModule.sections.filter(item => item.selected);
										let listPrices			=	sectionsWithPrice.map(section => { return infoModule.sections.find(moduleSection => section.id == moduleSection.id)});
										let priceSections		=	listPrices.reduce((sum,current) => sum + (current.pricing.qty || 0),0);
										
										return infoModule.pricing ? (infoModule.pricing.base_price + priceSections) : priceSections;
									}
	

	getSumPriceModule()				{	if(!(this.pageInfo.moduleSelected && this.pageInfo.moduleSelected['sections'])){ return null }
										let sumPriceSections	=	this.pageInfo.moduleSelected.sections.filter(section => section.selected).reduce((sum,current) => sum + (current.pricing.qty || 0),0);
										
										let currentModule		=	(this.formModules && Object.keys(this.formModules.info.item).length > 0) ? {formInfo : this.formModules.info.item} : null;
										if(!currentModule){
											currentModule 		=	this.pageInfo.modulesCompany.find(module => module.id == this.pageInfo.moduleSelected.id);			
										}
										return (currentModule && currentModule.formInfo.discount_number) 	?	sumPriceSections * ((100 - +currentModule.formInfo.discount_number)/100)
																											:	sumPriceSections;
									}

	getSumAllModules()				{	return this.modules.map(module => this.calculatePriceModule(module.id)).filter(module => module!=null).reduce((sum,current)=> sum + current,0);	}

	displayNextModule()				{	const currentIndexModule	=	this.modules.findIndex(el => el.selected);
										this.modules.forEach(module => module.selected = false);
										return currentIndexModule + 1 >= this.modules.length	? 	this.emitter.emit({action : 'nextSection'}) 
																								:	this.toggleModule(this.modules[currentIndexModule + 1]);
									}

	restartForm()					{	this.form.item = {};
										this.form.info = {};										
										this.form.form.items.forEach(item => {
											item['invalidInfo'] ? delete item.invalidInfo : null
										});
									}

	getItemForm(field)				{	let findedGroup	=	this.form.form.find(section => section.items.find(item => item.field == field));
										if (!findedGroup || !findedGroup.items){ return null }
										return findedGroup.items.find(item => item.field == field) || null;
									}

	getItemInfo(field)				{	if(!this.form.item){ return null; }
										return this.form.item[field] || null;
									}

	changePropertiesItemForm(field,properties){
										let itemData					=	this.getItemForm(field);
										Object.keys(properties).forEach(property =>{
											switch(property){
												default 			: itemData[property]	=	properties[property]; break;
												case 'invalidInfo'	: itemData.invalidInfo	?	delete itemData.invalidInfo : null; break;
											}
										});
									} 

	changePropertiesColForm(field, properties){
										let findedCol	=	this.form.form.find(section => section.value == field);
										if(!findedCol){ return; }
										Object.keys(properties).forEach(property => {
											switch(property){
												default	:	findedCol[property]	=	properties[property]; break;
											}
										});	
									}

	checkItemsFreeTrial()			{	let statusToggle	=	this.getItemInfo('free_trial_status');
										if(statusToggle == null || statusToggle == undefined ){ statusToggle = false; }
										this.changePropertiesItemForm('free_trial_days',	{ disabled : !statusToggle, 	invalidInfo : 'check'}) // check is just to put something on the value 
										this.changePropertiesItemForm('start_free_trial',	{ disabled : !statusToggle, 	invalidInfo : 'check'});
										this.changePropertiesColForm('period_hire',			{ disabled : statusToggle}) 
									}

	checkItemsDiscount()			{	let statusToggle	=	this.getItemInfo('discount_module');
										if(statusToggle == null || statusToggle == undefined ){ return; }
										this.changePropertiesItemForm('discount_number',{disabled : !statusToggle, 	invalidInfo : 'check'});
									}

	checkIfRemovableModule()		{	let currentModule = this.getCompanyModule();
										return !(currentModule && currentModule.formInfo);
									}

	cloneForm()						{	let clonedItem		=	JSON.parse(JSON.stringify(this.form.info));
										
										Object.keys(clonedItem).forEach(key =>{
											switch(Object.prototype.toString.call(clonedItem[key])){
												case '[object String]'	:	
													switch(true){
														case	!isNaN(clonedItem[key]) 					:   return clonedItem[key] = +clonedItem[key]; 
														case	this.commons.isValidDate(clonedItem[key])	:	return clonedItem[key] = new Date(this.commons.formatTimestamp(clonedItem[key]));
													
													}
												case '[object Object]'	:	
													switch(true){
														case this.commons.isValidDate(clonedItem[key])	:	console.log('IS VALID DATE'); return clonedItem[key]	=	new Date(this.commons.formatTimestamp(clonedItem[key]));
														default : break;
													}
												default					:	return;
											}
										});
										this.form.item	=	clonedItem;
									}
}