import { Http			 						} from "@angular/http";
import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation	} from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { FirebaseService 						} from './../../../service/database/firebase.service';
import { StorageService 						} from './../../../service/storageservice';

interface Calendar {
	value	: any,
	date	: string,
	last	: string
};

@Component({
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class DashboardResellerComponent implements OnInit
{
    locale      : string 	= 'es';    
	pageInfo	: any 		= {};
	userInfo	: any 		= {};
	bookings	: any		= {};
	calendar	: Calendar 	= <Calendar>{ last: '', value: new Date() };
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService		
	)					{ this.init(); 					}
    async ngOnInit() 	{ await this.loadEntities();	this.pageInfo.entities			= { destinations: [ 'Mallorca', 'Tenerife' ]	};}
    async init()		{		
		this.pageInfo.dataLoaded      	= false;	
		// this.pageInfo.entities			= { destinations: [ 'Mallorca', 'Tenerife' ]	};
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {};
		this.pageInfo.trends			= [
			{ id: 1, icon: "paper",		name: "_EARNINGS", 		count: "5543€",	value: "+15%" 	},
			{ id: 2, icon: "mail",		name: "_EXPENDITURES",	count: "3216€", value: "+21%" 	},
			{ id: 3, icon: "location",	name: "_BENEFITS", 		count: "2227€", value: "+43%" 	},
			{ id: 4, icon: "location",	name: "_BOOKINGS", 		count: "1243", 	value: "+3%" 	},
			{ id: 5, icon: "location",	name: "_SERVICES", 		count: "34/42", value: "80%" 	},
			{ id: 6, icon: "location",	name: "_TRANSPORTERS", 	count: "12/23", value: "52%" 	},
			{ id: 7, icon: "location",	name: "_DRIVERS", 		count: "15/15", value: "100%" 	},
			{ id: 8, icon: "orders",	name: "_PROVIDERS", 	count: "2/2", 	value: "100%" 	}
		];
		this.pageInfo.contacts			= [
			{ id: 1, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 2, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 3, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 4, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 5, name: "Emily Clark", email: "eclark@radeon.com", status: "online" }
		];	
		
		this.bookings	= {
			toggles	: [ "arrival", "departure" ],
			period	: {
				types	: [ 
					{ label: "_WEEK", 	value: "week", 	icon: 'fa fa-calendar' 	},
					{ label: "_MONTH",	value: "month", icon: 'fa fa-calendar' 	},
					{ label: "_YEAR", 	value: "year",	icon: 'fa fa-calendar' 	} 
				],
				selected: "month"
			}
		}
		// Translate
		this.bookings.period.types.forEach(async item=>{ item.label = await this.commons.getTranslate(item.label) });
		this.calendarChange();
	}	

    async loadEntities(){	this.pageInfo.dataLoaded = true;	}
    async load($entity)	{
        switch($entity){
			case "bookings":
				let response 							= await Promise.resolve(this.entityService.getRequest("bookings_digest",{ startdate: this.calendar.date, offset:30 }));
				let data								= response["data"];
				this[$entity].data						= data;
				this[$entity].days						= data["days"] || [];
				this[$entity].arrival_bookings			= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].bookings);
				this[$entity].departure_bookings		= Object.keys(data["departure"	]).map(day=>data["departure"][day].bookings);
				this[$entity].arrival_pax				= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].pax);
				this[$entity].departure_pax				= Object.keys(data["departure"	]).map(day=>data["departure"][day].pax);
				this[$entity].arrival_avg				= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].avg);
				this[$entity].departure_avg				= Object.keys(data["departure"	]).map(day=>data["departure"][day].avg);
				
				this[$entity].bookings_pending			= Math.ceil(Math.random()*this[$entity].arrival_bookings);

				// Compute aggregation
				this[$entity].arrival_bookings_total	= this[$entity].arrival_bookings	.reduce((acc,item)=>acc+item,0);
				this[$entity].departure_bookings_total	= this[$entity].departure_bookings	.reduce((acc,item)=>acc+item,0);
				this[$entity].arrival_pax_total			= this[$entity].arrival_pax			.reduce((acc,item)=>acc+item,0);
				this[$entity].departure_pax_total		= this[$entity].departure_pax		.reduce((acc,item)=>acc+item,0);

				this[$entity].arrival_avg_total			= this[$entity].arrival_avg			.reduce((acc,item)=>acc+item,0)/this[$entity].arrival_avg.length;
				this[$entity].departure_avg_total		= this[$entity].departure_avg		.reduce((acc,item)=>acc+item,0)/this[$entity].departure_avg.length;

				// console.log("ENTITY "+$entity,response);
				break;
		};        
	}

	async calendarChange(){
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];
		console.log("CALENDAR",this.calendar);
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last = this.calendar.date;
			await this.load('bookings');
			this.generateCharts('bookings',this['bookings']);
		}
	}

	async generateCharts($entityName,$entity){

		$entity.charts = undefined===$entity.charts?{}:$entity.charts;

		switch($entityName){
			case "bookings":
				$entity.charts["bookings"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_BOOKINGS'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_bookings 		},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_bookings 	}
					 ]
				});
				$entity.charts["pax"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_PAX'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_pax 		},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_pax 	}
					 ]
				});
				break;
		}
	}

	async generateChartByType($params){
		return { 
			line : {
				type	: "bar",
				options	: {
					title: {
						display	: false,
						text	: $params.title,
						fontSize: $params.titleSize || 20
					},
					legend: {	
						position: $params.legendPosition || 'bottom'
					}			
				},
				data 	: {
					labels	: $params["labels"],
					datasets: $params.datasets.map(dataset => ({
									label			: dataset.label,
									data			: dataset.data,
									steppedLine		: dataset.steppedLine 	|| false,
									tension			: dataset.tension 		|| 0,
									backgroundColor	: dataset.color,
									borderColor		: dataset.borderColor 	|| '#505050'								
								}))							
				}
			}
		};
	}
}