import { AuthFirebaseService	} from './demo/service/database/authfirebase.service';
import { CommonsService 		} from './demo/service/commons.service';
import { EntityService 			} from './demo/service/entity.service';
import { OnInit 				} from '@angular/core';
import { Component 				} from '@angular/core';
import { TranslateService   	} from '@ngx-translate/core';
import { StorageService     	} from './demo/service/storageservice';
import { Router             	} from '@angular/router';
import { FirebaseService 		} from './demo/service/database/firebase.service';
import { firebaseForm 			} from './config/app.form';
import { CompanyService 		} from './demo/service/database/company.service';
import { GoogleService 			} from './demo/service/google/google.service';
import { MapService 			} from './demo/service/mapservice';
import { pipe 					} from 'rxjs';
import { map 					} from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { TransportService } from './demo/service/transports/transports.service';

@Component({
    selector	: 'app-root',
    templateUrl	: './app.component.html'
})
export class AppComponent implements OnInit {

    public menuMode 		= 'horizontal';
    public menuActive 		= false;
    public topbarMenuActive = false;
    activeTopbarItem		: Element;
    menuClick				: boolean;
    menuButtonClick			: boolean;
    topbarMenuButtonClick	: boolean;
    resetMenu				: boolean;
    menuHoverActive			: boolean;
    pageInfo				: any = {
		dataLoaded	:	false,
		userLogged	:	false,
        header: {
            mainTitle   : 'Tourinia',
			mainSubtitle: 'DMC',
			mainLogo	: 'assets/layout/images/tourinia_logo.png'
        },
        i18n    : {
            loaded     	: false,
            items    	: []
        },
		entities		:	{ 'firebase' : {} }
    };

	public userInfo: any = {}	

    constructor(
		private mapService 				: MapService,
        private commons					: CommonsService,
        private entityService			: EntityService,
        private translate       		: TranslateService,
        private storage         		: StorageService,
        private router          		: Router,
		private authFirebaseService    	: AuthFirebaseService,
		private firebaseService			: FirebaseService,
		private companyService			: CompanyService,
		private transportService		: TransportService
		// private googleCtrl 			: GoogleService
	){	
		this.authFirebaseService.subscribeAuthState().subscribe(async authState => { this.loginWatchdog(authState); });	
		this.pageInfo.version 		= this.commons.pageInfo.version;
		this.pageInfo.globalEntities= [ 
			"providers" 
		];
		this.pageInfo.userEntities 	= [ 
			// "zones", 
			// "tourinia_zones", 
			// "areas", 
			// "provider_areas",
			// "dmcs",
			"config_modules" 
		];
	}

	/***
	 * Login watchdog
	 * Raised each time login action is performe
	 * @param $state 
	 */
	 async loginWatchdog($state){
		console.group();
		console.log("AUTHSTATE",$state);

		if($state==null){
			this.authFirebaseService.logOut();
			if(this.router.url!=this.commons.pageInfo.defaultNotLoggedPage){ 
				this.router.navigate([ this.commons.pageInfo.defaultNotLoggedPage ]); 
			}
		}

		if($state && $state!=null && $state.emailVerified){		// Logged and email verified

			await this.commons.generateTranslates();
			this.loadGlobalEntities();
			
			await this.mountUser($state);

			this.pageInfo.entityType		= this.commons.getEntityProfile(this.commons.userInfo.profile);
			this.pageInfo.dataLoaded 		= true;

			// this.commons.pageInfo.storage	= this.storage.getItem('dmcSuite');
			
			this.loadUserEntities();			

			const page = this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].loggedWithSetupDone;
			this.router.navigate([page]);

			let type = this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type];
			console.log("Type",type);
			console.groupEnd();

			if(undefined==this.commons.userInfo.transporter){
				this.commons.generateToast("_ERROR","_NOT_TRANSPORTER_ACCOUNT","error");
				this.commons.userInfo.transporter = this.commons.userInfo.transporter || {
					id			: "santiago_bus",
					name 		: "Santiago bus",
					destination	: "QGBHKJzKHcGtoiVY5tVU"
				}
			}

			// this.storage.setItem('dmcSuite', this.commons.pageInfo.storage );

			this.router.navigate([this.commons.userInfo.currentDestination
									?type.loggedWithSetupDone
									:type.loggedWithSetupNotDone]);

		} else {												// Not logged or email not verified

			this.commons.userInfo		=	{}
			this.pageInfo.dataLoaded 	= 	true;

			console.groupEnd();

			if(!this.pageInfo.entityType)	{	
				this.router.navigate([this.commons.pageInfo.defaultNotLoggedPage]);		
			}
			else							{
				let page = this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].notLoggedPage;	
				if(this.router.url!=page){ this.router.navigate([ page ]); }		
			}		
		}

		this.commons.userInfo.transporter = {
			id			: "santiago_bus",
			name 		: "Santiago bus",
			destination	: "QGBHKJzKHcGtoiVY5tVU"
		}
	}

    async ngOnInit() {	
		await this.initApp();	
		this.initForms();
	}
	
	async ngOnDestroy()	{
		// unsubscribe all
	}

	initForms()	{	
		const entities = {};

		Object.keys(entities).forEach(async item=>{
			let entry = entities[item].map(i=>{ 
				return { name: i, label: this.commons.getTranslate(i), value: i };
			});
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entry);
			console.log("Entity",item,"values",this.pageInfo.entities[item]);
		})
		
		console.log("INITFORM ENTITIES",this.pageInfo.entities);
	
		this.pageInfo.entities["firebase"].form		= {
			ready		: true,
			entities	: this.pageInfo.entities,
			commons		: [],
			options		: { height: '70vh', showButtons: false	},
			buttons		: [
				{ name: 'execute', 		label: '_EXECUTE'	},
				{ name: 'cancel', 		label: '_CANCEL'	}
			],
			form		: firebaseForm
		};
	}
										
	async mountUser($info)							{		
		
		// let dmcSuite	= this.storage.getItem('dmcSuite') || { userInfo: {} };
		let response 	= await this.firebaseService.getUserInfo($info.uid);

		if(response.success){
			this.commons.userInfo						= response;
			this.commons.userInfo.isLogged 				= true;
			this.commons.userInfo.complete_name			= response.first_name+" "+response.last_name;
			this.commons.userInfo.avatar				= this.commons.userInfo.avatar || $info.photoUrl;
			this.commons.userInfo.roles    		 		= await this.firebaseService.getRolesFromProfile(this.commons.userInfo.profile);
			
			// this.commons.userInfo.dmc 				= dmcSuite.userInfo.currentDmc;
			// this.commons.userInfo.currentDmc			= dmcSuite.userInfo.currentDmc;
			// this.commons.userInfo.destination 		= dmcSuite.userInfo.currentDestination;
			// this.commons.userInfo.currentDestination = dmcSuite.userInfo.currentDestination;
			// this.commons.userInfo.station			= dmcSuite.userInfo.currentStation;
			// this.commons.userInfo.currentStation		= dmcSuite.userInfo.currentStation;
			// this.commons.userInfo.country			= dmcSuite.userInfo.currentCountry;
			// this.commons.userInfo.currentCountry		= dmcSuite.userInfo.currentCountry;
					
			// if(!this.commons.userInfo.dmc){	
			// 	this.commons.userInfo.dmc = response.currentDmc;
			// }

			// if(!this.commons.userInfo.currentDestination){
			// 	this.commons.userInfo.currentDestination = response.currentDestination;
			// }

			// if(!this.commons.userInfo.station){
			// 	this.commons.userInfo.station = response.currentStation;
			// }
			
			// if(!this.commons.userInfo.country){
			// 	this.commons.userInfo.country = response.currentCountry;
			// }
			
		} else {
			this.commons.userInfo.isLogged			= false;
		}

		// this.storage.setItem('dmcSuite',dmcSuite);
		console.log("USERINFO",this.commons.userInfo);
		// await this.load("destination");
	}

	async load($entity)					{
		switch($entity){	
			case 'destination'		:	break;								
		}
	}

    async loadCompanyModules()						{
		let dmcInfo				=	(this.storage.getItem('dmcSuite') || {}).userInfo;
		this.pageInfo.modules	=	(dmcInfo && dmcInfo.idDmc) 	? await this.companyService.getModulesFromCompany(dmcInfo.idDmc): [];
	}

	async initApp() {	
		this.setTranslator()
		this.pageInfo.subscriptions = {};
	}
									
	setTranslator()            						{	
		this.translate.setDefaultLang('es');     
	}

	async loadGlobalEntities()    					{	
		
		this.pageInfo.globalEntities.map(async $entity=>{
			await this.entityService.loadEntity($entity);
			this.commons.setEntity($entity, 			await this.entityService.get($entity));
			this.commons.setEntity($entity+'List', 	await this.entityService.getList($entity));
		});

		// Main entitiies														
		this.pageInfo.globalEntities.forEach(entity=>{
			switch(entity){
				default	:	this.firebaseService.subscribeEntity(entity).subscribe(data=>{ 
								this.processEntity(entity,data) 
							}); break;
			}
		});
	}													   

	async loadUserEntities()    	
	{
		let docPath;
		this.pageInfo.userEntities.forEach(async entity=>{
			switch(entity){
				case "config_modules"	:	
					docPath	= "config/modules";
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntityDoc(docPath).subscribe( data => { this.processEntity(entity,data) }); 
					break;

				default					:	
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(entity).subscribe( data => { this.processEntity(entity,data); });
					break;
			}
		});
	}

	loadProviders($info){
		this.commons.setEntity("providers",$info||[]);		
	}

	async processEntity($entity,$info)	{

		let response, defaults;
		
		switch($entity){
			case "config_modules"		:	
				this.commons.setEntity($entity,$info || []); 
				break;

			case "users"				:	
				this.commons.setEntity(	"users", 	
										[ 	{ status: "new", name: this.commons.getTranslate('_NEW') }, 
											...$info
												// .filter(item=>item.group=="staff")
												.map(item=>{
												if( item.status!="new"){
													item.complete_name  = item.first_name;
													item.complete_name += item.last_name?" "+item.last_name:"";
													item.profile_entry	= item.profile_entty;
													item.profile		= item.profile_entry.value;
												}
												return item;
											})
										]
				);
				break;

			case "customers"			:	
				this.commons.setEntity("customers",	
				[ 	{ 
						status: "new", 
						name: this.commons.getTranslate('_NEW'), 
						is_company: true 
					}, 
					...$info																											
				]);
				break;

			case "profiles"				:	
				this.commons.setEntity("profiles",	$info);
				break;	
																							
			default						: 	this.commons.setEntity($entity,			$info);
											this.commons.setEntity($entity+"List", 	$info);
											break;
		}
	}

	getInfo($entity,$type)							{	
		switch($entity){
			case "user"		: 	
				if( !this.commons.userInfo ){ return false; }
				switch($type){
					case "isNotLogged"	:	
						return !this.getInfo("user","isLogged");

					case "isLogged"		:	
						if (!this.commons.userInfo.roles				){ return false; } 
						if (this.commons.userInfo.roles.length == 0 	){ return false; } 
						if (!this.commons.userInfo.isLogged 			){ return false; }
						return true;
				}
		}
	}

    onMenuButtonClick(event: Event) 				{	
		this.menuButtonClick 		= true;
		this.menuActive 			= !this.menuActive;
		event.preventDefault();
	}

    onTopbarMenuButtonClick(event: Event) 			{	
		this.topbarMenuButtonClick 	= true;
		this.topbarMenuActive 		= !this.topbarMenuActive;
		event.preventDefault();
	}

    onTopbarItemClick(event: Event, item: Element) {	
		this.topbarMenuButtonClick 	= true;
		if (this.activeTopbarItem === item) {	this.activeTopbarItem 	= null;	}
		else 								{	this.activeTopbarItem 	= item;	}
		event.preventDefault();
	}

    onTopbarSubItemClick(event) 					{	
		event.preventDefault();				
	}

    onLayoutClick() 								{
		if (!this.menuButtonClick && !this.menuClick) {
			if (this.menuMode === 'horizontal') 	{	this.resetMenu = true;		}
			if (this.isMobile() 			|| 
				this.menuMode === 'overlay' ||  
				this.menuMode === 'popup') 			{	this.menuActive = false;	}
			this.menuHoverActive 	= false;
		}

		if (!this.topbarMenuButtonClick) 			{	this.activeTopbarItem = null; this.topbarMenuActive = false;	}

		this.menuButtonClick 		= false;
		this.menuClick 				= false;
		this.topbarMenuButtonClick 	= false;
	}

    onMenuClick() 									{	this.menuClick = true; this.resetMenu = false;							}
    isMobile() 										{ 	return window.innerWidth < 1025;										}
    isHorizontal() 									{ 	return this.menuMode === 'horizontal'; 									}
    isTablet()     									{ 	const width = window.innerWidth; return width <= 1024 && width > 640; 	}
    
    async generateTranslates(force=false)			{ 	
		if( this.pageInfo.i18n.loaded==true && force==false ){ return false; }
		this.pageInfo.i18n.items = await this.translate.getTranslation(this.translate.getBrowserLang()).toPromise();
		this.pageInfo.i18n.loaded = true;
	}

	getTranslate($item)								{	return this.pageInfo.i18n.items[$item]	|| $item; 	}
	getAllTranslates()								{	return this.pageInfo.i18n.items;					}

	loadContent()									{												
		if(this.pageInfo.userLogged == false) { return true }; // go to landing 
		return this.pageInfo.dataLoaded;
	}

	doAction($type,$info?)							{	
		switch($type){
		}
	}
}
