import { Injectable } from '@angular/core';
import { HoppaService } from './hoppa.service';

@Injectable()
export class IntegrationFactoryService {

    constructor(
        private hoppaService    : HoppaService
    ){}

    async validateBooking($data){
        await this.hoppaService.validateBooking($data);
    }
}
