export const heightInfo		=	{	
	container		: "70vh",
	drop			: "9vh",												
	windowed		: {
		small		: "45vh",
		big			: "71vh",
		bigChild	: "70vh",
		item		: "20vh",
	},
	fullScreen		: {
		mainControls	: "80vh",
		container		: "77vh",
		calendar		: "71vh",
	},
	assigned		: "20vh",
	chart			: "70vh",
	chart3			: "24vh"
};

export const colorInfo		= {
	empty					: "coral",
	active					: "rgba(255,255,255,1)",
	linked					: "green",
	pending					: "darkorchid",
	arrival					: "slategray",
	departure				: "slategray",
	transportAssigned		: "slategray",
	transportNotAssigned	: "brown"
};

export const servicesGrid	=	{									
	height				: '63vh', 
	border				: '1px solid #f0f0f0', 
	rowExpansionWidth	: '63vw',
};

export const bookingsGrid	=	{									
	height				: '63vh', 
	border				: '1px solid #f0f0f0', 
	rowExpansionWidth	: '63vw',
};

export const calendarInfo	=  	{
	heightVh			: '71vh',
	scaleFactor			: 1,
	scaleFactorYInitial	: 0.4,
	scaleFactorXInitial	: 1,												
	scaleOffset			: 70,
	scaleRange			: 1675,
	minutWidth			: 1,
	minutHeight			: 1,
	height				: '900px',
	itemWidth			: '220px',
	itemExtraWidth		: '250px',
	itemOptionsWidth	: '230px',
	itemMinWidth		: 100,
	// itemMinHeight		: 35,
	itemMinHeight		: 20,
	emptyColWidth		: '-webkit-fill-available',
	driverThumbnailSize	: 20,								

	view		: {	
		items	: [ 
			// { name: 'bookings_grid', 		label: '_BOOKINGS_GRID', 		icon: 'bus'	 					},											
			// { name: 'services_groups', 		label: '_SERVICES_GROUPS', 		icon: 'bus'	 					},
			// { name: 'services_calendar', 	label: '_SERVICES_CALENDAR', 	icon: 'bus'	 					},
			{ name: 'services_grid', 		label: '_SERVICES_GRID', 		icon: 'bus'	 					},											
			// { name: 'services_unassigned', 	label: '_SERVICES_uNASSIGNED', 	icon: 'bus'	 					},
			// { name: 'drivers', 				label: '_DRIVERS', 				icon: 'user'					},
			// { name: 'transporters', 		label: '_TRANSPORTERS',			icon: 'building'				}
		], 
		cols	: 2,
		// selected: 'services_calendar'
		selected	: 'services_grid'
	},

	buttons				: {
		'servicesButtons'	: {			
			name		: 'services_buttons',
			title		: "_SERVICES_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: false,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items		: [	
				{ type  : 'calendar' 																		},						
				{ name	: 'accept_all_services', 			label: '_ACCEPT_ALL_SERVICES', 	icon: 'gear'	},
				{ name	: 'reject_all_services', 			label: '_REJECT_ALL_SERVICES', 	icon: 'gear'	},
				{ name	: 'export_services', 				label: '_EXPORT_CSV', 			icon: 'file'	},
				{ name	: 'copy_services_to_clipboard', 	label: '_COPY_TO_CLIPBOARD', 	icon: 'copy'	}
			]	
		}
	}
};