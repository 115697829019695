import { AngularFirestore   } from '@angular/fire/firestore';
import { Injectable         } from '@angular/core';
import { htmlASCII          } from './data/data'

@Injectable()
export class LodgingService {

    data: any = {};

    constructor(private afs : AngularFirestore) {}

    public init($data){
        this.data = $data; 
	}
    
    fixFormatText(item)             
	{   
        if(!item){ return null; }
        
        const ASCIIRegex    =   /[\d|E|#|\+]+/g;
        let splitteds       =   item.split(' ');

        splitteds           =   splitteds.map(  word => {   
                                    word = word.replace(/&/g,'');
                                    word = word.replace(/amp/g,'');
                                    word = word.replace(/;/g,'');
                                    return word;
                                });

        splitteds           =   splitteds.map(  word => {                                                            
                                    let matchAscii  =   word.match(ASCIIRegex);
                                    if(matchAscii == null || matchAscii.length == 0 ){ return word; }
                                    matchAscii[0].split('#').filter (   el      => el)
                                                            .map    (   item    => '#'+item)
                                                            .forEach(   result  => {
                                                                if(result.includes('#')){
                                                                    let code = htmlASCII[+result.replace('#','')];
                                                                    word = code ? word.replace(result,code) : word;
                                                                }
                                                            });
                                    return word;
                                });

        return splitteds.reduce((word1,word2)=>word1 +' '+ word2,'').replace(' ','');
    }

    getMinimalFactibleValue(item,currentItemSplitted)
    {
        if(!item)				{ return null; }
        let splitteds			=   item.split(' ');
        currentItemSplitted   	=   currentItemSplitted || {};

        if(currentItemSplitted.index){
            let currentItem 			= 	currentItemSplitted.index;
            currentItemSplitted.index 	= 	splitteds[currentItem + 1 ] ? currentItem +1              :   null;
            currentItemSplitted.word  	= 	splitteds[currentItem + 1 ] ? splitteds[currentItem + 1 ] :   null;
        } else {
            currentItemSplitted   		=   {
                                                index : splitteds.length > 5 ? 5 : 4, word : splitteds.length > 5 ? splitteds[5] : splitteds[4] 
                                            };
        }

        return { 	query                	: splitteds.slice(0,currentItemSplitted.index).reduce((word1,word2) => word1 + ' ' + word2,''),
                    currentItemSplitted    	: currentItemSplitted 
                }   
    }

    mountLodgingFromGoogle(place)	
    {
        console.log('Google Place',place);
        
        let item;
        item           	    =   place;
        item.latitude  	    =   place.latitude  || place.geometry.location.lat();
        item.longitude 	    =   place.longitude || place.geometry.location.lng();
        item.address		=	place.formatted_address;
        let municipality    =   place.address_components.find(el => el.types.find(type => type == 'locality'));
        item.municipality   =   municipality 
                                    ?municipality.long_name
                                    :place.address_components[place.address_components.length -1].long_name+ ", " + place.address_components[0].long_name;
        
        return item;
    }

	/**
	 * check current location against lodgings database and aliases
	 * 
	 * @param location 
	 * @param lodgings 
	 * @param aliases 
	 * @returns 
	 */
    checkLocation(row,field):any{
        let area, location;

        switch(field){
            case "arrival_Location"     :   location = row[field]; area = row["arrival_To"      ]; break;
            case "departure_Location"   :   location = row[field]; area = row["departure_From"  ]; break;
            default                     :	alert("[CHECK LOCATION] unsupported field "+field);
        }

        if(!location || location=="") { 
            return { error : true, type : '_EMPTY_LOCATION', errorCode : '53'} 
        }
        
        // Clean location
        location = location.replaceAll('#',    '')
                            .replaceAll('amp;', '')
                            .replaceAll('&amp', '')
                            ;
        
        let lodgingFound 	= (this.data.lodgings||[]).some(lodging=>lodging.name==location);	
        let aliasFound 		= Object.keys(this.data.aliases||{}).some(item=>{
			let tmp = this.data.aliases[item];
            return tmp["alias_name"]==location && tmp["alias_area"]==area;
        });
		
		if(lodgingFound || aliasFound)	{ 	return { error: false };							}
		else 							{	return { error: true, type : '_COULD_NOT_VERIFY'};	}
	}
}

