import { TranslateService				} from '@ngx-translate/core';
import { Injectable         			} from '@angular/core';
import { ToastrService 					} from 'ngx-toastr';
import { extendMoment 					} from 'moment-range';
import { AngularCsv						} from 'angular7-csv';
import { StorageService 				} from './storageservice';

/**
 *		Fomento		moreAreas  		Areas de Provider a Touirinia
					fomento_mapping	De Touinia a Fomento
		Commons		moreZones		Areas de Provider a Tourinia
 */
import { moreZones, zones 				} from './data/moreZones';
import { distances 						} from './data/distances';
import { pages 							} from './pages';
import * as moment 			 			  from 'moment';

@Injectable()
export class CommonsService {
	entities        : any = {};
	i18n			: any = {};
	userInfo 		: any = {};
    pageInfo        : any = {
		version					: '0.9.132',
		entities				: {},	
		pages					: pages,

		init_service_time				: "04:00",
		init_service_time_min			: 240,
		average_speed_in_km				: 60,
		service_long_distance_min_time	: 2000,
		vehicles : {
			turn_duration_min	: 11*60,			
			turns: {
				afternoon 		: "17:00",
				morning			: "05:00"
			}
		},
		_turns: {
			early_morning		: "04:00",
			morning				: "07:00",
			afternoon			: "12:00",
			night				: "18:00"
		},
		moreZones		: moreZones,
		base			: "Palma",					// Cambiar como valor en /dmcs/1/destinations/1 > base
		zones 			: zones,
		distances 		: distances,

		waitingTimes	: {							// Get from DMC config and provider
			privateArrival		:	50,			
			shuttleArrival		: 	50,
			airport				:	180				// 3 horas
		},
		pickupTimes		: {
			perLodging			: 	5
		},

		route			: {
			min_duration		: 15
		},
			
		langs					: 	[	
			{ code: "es", flag: "assets/layout/icons/flags/es.png" },
			{ code: "en", flag: "assets/layout/icons/flags/en.png" },
			{ code: "de", flag: "assets/layout/icons/flags/de.png" },
			{ code: "it", flag: "assets/layout/icons/flags/it.png" },
			{ code: "fr", flag: "assets/layout/icons/flags/fr.png" }
		],
		privateWaitTime			: 	30,
		shuttleWaitTime			: 	45,
		moment					: 	moment,
		calendar				: 	{
			locale				: 	{
				"es"			: 	{
					closeText			: "Cerrar",
					prevText			: "Anterior",
					nextText			: "Siguiente",
					monthNames			: ["Enero","Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
					monthNamesShort		: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
					dayNames			: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
					dayNamesShort		: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
					dayNamesMin			: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
					weekHeader			: "Semana",
					firstDay			: 0,
					isRTL				: false,
					showMonthAfterYear	: false,
					yearSuffix			: "",
					timeOnlyTitle		: "Solo hora",
					timeText			: "Tiempo",
					hourText			: "Hora",
					minuteText			: "Minuto",
					secondText			: "Segundo",
					currentText			: "Fecha actual",
					ampm				: false,
					month				: "Mes",
					week				: "Semana",
					day					: "Día",
					allDayText 			: "Todo el día"				
				}
			}
		},
		currency				:	'euro',
		currencySign			:	'€',
		defaultNotLoggedPage	:	'/',
		typesProfile			:	[	
										{	profiles	:	[ 'admin' ],
											activators	:	[],
											entityType	:	'admin'
										},
										{	profiles	:	[ 'reseller_operator','reseller_admin','reseller' ],
											activators 	:	[ 'permission' ],
											entityType	:	'reseller'
										},
										{	profiles	:	[ 'officer','dmc_admin','dmc' ],
											activators	:	[ 'permission','modules' ],
											entityType	:	'dmc'
										}
									],
		i18nLoaded				: false,
		optionsToast  			: {
									timeOut         	: 4000,
									positionClass      	: 'toast-center-center',
									preventDuplicates	: true
								},
		positions 				: [	'toast-top-left', 
									'toast-top-center', 
									'toast-top-right', 
									'toast-bottom-left', 
									'toast-bottom-center', 
									'toast-bottom-right',
									'toast-center-left',
									'toast-center-center',
									'toast-center-right'
								],
		imgs	:	{
			'defaultAvatar'		:	'assets/demo/images/general/default-avatar.jpg',
			'noImg'				:	'assets/demo/images/general/noImg.jpg',
		}
    }
    
	constructor(
        private toastr      : ToastrService,
		private translate   : TranslateService,
		private storage		: StorageService
	){	
		this.pageInfo.currentLang	= "es";
		this.pageInfo.zones 		= [ "Palma", "Norte", "Sur", "Levente", "Este", "Tramontana" ];
	}

	/**
	 * 
	 * @param $origin 
	 * @param $destination 
	 * @returns 
	 */
	public getInterZonesDistance($origin,$destination){
		if(undefined==$origin || undefined==$destination || this.pageInfo.distances[$origin] || this.pageInfo.distances[$origin][$destination]){
			console.log("[getZoneDistance] zone not found");
			return 0;
		}
		return this.pageInfo.distances[$origin][$destination];
	}

	copyToClipboard($info){
		try {
			if(navigator){
				navigator["clipboard"].writeText($info.value);
			}
			this.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_SUCCESS","info");
		}catch(e){
			this.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_ERROR","error");
		}
	}	

	/**
	 * get distances between areas center in km
	 * @param $a 
	 * @param $b 
	 * @returns 
	 */
	public getInterAreasDistance($a,$b){
		
		if(undefined==$a||undefined==$b){
			console.log("[getInterAreasDistance] Origin or destination not defined");
			return 0;
		}
		
		if(undefined==$a.center||undefined==$b.center){
			console.log("[getInterAreasDistance] Origin or destination center not defined");
			return 0;
		}

		let lat1	=	$a.center.lat;
		let lon1	=	$a.center.lng;
		let lat2	=	$b.center.lat;
		let lon2	=	$b.center.lng;
		let R 		= 	6371; 					// Radius of the earth in km
		let dLat 	= 	this.deg2rad(lat2-lat1);  	// deg2rad below
		let dLon 	= 	this.deg2rad(lon2-lon1); 
		let a 		= 	Math.sin(dLat/2) 				* Math.sin(dLat/2) +
						Math.cos(this.deg2rad(lat1)) 	* Math.cos(this.deg2rad(lat2)) * 
						Math.sin(dLon/2) 				* Math.sin(dLon/2)
						; 
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		let d = R * c; // Distance in km
		return d;
	}
	  
	public deg2rad(deg) {
		return deg * (Math.PI/180)
	}

	public changeLang($lang){
		this.generateToast("LANG","_CHANGE_LANG","info");
		this.pageInfo.currentLang = $lang.code;
		this.generateTranslates();
		this.pageInfo.storage  			= this.pageInfo.storage	|| {};
		this.pageInfo.storage["lang"]	= this.pageInfo.currentLang;
		this.storage.setItem('dmcSuite',this.pageInfo.storage);		
	}

	public export($format:string ,$info: object[],$fileName: string,$columns?: string[]){
		switch($format){
			case "csv"	: 	this.exportToCsv($info,$fileName,$columns); break;
			default		:	this.generateToast("_ERROR","_UNKNOWN_EXPORT_FORMAT","error");
		}
	}

	private exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
		if (!rows || !rows.length) { return; }		
		new AngularCsv(rows, fileName);
	}

	init()													{	this.generateTranslates(); 
																moment.locale(this.getActiveLang());
															}
	getMoment()												{	return moment; 																			}
	getEndOfDay($date,$format)								{	return moment($date,$format).endOf(); 													}
	getDate($date)											{	return moment($date);																	}
	today()													{	return moment();																		}
	addTimeToDate($params)									{	
		let time = moment($params["date"]).add($params["time"],$params["unit"]);
		return time;
	}
	addDaysToDate($params){
		const date = this.addTimeToDate({ date: $params["date"], time: $params["days"], unit: 'days' });
		if(undefined!=$params["format"]){
			return date.format($params["format"]);
		} else {
			return date;
		}
	}
	nextDay($date)											{	return moment($date).add(1,"days");														}
	pastDay($date)											{	return moment($date).add(-1,"days");													}
	setEntity($entity, $values)								{ 	this.entities[$entity] = $values;														}
    getEntity($entity)         								{ 	return this.entities[$entity];  														}
    generateHash(randomString?)								{	randomString = String(randomString).toString() || Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
														        let random	=	randomString.split('').reduce((prevHash, currVal) =>(((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
																return Number.isInteger(random) ? Math.abs(random) : random;	
															}

	getDmcId()												{	return this.userInfo.IdDmc;			}
	getDestination()										{	return this.userInfo.destination;	}

	// TRANSLATION		-----------------------------------------------------------------
	getAvailablesLangs()									{	return this.translate.getLangs();}
	getActiveLang()											{ 	return this.translate.getBrowserLang();																		}
	getTranslate(info)										{ 	return this.i18n[info] || info;																				}
	getLegendTranslate(item,label)							{ 	return undefined===item.legend||item.legend?this.getTranslate("_INSERT")+" "+this.getTranslate(label):""	}
	getAllTranslates()										{ 	return this.i18n;
																							}
	async generateTranslates()								{ 	if(this.pageInfo.i18nLoaded){ return false; }	
																this.pageInfo.currentLang = this.pageInfo.currentLang || this.translate.getDefaultLang();
																this.i18n = await Promise.resolve(this.translate.getTranslation(this.pageInfo.currentLang).toPromise());
																this.pageInfo.i18nLoaded = true;										
															}

	async translateRecursively($items,$params?)				{	if(!this.pageInfo.i18nLoaded){ await this.generateTranslates(); }
														
																let isArray	= undefined!==$items.length?true:false;
																let items	= isArray?$items:[$items];
																let params	= $params || {};
																let label 	= undefined!=params.label		? params.label		: "label";
																let children= undefined!=params.children	? params.children	: "children";		
																
																let res		= await Promise.all(items.map(async item=>{	
																	if(undefined!==item[label]		){ item[label] 		= this.getTranslate(item[label]) 							}
																	if(undefined!==item[children]	){ item[children]	= await this.translateRecursively(item[children],params)	}
																	return item;
																}));
														
																return isArray?res:res[0];
															}

	getCurrentLang()										{	return this.translate.getBrowserLang();			}

	getProfileInfo(userProfile)								{	return this.pageInfo.typesProfile.find(el => el.profiles.find(profile => profile == userProfile));	}

	getEntityProfile(userProfile)							{	let foundProfile 	=	this.getProfileInfo(userProfile);
																if(!foundProfile)	{ 	return { profile : 'admin', type : 'admin' } }
																return				{	profile	:	foundProfile.entityType,
																						type	:	userProfile
																					}
															}
	getImg(img)												{	if(!img || !this.pageInfo.imgs[img]){ return this.pageInfo.imgs['noImg']	};
																return this.pageInfo.imgs[img];
															}

	// TIME SUPPORT		-----------------------------------------------------------------

	getTimestampMoment($timestamp)							{	return moment.unix($timestamp);																	}
	getTimestampFormat(timestamp,format)					{	return moment.unix(timestamp).format(format);													}
	isValidDate(date)										{ 	return moment(this.formatTimestamp(date)).isValid();											}
	getFormatDate(date)										{ 	return moment(date).format('DD-MM-YYYY') || date;												}
	getFormattedDate($date,$format,$fromFormat?)			{ 	
		if($fromFormat)	{	return moment($date,$fromFormat).format($format);	}
		else 			{	return moment($date).format($format);				}
	}
	getToday(format)										{	return moment().format(format||'DD-MM-YYYY');													}
	getDateFormat2Format(date,from,to)						{ 	return moment(date,from).format(to) || date;													}
  	getFormatDateHour(date)									{	try{
															        const  timeRegEx    =   new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
															        const result        =   timeRegEx.exec(date);
																	return (result && result.length>0) ? result[0] : moment(date).format('HH:mm') || date; 		
																} catch(e) { console.log('error date', date); }
															}
	checkCompatibilityPeriodsSchedule(data, periods) 		{   const extendedMoment 	= 	extendMoment(moment);
															    if(Array.from(extendedMoment().range(data.start, data.end).by('days')).length < 7) return false;
															
															    const filteredNull  =   periods.filter(data => data.start != null || data.end != null);
															    const wrongPeriod   =   filteredNull.find(el =>{
															                            const dateStart =   moment(data.start).format();    const dateEnd   =   moment(data.end).format();
															                            const elStart   =   moment(el.start).format();      const elEnd     =   moment(el.end).format();
															                            return (moment(dateStart).isBetween(elStart, elEnd) || moment(dateEnd).isBetween(elStart,elEnd)
															                                    || 
															                                    (moment(dateStart).isBefore(moment(elStart)) && moment(dateEnd).isAfter(moment(elEnd)))
															                                    ||
															                                    (moment(dateStart).isAfter(moment(elStart)) && moment(dateEnd).isBefore(elEnd)))});
															    if(wrongPeriod) return false;
															    return true;
															}

//------------------------------------
// MOMENT AND DATE MANIPULATION
// ------------------------------------
	
	/**
	 * Return a computed date after add or substract a value
	 * @param time current time
	 * @param method add, substract
	 * @param amount unit number
	 * @param unitTime unitTime days, minute, hour, etc
	 */
	computeDate(time, method, amount, unitTime)				{	try{ return moment(time)[method](amount,unitTime) } catch(e){ return '_INVALID_DATE'; }	}
	
	/**
	 * Convert the object into timestamp
	 * @param element Object from firebase, can have seconds property or timestamp
	 */
	formatTimestamp(element)								{	return element['seconds'] ? element['seconds']*1000 : element;	}
	formatTimePassed(time)									{	let today					=	moment(new Date()).startOf('day');
																let current					=	moment(new Date(this.formatTimestamp(time))).startOf('day');
																let daysDiff				=	this.getDiffDays(today,current, 'days');
																switch(true){
																	case daysDiff == 0						:	return moment(this.formatTimestamp(time)).format('HH:mm');
																	case daysDiff == 1						:	return '_YESTERDAY';
																	case (daysDiff > 0) && (daysDiff <8)	:	return current.locale(this.getCurrentLang()).format('dddd');
																	default									:	return current.format('DD/MM/YYYY');
																}
															}

	/**
	 * 
	 * @param time1 first date
	 * @param time2 second date
	 * @param interval unit difference between dates ( days, weeks, hours, minutes, etc)
	 */
	getDiffDays(time1,time2, interval)						{	return moment(time1).diff(moment(time2),interval);					}
	formatCustomDate(date, format)							{	return moment(date).format(format);									}
	humanize(from,to,suffixless=true)						{	return moment.duration(moment(from).diff(to)).humanize(suffixless);	}
	getWorkableYears()										{ 	return '1930:2080';													}	
	getNow($format)											{	return moment().format($format);									}

// MESSAGING 		-----------------------------------------------------------------

    /**
     * generateToast
	 * 
     * @param summary Title
     * @param text    Message to display
     * @param type    success, info, warning, error  
     * @param options 
     */
    async generateToast(summary,text,type="info",position?)	{	if(position){ this.setPosition(position);	}
																await this.toastr[type](this.getTranslate(text),this.getTranslate(summary),this.pageInfo.optionsToast);
															}

	generateComplexToast($info){
		let $title 		= $info.title;
		let $content	= $info.content.map(item=>this.getTranslate(item)).join(" ");
		let $type		= $info.type;
		this.generateToast($title,$content,$type)
	}	

	generateToastError(text){
		this.generateToast("_ERROR",text,"error");
	}

	setPosition(i : number)									{ 	this.pageInfo.optionsToast.positionClass = this.pageInfo.positions[i];	}
	
	async getAuthErrorMessage(code)							{	switch(code){
														            // case 'auth/wrong-password'	:	return await this.getTranslate('_WRONG_PASSWORD');
														            // case 'auth/invalid-email'	:	return await this.getTranslate('_INVALID_EMAIL');
														            // case 'auth/user-disabled'	:	return await this.getTranslate('_USER_DISABLED');
														            // case 'auth/user-not-found'	:	return await this.getTranslate('_USER_NOT_FOUND');
														            // case '_UPDATE_PASSWORD'     :   return await this.getTranslate('_UPDATE_PASSWORD');
																	// case '_USER_NOT_CREATED'    :   return await this.getTranslate('_USER_NOT_CREATED');
																	default                     :   return await this.getTranslate('_WRONG_PASSWORD'	);
																	case '_NO_DMC_ASSIGNED'     :   return await this.getTranslate('_NO_DMC_ASSIGNED'	);														
														    	}
															  }
	/**
	 * 
	 * @param $event item to change
	 * @param data array items
	 * @param property property to toggle
	 * @param multipleSelection if enable more than one property toggled
	 */
	toggleItem($event,data, property, multipleSelection?)	{
																let tmp	=	$event[property] ? !$event[property] : true;
																if (!multipleSelection) data.forEach(el => el[property] = false);
																$event[property] = tmp;
															} 

	compareObjects(obj1,obj2)								{	return JSON.stringify(obj1) == JSON.stringify(obj2)																		}
	openFullscreen(document) 								{
																if 		( document.requestFullscreen		) {	document.requestFullscreen();											} 
																else if ( document.mozRequestFullScreen		) {	document.mozRequestFullScreen();		/* Firefox */					} 
																else if ( document.webkitRequestFullscreen	) {	document.webkitRequestFullscreen();	/* Chrome, Safari and Opera */ 		}
																else if ( document.msRequestFullscreen		) {	document.msRequestFullscreen(); 		/* IE/Edge */ 					}
															}
	
	closeFullscreen(document) 								{	document.dispatchEvent(new KeyboardEvent('keypress',{'key':'a'}));
																if 		( document.exitFullscreen		) 	{	document.exitFullscreen();											}
																else if ( document.mozCancelFullScreen	) 	{	document.mozCancelFullScreen();	/* Firefox */ 						}
																else if ( document.webkitExitFullscreen	) 	{	document.webkitExitFullscreen();	/* Chrome, Safari and Opera */ 	} 
																else if ( document.msExitFullscreen		) 	{	document.msExitFullscreen();		/* IE/Edge */					}
															}
}
