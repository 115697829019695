import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter      } from '@angular/core';
import * as moment 			 from 'moment';

@Component({
  selector    : 'app-scrolled-items',
  templateUrl : 'scrolled-items.html'
})
export class ScrolledItemsComponent {

  	@Input("type")              	type		  	: string;
	@Input("more")					more            : boolean	= true;
	@Input('title')             	title         	: string;
	@Input('titleClass')        	titleClass      : string 	= "bigTitle";
	@Input('subtitle')          	subtitle        : string;
	@Input('items')             	items           : any[];
	@Input('services')          	services        : any[];
	@Input("bgColor")           	bgColor         : string;
	@Input("circle")            	circle          : string;
	@Input("width")             	width           : number;
	@Input("height")            	height          : number;
	@Input('containerHeight')   	containerHeight : number;
	@Input("shadow")            	shadow          : boolean 	= false;
	@Input("inner-margin")      	innerMargin     : string;
	@Input("inner-padding")     	innerPadding    : string;
	@Input("sections")				sections		: any[] = [];
	@Input("typeList")          	typeList        : string;
	@Input("reservations")      	reservations  	: any[] 	= [];
	@Output()                   	viewEmitter     = new EventEmitter<any>();
	@Output()                   	moreEmitter     = new EventEmitter<any>();
	
	pageInfo  : any = {}
	  
	constructor(
		// private commons : CommonsProvider, 
    	private translateService : TranslateService
  	) {
		this.sections		= [ 'pax', 'comments', 'rating', 'subtitle', 'prices ', 'pending' ];
	}

  	ngOnInit()					{ this.pageInfo.today 	= new Date(); 																	}
	checkSection(item)			{ return this.sections.some(el=>el==item);																}
  	moreAction(detail?)			{ this.moreEmitter.emit({ type: this.type }); 	            					}
    viewAction(detail)			{ this.viewEmitter.emit({ type: this.type, info: detail});						}
	getDateLocale(date)			{ return moment(date, 'YYYY/MM/DD').locale(this.translateService.currentLang).format("DD/MM/YYYYY"); 	}
	isReserved(item	)			{ return this.reservations.some(res => res.id === item.id);												}
	isActive(item	)			{ return undefined!==item.active && item.active;														}
	checkDifferenceTime(date)	{
		let diffDays 	= this.daysBetween(this.pageInfo.today,date);
		diffDays 		= diffDays >= 3 ? diffDays -1 : diffDays;
		diffDays 		= diffDays == 1 || diffDays == 0 ? diffDays == 1 ? '_TOMORROW' : '_TODAY' : diffDays;
		return diffDays;

    }
    daysBetween(date1: Date,date2: Date):any{
		let oneDay     		= 1000*60*60*24;
		let msDate1    		= date1.getTime();
		let msDate2    		= date2.getTime();
		let differenceDates = msDate2 - msDate1;
		return Math.round(differenceDates / oneDay);		
    }  	
}
