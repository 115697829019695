export const fomento_areas = {
	"baleares"	: [ 
		[ "07","002","7","Alaior",                  	"ALAIOR"],
		[ "07","001","2","Alaró",                   	"ALARÓ"],
		[ "07","003","3","Alcúdia",                 	"ALCÚDIA"],
		[ "07","004","8","Algaida",                 	"ALGAIDA"],
		[ "07","005","1","Andratx",                 	"ANDRATX"],
		[ "07","901","3","Ariany",                  	"ARIANY"],
		[ "07","006","4","Artà",                    	"ARTÀ"],
		[ "07","007","0","Banyalbufar",             	"BANYALBUFAR"],
		[ "07","008","6","Binissalem",              	"BINISSALEM"],
		[ "07","009","9","Búger",                   	"BÚGER"],
		[ "07","010","3","Bunyola",                 	"BUNYOLA"],
		[ "07","011","0","Calvià",                 		"CALVIÀ"],
		[ "07","012","5","Campanet",                	"CAMPANET"],
		[ "07","013","1","Campos",                  	"CAMPOS"],
		[ "07","014","6","Capdepera",               	"CAPDEPERA"],
		[ "07","064","5","Castell, Es",             	"CASTELL, ES"],
		[ "07","015","9","Ciutadella de Menorca",   	"CIUTADELLA DE MENORCA"],
		[ "07","016","2","Consell",                 	"CONSELL"],
		[ "07","017","8","Costitx",                 	"COSTITX"],
		[ "07","018","4","Deià",                    	"DEIÀ"],
		[ "07","026","0","Eivissa",                 	"EIVISSA"],
		[ "07","019","7","Escorca",                 	"ESCORCA"],
		[ "07","020","1","Esporles",                	"ESPORLES"],
		[ "07","021","8","Estellencs",              	"ESTELLENCS"],
		[ "07","022","3","Felanitx",                	"FELANITX"],
		[ "07","023","9","Ferreries",               	"FERRERIES"],
		[ "07","024","4","Formentera",              	"FORMENTERA"],
		[ "07","025","7","Fornalutx",               	"FORNALUTX"],
		[ "07","027","6","Inca",                    	"INCA"],
		[ "07","028","2","Lloret de Vistalegre",		"LLORET DE VISTALEGRE"],
		[ "07","029","5","Lloseta",						"LLOSETA"],
		[ "07","030","9","Llubí",						"LLUBÍ"],
		[ "07","031","6","Llucmajor",					"LLUCMAJOR"],
		[ "07","033","7","Manacor",						"MANACOR"],
		[ "07","034","2","Mancor de la Vall",			"MANCOR DE LA VALL"],
		[ "07","032","1","Maó-Mahón",					"MAÓ-MAHÓN"],
		[ "07","035","5","Maria de la Salut",			"MARIA DE LA SALUT"],
		[ "07","036","8","Marratxí",					"MARRATXÍ"],
		[ "07","037","4","Mercadal, Es",				"MERCADAL, ES"],
		[ "07","902","8","Migjorn Gran, Es",			"MIGJORN GRAN, ES"],
		[ "07","038","0","Montuïri",					"MONTUÏRI"],
		[ "07","039","3","Muro",						"MURO"],
		[ "07","040","7","Palma",						"PALMA"],
		[ "07","041","4","Petra",						"PETRA"],
		[ "07","044","0","Pobla, Sa",					"POBLA, SA"],
		[ "07","042","9","Pollença",					"POLLENÇA"],
		[ "07","043","5","Porreres",					"PORRERES"],
		[ "07","045","3","Puigpunyent",					"PUIGPUNYENT"],
		[ "07","059","8","Salines, Ses",				"SALINES, SES"],
		[ "07","046","6","Sant Antoni de Portmany",		"SANT ANTONI DE PORTMANY"],
		[ "07","049","1","Sant Joan",					"SANT JOAN"],
		[ "07","050","4","Sant Joan de Labritja",		"SANT JOAN DE LABRITJA"],
		[ "07","048","8","Sant Josep de sa Talaia",		"SANT JOSEP DE SA TALAIA"],
		[ "07","051","1","Sant Llorenç des Cardassar",	"SANT LLORENÇ DES CARDASSAR"],
		[ "07","052","6","Sant Lluís",					"SANT LLUÍS"],
		[ "07","053","2","Santa Eugènia",				"SANTA EUGÈNIA"],
		[ "07","054","7","Santa Eulària des Riu",		"SANTA EULÀRIA DES RIU"],
		[ "07","055","0","Santa Margalida",				"SANTA MARGALIDA"],
		[ "07","056","3","Santa María del Camí",		"SANTA MARÍA DEL CAMÍ"],
		[ "07","057","9","Santanyí",					"SANTANYÍ"],
		[ "07","058","5","Selva",						"SELVA"],
		[ "07","047","2","Sencelles",					"SENCELLES"],
		[ "07","060","2","Sineu",						"SINEU"],
		[ "07","061","9","Sóller",						"SÓLLER"],
		[ "07","062","4","Son Servera",					"SON SERVERA"],
		[ "07","063","0","Valldemossa",					"VALLDEMOSSA"],
		[ "07","065","8","Vilafranca de Bonany",		"VILAFRANCA DE BONANY"]
	]
};