import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import { EntityService } from '../../../service/entity.service';
import { CommonsService } from '../../../service/commons.service';
import { MessageService } from 'primeng/components/common/messageservice';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';


@Component({
    templateUrl: './companies.component.html',
    styles: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }
        body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
            width: 2em;
            height: 26px;
            
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation: ViewEncapsulation.None,
    providers: [ MessageService,ConfirmationService ]
})

export class TransportCompaniesComponent implements OnInit {
    
    @ViewChild('mainTable') mainTable : any;

    transport_companies	: any = { cols: [], filters: {} };
    areasmallorca       : any = {};
    results				: any[];
    rowGroupMetadata	: any;
    pageInfo : any = {
        tempRpw             : null,
        tempTdSelected      : null,
        buttonEditRow       : false,
        buttonRemoveRow     : true,
        buttonCancelRow     : true,
           //***** VALIDATIONS ******/
           validations : {
            transport_companies : [
                    {
                        field       : 'email',
                        validation  : this.isEmail,
                        msgError    : 'The email is badly formatter'
                    }
            ]
        }
    }
    menu: any = {
        options: [
            { label: 'reload', icon: 'fa fa-plus' },
            { label: 'download', icon: 'fa fa-download' },
        ]
    };
    
     

    constructor(
        private http: HttpClient,
        private commons: CommonsService,
        private entityService: EntityService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService    ) {
        this.loadEntities();
    }

    async loadEntities() {
        await this.load('areasmallorca');         
        await this.load('transport_companies');
    }

    ngOnInit() {
        this.init();
    }

    async init() {

        this.transport_companies.cols = [
			{ field: 'name',     	header: 'Name', 		width: '200px', 	align: "center",    editor : 'input',  options : {type : 'text'}, mandatory : true 	},
			// { field: 'area',	    header: 'Area',  	    width: '100px',		align: "center" 	},
            { field: 'location',	header: 'Location',  	width: '100px',		align: "center",    editor : 'autocomplete',  options : {suggestions : this.results},mandatory : false 	},
            // { field: 'vehicles',	header: 'Vehicles',  	width: '75px',     	align: "center" 	},
            { field: 'pax',	    	header: 'Pax',  	    width: '75px',     	align: "center",    editor : 'input',  options : {type : 'number'},mandatory : false 	},
            { field: 'address',	    header: 'Address',  	width: '300px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false 	},
			{ field: 'phone',	    header: 'Phone',  		width: '150px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false		},
			{ field: 'email',	    header: 'Email',  	    width: '250px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : true 	}            
        ];
        this.transport_companies.form = [		
			{
				title	: "Info",
				items	: [					
					{ field: 'name',     	label: 'Name', 		width: '200px', 	align: "center",    editor : 'input',  editable:true,options : {type : 'text'}, mandatory : true 	},
                     { field: 'location',	label: 'Location',  	width: '100px',		align: "center",    editor : 'autocomplete',  editable:true, options : {suggestions : this.results},mandatory : false 	},
				]
			},
			{
				title	: "Info",
				items	: [					
                    { field: 'pax',	    	label: 'Pax',  	    width: '75px',     	align: "center",    editor : 'input', editable:true, options : {type : 'number'},mandatory : false 	},
                    { field: 'address',	    label: 'Address',  	width: '300px', 	align: "center",    editor : 'input', editable:true, options : {type : 'text'},mandatory : false 	},
		            // { field: 'pax',	    	label: 'Pax',  	    width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input', 			options : {type : 'number'},	},
		            // { field: 'qty',   	    label: 'Quantity',	width: '50px', 	align: "center",  	type: 'text', editable: false, editor : 'input',     		options : {type : 'number'},	},
					
				]
			},
			{
				title	: "Info",
				items	: [					
					{ field: 'phone',	    label: 'Phone',  		width: '150px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false		},
			        { field: 'email',	    label: 'Email',  	    width: '250px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : true 	}
				]
            }
        ];
        this.generateMenuCols('transport_companies');
        
        this.transport_companies.filters = [
			{ name: 'private', 	labelOn: 'Showing private', 	labelOff: 'No private', status: true },
			{ name: 'shared', 	labelOn: 'Showing shared', 	    labelOff: 'No shared', 	status: true }
        ];

        // this.loadEntities();

        // this.updateRowGroupMetaData();
    }

    generateMenuCols($entity) {
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
    }

    async load($entity) {
        switch ($entity) {
            
            case 'transport_companies':
				this[$entity].data  = [];				
                await this.entityService.loadEntity($entity);
                this[$entity].data	= this.entityService.get($entity);
                console.log(this[$entity].data);
            break;
            
            case 'areasmallorca':
                this[$entity].data  = [];
                await this.entityService.loadEntity($entity,{});
                this[$entity].data  = this.entityService.get($entity).map(el => el.name);                
            break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
        this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
    }

    getEditor($col) {
        return $col.editor ? $col.editor : 'input';
    }

    search(field,event) {
        switch(field){
            case 'location' : this.results  = this.areasmallorca.data.filter(item => item.includes(event.query)); break;            
            case 'brand'    : this.results  = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;            
            default         : this.results  = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;
         }
    }

    checkEntity($entity, $value) {
        // alert('CHECKENTITY[' + $entity + ']->' + JSON.stringify(this.commons.getEntity($entity)));
        // return this.commons.getEntity($entity).some(item => item === $value);
        return true;
    }

    areaEditor($me, $type, $col, $items) {
        alert('AREA EDITOR');
    }

    getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
            case 'content'	: return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    onSort() {
        // this.updateRowGroupMetaData();
    }

    makeColEditable($entity,value,rowData){
        
        this[$entity].cols.map(el => el.editable = value);
        this.pageInfo.tempRow = JSON.stringify(rowData);
        this.pageInfo.buttonEditRow = value ? true : false;
    }
    
    addNewCompany($event){
        let newId = this.commons.generateHash(Math.random().toString(36).replace(/[^a-z]+/g, '') );
        this[$event].data.unshift({id: newId});
        this.paginate($event,null);
        this.mainTable["expandedRowKeys"][newId] = 1;
        this.makeColEditable($event,true,this[$event].data.find(el=> el.id == newId));
        this.commons.generateToast('Element Created','Please, fill the info','info');
    }

    cancelEdit($entity){
        try{
            this[$entity].data[this[$entity].data
                                            .findIndex(el => el.id == JSON.parse(this.pageInfo.tempRow).id)] = JSON.parse(this.pageInfo.tempRow);
            
            this.paginate       ($entity,null);
            this.makeColEditable($entity,false,null)
            
            this.commons.generateToast('Canceled','Edit canceled','info',2);
        }catch(e){
            this.commons.generateToast('ERROR',e,'error',1);
            console.error('ERROR. Row not found', e);
        }
    }

    async saveRow($entity,rowData){
        try{
            // let validation = this.validateInfo($entity,rowData);
            let validation = this.validateInfo($entity,rowData);
            console.log(validation)
            if(validation.success){
                //****** UPDATE/CREATE ROW *********** API PUT / POST rowData
                console.log('PUT/POST',rowData);
                await this.entityService.postJSON(this.entityService.getUrl('transport_companies'),[rowData]);
                await this.load('transport_companies');                
                // this.paginate($entity,null);
                this.makeColEditable($entity,false,rowData);
                this.commons.generateToast('SUCCESS','Row Updated','success',2);
                this.mainTable.expandedRowKeys = {};
            }else{
                this.commons.generateToast('ERROR',validation.msg,'error',2);                
            }            
        }
        catch(e){
            this.commons.generateToast('ERROR',e,'error',2);    
        }
    }

    async removeRow($entity,rowData){
        let findedRow = this[$entity].data.findIndex(el => el.id == rowData.id);

        if(findedRow > -1){
            //****** DELETE ROW *********** API REMOVE
            console.log('GOING TO DELETE', this[$entity].data[findedRow]);
            await this.entityService.delete(this.entityService.getUrl('transport_companies'),{ id: this[$entity].data[findedRow].id });
            await this.load('transport_companies');
            // this[$entity].data.splice(findedRow,1);
            // this.paginate($entity,null);
            this.commons.generateToast('Success','Item removed','success',2);
            
        }else{
            this.commons.generateToast('Error','Could not remove the item','error',2);
        }

        this.pageInfo.buttonEditRow = false;
    }

    validateInfo($entity,rowData){

        let specificValidations =  this.pageInfo.validations[$entity]   ? this.pageInfo.validations[$entity].find(el => !el['validation'](this[$entity].data,rowData))
                                                                        : null;

        if( specificValidations ){
            return { success    : false,
                     msg        : specificValidations.msgError
            }
        }else{
            let mandatoryFields = this[$entity].cols.filter(el => el.mandatory)
                                                    .filter(mandatoryElement =>     (rowData[mandatoryElement.field] === undefined)
                                                                                ||  (rowData[mandatoryElement.field] === null) 
                                                                                ||  (rowData[mandatoryElement.field] === '') );
            if( mandatoryFields.length > 0){

                return {  
                        success : false,
                        msg     : mandatoryFields.length == 1   ? 'Field '+ mandatoryFields[0].field +' is mandatory'
                                                                : 'Fields '+ mandatoryFields.map(el => el.field) +' are mandatory'
                        }
            }else{
                return {
                        success :true
                }
            }
        }
    }

    
    confirmRemoveRow($entity,rowData) {
        this.dialogRemoveRow($entity,rowData);
    }

    dialogRemoveRow($entity,rowData){
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.removeRow($entity,rowData);
            },
            reject: () => {
            }
        });
    }

    saveCurrentValueOnTd(element){
        this.pageInfo.tempTdSelected = JSON.parse(JSON.stringify(element));
    }

    updateRow($entity,event,rowData, element,isBlur?){
        // || (event.code == 'Enter' || event.code == 'Tab')
        if((event.keyCode == 9 || event.keyCode == 13  || isBlur)  ){
            if(!(rowData[element] == this.pageInfo.tempTdSelected)){
                let validation = this.validateInfo($entity,rowData);
                if(validation.success){
                    // ********* UPDATE ROW ************* PUT / POST
                    console.log('Updating',rowData);
                    console.log('storage and then save? continue puts?');
                    this.saveRow($entity,rowData);
                    this.commons.generateToast('SUCCESS',element.toUpperCase() + ' has been updated','success',2);
                    this.pageInfo.tempTdSelected = null;
                }else{
                    this.commons.generateToast('ERROR',validation.msg,'error',2);  
                    rowData[element] = this.pageInfo.tempTdSelected;              
                }
            }else{
                rowData[element] = this.pageInfo.tempTdSelected;
                this.pageInfo.tempTdSelected = null;

            }
        }else if(event.keyCode == 27){
            rowData[element] = this.pageInfo.tempTdSelected;
        }
        
    }

    checkField(field){
        console.log(field);
    }
    uniqueCode(data,rowData){
        if(!rowData['code']) return false;
        console.log(this)
        return data.some(el => el.code == rowData.code);
    }

    isEmail(items, dataRow : any){ return (dataRow.email.includes("@")) && (dataRow.email.includes("."))     }
}
