import { CommonsService } from 'src/app/demo/service/commons.service';
import { CommonModule } from '@angular/common';
import { FirebaseService } from 'src/app/demo/service/database/firebase.service';
import { EntityService } from 'src/app/demo/service/entity.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { Component, OnInit, ViewEncapsulation, NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { ToastrService                          } from 'ngx-toastr';
import { MapsAPILoader 							} from '@agm/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
    styleUrls		: ['./profile.component.scss'],
	templateUrl		: './profile.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class ProfileComponent implements OnInit{
   
    userInfo : any = {};
    pageInfo : any = {
        form    : null,
        buttonOptions :   {save : true, delete : false, recoverPassword : false}
    };

    constructor(private firebaseCtrl    :   FirebaseService,
                private commons         :   CommonsService,
                private storageCtrl     :   StorageService){

    }
    async ngOnInit(){
        this.init();
        await this.loadUser();
    }

    init(){
        this.pageInfo.form  = [		
			{ field: 'name',     	label: 'Name', 		    width: '200px', 	align: "center",    editor : 'input',       editable : true,    options : {type : 'text'},      mandatory : true 	},
            { field: 'surname',		label: 'Surname',  	    width: '100px',		align: "center",    editor : 'input',       editable : true,    options : {type : 'text'},      mandatory : true 	},						
            { field: 'phone',	    label: 'Phone',  		width: '150px', 	align: "center",    editor : 'input',       editable : true,    options : {type : 'text'},      mandatory : false	},
            { field: 'email',	    label: 'Email',  	    width: '250px', 	align: "center",    editor : 'input',       editable : false,   options : {type : 'text'},      mandatory : true 	},
            { field: 'profile',		label: 'Profile',				            align: "center",    editor : 'input',       editable : false,   options : {type : 'text'},      mandatory : true 	},
            { field: 'destination',	label: 'Destination',  	                    align: "center",    editor : 'input',       editable : false,   options : {type : 'text'},      mandatory : true 	}
        ];
    }

    async loadUser(){
        const dmcSuite             =   this.storageCtrl.getItem('dmcSuite');
        this.userInfo              =   dmcSuite.userInfo;
        this.userInfo.destination  =   this.userInfo.destination.map(destination => destination.name);
        console.log(this.userInfo);
    }

    async handleOption($event){
        switch($event.type){
            case 'save'     : await this.saveRow($event.rowData);    break;
            // case 'delete'   : await this.deleteRow($event.rowData);  break;            
            default         : await this.commons.generateToast('_ERROR','_OPTION_NOT_FOUND','error');
        }
    }

    async saveRow(rowData){
        try{
            await this.firebaseCtrl.updateUserStaffDmc(rowData,this.userInfo.dmcId || '1' );
            const dmcSuite      =   this.storageCtrl.getItem('dmcSuite');
            dmcSuite.userInfo   = rowData;
            this.storageCtrl.setItem('dmcSuite',dmcSuite);
            await this.commons.generateToast('_SUCCESS','_USER_UPDATED','success');
        }catch(e){
            await this.commons.generateToast('_ERROR','_ERROR_UPDATE_USER','error');
        }
    }

    // async deleteRow(rowData){
    //     try{
    //         await this.firebaseCtrl.removeUserToStaffDmc(rowData,this.userInfo.dmcId || '1');
    //         await this.commons.generateToast('_SUCCESS','_USER_DELETED','success');
    //     }catch(e){
    //         await this.commons.generateToast('_ERROR','_ERROR_REMOVE_USER','error');
    //     }   
    // }
}