import { Component, OnInit 		} from '@angular/core';
import { CommonsService 		} from '../../service/commons.service';
import { FirebaseService 		} from '../../service/database/firebase.service';
import { EntityService 			} from '../../service/entity.service';

import readXlsxFile from 'read-excel-file';

@Component({
  selector		: 'app-importer',
  templateUrl	: './importer.component.html',
  styleUrls		: ['./importer.component.scss']
})
export class ImporterComponent implements OnInit {

	entities: any = [ "bookings", "providers" ];
	pageInfo: any = {
		updateBooking		: true,
		traceColors			: {
			"error"		: "crimson",
			"info"		: "gray",
			"insert"	: "green",
			"update"	: "blue",
			"skip"		: "yellow",
			"none"		: "transparent"
		},
		importTrace			: [],					
		importerConfig		: {
			options  : {										
				tabs		: [
					{ 	name	: 'bookings',		
						icon	: "info-circle", 	
						label	: "_IMPORT_BOOKINGS",
						items	: {
							tabs		: [
							   { name: 'current',			icon: "info-circle", 	label: "_IMPORT_CURRENT"	},
							   { name: 'register',			icon: "info-circle", 	label: "_IMPORT_REGISTER"	}
						   ],
						   tab			: "current"
					   }
					},
					{ 	name	: 'services',		
						icon	: "info-circle", 	
						label	: "_IMPORT_SERVICES",
						items	: {
							tabs		: [
							   { name: 'current',			icon: "info-circle", 	label: "_IMPORT_CURRENT"	},
							   { name: 'register',			icon: "info-circle", 	label: "_IMPORT_REGISTER"	}
						   ],
						   tab			: "current"
					   }
					}
				],
				tab			: "bookings"
			}
		}
	};
	
  	constructor(
		  private commons				: CommonsService,
		  private entityService			: EntityService
	){ }

	ngOnInit() 							{
		this.pageInfo.uploadedFiles	= [];										
	}

	onUpload($type,$info) 				{	
		switch($info.type){
			case "audios"		:
			case "pictures"		:
			case "services"		:
			case "bookings"		:	for(let file of $info.event.files) {  
										this.processFile({ info: $info, file: file });																			
									}
									break;
			default				:	alert("OnUpload " + $info.type); break;
		}
	}

	processFile($info)					{		
		let type = $info.format;
		switch(type){
			case "csv":		let fileReader		= new FileReader();										
							fileReader.onload 	= (() => { 
								switch($info.type){
									case "bookings": this.processBookingsCSV(<string>fileReader.result); break;
									case "services": this.processServicesCSV(<string>fileReader.result); break;
								};
							});

							fileReader.onerror = (error) => {
								this.pageInfo.importTrace.push({	type	:	"error",
																	label	:	this.commons.getTranslate("_FILE"),
																	content	: 	this.commons.getTranslate("_PROCESSING_FILE_ERROR")
																})
							}
							fileReader.readAsText($info.file);
							break;

			case "xlsx":	switch($info.type){
								case "bookings": this.processBookingsExcel($info.file);								
							}
							break;
		}
	}

// ----------------------------------------------------------------------------
// SERVICES
// ----------------------------------------------------------------------------

	processServicesCSV($info)			{	
		let header,entries,data;
		let wrongDelimiter = false;
		
		( $info.split('\n') || [] ).forEach(($line,$index)=>{
			if(wrongDelimiter){ return; }
			data = $line.split("#") || [];
			if(data.length<2){ 
				switch(data[0]){
					case ""	:	console.log("Últtma linea",$index);	break;
					default	:	wrongDelimiter = true;
								this.pageInfo.importTrace.push({ type: "error", label: "error", content: this.commons.getTranslate("_CSV_WRONG_DELIMITER") });
								break;
				}
			} else {													
				switch($index){
					case 0	:	header = data; 							break;
					default	:	this.addService(header,data,$index);	break;
				}
			}
		});
	}

	// WHY IS NECESSARY ????
	isSelected($action,$info){
		return false;
	}

	/**
	 * 
	 * @param $header 
	 * @param $item 
	 * @param $index 
	 * @returns 
	 */
	async addService($header,$item,$index){	
		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length});												
		}
	
		let result = {};

		($header||[]).forEach((header,index)=>{
			result[header] = $item[index];
		});

		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length });
			return false;
		}										

		try {
			let response = await Promise.resolve(this.persistBooking(result));
			switch(response["action"]){
				default			: this.pageInfo.importTrace.push({ type: "error", 	label: "error", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
				case "insert"	: this.pageInfo.importTrace.push({ type: "insert", 	label: "insert", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
				case "update"	: this.pageInfo.importTrace.push({ type: "update", 	label: "update", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;									
				case "skip"		: this.pageInfo.importTrace.push({ type: "skip", 	label: "skip", 		content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;														
			}
			return true;
		
		} catch(e){
			return false;
		}										
	}

	/**
	 * Persist Booking
	 * 
	 * @param $item
	 */
	async persistService($item) 		{	
		if(!this.pageInfo.providerSelected){
			this.commons.generateToast("Error","No provider selected","error");
			return false;
		}
		return await this.entityService.postJSON(
			this.entityService.getUrl("booking_update"),
			{ 	booking			: $item,
				source			: 'excel',
				provider		: this.pageInfo.providerSelected.id,
				dmc				: this.commons.userInfo.dmc.id,
				destination		: this.commons.userInfo.destination.id,
				updateBooking	: this.pageInfo.updateBooking
			}
		)
	}

// ----------------------------------------------------------------------------
// BOOKINGS
// ----------------------------------------------------------------------------

	processBookingsExcel($file)			{	
		readXlsxFile($file).then((rows)=>{
			let header = rows[0];
			rows.shift();	// Remove header line to process data rows	
			// this.addBooking(header,rows[0],1);
			rows.forEach((row,index)=>{
				this.addBooking(header,row,index+1);													
			});												
		})
	}

	processBookingsCSV($info)			{	
		let header,entries,data;
		let wrongDelimiter = false;
		
		( $info.split('\n') || [] ).forEach(($line,$index)=>{
			if(wrongDelimiter){ return; }
			data = $line.split("#") || [];
			if(data.length<2){ 
				switch(data[0]){
					case ""	:	console.log("Últtma linea",$index);	break;
					default	:	wrongDelimiter = true;
								this.pageInfo.importTrace.push({ type: "error", label: "error", content: this.commons.getTranslate("_CSV_WRONG_DELIMITER") });
								break;
				}
			} else {													
				switch($index){
					case 0	:	header = data; 							break;
					default	:	this.addBooking(header,data,$index);	break;
				}
			}
		});
	}

	async addBooking($header,$item,$index){	
		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length});												
		}
	
		let result = {};

		($header||[]).forEach((header,index)=>{
			result[header] = $item[index];
		});

		if($header.length!=$item.length){
			this.pageInfo.importTrace.push({ type: "error", label: "error", content: "Line["+$index+"] " + this.commons.getTranslate("_CSV_FIELDS_AND_HEADER_MISMATCH")+" "+$header.length+"/"+$item.length });
			return false;
		}										

		try {
			let response = await Promise.resolve(this.persistBooking(result));
			switch(response["action"]){
				default			: this.pageInfo.importTrace.push({ type: "error", 	label: "error", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
				case "insert"	: this.pageInfo.importTrace.push({ type: "insert", 	label: "insert", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;
				case "update"	: this.pageInfo.importTrace.push({ type: "update", 	label: "update", 	content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;									
				case "skip"		: this.pageInfo.importTrace.push({ type: "skip", 	label: "skip", 		content:"Line["+$index+"] "+this.commons.getTranslate("_BOOKING_WITH_REF"	)+" "+result[this.pageInfo.providerSelected.refField] }); break;														
			}
			return true;
		
		} catch(e){
			return false;
		}										
	}

	/**
	 * Persist Booking
	 * @param $item
	 */
	async persistBooking($item) 		{	
		if(!this.pageInfo.providerSelected){
			this.commons.generateToast("Error","No provider selected","error");
			return false;
		}
		return await this.entityService.postJSON(
			this.entityService.getUrl("booking_update"),
			{ 	booking			: $item,
				source			: 'excel',
				provider		: this.pageInfo.providerSelected.id,
				dmc				: this.commons.userInfo.dmc.id,
				destination		: this.commons.userInfo.destination.id,
				updateBooking	: this.pageInfo.updateBooking
			}
		)
	}

	getFilteredEntity($entity,$info?)	{
		let response, selected;
		switch($entity){
			case "executions"			:	return  [];
			case "providers"			:	this.pageInfo.providers = (this.commons.entities["providers"	] || []).filter(item=>item.importer	);
											if(undefined==this.pageInfo.providerSelected){
												this.pageInfo.providerSelected = this.pageInfo.providers[0];
											}
											return this.pageInfo.providers;

			case "views"				:	return (this.commons.entities["views"	 	] || [])[this.pageInfo.entity.plural];																														
			case "info"					:	return this.commons.entities[this.pageInfo.entity.plural] || [];
			default						:	return [];
		}
	}

	getInfo($type,$info?){
		let selected;
		let selected_tabs;
		switch($type){			
			case "current_tabs"			:	selected = this.pageInfo.importerConfig.options.tab;
											selected_tabs = this.pageInfo.importerConfig.options.tabs.find(item=>item.name==selected);
											return selected_tabs;

			case "current_exec_tabs"	:	selected = this.pageInfo.importerConfig.options.tab;
											selected_tabs = this.pageInfo.importerConfig.options.tabs.find(item=>item.name==selected).items.tabs;
											return selected_tabs.items;
		}
	}

	async doAction($type,$item?,$extra={})	{
		switch($type){
			case "clear_traces"		:	document.getElementById('trace_container').innerHTML=""; break;
			case "tab"				:	switch($item.action)	{
				case "select"		:	switch($item.type){
											case "importer"	:	this.pageInfo.importerConfig[$item.tabs].tab	= $item.tab.name; break; 	
										}												
										break;
			}
			case "provider"			:	switch($item.action)	{
											case "select"	:	if(this.pageInfo.providers.length<2)			{ return false; }
																if(undefined==this.pageInfo.providerSelected)	{ this.pageInfo.providerSelected = $item.provider; 	return false; }
																this.pageInfo.providerSelected = $item.provider;
																break;
										}
										break;
			break;
		}
	}
}
