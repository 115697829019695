// / <reference types="@types/googlemaps" />
import { Injectable                         } from '@angular/core';
import { GoogleMap                  		} from '@agm/core/services/google-maps-types';
import { MapsAPILoader, 
         AgmCircle,
         AgmMap 							} 	from '@agm/core';

declare const google: any;

@Injectable()
export class GoogleService {

    constructor(
        private mapsAPILoader	: MapsAPILoader,
    ) {}

    async loadApi(){return await this.mapsAPILoader.load();}

    getPlacePredictions(query: string): Promise<any> {
        let autocompleteSrv = new google.maps.places.AutocompleteService();
        return new Promise((resolve, reject) => {
         
            autocompleteSrv.getPlacePredictions({
                input: query,
                componentRestrictions: { country: 'ES' }
            }, function (predictions, status) {
                console.log(predictions,status);
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve({success: true, data : predictions});
                } else {
                    resolve({success: false, reason : status});
                }
            });
        });
    }

    getDetailsFromPlace(place_id) : Promise <any>{
        let placeInfo   =   new google.maps.places.PlacesService(document.createElement('div')); // createElement is a hack and actually it works 🤣
        return new Promise((resolve,reject)=>{
            placeInfo.getDetails(   {   placeId : place_id,
                                        fields  : [ 'place_id',
                                                    'name', 
                                                    'address_component', 
                                                    'type', 
                                                    'formatted_address', 
                                                    'geometry',
                                                    'international_phone_number',
                                                    'photos',
                                                    'vicinity',
                                                    'website']
            }, (place,status) =>{
                if(status == google.maps.places.PlacesServiceStatus.OK && place){
                    resolve({success : true, status : status, data : place});
                }else{
                    resolve({success : false, data : place, status : status});
                }
            })
        })
    }

    getNearbyData(latLng,radius=250) : Promise<any>{
        let self 				= this;
        let places              = [];
		let service 			= new google.maps.places.PlacesService(document.createElement('div'));
        return new Promise((resolve, reject)=>{            
            service.nearbySearch(
                {location: latLng, radius: radius, types : ['lodging'] },
                function(results, status, pagination) {
                    if (status !== 'OK'){ resolve({success : false, status : status})}
                    places = places.concat(
                            results.map(el => (
                                { ...el, 
                                    location : { 
                                        latitude	: el.geometry.location.lat(), 
                                        longitude 	: el.geometry.location.lng()
                                    }
                                }
                            ))
                    );	
                    if(pagination.hasNextPage){
                        // setTimeout(() =>{  }, 2000 );
                        pagination.nextPage()
                    }else{
                        resolve({ success : true, data : places });
                    }
                }
            );
        })    
    }

    getCenterPolygons(listCoords){
        var bounds = new google.maps.LatLngBounds();
        listCoords.forEach(coord =>{
            let points = new google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(points);
        });
        return {    
            lat     : bounds.getCenter().lat(),
            lng     : bounds.getCenter().lng(),
            bounds  : bounds  
        }
    }

}
