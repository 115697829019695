import { CompanyService 			} from './demo/service/database/company.service';
import { EntityService 				} from './demo/service/entity.service';
import { CommonsService 			} from 'src/app/demo/service/commons.service';
import { FirebaseService 			} from './demo/service/database/firebase.service';
import { Router 					} from '@angular/router';
import { StorageService 			} from './demo/service/storageservice';
import { Component, 
		 Input, 
		 OnInit, 
		 OnChanges, 
		 Output, 
		 EventEmitter, 
		 Inject 					} from '@angular/core';
import { AppComponent				} from './app.component';
import { AuthFirebaseService 		} from './demo/service/database/authfirebase.service';
import { DOCUMENT 					} from '@angular/common';

@Component({
  selector		: 'app-topbar',
  styleUrls		: [ './app.topbar.component.scss' ],
  templateUrl	: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit,OnChanges{

    @Input()  	visible : boolean   =   false;
	@Input()  	roles   : string[]  =   [];
	@Input()	modules	: any;
	@Output()	emitter				= new EventEmitter<any>();	

	pageInfo			: any 		=	{ entities: {}, fullScreen : false, taskManager: { openned: false }};
	userInfo 			: any 		=	{};
	filters				: any		= [
		{ showLabel: false,	name: 'country',		label: '_COUNTRY', 		type: 'dropdown', position: 'left', items: [], selected	: [] },
		{ showLabel: false, name: 'destination',	label: '_DESTINATION', 	type: 'dropdown', position: 'left', items: [], selected	: [] },
		{ showLabel: false,	name: 'station',		label: '_STATION', 		type: 'dropdown', position: 'left', items: [], selected	: [] }
	];
		
    constructor(
		public 	app      					: AppComponent, 
		public 	storage  					: StorageService, 
		private commons						: CommonsService,		
		public	route 						: Router, 
		public 	dbAuth 						: AuthFirebaseService, 
		public 	firebaseService				: FirebaseService,
		private entityService				: EntityService,
		private companyService				: CompanyService,
		private router						: Router,
		@Inject(DOCUMENT) private document	: any
	) {}

	ngOnChanges()						{	this.visible 	= 	this.visible != undefined ? this.visible : true;	}
    
	async ngOnInit(reload?)				{	
		this.pageInfo.entityType	= 	{ profile : 'admin', type : 'admin' };										
		this.pageInfo.filters		=	await this.commons.translateRecursively(this.filters,{ label: "label", children: "items" });																		
		switch(this.pageInfo.entityType.profile){
			default				:												
			case 	'dmc'		:	await this.loadEntitites();
									this.assignEntities();
									if(this.commons.userInfo.currentDestination)	{	
										this.initNotifications();
									}
									break;
			case	'reseller'	:	await this.loadEntitites();	break;
			case 	'admin'		:	await this.loadEntitites();	break;
		}
		console.log(this.commons.userInfo);
		// this.assignDestination();								
		// this.reloadPage();
	}

	async loadEntitites()				{	
		switch(this.pageInfo.entityType.profile){	
			case 'dmc'		:	await this.load("destinations");	break;
			case 'admin'	:	await this.load("dmcs"); 			break;
			case 'reseller'	:	await this.load("dmcs"); 			break;
		}
	}

	assignEntities()					{	
		if( this.commons.userInfo.currentCountry && this.commons.userInfo.currentCountry.id){
			this.doAction('selectCountry',this.pageInfo.entities.countries.data.find(el => el.id == this.commons.userInfo.currentCountry.id));

		}
		this.commons.userInfo.currentDestination	=	this.commons.userInfo.currentDestination ? this.commons.userInfo.currentDestination : null;
		this.commons.userInfo.currentStation		=	this.commons.userInfo.currentDestination ? this.commons.userInfo.currentStation		: null;
		this.pageInfo.enableChangeDest				=	this.commons.userInfo.currentDestination ? false							: true;
	}

    async logOut()						{	this.dbAuth.logOut();	}

    search($event)						{	switch(true){
												case $event == 'notifications'	:	this.pageInfo.displayNotifications = !this.pageInfo.displayNotifications; break;
												default							:	return;
											}
										}

	initNotifications()					{	this.pageInfo.tmpExpanded			= [];
											this.pageInfo.displayNotifications	= false;
											this.pageInfo.issues				= { "inbox" : [], "outbox" : []};
											this.pageInfo.notifications			= {};		
										}

	reloadPage()						{	window.location.reload();			}

	/**
	 * Store all entities in an object which works as an input to notify-component
	 * @param entity entity to update
	 */
	mergeNotifications(entity)			{	this.pageInfo.notifications[entity]	=	this.pageInfo[entity];
											console.log('NOTIFICATIONS',this.pageInfo.notifications);
										}

	mergeEntities(items)				{	this.pageInfo.pendingNotifications	=	0;
											let typeConsults	=	Array.from(new Set(items.map(item => item.type))); // get list of consult types (assistant, issue)
											typeConsults.forEach((type : string) =>{
												let filteredItems	=	items.filter(el => el.type == type);
												let badgeItem			=	filteredItems.reduce((a,b) => a+= b.unreadMsgs.operator,0);	
												this.pageInfo.notifications[String(type).toLocaleLowerCase().replace('_','')]	=	{
													items	:	filteredItems,
													badge	:	badgeItem
												};
												this.pageInfo.pendingNotifications += badgeItem;
											});
											console.log('CUSTOM MERGE ISSUE + CONSULTS',this.pageInfo.notifications);
											console.log('PENDING NOTIFICATIONS', this.pageInfo.pendingNotifications);
										}

	// --------	------------------------------------------------------------------------------
	// CHAT ASSISTANT
	// -------------------------------------------------------------------------------------	
	async getAssistantMessages(refRoom)	{	
		let data	=	await this.firebaseService.getPromisedMessages(refRoom); // get the list of messages
		let msgs	=	[];
		data.forEach(el => msgs = [...msgs,el.data()]);
		msgs 		= 	msgs.map(el => {return {...el, timestamp : el.timestamp['seconds'] ? el.timestamp['seconds']*1000 : el.timestamp}});
		msgs 		= 	msgs.sort((msg1,msg2) =>  msg1.timestamp - msg2.timestamp);
		return msgs; //return the list ordered and computed

	}

	/**
	 * Event from notification component
	 * @param $event param emited. 
	 */
	handleNotification($event)			{	
		switch($event.type){
			case 'expand'	:	let findItem	=	this.pageInfo.tmpExpanded.findIndex(item => item.id == $event.item.id);
								if($event.item.expanded){
									findItem > -1 ? null : this.pageInfo.tmpExpanded.push($event.item);
								}else{
									findItem > -1 ? this.pageInfo.tmpExpanded.splice(findItem,1) : null;
								};
								console.log('Temporary items expandeds', this.pageInfo.tmpExpanded);
								break;
			default			:	return;
		}
	}


	async doAction($type,$item)			{	
		switch($type){
			case "taskManager"			:	this.pageInfo.taskManager.openned	= this.pageInfo.taskManager.openned?false:true;
											break;

			case "toggleDmc"			:	this.pageInfo.toggleDmc 			= this.pageInfo.toggleDmc?false:true;
											break;

			case "toggleCountry"		:	this.pageInfo.toggleCountry 					= this.pageInfo.toggleCountry?false:true;
											this.pageInfo.toggleDestination 				= false;
											this.pageInfo.toggleStation						= false;
											break;

			case "toggleDestination"	:	this.pageInfo.toggleDestination 				= this.pageInfo.toggleDestination?false:true;
											this.pageInfo.toggleCountry 					= false;
											this.pageInfo.toggleStation						= false;
											break;

			case "toggleStation"		:	this.pageInfo.toggleStation 					= this.pageInfo.toggleStation?false:true;
											this.pageInfo.toggleCountry 					= false;
											this.pageInfo.toggleDestination 				= false;
											this.commons.userInfo.station					= $item;
											break;
			
			case "selectDmc"			:	this.commons.userInfo.currentDmc				= $item;
											this.pageInfo.toggleDmc 						= false;
											this.load('destinations');
											break;

			case "selectCountry"		:	this.commons.userInfo.currentCountry 			= $item;
											this.commons.userInfo.currentDestination 		= undefined;
											this.commons.userInfo.currentStation			= undefined;
											this.pageInfo.toggleCountry 					= false;																														
											break;

			case "selectDestination"	:	this.commons.userInfo.currentDestination 		= $item; 
											this.commons.userInfo.currentDestination.label	= $item.name;
											this.commons.userInfo.currentStation			= undefined;
											this.pageInfo.toggleDestination					= false;	
											this.pageInfo.entities['stations']				= this.pageInfo.entities['stations'] || {}
											this.pageInfo.entities['stations'].data			= !this.commons.userInfo.currentDestination.workingStations 
																								? [] 
																								: this.commons.userInfo.currentDestination.workingStations
																									.map(item => item.items)
																									.flat()
																									.map(item => { return {...item,label : item.type[0].toUpperCase() +item.type.substr(1) + ' ' + item.name}});
											this.pageInfo.enableChangeDest					= true;
											break;

			case "selectStation"		:	this.commons.userInfo.currentStation 			= $item; 
											this.pageInfo.toggleStation						= false;
											break;

			case "assignDestination"	:	this.assignDestination();
											break;
		}
	}

	private async assignDestination()			{
		if( undefined===this.commons.userInfo.currentDmc		){ 
			this.commons.userInfo.currentCountry = this.pageInfo.entities["dmcs"		].data[0];
			await this.load('destinations');											
		}
		if( undefined===this.commons.userInfo.currentCountry	){ 
			this.commons.userInfo.currentCountry = this.pageInfo.entities["countries"	].data[0];
		}
		if( undefined===this.commons.userInfo.currentDestination){ 
			this.commons.userInfo.currentCountry = this.pageInfo.entities["destinations"].data[0];
		}
		if( undefined===this.commons.userInfo.currentStations	){ 
			this.commons.userInfo.currentCountry = this.pageInfo.entities["stations"	].data[0];
		}
								
		this.commons.userInfo.dmc			= this.commons.userInfo.currentDmc;
		this.commons.userInfo.country		= this.commons.userInfo.currentCountry;
		this.commons.userInfo.destination	= this.commons.userInfo.currentDestination;									
		this.commons.userInfo.station		= this.commons.userInfo.currentStation;

		let infoStorage						= this.storage.getItem('dmcSuite') || {};
		infoStorage.userInfo				= infoStorage.userInfo || { userInfo: {} };
		
		await this.firebaseService.updateUserStaffDmc(	
			{	
				id		 			: 	this.commons.userInfo.id,
				currentDmc			:	this.commons.userInfo.dmc,
				currentCountry		: 	this.commons.userInfo.country,
				currentDestination	:	this.commons.userInfo.destination,
				currentStation		: 	this.commons.userInfo.station
			},
			this.commons.userInfo.currentDmc.id
		);

		infoStorage.userInfo.currentDmc			=	{
															id		:	this.commons.userInfo.dmc.id,
															name	:	this.commons.userInfo.dmc.name,
															label	:	this.commons.userInfo.dmc.label
													};
		infoStorage.userInfo.currentCountry		=	this.commons.userInfo.country;
		infoStorage.userInfo.currentDestination	=	{
														id				:	this.commons.userInfo.destination.id,
														name			:	this.commons.userInfo.destination.name,
														label			:	this.commons.userInfo.destination.label,
														refDestination	:	this.commons.userInfo.destination.refDestination.path
													};
		infoStorage.userInfo.currentStation		=	this.commons.userInfo.station

		let parsedInfoStorage = JSON.parse(JSON.stringify(infoStorage));
		this.storage.setItem('dmcSuite',parsedInfoStorage);
		this.reloadPage();
	}


	getFilteredEntity($entity,$info)	{	
		this.pageInfo.entities[$entity]	= this.pageInfo.entities[$entity] || {};
		switch($entity){
			case "dmcs"				:	return this.pageInfo.entities[$entity].data;
			
			case "countries"		:	return this.pageInfo.entities[$entity].data;

			case "destinations"		:	if(undefined==this.commons.userInfo.currentCountry){ return []; }
										return this.pageInfo.entities[$entity].data.filter(item=>item.country==this.commons.userInfo.currentCountry.value);

			case "stations"			: 	return this.pageInfo.entities['stations'].data;										
		}
	}

	async load($entity)					{	
		let response = {};
		this.pageInfo.entities[$entity]	= this.pageInfo.entities[$entity] || {};				
		switch($entity){	
			case 'dmcs'				:	break;
								
			case 'countries'		:	break;
			
			case 'destinations'		:	break;

			case 'stations'			:	break;
		}
	}

	toggleFullScreen()					{	this.pageInfo.fullScreen = !this.pageInfo.fullScreen;
											this.pageInfo.fullScreen ? this.commons.openFullscreen(document.documentElement) : this.commons.closeFullscreen(this.document); 
										}
}
