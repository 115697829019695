import { Component,
         Input,
         Output,
         EventEmitter,
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { entities 				} from './data/items';
import { ChartService 			} from '../../../chart.service';

@Component({
  selector    		: 'app-provider-list',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'
})
export class ProviderListComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();

	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [],
		buttons 		: [],
		filters			: [],
		views			: { items: [] },
		entities		: { providers: {} }
	}

	constructor(
		private commons			: CommonsService,
		private entityService	: EntityService,
		private chartService	: ChartService
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'reload',		label: '_RELOAD', 	icon: 'fa fa-refresh', 		type: 'button'	},
			{ 	name: 'new',		label: '_NEW',		icon: 'fa fa-plus',			type: 'button'	}
		];
		this.pageInfo.status		= "list";
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.entities["providers"].table	= {
			cols	: [
				// {	field: 'id',			header		: '_ID',			width		:  '50px',	align: 'center', disabled	: false			},
				{	field: 'status',		header		: '_STATUS',		width		:  '50px',	align: 'center', type		: 'check',	disabled	: false			},
				{	field: 'last_check',	header		: '_LAST_CHECK',	width		:  '50px',	align: 'center', disabled	: false			},
				{	field: 'name',			header		: '_NAME',			width		: '400px',	align: 'center', disabled	: false			},
				{	field: 'hire_init',		header		: '_HIRE_INIT',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'hire_end',		header		: '_HIRE_END',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'bookings',		header		: '_BOOKINGS',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'errors',		header		: '_ERRORS',		width		: '100px',	align: 'center', disabled	: false			}				
			],
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '35vh', showMenu: false },
			// buttons	: [
			// 	{ name: 'reload', 	label: '_RELOAD', 	icon: 'refresh'	},
			// 	{ name: 'new', 		label: '_NEW', 		icon: 'plus'	}
			// ],
			filters	: [],
			form	: {
				showEmptyTitle	: false,
				items			: [
					{
						cols	: 3,
						title	: "_INFO",
						items	: [
							{	field: 'id',			label		: '_ID',			editable	: false, 	type: 'text'		},
							{	field: 'name',			label		: '_NAME',			editable	: false, 	type: 'text' 		}							
						]
					},
					{
						cols	: 3,
						title	: "_STATUS",
						items	: [
							{	field: 'status',		label		: '_OPERATIVE',		editable	: false, 	editor: 'toggle' 	},
							{	field: 'last_check',	label		: '_LAST_CHECK',	editable	: false, 	type: 'text' 		}							
						]
					},
					{
						cols	: 3,
						title	: "_HIRE",
						items	: [
							{	field: 'hire_init',		label		: '_HIRE_INIT',		editable	: false, 	type: 'text' 		},
							{	field: 'hire_end',		label		: '_HIRE_END',		editable	: false, 	type: 'text' 		}							
						]
					},
					{
						cols	: 3,
						title	: "_STATS",
						items	: [
							{	field: 'bookings',		label		: '_BOOKINGS',		editable	: false, 	type: 'text' 		},
							{	field: 'errors',		label		: '_ERRORS',		editable	: false, 	type: 'text' 		}
						]
					}
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       	icon: 'floppy-o'	}					
				]
			}
		};

		await this.initFilters("providers");
	}

	async initFilters($entity)	{
		this.pageInfo.entities[$entity].filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
	}

	checkFilter(filter)			{	switch(filter.type){
										case "multiple":	this.checkMultipleFilter(filter); 	break;
										default:			this.checkSimpleFilter(filter);		break;
									}
	}

    checkMultipleFilter(filter)	{
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= {
																				field	: filter.field,
																				options	: filter.items.map(item=>item.value),
																				selected: filter.selected
																			};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this.pageInfo.entities[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this.pageInfo.entities[$entity].data			|| [];
		let filters = this.pageInfo.entities[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "shared":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		this.pageInfo.entities[$entity].filteredData	= data;
		this.pageInfo.entities[$entity].count 		= this.pageInfo.entities[$entity].filteredData ? this.pageInfo.entities[$entity].filteredData.length : 0;
		// this.paginateLocal($entity, null);
	}

	filterPending(filter){}

	async initMenu($entity){
		let me									= this;
		this.pageInfo.entities[$entity].menu	= [];
		this.pageInfo.entities[$entity].filters.forEach(async filter=>{
			let item 		= {
				label		: await this.commons.getTranslate(filter.label),
				icon		: filter.icon?('fa fa-'+filter.icon):'',
				style		: { padding: '5px 10px' },
				items		: []
			};
			filter.items.forEach(async current=> {
				let element	= {
					label		: 	await this.commons.getTranslate(current.label),
					icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
					command		: 	($event) => { me.doMenuAction(current.value,filter,$entity); }
				}
				item["items"].push(element);
			});
			this.pageInfo.entities[$entity].menu.push(item);
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter,$entity){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		$filter.selected = $filter.selected.some(item=>item===$item)
							?$filter.selected.filter(item=>item!==$item)
							:[...$filter.selected,$item];
		this.checkFilter($filter);		// Execute filtering
		this.initMenu($entity);		// Regenerate menu to show selection
	}

	async loadEntities()		{ 	await this.load("providers");
								}
  	async ngOnInit()			{	await this.init(); await this.loadEntities();			}
    onUpload(event) 			{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}
	async action($type,$item)			{
		switch($type){
			case "button"			:	switch($item.name){
											case "reload"		: 	this.loadEntities();												break;
											case "list"			:	this.pageInfo.status = $item.name;									break;
											case "stats"		:	this.pageInfo.status = $item.name;
																	if(this.pageInfo.entities["providers"].selected){
																		this.pageInfo.entities["providers"].charts = this.chartService.generateCharts({
																			entity		:	this.pageInfo.entities["providers"],
																			title		: 	await this.commons.getTranslate('_PAYMENTS'),
																			chartName	:	"payment",
																			datasets	:[
																				{ label: await this.commons.getTranslate("_TRANSPORTER"), 	color: '#59c429', data: Array.from(Array(30).keys()).map(item=>item<10?0:-425) 				},
																				{ label: await this.commons.getTranslate("_PROVIDER"), 		color: '#9c4290', data: Array.from(Array(30).keys()).map(item=>item<15?0: 650)				},
																				{ label: await this.commons.getTranslate("_TOTAL"), 		color: '#c42931', data: Array.from(Array(30).keys()).map(item=>item<10?0:(item>15?125:-425))}
																			]
																		});
																	}
																	break;
											case "api"			:	this.pageInfo.status = $item.name;									break;
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;
											case "new"			:	this.pageInfo.entities["providers"].data.unshift({});				
																	this.pageInfo.entities["providers"].table.form.items.forEach(formItem => formItem.items.forEach(item => item.editable = true));
																	break													
										}
										break;
			case "tableProviders"	:	switch($item.type){
											case "new"			:	this.pageInfo.entities["providers"].data.unshift({});
																	break;
											case "reload"		: 	this.loadEntities();												break;
											case "selectedRow"	:	//this.commons.generateToast("_INFO","Selected Item","info");
																	this.pageInfo.entities["providers"].selected = $item.item;
																	break;
											case "saveRow"		:	this.commons.generateToast("_INFO","Save row","info");				break;
											case "createInvoice":	this.commons.generateToast("_INFO","Create invoice","info");		break;
										}
										break;
		}
	}
	getFilteredEntity($entity)	{
		switch($entity){
			case "providers"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
		}
	}
	async load($entity)			{
		let response = {};
		switch($entity){
			case 'providers' :
				response 								= 	await Promise.resolve(this.entityService.getRequest('providers'));
				// response["data"]						=	serviceFakeData;				
				this.pageInfo.entities[$entity].data	= 	response["data"].map(item=>{
																item.hire_init	= "2019-09-01",
																item.hire_end	= "2020-08-31",
																item.bookings	= Math.ceil(Math.random()*50000);
																item.errors		= Math.ceil(Math.random()*500);
																item.status		= true;
																item.last_check	= "12:24";
																return item;
															});

				// this.pageInfo.entities[$entity].data	=	[...Array.from(Array(5).keys()).map(item=>[...response["data"]]) ];
				this.pageInfo.entities[$entity].data	=	[	...response["data"], 
																// ...response["data"], 
																// ...response["data"], 
																// ...response["data"],
																// ...response["data"], 
																// ...response["data"] 
															];
				break;
		}
	}
}
