import { Injectable 	} from '@angular/core';
import { HttpClient 	} from '@angular/common/http';
import { CommonsService } from '../commons.service';

@Injectable()
export class HoppaService {

	private endpoint:string	= "http://www.a2btransfers.com/xmlconf/";

    constructor(
		private http	: HttpClient,
		private common	: CommonsService
    ) {
	}
	
	public async validateBooking($data){
		let xml  = 	"<A2BConf>"+
					"	<Authentication>"+
					"		<Email>info@trasmedtour</Email>"+
					"		<Password>MAJORCA2018</Password>"+
					"	</Authentication>"+
					"	<SearchCriteria>"+
					"		<TransactionID>CB</TransactionID>"+
					"		<BookingID>"+$data["reference"]+"</BookingID>"+
					"		<Verify>yes</Verify>"+
					"		<PickUpTime></PickUpTime>"+
					"		<PickUpDate></PickUpDate>"+
					"		<VerificationNotes></VerificationNotes>"+
					"		<AlternativePickUpPoint></AlternativePickUpPoint>"+
					"	</SearchCriteria>"+
					"</A2BConf>";
		await this.postXML(xml).then(response=>{
			console.log("THEN",response);
			this.common.generateToast("Validate Booking","Validation Done","success");
			return true;
		}).catch(response=>{
			console.log("CATCH",response);
			this.common.generateToast("Validate Booking","Error validating booking","error");
			return false;
		});
	}

	private getURL()				{ return this.endpoint; }
    private async post($params)    	{ return Promise.resolve(this.http.post(this.getURL(),$params).toPromise());					}
    private async postJSON($params)	{ return Promise.resolve(this.http.post(this.getURL(),JSON.stringify($params)).toPromise());	}
    private async postXML($xml)    	{ return Promise.resolve(this.http.post(this.getURL(),$xml).toPromise());						}
}
