import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector		: 'app-form-issue',
  templateUrl	: './form-issue.component.html',  
  styleUrls   	: ['./form-issue.component.scss']
})
export class FormIssueComponent implements OnInit {

  @Input() issue : any = {};
  @Input() mode	 : any = null;

  constructor() {}
  
  ngOnInit(){

  }

}