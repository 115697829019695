export const filters = [			
	{
		entity	: 'mode',
		name	: 'mode',
		label	: 'Mode',
		type	: false,
		field	: 'mode',
		items	: [
			{	label	:	'Location',	value: 'location'},
			{	label	:	'Areas',	value:	'areas'},
			{	label 	:	'Free',		value:	'free'}
		],
		selected: ["location"]
	}
];

// {
// 	entity	: 'transfers',
// 	name	: 'transfers',
// 	label	: 'Transfers',
// 	type	: 'multiple',
// 	field	: 'type',
// 	items	: [
// 		{ label: 'Arrival', 	value: 'arrival' 	},
// 		{ label: 'Departure', 	value: 'departure' 	}
// 	],
// 	selected: [ "arrival", "departure" ]
// }