import { StorageService } from './../../../service/storageservice';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import { EntityService } from '../../../service/entity.service';
import { CommonsService } from '../../../service/commons.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/primeng';
import { throwError } from 'rxjs';

@Component({
    templateUrl: './vehicles.component.html',
    styles: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }

        .stickyRow{
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }
        body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
            width: 2em;
            height: 26px;
            padding: -0.94em;
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation: ViewEncapsulation.None,
    providers: [ MessageService, ConfirmationService ]
})

export class VehiclesComponent implements OnInit {
    
    @ViewChild('mainTable') mainTable : any;
    @ViewChild('subTable') subTable   : any;
    fleet        	: any = { cols: [], filters: {} };
    itemVehicles    : any = { cols: [], filters: {} };
    companie        : any = {};
    results         : any[];
    rowGroupMetadata: any;
    pageInfo        : any = {
        tempTdSelected  : null,
        searchElement   : null,
        //auxData if finally we decide to use it on the general search, otw we can delete it
        auxData         : [],
        fleetOpenRow 	: [],
        visible         : false,
        createVehicle   : false,
        newVehicle      : {},
        tempRow         : null,
        typeVehicle    	: [
            {title : 'Shared', value : 'SH'},
            {title : 'Private',value : 'PR'}
        ],
        buttonEditRow       : false,
        buttonRemoveRow     : true,
        buttonCancelRow     : true,

        //***** ITEM VEHICLES VARIABLES */
        columnsCreateItem     : [
            { field: 'field',			label: "Field",		align: "left",	 width: "50px"	 		},
            { field: 'value',			label: "Value",	    align: "left",   width: "500px" 		}],
        currentVehicleSelcted : null,
        buttonEditTransport   : false,

        //***** VALIDATIONS ******/
        validations : {
            fleet : [
                {
                    field       : 'code',
                    validation  : this.uniqueCode,
                    msgError    : 'This code already exists or it is not valid'
                }
            ]
        }

    }
    menu: any = {
        options: [
            { label: 'reload', icon: 'fa fa-plus' },
            { label: 'download', icon: 'fa fa-download' },
        ]
    };
    
    constructor(
        private http: HttpClient,
        private commons: CommonsService,
        private entityService: EntityService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private storageCtrl        : StorageService
    ) {
        this.loadEntities();
    }

    async loadEntities() {
        await this.load('fleet');
    }

    ngOnInit() {
        this.init();
    }

    async init() {

        this.fleet.cols = [
			{ field: 'code',     	header: 'Code', 		width: '50px', 	align: "center", 	editor : 'input',  			options : {type : 'text'}, mandatory : true 	},
			{ field: 'type',	    header: 'Type',  	    width: '75px', 	align: "center",	editor : 'autocomplete',  	options : {suggestions : this.results}, mandatory : true	},
            { field: 'name',	    header: 'Name',  	    width: '200px', align: "center",  	editor : 'input',  			options : {type : 'text'}, mandatory : true	},
            { field: 'pax',	    	header: 'Pax',  	    width: '50px', 	align: "center",  	editor : 'input',  			options : {type : 'number'},	},
            { field: 'qty',   	    header: 'Quantity',	    width: '50px', 	align: "center",  	editor : 'input',      		options : {type : 'number'},	},
			{ field: 'adapted',	    header: 'Adapted',  	width: '50px', 	align: "center",  	editor : 'inputswitch', 	renderer : 'adapted'  	},
			{ field: 'alias',	    header: 'Alias',  	    width: '50px', align: "center" , 	editor : 'input',      		options : {type : 'text'},	}
        ];

        this.fleet.form = [		
			{
				title	: "Info",
				items	: [					
					{ field: 'code',     	label: 'Code', 		width: '50px', 	align: "center", 	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'}, 	},
					{ field: 'type',	    label: 'Type',  	width: '75px', 	align: "center",	type: 'text', editable: true, editor : 'autocomplete',  	mandatory : true,	options : {suggestions : this.results} },
		            { field: 'name',	    label: 'Name',  	width: '200px', align: "center",  	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'} },
		            // { field: 'pax',	    	label: 'Pax',  	    width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input', 			options : {type : 'number'},	},
		            // { field: 'qty',   	    label: 'Quantity',	width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input',     		options : {type : 'number'},	},
					// { field: 'adapted',	    label: 'Adapted',  	width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'inputswitch', 	renderer : 'adapted'  	},
					// { field: 'alias',	    label: 'Alias',  	width: '250px', align: "center" , 	type: 'text', editable: true, editor : 'input',     		options : {type : 'text'},	}
				]
			},
			{
				title	: "Info",
				items	: [					
					// { field: 'code',     	label: 'Code', 		width: '50px', 	align: "center", 	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'}, 	},
					// { field: 'type',	    label: 'Type',  	width: '75px', 	align: "center",	type: 'text', editable: true, editor : 'autocomplete',  	mandatory : true,	options : {suggestions : this.results} },
		            // { field: 'name',	    label: 'Name',  	width: '200px', align: "center",  	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'} },
		            { field: 'pax',	    	label: 'Pax',  	    width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input', 			options : {type : 'number'},	},
		            { field: 'qty',   	    label: 'Quantity',	width: '50px', 	align: "center",  	type: 'text', editable: false, editor : 'input',     		options : {type : 'number'},	},
					// { field: 'adapted',	    label: 'Adapted',  	width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'inputswitch', 	renderer : 'adapted'  	},
					// { field: 'alias',	    label: 'Alias',  	width: '250px', align: "center" , 	type: 'text', editable: true, editor : 'input',     		options : {type : 'text'},	}
				]
			},
			{
				title	: "Info",
				items	: [					
					// { field: 'code',     	label: 'Code', 		width: '50px', 	align: "center", 	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'}, 	},
					// { field: 'type',	    label: 'Type',  	width: '75px', 	align: "center",	type: 'text', editable: true, editor : 'autocomplete',  	mandatory : true,	options : {suggestions : this.results} },
		            // { field: 'name',	    label: 'Name',  	width: '200px', align: "center",  	type: 'text', editable: true, editor : 'input', 			mandatory : true,	options : {type : 'text'} },
		            // { field: 'pax',	    	label: 'Pax',  	    width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input', 			options : {type : 'number'},	},
		            // { field: 'qty',   	    label: 'Quantity',	width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'input',     		options : {type : 'number'},	},
					{ field: 'adapted',	    label: 'Adapted',  	width: '50px', 	align: "center",  	type: 'text', editable: true, editor : 'inputswitch', 	renderer : 'adapted'  	},
					{ field: 'alias',	    label: 'Alias',  	width: '250px', align: "center" , 	type: 'text', editable: true, editor : 'input',     		options : {type : 'text'},	}
				]
            }
        ];

        this.itemVehicles.cols = [
            {field : 'codeVehicle',     header : 'Type',  align:"center",editor : 'input', editable: false,  options : {type : 'text'}, mandatory : false},
            {field : 'numberPlate',     header : 'Plate', align:"center",editor : 'input',  options : {type : 'text'}, mandatory : true},
            {field : 'name',            header : 'Name',  align:"center", editor : 'input',  options : {type : 'text'}, mandatory : false},
            {field : 'brand',           header : 'Brand', align:"center",editor : 'autocomplete',  options : {suggestions : this.results}, mandatory : false},
            {field : 'model',           header : 'Model', align:"center", editor : 'autocomplete',  options : {suggestions : this.results}, mandatory : false},
            {field : 'yearAcquisition', header : 'Year',  align:"center", editor : 'input',  options : {type : 'number'}, mandatory : false},
            {field : 'lastRevition',    header : 'Last Revition', align:"center",editor : 'input',  options : {type : "text"}, mandatory : false},
            {field : 'km',              header : 'Km done', align:"center", editor : 'input',  options : {type : 'number'}, mandatory : false},
            {field : 'hasGPS',          header : 'GPS',  align:"center", renderer : 'adapted',editor : 'inputswitch', mandatory : false},
            {field : 'hasBeacon',       header : 'Beacon',  align:"center", renderer : 'adapted', editor : 'inputswitch', mandatory : false},
            {field : 'company',         header : 'Company',  align:"center", editor : 'autocomplete',  options : {suggestions : this.results}, mandatory : false},
            {field : 'description',     header : 'Description',  align:"center", editor : 'input',  options : {type : 'text'}, mandatory : false}
        ];

        this.itemVehicles.data = [
            {
                id          : 0,
                codeVehicle : 'PR AD4',
                numberPlate : '5224 KKG',
                name        : 'Bus 25',
                type        : 'Bus',
                brand       : 'Mercedes',
                model       : 'B5',
                yearAcquisition : 2015,
                lastRevition    :  2017,
                km          : '100k',
                hasGPS      : true,
                hasBeacon   : true,
                company     : 'Trasmedtour',
                description : ''
            }, {
                id          : 1,
                codeVehicle : 'PR AD5',
                numberPlate : '5224 KKG',
                name        : 'Bus 25',
                type        : 'Bus',
                brand       : 'Mercedes',
                model       : 'B5',
                yearAcquisition : 2014,
                lastRevition    : 2016,
                km          : '100k',
                hasGPS      : true,
                hasBeacon   : true,
                company     : 'Trasmedtour',
                description : ''
            }

        ]

        // this.generateMenuCols('companies');
        this.generateMenuCols('fleet');

        this.fleet.filters = [
			{ name: 'private', 	labelOn: 'Showing private', 	labelOff: 'No private', status: true },
			{ name: 'shared', 	labelOn: 'Showing shared', 	    labelOff: 'No shared', 	status: true }
        ];

        // this.loadEntities();

        // this.updateRowGroupMetaData();
    }

	checkField( item ){}

    generateMenuCols($entity) {
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
        this.pageInfo.auxData = this[$type].paginate;
    }

    async load($entity) {
        switch ($entity) {
            case 'fleet':
				this[$entity].data 		= [];
				// Load data
                await this.entityService.loadEntity($entity,);
                this[$entity].data 	= this.entityService.get($entity);
                console.log('VEHICLES LOAD', this[$entity].data);
                break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
        this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
    }

    getEditor($col) {
        return $col.editor ? $col.editor : 'input';
    }

    search(field,event) {
        console.log(field,event);
        switch(field){
            case 'type' : this.results = this.pageInfo.typeVehicle.map(el => el.value); break;            
            case 'brand': this.results = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;            
            default     : this.results = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;
            
        }
        console.log(this.results);
    }

    checkEntity($entity, $value) {
        return true;
    }

    areaEditor($me, $type, $col, $items) {
        alert('AREA EDITOR');
    }

    getRendererType($type) {
        switch ($type) {
			case 'adapted':    return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
            case 'content'	: return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : '',
            
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    uniqueCode(data,rowData){
        if(!rowData['code']) return false;
        console.log(this)
        return data.some(el => el.code == rowData.code);
    }

    onSort() {
        // this.updateRowGroupMetaData();
    }

    filterRows($type,$event){
        if(!this.pageInfo.searchElement) this.pageInfo.auxData = this[$type].paginate;

        this.pageInfo.auxData = this[$type].paginate.filter(item => (item.name.toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1)
                                                                ||  (item.type.toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1)
                                                                ||  (item.code.toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1)
                                                                ||  (item.pax.toString().toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1)
                                                                ||  (item.qty.toString().toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1)
                                                                ||  (item.description.toLowerCase().indexOf(this.pageInfo.searchElement.toLowerCase()) > -1))
        
    }

    saveCurrentValueOnTd(element){
        this.pageInfo.tempTdSelected = JSON.parse(JSON.stringify(element));
    }
    updateRow(event,rowData, element,isBlur?){
        if(event.keyCode == 9 || event.keyCode == 13  || isBlur){
            console.log('Updating',rowData);
            console.log('storage and then save? continue puts?');
            // ******* Update rowData ***** POST / PUT
            if(!(rowData[element] === this.pageInfo.tempTdSelected)){
                this.pageInfo.tempTdSelected = null;
                this.commons.generateToast('SUCCESS',element.toUpperCase() + ' has been updated','success',2);
            }
        }else if(event.keyCode == 27){
            rowData[element] = this.pageInfo.tempTdSelected;
        }
        
    }
    getVehiclesFromType(rowData){
        return this.itemVehicles.data.filter(el => el.codeVehicle == rowData.code);
    }
    



    addNewTransport($event){
        let newId = this.commons.generateHash(Math.random().toString(36).replace(/[^a-z]+/g, '') );
        this[$event].data.unshift({id: newId, qty : 0});
        this.paginate($event,null);
        this.mainTable["expandedRowKeys"][newId] = 1;
        this.makeColEditable($event,true,this[$event].data.find(el=> el.id == newId));
        this.commons.generateToast('Element Created','Please, fill the info','info');
    }

    test($event, rowData){
        console.log($event,rowData);
        //this.fleet.cols.map(el => el.align = 'start')
    }

    createTransport($event){
        try{
            this.pageInfo.newVehicle.type = this.pageInfo.newVehicle.type.value;
            this[$event].data.unshift(this.pageInfo.newVehicle);
            this.paginate($event,null);
            this.pageInfo.newVehicle = {};
            this.pageInfo.createVehicle = false;
            this.commons.generateToast('Success','Item added','success',2);            
        }catch(e){
            this.pageInfo.createVehicle = false;
            this.commons.generateToast('Error','Could not add the item','error',2);
        }
        
    }
    restartFormCreateVehicle(){
       this.pageInfo.createVehicle = false; 
       
    }

    makeColEditable($entity,value,rowData){
        this.pageInfo.tempRow = JSON.stringify(rowData);
        this[$entity].cols.map(el => el.editable = value);
        switch($entity){
            case 'fleet':
                this.pageInfo.buttonEditRow = value ? true : false;
                this.fleet.cols.find(el => el.field == 'qty').editable = false;
            break;
            case 'itemVehicles':

                this.itemVehicles.cols.find(el => el.field == 'codeVehicle').editable = false;
                this.pageInfo.buttonEditTransport = value ? true : false;

            break;
        }
        
    }

    updateStatusDropDown($event,item,element){
        item[element] = $event.value.value;
    }

    confirmRemoveRow($entity,rowData) {
        if($entity === 'fleet'){
            if(this.itemVehicles.data.filter(el => el.codeVehicle == rowData.code).length > 0){
                this.commons.generateToast('ERROR','You can not delete it because it has fleet assigned','error',1);
            }else{
                this.dialogRemoveRow($entity,rowData);
            }
        }else{
            this.dialogRemoveRow($entity,rowData);
        }
        
    }

    dialogRemoveRow($entity,rowData){
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.removeRow($entity,rowData);
            },
            reject: () => {
            }
        });
    }

    removeRow($entity,rowData){
        let findedRow = this[$entity].data.findIndex(el => el.id == rowData.id);
        
        if(findedRow > -1){
            
            if($entity == 'itemVehicles'){
                let findedTypeVehicle = this.fleet.data.find(el => el.code == rowData.codeVehicle);
                findedTypeVehicle.qty = 
                findedTypeVehicle.qty ? findedTypeVehicle.qty = findedTypeVehicle.qty - 1 
                                      : this.itemVehicles.data.filter(el => el.codeVehicle == findedTypeVehicle.code).length - 1;
                this.pageInfo.currentVehicleSelected = null;
            }
            // REMOVE element this[$entity].data[findedRow] **** REMOVE
            this[$entity].data.splice(findedRow,1);
            this.paginate($entity,null);      
            this.commons.generateToast('Success','Item removed','success',2);
        
        }else{
            this.commons.generateToast('Error','Could not remove the item','error',2);
        }

        this.pageInfo.buttonEditRow = false;
    }

    saveRow($entity,rowData){
        try{
            let validation = this.validateInfo($entity,rowData);
            console.log(validation)
            if(validation.success){
                // this.pageInfo.currentVehicleSelected    = {};
                // this.subTable.selectionKeys             = null;
                // this.subTable.selection                 = null;
                this.pageInfo.currentVehicleSelected = null;

                //PUT / POST NEW EDIT ELEMENT ON CLICK ******* PUT / POST
                console.log(this.subTable);
                this.paginate($entity,null);
                this.makeColEditable($entity,false,rowData);
                this.commons.generateToast('SUCCESS','Row Updated','success',2);

                
            }else{
            
                this.commons.generateToast('ERROR',validation.msg,'error',2);                
               
            }            
        }
        catch(e){
            this.commons.generateToast('ERROR',e,'error',2);    
        }

        
    }

    closeRow($entity,rowData){
        if(this.pageInfo.buttonEditRow){this.cancelEdit('fleet');}
        else{this.makeColEditable($entity,false,null);}
        this.pageInfo.buttonEditRow = false;
        

    }
    cancelEdit($entity){
        try{
            // this.subTable.selectionKeys = {};
            // this.subTable.selectionKeys['1']  = 1;
            console.log(this[$entity].data);
            console.log(JSON.parse(this.pageInfo.tempRow));
            if(JSON.parse(this.pageInfo.tempRow)){
                this[$entity].data[this[$entity].data.findIndex(el => el.id == JSON.parse(this.pageInfo.tempRow).id)] = JSON.parse(this.pageInfo.tempRow);
            }
            if($entity == 'itemVehicles') {
                // this.pageInfo.currentVehicleSelected = JSON.parse(this.pageInfo.tempRow);
                this.pageInfo.currentVehicleSelected = null;
                this.subTable.selectionKeys     = null;
                this.subTable.selection         = null;
                console.log(this.subTable);
            }
            this.paginate($entity,null);
            this.makeColEditable($entity,false,null)
            this.commons.generateToast('Canceled','Edit canceled','info',2);
            
            
        }catch(e){
            this.commons.generateToast('ERROR',e,'error',1);
            console.error('ERROR. Row not found', e);
        }
    }

    hideSideMenuTypeCars($entity){
        if(JSON.parse(this.pageInfo.tempRow)){
            this[$entity].data[this[$entity].data.findIndex(el => el.id == JSON.parse(this.pageInfo.tempRow).id)] = JSON.parse(this.pageInfo.tempRow);
        }
        this.pageInfo.currentVehicleSelected = null;
        this.subTable.selectionKeys     = null;
        this.subTable.selection         = null;  
        this.paginate($entity,null);
        this.makeColEditable($entity,false,null)
    }

    /**
     * Will return the elements which are not filled and are mandatory
     * @param $entity Columns to entity to check
     * @param rowData Data to save
     */
    validateInfo($entity,rowData){
        let specificValidations =  this.pageInfo.validations[$entity]   ? this.pageInfo.validations[$entity].find(el => !el['validation'](this[$entity].data,rowData))
                                                                        : null;

        if( specificValidations ){
            return { success    : false,
                     msg        : specificValidations.msgError
            }
        }else{
            let mandatoryFields = this[$entity].cols.filter(el => el.mandatory)
                                                    .filter(mandatoryElement =>     (rowData[mandatoryElement.field] === undefined)
                                                                                ||  (rowData[mandatoryElement.field] === null) 
                                                                                ||  (rowData[mandatoryElement.field] === '') );
            if( mandatoryFields.length > 0){
                return {  
                        success : false,
                        msg     : mandatoryFields.length == 1 ? 'Field '+ mandatoryFields[0].field +' is mandatory'
                                                              : 'Fields '+ mandatoryFields.map(el => el.field) +' are mandatory'
                        }
            }else{
                return {
                        success :true
                }
            }
        }
    }


    testRowSelect(rowData){
        console.log('TEST ON ROW SELECT', rowData);
        this.pageInfo.currentVehicleSelected = rowData.data;
        console.log(this.pageInfo.currentVehicleSelected);
    }

    testCtrl($event){
        console.log($event);
    }

    createItemVehicle($entity,rowData){
        let newId = this.commons.generateHash(Math.random().toString(36).replace(/[^a-z]+/g, '') );
        this[$entity].data.unshift({id: newId,codeVehicle : rowData.code});
        this.paginate($entity,null);
        rowData.qty                          = rowData.qty ? rowData.qty = +rowData.qty +1 : rowData.qty = 1;    
        this.pageInfo.currentVehicleSelected = this[$entity].data.find(el=> el.id == newId);
        this.makeColEditable($entity,true,this.pageInfo.currentVehicleSelected);   

    }

    onSelectItemVehicle($event, $entity){
        console.log('Select row')
        console.log($event);
        this.pageInfo.currentVehicleSelected = this[$entity].data.find(el => el.id == $event.data.id);
        this.makeColEditable($entity,true,this.pageInfo.currentVehicleSelected);
        console.log(this.subTable);
        // this.subTable.selection = null;
        //     this.subTable.selection = this.pageInfo.currentVehicleSelcted;
        // this.subTable['selectionKeys'][0] = 1;
    }
    onUnselectItemVehicle($event){
        console.log('Unselect row')
        console.log($event);
        console.log(this.subTable);
    }




}
