import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit} from '@angular/core';

@Component({
  selector    : 'app-profile-info',
  templateUrl : './profile-info.component.html',
  styleUrls   : ['./profile-info.component.scss'] ,

})

export class ProfileInfoComponent implements OnInit {
	@Input() rowData        :   any =   null;
	@Input() profiles       :   any =   null;
	@Input() destinations   :   any =   null;
	@Input() buttonOptions	:		any =	{save : true, delete : true, recoverPassword : true}
	@Input() form						: 	any =	null;
	@Output() emiterOption	: EventEmitter<any> = new EventEmitter();

	formProfile             :   any = [];
    
    ngOnInit(){
      console.log('FORM IS', this.form);
    }

    init(){
    //   this.formProfile = [		
    //           { field: 'name',     		label: 'Name', 		   	width: '200px', 	align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},
    //           { field: 'surname',		label: 'Surname',  	    width: '100px',		align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},						
    //           { field: 'phone',	    	label: 'Phone',  		width: '150px', 	align: "center",    editor : 'input',       editable : true,  options : {type : 'text'},    mandatory : false	},
    //           { field: 'email',	    	label: 'Email',  	    width: '250px', 	align: "center",    editor : 'input',       editable : false,  options : {type : 'text'},   mandatory : true 	},
    //           { field: 'profile',		label: 'Profile',							align: "center",    editor : 'dropdown',    options : {suggestions : 'profiles'},		    mandatory : true 	},
    //           { field: 'destination',	label: 'Destination', 						align: "center",    editor : 'multiselect', options : {suggestions : 'destinations'},    	mandatory : true 	}
             
    //       ];
    }

    getSuggestions($entity){
		return this[$entity];
    }

    getRenderer($type, $col, $items) {
      return $col.renderer
                  // ? $col.renderer($type, $col, $items)
                  ? this.getRendererType($col.renderer)($type, $col, $items)
                  : this.defaultRenderer($type, $col, $items);
  	}

  	defaultRenderer($type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
          	case 'style'	: return this.defaultStyleRenderer($col);
    		case 'content'	: switch($col.field) {
				case 'profile'          : return $items[$col.field];
				case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
				default 		 		: return $items[$col.field]
			}
		}
  	}

  	defaultStyleRenderer($col) {
		return {
			'width'			: $col.width ? $col.width : '',
        	'text-align'		: $col.align ? $col.align : ''
      	};
  	}

  	adaptedRenderer($me, $type, $col, $items) {
  		switch ($type) {
    		case 'header'	: return this.defaultStyleRenderer($col);
          	case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
				'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
			};
          	case 'expanded'	: return {
      			'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
      			'fontWeight'	: '900',
      			'fontSize'		: '1rem'
          	};
          	case 'content'	: return $items[$col.field]?'V':'X';
      	}
  	}

	getRendererType($type) {
    	switch ($type) {
  			case 'adapted': return (type, col, items) => this.adaptedRenderer(this, type, col, items);
    	}
	}

	checkIfDisableInput(rowData, field){
  		if(field == 'email' && rowData.isNew){ return true; }
	  	// const staff =   [].concat.apply([],this.staff.form.map(el => el.items.map(data => data)));
	  	const staff =   [].concat.apply([],this.form.map(data => data));
	  	return staff.find(el => el.field == field).editable;
	}

	getImgUser(rowData){
  		return rowData.avatar || 'http://vyshnevyi-partners.com/wp-content/uploads/2016/12/no-avatar-300x300.png';
	}

	checkFile(event,rowData) {
		if (event.target.files && event.target.files[0]) {
            const file 		= event.target.files[0];
            // rowData.fileImg = file;
			const reader 	= new FileReader();
			reader.onload 	= e => rowData.avatar = reader.result;
            reader.readAsDataURL(file);
		}
	}
	
	emitOption(entity,rowData,optionType){
		const data = { entity: entity, rowData: rowData, type: optionType};
		console.log(data);
		this.emiterOption.emit(data);
	}
}