import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation,		
		 ViewChild} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { providerCols 			} from './data/columns';
import { detailItems, entities 	} from './../detail/data/items';
import { SuperformService } from '../../../demo/service/super-form/superform.service';

interface Calendar {
	value	: any,
	date	: string,
	last	: string
};

@Component({
  selector    		: 'app-transporter-list',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class TransporterListComponent {

	@Input()			transporters	= [];
  	@Output()			emitter			= new EventEmitter<any>();	
	
	@ViewChild('superTable')	superTable : any;
	calendar	: Calendar 	= <Calendar>{ last: '', value: new Date() };
	pageInfo	: any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { 
			transporters: {}, 
			bookings	: {				
				toggles	: [ "arrival", "departure" ],
				period	: {
					types	: [ 
						{ label: this.commons.getTranslate("_WEEK"	), 	value: "week", 	icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_MONTH"	),	value: "month", icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_YEAR"	), 	value: "year",	icon: 'fa fa-calendar' 	}
					],
					selected: "month"
				}
			},
			services	: {				
				toggles	: [ "arrival", "departure" ],
				period	: {
					types	: [ 
						{ label: this.commons.getTranslate("_WEEK"	), 	value: "week", 	icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_MONTH"	),	value: "month", icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_YEAR"	), 	value: "year",	icon: 'fa fa-calendar' 	}
					],
					selected: "month"
				}
			},
			issues	: {				
				toggles	: [ "arrival", "departure" ],
				period	: {
					types	: [ 
						{ label: this.commons.getTranslate("_WEEK"	), 	value: "week", 	icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_MONTH"	),	value: "month", icon: 'fa fa-calendar' 	},
						{ label: this.commons.getTranslate("_YEAR"	), 	value: "year",	icon: 'fa fa-calendar' 	}
					],
					selected: "month"
				}
			} 
		}
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService,
		private superFormCtrl	: SuperformService
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'list',		label: '_LIST', 	icon: 'fa fa-list', 		type: 'button'	},
			// { 	name: 'stats',		label: '_STATS',	icon: 'fa fa-line-chart',	type: 'button'	},
			// { 	name: 'api',		label: '_API',		icon: 'fa fa-cogs',			type: 'button'	}
		];
		this.pageInfo.status		= "list";		
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };	

		this.pageInfo.items			= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});
		
		this.pageInfo.entities["transporters"].table	= {
			cols	: providerCols,
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh' },
			buttons	: [
				{ name: 'reload', 	label: '_RELOAD', 	icon: 'refresh'	},
				{ name: 'new', 		label: '_NEW', 		icon: 'plus'	}
			],
			filters	: [],
			_form	: {

				showEmptyTitle	: true,
				items	: [
					{
						title		: "_MAIN",
						cols		: 6,
						showTitle	: true,
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							// { mandatory: true, 		field	: 'id', 					label:'_CODE',					type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: true, 		field	: 'company_name', 			label:'_COMPANY',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'company_label', 			label:'_COMPANY_LABEL',			type: 'text',	editor: 'autocomplete',	entityList	: 'customerType'	},
							{ mandatory: false, 	field	: 'type', 					label:'_ZIPCODE',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'address',		 		label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'municipality', 			label:'_MUNICIPALITY',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'region', 				label:'_REGION',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'country',				label:'_COUNTRY',				type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	}
						]
					}									
					,{
						clear		: true,
						title		: "_COMPANY_CONTACT",
						cols		: 6,
						showTitle	: true,
						icon		: "fa fa-phone",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory: false, 	field	: 'phone', 					label:'_TRANSPORTER_PHONE',		type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: false, 	field	: 'fax', 					label:'_FAX',					type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: false, 	field	: 'web', 					label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'contact_name', 			label:'_CONTACT_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							// { mandatory: true, 	field	: 'contact_role', 				label:'_ROLE',					type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 		field	: 'contact_phone', 			label:'_CONTACT_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 		field	: 'contact_email',			label:'_CONTACT_EMAIL',			type: 'text',	editor: 'number',		editable	: true				},
							{ mandatory: true, 		field	: 'contact_lang',			label:'_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		}
						]
					}							
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'	},
					{ name: 'select', 		label: '_SELECT', 		action: 'selectRow',     icon: 'check'		}
				]
			},
			form 	: { 
				ready			: false,
				entities		: this.pageInfo.entities,	
				displayButtons	:	true,		
				form			: [
					{
						title		: "_MAIN",
						cols		: 4,
						showTitle	: true,
						icon		: "fa fa-check",
						_padding		: "1rem 1rem 1rem 1rem",
						items		: [
							// { mandatory: true, 		field	: 'id', 					label:'_CODE',					type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: true, 		field	: 'company_name', 			label:'_COMPANY',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'company_label', 			label:'_COMPANY_LABEL',			type: 'text',	editor: 'text',			entityList	: 'customerType'	},
							{ mandatory: false, 	field	: 'type', 					label:'_ZIPCODE',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'address',		 		label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'municipality', 			label:'_MUNICIPALITY',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'region', 				label:'_REGION',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'country',				label:'_COUNTRY',				type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	}
						]
					},	
					{
						title		: "_COMPANY_CONTACT",
						cols		: 4,
						showTitle	: true,
						icon		: "fa fa-phone",
						_padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory: false, 	field	: 'phone', 					label:'_TRANSPORTER_PHONE',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'fax', 					label:'_FAX',					type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'web', 					label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'contact_name', 			label:'_CONTACT_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							// { mandatory: true, 	field	: 'contact_role', 				label:'_ROLE',					type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 		field	: 'contact_phone', 		label:'_CONTACT_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 		field	: 'contact_email',		label:'_CONTACT_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 		field	: 'contact_lang',		label:'_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		}
						]
					},
					{
						title		: "_LOGO_TRANSPORTER",
						cols		: 4,
						showTitle	: true,
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
						]
					},	
				],
				buttons	:	[
					{ id: 1, name: 'save', label: '_SAVE', action: 'save'	}
				]
			}
		};		
	}

	async loadEntities()		{ 	await this.load("transporters");	}
  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();
		this.calendarChange();
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	

	async calendarChange(){
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];
		console.log("CALENDAR",this.calendar);
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last = this.calendar.date;
			await this.load('bookings');
			this.generateCharts('bookings',this.pageInfo.entities['bookings']);
			await this.load('services');
			this.generateCharts('services',this.pageInfo.entities['services']);
			await this.load('issues');
			this.generateCharts('issues',this.pageInfo.entities['issues']);
		}
	}

	async generateCharts($entityName,$entity)
	{
		$entity.charts = undefined===$entity.charts?{}:$entity.charts;
		switch($entityName){
			case "bookings":
				$entity.charts["bookings"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_BOOKINGS'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_bookings 		},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_bookings 		}
					 ]
				});
				$entity.charts["pax"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_PAX'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_pax 			},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_pax 			}
					 ]
				});
				break;
			case "services":
				$entity.charts["services"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_SERVICES'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrivals 				},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departures				}
					 ]
				});
				break;
			case "issues":
				$entity.charts["issues"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_ISSUES'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrivals 				},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departures				}
					 ]
				});
				break;
		}
	}

	async generateChartByType($params){
		return { 
			line : {
				type	: "bar",
				options	: {
					title: {
						display	: false,
						text	: $params.title,
						fontSize: $params.titleSize || 20
					},
					legend: {	
						position: $params.legendPosition || 'bottom'
					}			
				},
				data 	: {
					labels	: $params["labels"],
					datasets: $params.datasets.map(dataset => ({
									label			: dataset.label,
									data			: dataset.data,
									steppedLine		: dataset.steppedLine 	|| false,
									tension			: dataset.tension 		|| 0,
									backgroundColor	: dataset.color,
									borderColor		: dataset.borderColor 	|| '#505050'								
								}))							
				}
			}
		};
	}

	action($type,$item)	{
		switch($type){
			case "button"			:	switch($item.name){	
											case "list"			:	this.pageInfo.status = $item.name;									break;
											case "stats"		:	this.pageInfo.status = $item.name;									break;
											case "api"			:	this.pageInfo.status = $item.name;									break;
										}
										break;
			case "tableTransporters":	switch($item.type){									
											case "new"			:	this.superFormCtrl.restartErrors(this.pageInfo.entities["transporters"].table.form.form);
																	let tmpId	=	'tmpExpandedRow'+this.commons.generateHash(+new Date())
																	this.pageInfo.entities["transporters"].data.unshift({id : tmpId, tmpId : tmpId});
																	this.superTable.dataTable.expandedRowKeys[tmpId] = 1;
																	console.log(this.superTable);
																	break;
											case "reload"		: 	this.loadEntities();												break;
											case "selectRow"	:	this.emitter.emit({ type: 'selected', item: $item	});				break;
											case "selectedRow"	:	//this.commons.generateToast("_INFO","Selected Item","info");
																	this.superFormCtrl.restartErrors(this.pageInfo.entities["transporters"].table.form.form)
																	this.pageInfo.entities["transporters"].selected = $item.item;
																	this.emitter.emit({ type: 'selected', item: $item	});	
																	break;
											case "saveRow"		:	
																	this.emitter.emit({ type: 'saveTransporter', item: $item	});		break;
											case "createInvoice":	this.commons.generateToast("_INFO","Create invoice","info");		break;											
										}
										break;
		}
	}	

	getFilteredEntity($entity){
		switch($entity){			
			case "transporters"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
		}
	}
	async load($entity){
		let response 	= {};
		let data		= {};
		switch($entity){
			case 'transporters' :

				if(this.transporters){ this.pageInfo.entities[$entity].data = this.transporters; return; }
				response 												= 	await Promise.resolve(this.entityService.getRequest($entity));
				this.pageInfo.entities[$entity].data					= 	response["data"];				
				break;

			case "bookings":
				response 												= await Promise.resolve(this.entityService.getRequest("bookings_digest",{ startdate: this.calendar.date, offset:30 }));
				data													= response["data"];
				this.pageInfo.entities[$entity].data					= data;
				this.pageInfo.entities[$entity].days					= data["days"] || [];
				this.pageInfo.entities[$entity].arrival_bookings		= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].bookings);
				this.pageInfo.entities[$entity].departure_bookings		= Object.keys(data["departure"	]).map(day=>data["departure"][day].bookings);
				this.pageInfo.entities[$entity].arrival_pax				= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].pax);
				this.pageInfo.entities[$entity].departure_pax			= Object.keys(data["departure"	]).map(day=>data["departure"][day].pax);
				this.pageInfo.entities[$entity].arrival_avg				= Object.keys(data["arrival"	]).map(day=>data["arrival"	][day].avg);
				this.pageInfo.entities[$entity].departure_avg			= Object.keys(data["departure"	]).map(day=>data["departure"][day].avg);
				
				// Compute aggregation
				this.pageInfo.entities[$entity].arrival_bookings_total	= this.pageInfo.entities[$entity].arrival_bookings	.reduce((acc,item)=>acc+item,0);
				this.pageInfo.entities[$entity].departure_bookings_total= this.pageInfo.entities[$entity].departure_bookings.reduce((acc,item)=>acc+item,0);
				this.pageInfo.entities[$entity].arrival_pax_total		= this.pageInfo.entities[$entity].arrival_pax		.reduce((acc,item)=>acc+item,0);
				this.pageInfo.entities[$entity].departure_pax_total		= this.pageInfo.entities[$entity].departure_pax		.reduce((acc,item)=>acc+item,0);

				this.pageInfo.entities[$entity].arrival_avg_total		= this.pageInfo.entities[$entity].arrival_avg		.reduce((acc,item)=>acc+item,0)/this.pageInfo.entities[$entity].arrival_avg.length;
				this.pageInfo.entities[$entity].departure_avg_total		= this.pageInfo.entities[$entity].departure_avg		.reduce((acc,item)=>acc+item,0)/this.pageInfo.entities[$entity].departure_avg.length;

				// console.log("ENTITY "+$entity,response);				
				break;

			case "services":
				response 												= await Promise.resolve(this.entityService.getRequest("bookings_digest",{ startdate: this.calendar.date, offset:30 }));
				data													= response["data"];
				this.pageInfo.entities[$entity].data					= data;
				this.pageInfo.entities[$entity].days					= data["days"] || [];
				this.pageInfo.entities[$entity].factor					= 55;
				this.pageInfo.entities[$entity].factorComputed			= Math.ceil(Math.random()*this.pageInfo.entities[$entity].factor);
				this.pageInfo.entities[$entity].arrivals				= Object.keys(data["arrival"	]).map(day=>Math.ceil(data["arrival"  ][day].bookings/this.pageInfo.entities[$entity].factorComputed));
				this.pageInfo.entities[$entity].departures				= Object.keys(data["departure"	]).map(day=>Math.ceil(data["departure"][day].bookings/this.pageInfo.entities[$entity].factorComputed));
				
				// Compute aggregation
				this.pageInfo.entities[$entity].arrival_services_total	= this.pageInfo.entities[$entity].arrivals	.reduce((acc,item)=>acc+item,0);
				this.pageInfo.entities[$entity].departure_services_total= this.pageInfo.entities[$entity].departures.reduce((acc,item)=>acc+item,0);
				// console.log("ENTITY "+$entity,response);				
				break;

			case "issues":
				response 												= await Promise.resolve(this.entityService.getRequest("bookings_digest",{ startdate: this.calendar.date, offset:30 }));
				data													= response["data"];
				this.pageInfo.entities[$entity].data					= data;
				this.pageInfo.entities[$entity].days					= data["days"] || [];
				this.pageInfo.entities[$entity].factor					= 20;
				this.pageInfo.entities[$entity].factorComputed			= Math.ceil(Math.random()*this.pageInfo.entities[$entity].factor);
				this.pageInfo.entities[$entity].arrivals				= Object.keys(data["arrival"	]).map(day=>Math.ceil(data["arrival"  ][day].bookings/this.pageInfo.entities[$entity].factorComputed));
				this.pageInfo.entities[$entity].departures				= Object.keys(data["departure"	]).map(day=>Math.ceil(data["departure"][day].bookings/this.pageInfo.entities[$entity].factorComputed));
				
				// Compute aggregation
				this.pageInfo.entities[$entity].arrival_total			= this.pageInfo.entities[$entity].arrivals	.reduce((acc,item)=>acc+item,0);
				this.pageInfo.entities[$entity].departure_total			= this.pageInfo.entities[$entity].departures.reduce((acc,item)=>acc+item,0);
				// console.log("ENTITY "+$entity,response);				
				break;
		}
	}
}
