import { DestinationsService } from './../../service/database/destinations.service';
import { CommonsService 						} from './../../service/commons.service';
import { StorageService                         } from './../../service/storageservice';
import { Component, OnInit, ViewEncapsulation, 
		 NgZone, ElementRef, ViewChild, Inject  } from '@angular/core';
import { filters 								} from './data/filters';
import { views 									} from './data/views';
import { interestPoints							} from './data/views';
import { EntityService                          } from '../../service/entity.service';
import { MapService                             } from '../../service/mapservice';
import { ToastrService                          } from 'ngx-toastr';
import { MapsAPILoader 							} from '@agm/core';
// import { } 										  from '@types/googlemaps';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/platform-browser';
import { GoogleMap 						} from '@agm/core/services/google-maps-types';
declare var google	:	any;

@Component({
    styleUrls		: ['./mappricing.component.scss'],
	templateUrl		: './mappricing.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class MapPricingComponent implements OnInit{
	public provideRouteAlternatives	:	boolean	= true;
	public waypoints: any = []
	public renderOptions = {
    	draggable		: true,
	}
	public markerOptions = {
		origin: {
			infoWindow	: 'This is origin.',
			icon		: 'https://cdn3.iconfinder.com/data/icons/map-markers-2/512/marker_2-512.png',
			draggable	: true,
		},
		destination: {
			icon		: 'https://cdn3.iconfinder.com/data/icons/map-markers-2/512/marker_2-512.png',
			label		: 'marker label',
			opacity		: 0.8,
		},
	}
	public transitOptions:any = {
		departureTime		: new Date(1337675679473),
		modes				: ['BUS','SUBWAY'],
		routingPreference	: 'FEWER_TRANSFERS'
	  };
    pageInfo : any   = {};
    zoom                :   number  =	10;
    lat                 :   number  = 39.638464;
	lng                 :   number  = 3.003831 ;
	areasmallorca  : any = {};
    @ViewChild("searchOrigin")  	searchOrigin		: 	ElementRef;
	@ViewChild("searchDestination") searchDestination	:	ElementRef;
    constructor
    (
        private entityService	: EntityService,
		private mapService 		: MapService,
		private destinationCtrl : DestinationsService,
        private storage  		: StorageService,
		private commons         : CommonsService,
		private mapsAPILoader	: MapsAPILoader,
		private ngZone			: NgZone,
		@Inject(DOCUMENT) private document: any
    ){}
    
    async ngOnInit(){
		await this.init();
		 
	}

	async init(){
		this.pageInfo	=	{
			fullScreen	: false,
			cls			: {
				map			: '',
				toolbar		: ''
			},
			route					: {
				origin		:	{
						coords	:	{}
				},
				destination	:	{
						coords	:	{}
				}
			},
			modeSelected			:	'location',
			directionResponse		: null,
			dropDownOrigin			: null,
			dropDownDestination 	: null,
			pathRoutes				: [],
			routeSelected 			: {
				index	:	null,
				points	:	[]
			},
			displayDirection 		: true,
			originSelected			: false,
			destinationSelected		: false, 
			zones           		: [],
			currentZones    		: [],
			elem 					: 	document.documentElement,
			filters 				: 	filters,
			views					:	views,
			interestPoints			:	interestPoints,
			entities				:	{	areas	:	{},
											zones	:	{}	
										}
		}	
        await this.loadEntities();
		this.initMap();    
		this.listenSearchers();   
	}

    initMap(){
        // this.pageInfo.filters.find(el => el.entity == 'zones').items    = this.storageService.getItem('filtersZones') || [];
    }
    async loadEntities() {
		// this.destinationCtrl.getAreasFromDestination()
		await this.load('areasDestination');
		await this.load('areasmallorca');  
    }
    
    async load($entity){
		this.pageInfo[$entity] = this.pageInfo[$entity] || {};
		let response;
        switch ($entity)
        {
            case 'areasmallorca':
			await this.entityService.loadEntity($entity);
            this[$entity].data = this.entityService.get($entity);
			this[$entity].data.map(el => el.color = 'lightgray' );
			console.log('zones',this[$entity]);
			break;
			case 'areasDestination'	:
				response	= await this.destinationCtrl.getAreasFromDestination(this.pageInfo.destinationSelected.refAreas);

        }
	}

	changeMode(element){
		this.restartAll();
		this.listenSearchers();    
		this.pageInfo.modeSelected		=	this.pageInfo.views[element.index].value;
		console.log(element);
	}
	onChangeTab(element){
		console.log(element);
	}

	onClickDropdown($event, type){
		switch(type){
			case 'origin'		: 	this.pageInfo.route.origin.coords 		= $event.value.coords; 
									let findPolygon	=	this.areasmallorca.data.find(area => this.pointInsidePolygon(area.coords, $event.value.coords.lat, $event.value.coords.lng) );
									console.log('AREA IS', findPolygon);
									break;
			case 'destination'	:	this.pageInfo.route.destination.coords 	= $event.value.coords; break;
				
		};
		this.pageInfo.displayDirection 			=	true;
		console.log($event);
	}

	restartAll(){
		this.pageInfo.modeSelected					=	'location';
		this.pageInfo.originArea					=	null;
		this.pageInfo.destinationArea				=	null;
		this.pageInfo.originSelected				=	false;
		this.pageInfo.routeSelected 				= 	{index : null, points : []};
		this.pageInfo.pathRoutes					=	[];
		this.pageInfo.directionResponse 			=	null;
		this.pageInfo.displayDirection 				=	false;
		this.searchOrigin.nativeElement.value		=	null;
		this.searchDestination.nativeElement.value	=	null;
		this.pageInfo.autocompleteOrigin			=	null;
		this.pageInfo.autocompleteDestination 		=	null;
		this.pageInfo.dropDownDestination 			=	null;
		this.pageInfo.dropDownOrigin 				=	null;
		this.waypoints								=	[];
		this.pageInfo.route							=	 {
						origin		:	{
								coords	:	{}
						},
						destination	:	{
								coords	:	{}
						}
		},
		this.areasmallorca.data.map(el => el.color = 'lightgray');
		this.listenSearchers();    
		console.log(this.pageInfo.route);

	}
	
	
	mapClick($event){
		this.pageInfo.displayDirection	=	true;
		switch(this.pageInfo.modeSelected){
			case 'free':
			console.log($event);
				if(!this.pageInfo.originSelected){
					this.pageInfo.route.origin.coords		=	$event.coords;
					this.pageInfo.originSelected			=	true;
				}else{
					this.pageInfo.route.destination.coords	=	$event.coords;
				}
			break;
			case 'areas'	:	console.log('selected????');	break;
			case 'location'	:	this.commons.generateToast('Info','If you want to put markers, select FREE mode','info'); break;
		}
		console.log('CLICKED');
	}

	infoRouteObtained($event){
		
		console.log('response',$event);

		if( $event == null || $event.status != 'OK') return;

		this.pageInfo.pathRoutes		=	[];
		this.pageInfo.directionResponse	=	$event.routes;
		this.pageInfo.directionResponse.forEach(route =>{
			this.pageInfo.pathRoutes.push(route.overview_path.map(path =>{
				return {	lat : 	path.lat(), 
							lng	:	path.lng()}
			}))
		})
	}
	displayAlternativeRoute(index){
		if(this.pageInfo.routeSelected.index === index){
			this.pageInfo.routeSelected = {index : null, points : []};
		}else{
			this.pageInfo.routeSelected	=	{
				index	:	index,
				points	:	this.pageInfo.pathRoutes[index]}
		}
		
	}
	computePrice(price){
		return (price * 0.00029).toFixed(2)
	}
	onChangeRoute($event){
		console.log('change', $event);
		this.waypoints = $event.request.waypoints;
	}

	getCenterPolygon(polygon){
		console.log(polygon)
		if(!this.pageInfo.originSelected){
			this.pageInfo.route.origin.coords		=	this.mapService.get_polygon_centroid(polygon.coords);
			this.pageInfo.originSelected			=	true;
			this.pageInfo.originArea				=	polygon.name;
			this.pageInfo.displayDirection 			=	true;

		}else{
			this.pageInfo.route.destination.coords	=	this.mapService.get_polygon_centroid(polygon.coords);
			this.pageInfo.destinationArea			=	polygon.name;
			this.pageInfo.displayDirection 			=	true;
		}
		polygon.color 	=	'tomato';
	}
	
	listenSearchers(){
		
		this.mapsAPILoader.load().then(() => {
			console.log(this.searchOrigin);
			let autocompleteOrigin = new google.maps.places.Autocomplete(this.searchOrigin.nativeElement);			
			console.log('listeing');
			autocompleteOrigin.addListener("place_changed", () => {
			  this.ngZone.run(() => {
				//get the place result
				// let place: google.maps.places.PlaceResult = autocompleteOrigin.getPlace();
				let place = autocompleteOrigin.getPlace();
	  
				//verify result
				if (place.geometry === undefined || place.geometry === null) {
				  return;
				}
	  
				//set latitude, longitude and zoom
				this.pageInfo.route.origin.coords	=	{
					lat	:	place.geometry.location.lat(),
					lng	:	place.geometry.location.lng()
				}
				console.log(this.pageInfo.route.origin.coords);
			  });
			},google);

			let autocompleteDestination = new google.maps.places.Autocomplete(this.searchDestination.nativeElement, {
				types: ["address"]
			  });
			  autocompleteDestination.addListener("place_changed", () => {
				this.ngZone.run(() => {
				  	//get the place result
					//let place: google.maps.places.PlaceResult = autocompleteDestination.getPlace();
				  	let place = autocompleteDestination.getPlace();
		
				  	//verify result
				  	if (place.geometry === undefined || place.geometry === null) {
						return;
				  	}
		
				  	//set latitude, longitude and zoom
					this.pageInfo.route.destination.coords	=	{
						lat	:	place.geometry.location.lat(),
						lng	:	place.geometry.location.lng()
					}
				});
			  });
		  });
	}

	pointInsidePolygon(polygon, lat, lng){
		return this.mapService.isPointInsidePolygon(polygon, lat, lng)
	}

	toggleFullScreen()
	{
		this.pageInfo.fullScreen = this.pageInfo.fullScreen?false:true;
	
		if(this.pageInfo.fullScreen){
			this.pageInfo.cls.map 		= "map-fullscreen";
			this.pageInfo.cls.toolbar	= "toolbar-fullscreen";
			this.openFullscreen();
		} else {
			this.pageInfo.cls.map 		= "";
			this.pageInfo.cls.toolbar	= "";
			this.closeFullscreen();
		}
	
		console.log("Classes",this.pageInfo.fullScreen,this.pageInfo.cls);
	}
	
	openFullscreen() {
		if 		( this.pageInfo.elem.requestFullscreen		) { this.pageInfo.elem.requestFullscreen();											} 
		else if ( this.pageInfo.elem.mozRequestFullScreen	) {	this.pageInfo.elem.mozRequestFullScreen();		/* Firefox */					} 
		else if ( this.pageInfo.elem.webkitRequestFullscreen) {	this.pageInfo.elem.webkitRequestFullscreen();	/* Chrome, Safari and Opera */  }
		else if ( this.pageInfo.elem.msRequestFullscreen	) {	this.pageInfo.elem.msRequestFullscreen(); 		/* IE/Edge */ 					}
	}
	
	closeFullscreen() {
		console.log(this.document);
		if 		( this.document.exitFullscreen		) {	this.document.exitFullscreen();											}
		else if ( this.document.mozCancelFullScreen	) {	this.document.mozCancelFullScreen();	/* Firefox */ 					}
		else if ( this.document.webkitExitFullscreen) {	this.document.webkitExitFullscreen();	/* Chrome, Safari and Opera */ 	} 
		else if ( this.document.msExitFullscreen	) {	this.document.msExitFullscreen();		/* IE/Edge */					}
	}

}