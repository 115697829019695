import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore,
         AngularFirestoreModule   	} from '@angular/fire/firestore';
import { AngularFireAuth          	} from '@angular/fire/auth';
import { AngularFireStorage 		} from '@angular/fire/storage';

@Injectable()
export class SimpleFirebaseService {
		
  	constructor(  
		private afs     	: AngularFirestore,
		private afAuth  	: AngularFireAuth,
		public 	afStorage	: AngularFireStorage,
		private commons 	: CommonsService,
	){}
	

	async getDocData(reference,withRef=true){	
		let result =  await Promise.resolve(this.afs.doc(reference).ref.get()); 
		if(!result.exists){ return {}; }
		return { ...result.data(), ref : result.ref }
	}

	async getDoc($path, $info={}){	
		return this.afs.doc($path);
	}

	async getCol($path, $info?){	
		return this.afs.collection($path);							
	}

	async addDoc($path, $info){	
		let doc = await Promise.resolve(this.afs.collection($path).add($info));																
		await doc.update({ id: doc.id });
		return doc;									
	}

	async updateDoc($path, $info){	
		console.log("UpdateDoc",$path,$info);
		try 		{ 	return await Promise.resolve(this.afs.doc($path).update($info));	}
		catch(e)	{	console.log("[firebase::updateDoc] Error",e); return false;			}
	}

	async updateDocPromise($path, $info){	
		return this.afs.doc($path).update($info);
	}

	setDoc($path, $info){	
		return this.afs.collection($path).doc($info.id).set($info);	
	}

	async setDocWithResponse($path, $info){	
		let response:any = { success: true }
		try {
			let responseInfo = await this.afs.collection($path).doc($info.id).set($info);
			console.log("[setDocWithResponse] response",responseInfo);			
		}catch(e){
			response.success	= false;
			response.error		= e;
		}
		return response;
	}
	
	async deleteDoc($path){
		let response 	= await this.afs.doc($path).delete();
		let doc	 		= this.afs.firestore.doc($path);
							doc.delete();
			
		return response;					
	}

	async getColDocs($col){
		let colInfo = await this.afs.collection($col).ref.get();
		return !colInfo?[]:colInfo.docs.map(doc=>{
			let info = { ref: doc.ref, ...doc.data() };
			return info;
		});
	}

	subscribeCol($col,$fn){
		return this.afs.collection($col).valueChanges().subscribe($fn);
	}

	subscribeEntity($entityRef,$queryFn?){	
		return this.afs.collection($entityRef,$queryFn).valueChanges(); 	
	}

	subscribeEntityDoc($entityRef){	
		return this.afs.doc($entityRef).valueChanges();
	}													
	
	async updateItemByRef(ref,data)	{	
		try			{ await this.afs.doc(ref).update(data); return { success : true};	}
		catch(e)	{ return { success : false};										}											
	}
}
