import { CommonsService 						} from '../../../service/commons.service';
import { Component, OnInit, ViewEncapsulation	} from '@angular/core';
import { colFilters                             } from "./data/filters";
import { StorageService 						} from './../../../service/storageservice';

@Component({
	selector		: 'app-create-admin',
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class AdminContainerComponent implements OnInit
{
	
	locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private commons 		: CommonsService,
		private storage 		: StorageService,
	)					{ this.init(); 					}
    async ngOnInit() 	{ await this.loadEntities();	}
    async init()		{
		//
		this.pageInfo.dataLoaded      	= false;
        this.pageInfo.indexTabCalendar	= 0;
		this.pageInfo.variant        	= {};		
		this.userInfo					= ( this.storage.getItem('dmcSuite') || {}).userInfo || {};		
		this.pageInfo.status			= { 
											main		: "info", 
											accounting	: "treasury" 
										};
		this.pageInfo.i18n				= this.commons.getAllTranslates();
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_STAFF'			),	icon : 'fa fa-user'		 		, command: ($event)=> { this.setTabItem('main',	$event)},	},
					{ id:1, label: this.commons.getTranslate('_TRANSLATOR'		),	icon : 'fa fa-list'				, command: ($event) => { this.setTabItem('main',$event)}}

				],
				selected		: 0
			}
		};
		this.pageInfo.buttons			= {
			main			: [
				{ 	name: 'info',				label: '_INFO', 			icon: 'fa fa-list'		, type: 'button'	},
				{ 	name: 'logos',				label: '_LOGOS',	 		icon: 'fa fa-picture-o'	, type: 'button'	}
			],
			accounting		: [
				{ 	name: 'treasury',			label: '_TREASURY', 		icon: 'fa fa-eur'		, type: 'button'	},
				{ 	name: 'accounting_link',	label: '_ACCOUNTING_LINK',	icon: 'fa fa-link' 		, type: 'button'	},
				{ 	name: 'remittance',			label: '_REMITTANCE',		icon: 'fa fa-archive' 	, type: 'button'	},
				{ 	name: 'normative',			label: '_NORMATIVE',		icon: 'fa fa-list' 		, type: 'button'	},
				{ 	name: 'invoicing_format',	label: '_INVOICING',		icon: 'fa fa-pencil' 	, type: 'button'	},
				{ 	name: 'api',				label: '_API',				icon: 'fa fa-cogs' 		, type: 'button'	}				
			]
		}
		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
        };
        this.pageInfo.colFilters 		= await Promise.all(colFilters.map(async item=>await this.commons.translateRecursively(item)));
	}				

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

    async loadEntities(){	this.pageInfo.dataLoaded = true;	}
    async load($entity)	{
        switch($entity){
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	action($type,$event,$group){
		switch($type){
			case "button"			:	switch($group){
											case "main"				:	this.pageInfo.status[$group]	= $event.name;		break;
											case "accounting"		:	this.pageInfo.status[$group]	= $event.name;		break;
										}
										break;

			case "companyInfo"		: 	switch($event.action){
										case "provider_unselected"	:	this.pageInfo.currentProvider	= undefined;		break;
										case "provider_selected"	:	this.pageInfo.currentProvider	= $event["item"];	break;
										case "view_provider"		: 	if(undefined==this.pageInfo.currentProvider){
																			this.commons.generateToast("_ERROR","_PROVIDER_NOT_SELECTED","error");
																			return false;
																		}
																		this.pageInfo.status.providers 		= "providerDetail"; 	
																		break;
										case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
									}
									break;			

			case "companyStaff"		: 	this.pageInfo.status.providers 		= "providerList"; 		break;			
			
		}
	}
}