import { DestinationsService } from './../../../demo/service/database/destinations.service';
import { AuthFirebaseService } from 'src/app/demo/service/database/authfirebase.service';
import { auth } from 'firebase/app';
import { CompanyService } from './../../../demo/service/database/company.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { FirebaseService 		} from 'src/app/demo/service/database/firebase.service';
import { StorageService 		} from '../../../demo/service/storageservice';
import { detailItems, entities 	} from './data/items';

@Component({
  selector    		: 'app-reseller-staff',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class ResellerStaffComponent {

  	@Output()			emitter			= new EventEmitter<any>();	
	
	userInfo			: any =	{};    
	pageInfo  			: any = {
		loadings		: {
			creatingUser	:	false
		},
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { staff: {}, countries: {}, destinations: {}, stations: {}, profiles: {} }
	}
	  
	constructor( 
		private commons				: CommonsService,
		private entityService		: EntityService,
		private firebaseCtrl		: FirebaseService,
		private storage 			: StorageService,
		private companyService 		: CompanyService,
		private destinationService	: DestinationsService,
		private authService			: AuthFirebaseService
  	) {}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'reload',			label: '_RELOAD', 			icon: 'fa fa-refresh' 		, type: 'button'	},
			{ 	name: 'new',			label: '_CREATE',	 		icon: 'fa fa-plus' 			, type: 'button' 	},
			{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	}			
		];
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.items			= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});
				
	}

	async loadEntities()		{ 	this.userInfo	=	( this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
									await this.load("profiles");
									await this.load("countries");
									await this.load("staff");

								}

  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();	
		this.initForms();	
	}

	initForms(){
		this.pageInfo.entities["staff"].table	= {
			cols	: [
				{ field: 'name',     	header: 'Name', 		width: '100px', 	align: "center",    editable:false,  	mandatory : true 	},
				{ field: 'surname',    	header: 'Surname', 		width: '300px', 	align: "center",    editable:false, 	mandatory : true 	},
				{ field: 'profile',		header: 'Profile',		width: '100px',		align: "center",    editable:false, 	mandatory : true 	},
				{ field: 'phone',	    header: 'Phone',  		width: '100px', 	align: "center",    editable:false, 	mandatory : false	},
				{ field: 'email',	    header: 'Email',  	    width: '300px', 	align: "center",    editable:false,		mandatory : true 	}
			],
			options	: { rowExpander: true, showMenu: false, scrollHeight: '55vh' },
			filters	: [],			
			_form	: {
				showEmptyTitle	: false,
				items	: [
					{
						cols		: 4,
						padding		: "0rem",
						items		: [
							{ mandatory: true, 	field: 'name', 			label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 	field: 'surname', 		label:'_SURNAME',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 	field: 'email', 		label:'_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},							
							{ mandatory: true, 	field: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							// { mandatory: true, 	field: 'profile',		label:'_PROFILE',		type: 'text',	editor: 'autocomplete',	entityList	: 'profiles'		},
						]
					},
					{
						cols		: 8,
						padding		: "0rem",
						template	: 'app-list-countries',
						inputs		: {
							'destinationsCountries'	:	this.pageInfo.entities['destinationsCountries'].data,
						},
						items		: [],
					}						
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'	},
					{ name: 'select', 		label: '_SELECT', 		action: 'selectRow',     icon: 'check'		}
				]
			},
			form 	: { 
				ready			: false,
				entities		: this.pageInfo.entities,	
				displayButtons	:	true,		
				form			: [
					{
						title		: "_MAIN",
						cols		: 6,
						showTitle	: true,
						icon		: "fa fa-check",
						_padding		: "1rem 1rem 1rem 1rem",
						items		: [
							// { mandatory: true, 		field	: 'id', 					label:'_CODE',					type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: true, 		field: 'name', 			label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field: 'surname', 		label:'_SURNAME',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 		field: 'email', 		label:'_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},							
							{ mandatory: false, 	field: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
						]
					},	
					{
						title		: "_AVATAR",
						cols		: 6,
						showTitle	: true,
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
						]
					},	
				],
				buttons	:	[
					{ id: 1, name: 'save', label: '_SAVE', action: 'save'	}
				]
			}
		};
	}

    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	

	async doAction($type,$item)	{
		console.log($type, $item);
		switch($type){
			case "table"			:	switch($item.type){
											case "selectedRow"	:	this.pageInfo.rowData	=	$item.item;			break;
											case "unselectedRow":	this.pageInfo.rowData	=	null;				break;
											case "new"			:	break;
											case "reload"		:	this.load("staff");								break;
											case "saveRow"		:	await this.saveUser($item.item);				break;
										}
										break;
			case "button"			:	switch($item.name){		
											case "new"			: 	//this.emitter.emit({ action: 'new' });
																	this.pageInfo.entities["staff"].data.unshift({});
																	this.pageInfo.entities["staff"].count = this.pageInfo.entities["staff"].data.length;																	
																	break;
											case "reload"		: 	this.loadEntities();												break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
										}
										break;
		}
	}

	getFilteredEntity($entity,$info=undefined){
		switch($entity){
			case "staff"			: 	return (this.pageInfo.entities[$entity].data||[]).filter(item=>{return true;});
			case "stations"			: 	return (this.pageInfo.destination.tableStations.data		|| []).filter(item=>{return true;});
			case "meetingPoints"	: 	return (this.pageInfo.destination.tableMeetingPoints.data	|| []).filter(item=>{return true;});
			
			case 'formStations'		:	return 	{ 	info	: $info, 
													entities: this.pageInfo["destination"].tableStations.entities,
													form	: this.pageInfo["destination"].tableStations.form 
												};
			case 'formMeetingPoints':	return 	{ 	info	: $info, 
													entities: this.pageInfo["destination"].tableMeetingPoints.entities,
													form	: this.pageInfo["destination"].tableMeetingPoints.form 
												};
												
			case "countries"		:	return this.pageInfo.entities[$entity].data;
			case "destinations"		:	let country = this.pageInfo.entities['countries'].data.find(item=>item.openned);
										return undefined==country? [] : this.pageInfo.entities[$entity].data.filter(item=>item.country==country.name);
		}
	}

	async load($entity){
		let response = {};
		switch($entity){
			case 'staff' :
				this.pageInfo.entities[$entity].data	= [];
				// let entityId	= "3pyEdZJLgWCgOgz5C9wS";
				let entityId	= this.userInfo.resellerId || null;
				console.log('ENTITY ID', entityId);
				if(!entityId){	
								this.pageInfo.entities[$entity].count = this.pageInfo.entities[$entity].data ? this.pageInfo.entities[$entity].data.length : 0;
								this.paginate($entity, null);
								return;
				}
				this.firebaseCtrl.getStaff({ type: 'reseller', id: entityId }).subscribe(users => {	
					console.log('USERS FROM RESELLER', users);
					this.pageInfo.entities[$entity].data  = users;
					this.pageInfo.entities[$entity].count = this.pageInfo.entities[$entity].data ? this.pageInfo.entities[$entity].data.length : 0;
					this.paginate($entity, null);
				});
				break;
			
			case 'profiles'			:
				response								= await Promise.resolve(this.entityService.getRequest('profiles',{ type: 'dmc' }));
				this.pageInfo.entities[$entity].data	= response["data"].map(item=>{ item.text = this.commons.getTranslate(item.text); return item; });
				this.pageInfo.entities[$entity].data	= this.pageInfo.entities[$entity].data.map(data => { return { label : data.text, value : data}}); 		
				this.pageInfo.entities[$entity].data	= [{ label : 'reseller_operator', value : 'reseller_operator'}]
				// Refresh dropdown filter
				this.pageInfo.filters[3].items			= this.pageInfo.entities[$entity].data;
				// this.pageInfo.filters[3].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.text, value: item.name }));
				this.paginate($entity,null);
				break;			
			
			case 'countries'		:
				
				this.pageInfo.entities[$entity].data	= await this.destinationService.getCountries();
				this.pageInfo.filters[0].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.label, value: item.value }));
				this.paginate($entity,null);

				this.pageInfo.entities['destinationsCountries']		=	{};
				
				this.pageInfo.entities['destinationsCountries'].data	=	await Promise.all(this.pageInfo.entities.countries.data.map(async country => {
					let destinationsCountry	=	await this.destinationService.getDestinationsFromCountry(country.value);
					return {country : country, destinations : destinationsCountry.filter((item : any ) => !item.customCreation)};
				}));

				break;			

		}
	}

	paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
	}
	
	changeLoading(entity,value){	this.pageInfo.loadings[entity] = value;		}
	/**
	 * Save user for login
	 */
	async saveUser(userInfo){
		try{
			this.changeLoading('creatingUser',true);
			if(userInfo.id)	{	
				await this.firebaseCtrl.updateUser(userInfo); 
				this.changeLoading('creatingUser',false);
				this.commons.generateToast('_SUCCESS','_USER_UPDATED','success');
			} else {
				let response 	=  await this.authService.signIn(userInfo);
				let message;
				// let entityId	= "3pyEdZJLgWCgOgz5C9wS";
				let entityId	=  this.userInfo.resellerId || null;
				console.log('RESPONSE CREATE USER',response);
				for(let item of response["items"]){
					if(item.uid)	{ 
						let type = this.commons.getEntityProfile(userInfo["profile"] || 'reseller_operator');
						switch(type.profile){
							case "reseller"	:	message	= await this.firebaseCtrl.addUserToStaff({ userInfo: userInfo, uid: item.uid, entityId: entityId });
												break;
						}	
						// Persist into DMC info
						if(message.success)	{	this.changeLoading('creatingUser',false);
												await this.commons.generateToast('_SUCCESS','_USER_CREATED','success',2);		} 
						else 				{	this.changeLoading('creatingUser',false);
												await this.commons.generateToast('_ERROR','_USER_NOT_CREATED','error');			}			
					} else {
						this.changeLoading('creatingUser',false);
					}
				}
			}

		}catch(e){
			this.changeLoading('creatingUser',false);
			this.commons.generateToast('_ERROR', String(e).toString(), 'error');
		}		
	}
}