// FULLSCREEN SUPPORT -------------------------------------------------------------------

export const toggleFullScreen = (me)=>{
	// me.pageInfo.fullScreen = me.pageInfo.fullScreen?false:true;
	// me.pageInfo.fullScreen = me.openFullscreen() : me.closeFullscreen();
	me.pageInfo.fullScreen = me.pageInfo.fullScreen?!closeFullscreen(me):openFullscreen(me);
}

export const openFullscreen = (me)=>{
	try {
		if 		( me.pageInfo.elem.requestFullscreen		) { me.pageInfo.elem.requestFullscreen();											}
		else if ( me.pageInfo.elem.mozRequestFullScreen		) {	me.pageInfo.elem.mozRequestFullScreen();		/* Firefox */					}
		else if ( me.pageInfo.elem.webkitRequestFullscreen	) {	me.pageInfo.elem.webkitRequestFullscreen();		/* Chrome, Safari and Opera */  }
		else if ( me.pageInfo.elem.msRequestFullscreen		) {	me.pageInfo.elem.msRequestFullscreen(); 		/* IE/Edge */ 					}
		return true;
	} catch(e){ return false; }		
}

export const closeFullscreen = (me)=>{
	try {
		if 		( me.document.exitFullscreen		) {	me.document.exitFullscreen();										}
		else if ( me.document.mozCancelFullScreen	) {	me.document.mozCancelFullScreen();	/* Firefox */ 					}
		else if ( me.document.webkitExitFullscreen	) {	me.document.webkitExitFullscreen();	/* Chrome, Safari and Opera */ 	}
		else if ( me.document.msExitFullscreen		) {	me.document.msExitFullscreen();		/* IE/Edge */					}
		return true;
	} catch(e){ return false; }		
}