// CORE

import { NgModule							} from '@angular/core';
import { FormsModule						} from '@angular/forms';
import { BrowserModule						} from '@angular/platform-browser';
import { BrowserAnimationsModule			} from '@angular/platform-browser/animations';
import { LocationStrategy,
		 HashLocationStrategy,
         registerLocaleData					} from '@angular/common';

// PRIMENG

import { AccordionModule,
		 ProgressSpinnerModule,
		 SidebarModule,
         ConfirmationService                } from 'primeng/primeng';
import { AutoCompleteModule					} from 'primeng/primeng';
import { BreadcrumbModule					} from 'primeng/primeng';
import { ButtonModule						} from 'primeng/primeng';
import { CalendarModule						} from 'primeng/primeng';
import { CarouselModule						} from 'primeng/primeng';
import { ChartModule						} from 'primeng/primeng';
import { CheckboxModule						} from 'primeng/primeng';
import { ChipsModule						} from 'primeng/primeng';
import { CodeHighlighterModule				} from 'primeng/primeng';
import { ConfirmDialogModule				} from 'primeng/primeng';
import { ColorPickerModule					} from 'primeng/primeng';
import { SharedModule						} from 'primeng/primeng';
import { ContextMenuModule					} from 'primeng/primeng';
import { DataGridModule						} from 'primeng/primeng';
import { DataListModule						} from 'primeng/primeng';
import { DataScrollerModule					} from 'primeng/primeng';
import { DataTableModule					} from 'primeng/primeng';
import { DataViewModule						} from 'primeng/dataview';
import { DialogModule						} from 'primeng/primeng';
import { DragDropModule						} from 'primeng/primeng';
import { DropdownModule						} from 'primeng/primeng';
import { EditorModule						} from 'primeng/primeng';
import { FieldsetModule						} from 'primeng/primeng';
import { FileUploadModule					} from 'primeng/primeng';
import { GalleriaModule						} from 'primeng/primeng';
import { GMapModule							} from 'primeng/primeng';
import { GrowlModule						} from 'primeng/primeng';
import { InputMaskModule					} from 'primeng/primeng';
import { InputSwitchModule					} from 'primeng/primeng';
import { InputTextModule					} from 'primeng/primeng';
import { InputTextareaModule				} from 'primeng/primeng';
import { LightboxModule						} from 'primeng/primeng';
import { ListboxModule						} from 'primeng/primeng';
import { MegaMenuModule						} from 'primeng/primeng';
import { MenuModule							} from 'primeng/primeng';
import { MenubarModule						} from 'primeng/primeng';
import { MessagesModule						} from 'primeng/primeng';
import { MessageModule                      } from 'primeng/primeng';
import { MultiSelectModule					} from 'primeng/primeng';
import { OrderListModule					} from 'primeng/primeng';
import { OrganizationChartModule			} from 'primeng/primeng';
import { OverlayPanelModule					} from 'primeng/primeng';
import { PaginatorModule					} from 'primeng/primeng';
import { PanelModule						} from 'primeng/primeng';
import { PanelMenuModule					} from 'primeng/primeng';
import { PasswordModule						} from 'primeng/primeng';
import { PickListModule						} from 'primeng/primeng';
import { ProgressBarModule					} from 'primeng/primeng';
import { RadioButtonModule					} from 'primeng/primeng';
import { RatingModule						} from 'primeng/primeng';
import { ScheduleModule						} from 'primeng/primeng';
import { ScrollPanelModule					} from 'primeng/primeng';
import { SelectButtonModule					} from 'primeng/primeng';
import { SlideMenuModule					} from 'primeng/primeng';
import { SliderModule						} from 'primeng/primeng';
import { SpinnerModule						} from 'primeng/primeng';
import { SplitButtonModule					} from 'primeng/primeng';
import { StepsModule						} from 'primeng/primeng';
import { TableModule						} from 'primeng/table';
import { TabMenuModule						} from 'primeng/primeng';
import { TabViewModule						} from 'primeng/primeng';
import { TerminalModule						} from 'primeng/primeng';
import { TieredMenuModule					} from 'primeng/primeng';
import { ToggleButtonModule					} from 'primeng/primeng';
import { ToolbarModule						} from 'primeng/primeng';
import { TooltipModule						} from 'primeng/primeng';
import { TreeModule							} from 'primeng/primeng';
import { TreeTableModule					} from 'primeng/primeng';

// APP
import { AppRoutes							} from './app.routes';
import { AppComponent						} from './app.component';
import { AppMenuComponent,
		 AppSubMenuComponent				} from './app.menu.component';
import { AppTopBarComponent					} from './app.topbar.component';
import { AppFooterComponent					} from './app.footer.component';

import { AgmCoreModule 						} from '@agm/core';
import { AgmSnazzyInfoWindowModule      	} from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule     	} from '@agm/js-marker-clusterer';
import { AgmDirectionModule             	} from 'agm-direction';
import { AgmOverlays                        } from "agm-overlays";
import { ParticlesDirective 				} from './particles.directive';
import { DragulaModule 						} from 'ng2-dragula';
import { AngularFireModule              	} from '@angular/fire';
import { environment                    	} from '../environments/environment';
import { AngularFireAuthModule          	} from '@angular/fire/auth';
import { AngularFirestoreModule         	} from '@angular/fire/firestore';
import { AngularFireStorageModule           } from '@angular/fire/storage';
import { LyThemeModule, LY_THEME            } from '@alyle/ui';
import { LyButtonModule                     } from '@alyle/ui/button';
import { LyToolbarModule                    } from '@alyle/ui/toolbar';
import { LyResizingCroppingImageModule    	} from '@alyle/ui/resizing-cropping-images';
import { MinimaLight, MinimaDark 			} from '@alyle/ui/themes/minima';

import { CommonModule                   	} from '@angular/common';
import { HttpClientModule, HttpClient    	} from '@angular/common/http';
import localeEs                               from '@angular/common/locales/es';
import { TranslateModule, TranslateLoader	} from '@ngx-translate/core';
import { TranslateHttpLoader             	} from '@ngx-translate/http-loader';
import { AngularDraggableModule 			} from 'angular2-draggable';
import { CalendarModule as AngularCalendar,
         DateAdapter 						} from 'angular-calendar';
import { adapterFactory 					} from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule                   	} from 'ngx-toastr';

// PROJECT SPECIFICS    -------------------------------------------------------

// SERVICES

import { CommonsService 					} from './demo/service/commons.service';
import { EntityService 						} from './demo/service/entity.service';
import { IntegrationFactoryService          } from './demo/service/integrations/factory.service';
import { AuthGuard                          } from './demo/service/auth/auth.guard';
import { CountryService						} from './demo/service/countryservice';
import { EventService						} from './demo/service/eventservice';
import { NodeService						} from './demo/service/nodeservice';
import { HoppaService                       } from './demo/service/integrations/hoppa.service';
import { AuthFirebaseService            	} from './demo/service/database/authfirebase.service';
import { FirebaseService 					} from './demo/service/database/firebase.service';
import { CompanyService                     } from './demo/service/database/company.service';
import { DestinationsService                } from './demo/service/database/destinations.service';
import { GoogleService 						} from './demo/service/google/google.service';
import { LodgingService 					} from './demo/service/lodging/lodging.service';
import { BookingService                    } from './demo/service/bookings/booking.service';
import { SuperformService                   } from './demo/service/super-form/superform.service';
import { MapService                     	} from './demo/service/mapservice';
import { StorageService                 	} from './demo/service/storageservice';

// VIEWS

import { DocumentationComponent				} from './demo/view/documentation.component';
import { HotelsComponent		 			} from './demo/view/entities/hotels/hotels.component';
import { FlightsComponent 					} from './demo/view/entities/flights/flights.component';
import { VehiclesComponent 					} from './demo/view/entities/transports/vehicles.component';
import { TransportCompaniesComponent 		} from './demo/view/entities/transports/companies.component';
import { DriversComponent 					} from './demo/view/entities/transports/drivers.component';
import { LandingComponent               	} from './demo/view/landing/landing.component';
import { MapPricingComponent               	} from './demo/view/map_pricing/mappricing.component';
import { GeneratorPricingComponent          } from './demo/view/pricing/generatorPricing/generatorpricing.component';
import { ProfileComponent                   } from './demo/view/profile/profile.component';
import { DashboardComponent 				} from './demo/view/dashboard/component';
import { DashboardAggregatorComponent 		} from './demo/view/dashboard_aggregator/component';
import { ImporterComponent                  } from './demo/view/importer/importer.component';
import { BasicDataComponent                 } from './demo/view/noDestinationView/basicdata.component';
import { AdminContainerComponent            } from './demo/view/admin/main/component';
import { ResellerContainerComponent         } from './demo/view/reseller/main/component';
import { DashboardResellerComponent         } from './demo/view/reseller/dashboard/component';
import { ChartBookingsComponent 			} from './demo/view/statistics/bookings/bookings.component';

// COMPONENTS

import { ScrolledItemsComponent         	} from './components/scrolled-items/scrolled-items';
import { ListItemsComponent             	} from './components/list-items/list-items';
import { ProfileInfoComponent               } from './components/profile-info/profile-info.component';
import { AdaptableFormComponent             } from './components/adaptable-form/adaptable-form.component';
import { LodgingCheckerComponent            } from './components/lodging-checker/lodging-checker.component';
import { InfoMessageComponent             	} from './components/info-message/info-message.component';
import { ChatComponent 						} from './components/chat/chat.component';
import { FormIssueComponent 				} from './components/issues/form-issue/form-issue.component';
import { SuperTableComponent 				} from './components/super-table/super-table.component';
import { SuperFormComponent 				} from './components/super-form/component';
import { AdminStaffComponent             	} from './components/admin/staff/component';
import { ResellerStaffComponent            	} from './components/reseller/staff/component';
import { ProviderDetailComponent     		} from './components/provider/detail/component';
import { ProviderServicesComponent         	} from './components/provider/services/component';
import { ProviderListComponent            	} from './components/provider/list/component';
import { ProviderConciliationComponent 		} from './components/provider/conciliation/component';
import { DriverListComponent             	} from './components/company/drivers/services/component';
import { TransporterServicesComponent 		} from './components/transporter/services/component';
import { TransporterFleetComponent        	} from './components/transporter/fleet/component';
import { TransporterListComponent 			} from './components/transporter/list/component';
import { AppStepperComponent                } from './components/stepper/app-stepper.component';
import { AppSpinnerComponent                } from './components/spinner/app-spinner.component';
import { AppProfileDestinationComponent     } from './components/app-profile-destination/app-profile-destination.component';
import { CompanyModulesComponent 			} from './components/company/modules/app-company-modules.component';
import { StationsComponent                  } from './components/company/stations/app-stations.component';
import { AppListCountriesComponent          } from './components/app-list-countries/app-list-countries.component';
import { AppTreeDestinationsComponent       } from './components/app-tree-destinations/app-tree-destinations.component';
import { AppAdminTranslateComponent         } from './components/app-admin-translate/app-admin-translate.component';
import { AppTransportFleetComponent         } from './components/app-transport-fleet/app-transport-fleet.component';
import { ListPricesComponent                } from './components/provider/list-prices/list-prices.component';

import { MatAutocompleteModule,
         MatBadgeModule,
         MatBottomSheetModule,
         MatButtonModule,
         MatButtonToggleModule,
         MatCardModule,
         MatCheckboxModule,
         MatChipsModule,
         MatDatepickerModule,
         MatDialogModule,
         MatDividerModule,
         MatExpansionModule,
         MatFormFieldModule,
         MatGridListModule,
         MatIconModule,
         MatInputModule,
    	 MatListModule,
         MatMenuModule,
         MatNativeDateModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatRadioModule,
         MatRippleModule,
         MatSelectModule,
         MatSidenavModule,
         MatSliderModule,
         MatSlideToggleModule,
         MatSnackBarModule,
         MatSortModule,
         MatStepperModule,
         MatTableModule,
         MatTabsModule,
         MatToolbarModule,
         MatTooltipModule,
         MatTreeModule 						} from '@angular/material';
import { OverlayModule					 	} from '@angular/cdk/overlay';
import { PortalModule 						} from '@angular/cdk/portal';
import { ScrollingModule 					} from '@angular/cdk/scrolling';

import { AppExternalTransporters            } from './components/external-transporters/container/app-external-transporters.component';
import { AppExternalTransportersPrices      } from './components/external-transporters/prices/prices.component';

import { FomentoService                     } from './demo/service/fomento/fomento.service';
import { SimpleFirebaseService              } from './demo/service/database/simplefirebase.service';
import { ServiceFiltersService              } from './demo/service/serviceFilters/service-filters.service';
import { ImporterService                    } from './demo/service/importer/importer.service';
import { TransportService                   } from './demo/service/transports/transports.service';
import { TransportDriverService             } from './demo/service/transports/driver.service';
import { TransportFleetService 				} from './demo/service/transports/fleet.service';
import { TransportTransporterService 		} from './demo/service/transports/transporter.service';
import { TransportTypeService 				} from './demo/service/transports/type';
import { MyDialogComponent                  } from './my-dialog/my-dialog.component';

import { ProviderWTRService                 } from './services/providers/wtr.service';
import { ProviderHTXService                 } from './services/providers/htx.service';
import { ProviderServiceController          } from './services/providers/controller.service';
import { ExternalsComponent                 } from './demo/view/externals/externals.component';

// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }

registerLocaleData(localeEs);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        SharedModule,
        ContextMenuModule,
        DataGridModule,
        DataListModule,
        DataScrollerModule,
        DataTableModule,
        DataViewModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GMapModule,
        GrowlModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MessageModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        ProgressSpinnerModule,
        SidebarModule,
        ConfirmDialogModule,

		// Alyle UI
		BrowserAnimationsModule,
	    LyThemeModule.setTheme('minima-light'),
		LyButtonModule,
		// LyIconModule,
		// LyExpansionIcon,
	    LyToolbarModule,
		LyResizingCroppingImageModule,
		// End Alyle UI

        // ComponentsModule,

        AgmCoreModule.forRoot(  {
            apiKey      : 'AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM',        // Tourinia
            libraries   : ["places","geometry"]
        }),

        AgmSnazzyInfoWindowModule,
        AgmJsMarkerClustererModule,
        AgmDirectionModule,
        DragulaModule.forRoot(),
        CommonModule,
        ToastrModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        // AngularFireModule.initializeApp(environment.firebaseSecondary),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularDraggableModule,
        BrowserAnimationsModule,
        AgmOverlays,
        AngularCalendar.forRoot({ 	provide: DateAdapter, useFactory: adapterFactory }),
		TranslateModule.forRoot({	loader: {	provide: TranslateLoader,useFactory: (createTranslateLoader), deps: [HttpClient]	}
		// TranslateModule.forRoot({	loader: {	provide: TranslateLoader,useFactory: (createTranslateLoader), deps: [HttpClient]	}
		}),

        MatButtonModule,
        MatTabsModule,
        MatSelectModule,
        MatIconModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule
    ],
    declarations: [
        ParticlesDirective,
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DocumentationComponent,
        ExternalsComponent,
        HotelsComponent,
        FlightsComponent,
        VehiclesComponent,
        TransportCompaniesComponent,
        DriversComponent,
        MapPricingComponent,
        GeneratorPricingComponent,
        LandingComponent,
        ScrolledItemsComponent,
        ListItemsComponent,
        ProfileComponent,
        ProfileInfoComponent,
        AdaptableFormComponent,
        LodgingCheckerComponent,
        ChartBookingsComponent,
        InfoMessageComponent,
        ChatComponent,
        FormIssueComponent,
		SuperTableComponent,
		SuperFormComponent,
        ProviderListComponent,
        ProviderDetailComponent,
        ProviderConciliationComponent,
        ProviderServicesComponent,
		AdminContainerComponent,
		AdminStaffComponent,
		ResellerContainerComponent,
        ResellerStaffComponent,
        DriverListComponent,
        TransporterListComponent,
        TransporterServicesComponent,
        TransporterFleetComponent,
        DashboardComponent,
        DashboardAggregatorComponent,
        DashboardResellerComponent,
        AppStepperComponent,
        AppSpinnerComponent,
        BasicDataComponent,
        AppProfileDestinationComponent,
        CompanyModulesComponent,
        StationsComponent,
        AppListCountriesComponent,
        AppTreeDestinationsComponent,
        AppAdminTranslateComponent,
        AppTransportFleetComponent,
        AppExternalTransporters,
        AppExternalTransportersPrices,
        ListPricesComponent,
        ImporterComponent,
        MyDialogComponent
    ],
    providers: [
        ProviderServiceController,
        ProviderHTXService,
        ProviderWTRService,
        FomentoService,
        ServiceFiltersService,
        ImporterService,
        TransportService,
        TransportDriverService,
        TransportFleetService,
        TransportTransporterService,
        TransportTypeService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
		CountryService,
		EventService,
		NodeService,
		EntityService,
		CommonsService,
        MapService,
        StorageService,
        AuthFirebaseService,
        AuthGuard,
        FirebaseService,
        SimpleFirebaseService,
        CompanyService,
        DestinationsService,
        ConfirmationService,
        IntegrationFactoryService,
        HoppaService,
        GoogleService,
        LodgingService,
        BookingService,
        SuperformService,
        TransportService,
        { provide: LY_THEME, useClass: MinimaLight, multi: true }, 	// name: `minima-light`
	    { provide: LY_THEME, useClass: MinimaDark, 	multi: true } 	// name: `minima-dark`
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
