import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { EntityService } from '../../../service/entity.service';
import { CommonsService } from '../../../service/commons.service';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
    templateUrl: './flights.component.html',
    styles: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation: ViewEncapsulation.None,
    providers: [ MessageService ]
})

export class FlightsComponent implements OnInit {
    flights: any = { cols: [], filters: {} };
    companies: any = {};
    results: any[];
    rowGroupMetadata: any;

    menu: any = {
        options: [
            { label: 'reload', icon: 'fa fa-plus' },
            { label: 'download', icon: 'fa fa-download' },
        ]
    };

    constructor(
        private http: HttpClient,
        private commons: CommonsService,
        private entityService: EntityService,
        private messageService: MessageService
    ) {
        this.loadEntities();
    }

    async loadEntities() {
        // await this.load('companies');
        await this.load('flights');
    }

    ngOnInit() {
        this.init();
    }

    async init() {

        // this.companies.cols = [
        //     { field: 'code', header: 'Code', width: '50px', align: 'center', editable: true },
        //     { field: 'name', header: 'Name', width: '100px', align: 'center', editable: true }            
        // ];
        // this.companies.nodeSelect = ($event) => {
        //     alert("Node selected:"+$event.node.label);
        //     this.messageService.add({severity: 'info', summary: 'Node Selected', detail: $event.node.label});       
        // };
        // this.companies.cm = {
        //     items    : [
        //         { label: 'open', icon: 	"fa fa-folder" }
        //     ]
        // }

        this.flights.cols = [
			{ field: 'code',     	header: 'Code', 		width: '100px' },
			{ field: 'company',		header: 'Company',  	width: '200px', disabled: true },
            { field: 'direction',	header: 'Direction',  	width: '150px' },
			{ field: 'origin',      header: 'Origin',      	width: '200px' },
			{ field: 'destination',	header: 'Destination',  width: '200px' },
			{ field: 'departure',	header: 'Departure',  	width: '100px' },
			{ field: 'monday',		header: 'MO',  			width: '50px', renderer: 'day'	},
			{ field: 'tuesday',		header: 'TU',  			width: '50px', renderer: 'day' 	},
			{ field: 'wednesday',	header: 'WE',  			width: '50px', renderer: 'day' 	},
			{ field: 'thursday',	header: 'TH',  			width: '50px', renderer: 'day' 	},
			{ field: 'friday',		header: 'FR',  			width: '50px', renderer: 'day' 	},
			{ field: 'saturday',	header: 'SA',  			width: '50px', renderer: 'day' 	},
			{ field: 'sunday',		header: 'SU',  			width: '50px', renderer: 'day' 	},
        ];

        // this.generateMenuCols('companies');
        this.generateMenuCols('flights');

        this.flights.filters = [
			{ name: 'arrivals', 	labelOn: 'Showing arrivals', 	labelOff: 'No arrivals', 	status: true },
			{ name: 'departures', 	labelOn: 'Showing departures', 	labelOff: 'No departures', 	status: true }
        ];

        // this.loadEntities();

        // this.updateRowGroupMetaData();
    }

    generateMenuCols($entity) {
        // this[$entity].selectedColumns = this[$entity].cols;
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);

        // this[$entity].menucols = this[$entity].cols.map(item => ({
        //     label: item.header,
        //     icon: !item.disabled ? 'pi pi-check' : 'pi',
        //     style: 'width:300px',
        //     command: ($event) => {
        //         item.disabled = item.disabled ? false : true; 			// Col status
        //         $event.item.icon = item.disabled ? 'pi' : 'pi pi-check';		// Menu col status
        //         // alert('INNER->'+JSON.stringify($event.item)+'. ITEM Disabled->'+item.disabled+'#');
        //     }
        // }));
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
    }

    async load($entity) {
        switch ($entity) {
            case 'companies':
                this[$entity].data 		= [];
                this[$entity].tree 		= [];
                this[$entity].loading 	= true;
				
				// Load data
				await this.entityService.loadEntity($entity);
				this[$entity].data = this.commons.getEntity($entity);				

				// Generate tree
                this[$entity].tree    	= [
					{
						"label"			: 	"Companies",
						"data"			: 	"Companies",
                        "expandedIcon"	: 	"fa fa-folder-open",
						"collapsedIcon"	: 	"fa fa-folder",
						"expanded"		:	true,
                        "children"		: 	this[$entity].data.map(item => ({
							                    id    	: item.id,
												label	: item.name,
												icon 	: 'fa fa-folder'
											}))
					}
				];	
                this[$entity].loading = false;
                break;

            case 'flights':
				this[$entity].data 		= [];
				
				// Load faked data
				// this[$entity].data		= Array.from(Array(20).keys()).map(item=>(
				// 	{ 
				// 		name		: "BR12"+(10+item),
				// 		company		: "British Airways", 
				// 		origin		: "London Gatwick (GAT)", 
				// 		destination	: "Palma de Mallorca (PMI)",
				// 		departure	: "12:45 am",
				// 		// calendar	: [ "M","T","W","TH","F","SA","S"]	
				// 		// calendar	: [ "M","W","SA","S"]
				// 		monday		: false,
				// 		tuesday		: true,
				// 		wednesday	: true,
				// 		thursday	: false,
				// 		friday		: true,
				// 		saturday	: false,
				// 		sunday		: true,									
				// 	}
				// ));

				// Load data
                await this.entityService.loadEntity($entity);
				this[$entity].data 	= this.entityService.get($entity);
                break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
        this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
    }

    getEditor($col) {
        return $col.editor ? $col.editor : 'input';
    }

    search(event) {
        this.results = this.commons.getEntity('areasList').filter(item => item.includes(event.query));
    }

    checkEntity($entity, $value) {
        // alert('CHECKENTITY[' + $entity + ']->' + JSON.stringify(this.commons.getEntity($entity)));
        // return this.commons.getEntity($entity).some(item => item === $value);
        return true;
    }

    areaEditor($me, $type, $col, $items) {
        alert('AREA EDITOR');
    }

    getRendererType($type) {
        switch ($type) {
			case 'day':
				return (type, col, items) => this.dayRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
            case 'content'	: return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    dayRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width ? $col.width : '',
            	'text-align'	: $col.align ? $col.align : '',
				'color'			: 'green',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
			};
            case 'content'	: return $items[$col.field]?'x':'';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    onSort() {
        // this.updateRowGroupMetaData();
    }

    // updateRowGroupMetaData() {
    //     this.rowGroupMetadata = {};
    //     if (this.cars) {
    //         for (let i = 0; i < this.cars.length; i++) {
    //             let rowData = this.cars[i];
    //             let brand = rowData.brand;
    //             if (i == 0) {
    //                 this.rowGroupMetadata[brand] = { index: 0, size: 1 };
    //             }
    //             else {
    //                 let previousRowData = this.cars[i - 1];
    //                 let previousRowGroup = previousRowData.brand;
    //                 if (brand === previousRowGroup)
    //                     this.rowGroupMetadata[brand].size++;
    //                 else
    //                     this.rowGroupMetadata[brand] = { index: i, size: 1 };
    //             }
    //         }
    //     }
    // }
}
