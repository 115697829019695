import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild} from '@angular/core';

@Component({
  selector    : 'adaptable-form',
  templateUrl : './adaptable-form.component.html',
  styleUrls   : ['./adaptable-form.component.scss'] ,

})
export class AdaptableFormComponent implements OnInit {

    @Input() form           :   any = null;
    @Input() rowData        :   any = {};
    @Input() suggestions    :   any = null;
  	@Output() emiterOption	:   EventEmitter<any> = new EventEmitter();
    @ViewChild('customMultiSelect') customMultiSelect : any;
    ngOnInit(){
		console.log('suggestions',this.suggestions);
    }

    getSuggestions($entity){
		if(! this.suggestions) return [];
		return this.suggestions[$entity];
    }

    onChangeMultiSelect($event, field){
      this.emiterOption.emit({
        type    : 'multiselect',
        field   : field,
        item    : $event
      });
    }

    getRenderer($type, $col, $items) {
      return $col.renderer
                  // ? $col.renderer($type, $col, $items)
                  ? this.getRendererType($col.renderer)($type, $col, $items)
                  : this.defaultRenderer($type, $col, $items);
  }

  defaultRenderer($type, $col, $items) {
      switch ($type) {
        case 'header'	: return this.defaultStyleRenderer($col);
        case 'style'	: return this.defaultStyleRenderer($col);
   		case 'content'	: switch($col.field) {
							case 'profile'          : return $items[$col.field];
							case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
        default 		 : return $items[$col.field]}
      }
  }

  defaultStyleRenderer($col) {
      return {
          'width'			: $col.width ? $col.width : '',
          'text-align'		: $col.align ? $col.align : ''
      };
  }

  adaptedRenderer($me, $type, $col, $items) {
 	 switch ($type) {
          case 'header'	: return this.defaultStyleRenderer($col);
          case 'style'	: return {
     							 	'width'			: $col.width         ? $col.width   : '',
            						'text-align'	: $col.align         ? $col.align   : '',
      								'color'			: $items[$col.field] ? 'green'      : 'red',
      								'fontWeight'	: '900',
      								'fontSize'		: '1rem'
          };
          case 'expanded'	: return {
      			'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
      			'fontWeight'	: '900',
      			'fontSize'		: '1rem'
          };
          case 'content'	: return $items[$col.field]?'V':'X';
      }
  }

  
  getRendererType($type) {
    switch ($type) {
  		case 'adapted':
    	return (type, col, items) => this.adaptedRenderer(this, type, col, items);
    }
}

checkIfDisableInput(rowData, field){

  if(field == 'email' && rowData.isNew) return true; 
  // const staff =   [].concat.apply([],this.staff.form.map(el => el.items.map(data => data)));
  const staff =   [].concat.apply([],this.form.map(data => data));
  return staff.find(el => el.field == field).editable;
}

}