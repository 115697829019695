import { TransportService	 	} from '../../../../demo/service/transports/transports.service';
import { AuthFirebaseService 	} from './../../../../demo/service/database/authfirebase.service';
import { StorageService 		} from './../../../../demo/service/storageservice';
import { FirebaseService 		} from './../../../../demo/service/database/firebase.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { providerCols 			} from './data/columns';
import { detailItems, entities 	} from './data/items';

@Component({
  selector    		: 'app-driver-list',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class DriverListComponent {

	@Input() 			fromWizard	:	Boolean = false;
	@Input()			dmc			:	any;
	@Input()			destination	:	any;
	@Input()			mode		:	any;

	@Input()			drivers	: any[] = [];
  	@Output()			emitter			= new EventEmitter<any>();	
	
	userInfo	:	any	=	{};
	pageInfo  	: 	any = {	currency		: "€",
							uploadedFiles	: [], 
							buttons 		: [],
							filters			: [],
							entities		: { drivers: {} }
						}
	  
	constructor( 
		private commons			: CommonsService,
		private storage			: StorageService,
		private transportCtrl	: TransportService
  	) {}

  	async ngOnInit()			{
		await this.init();
	}

	async init()				{
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 		
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'list',		label: '_LIST', 	icon: 'fa fa-list', 		type: 'button'	},
			// { 	name: 'stats',		label: '_STATS',	icon: 'fa fa-line-chart',	type: 'button'	},
			// { 	name: 'api',		label: '_API',		icon: 'fa fa-cogs',			type: 'button'	}
		];
		this.pageInfo.status		= "list";		
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };	

		this.pageInfo.items			= detailItems;
		this.pageInfo.loadings		= {
			creatingUser	:	false
		},
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});
		
		this.pageInfo.entities["drivers"].table	= {
			cols	: [
				// {	field: 'id',			header		: '_ID',			width		:  '50px',	align: 'center', disabled	: true			},
				{	field: 'active',		header		: '_ACTIVE',		width		:  '50px',	align: 'center', type: 'check', disabled	: false			},
				{	field: 'license',		header		: '_LICENSE',		width		: '50px',	align: 'center', disabled	: false			},
				{	field: 'name',			header		: '_NAME',			width		: '200px',	align: 'center', disabled	: false			},
				{	field: 'location',		header		: '_LOCATION',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'address',		header		: '_ADDRESS',		width		: '200px',	align: 'center', disabled	: false			},
				{	field: 'idcard',		header		: '_IDCARD',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'phone',			header		: '_PHONE',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'email',			header		: '_EMAIL',			width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'gender',		header		: '_GENDER',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'birthday',		header		: '_BIRTHDAY',		width		: '100px',	align: 'center', disabled	: false			}
			],
			buttons	: [
				{ name: 'reload', 	label: '_RELOAD', 	icon: 'refresh'	},
				{ name: 'new', 		label: '_NEW', 		icon: 'plus'	}
			],
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh', showMenu: true },
			filters	: [],
			form 	: { 
				ready			: false,
				entities		: this.pageInfo.entities,	
				displayButtons	:	true,		
				form			:  [
					{
						cols		: 4,
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							// { mandatory: false, 	field	: 'id', 		label:'_ID',			type: 'text',	editor: 'text',			editable	: false				},
							{ mandatory: false, 		field	: 'active', 	label:'_ACTIVE',		type: 'text',	editor: 'toggle',		editable	: true				},
							{ mandatory: true, 		field	: 'name', 		label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'location', 	label:'_LOCATION',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'address',	label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				}							
						]
					}									
					,{
						cols		: 4,
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory: false, 	field	: 'idcard', 	label:'_IDCARD',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: false, 	field	: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 		field	: 'email',		label:'_EMAIL',			type: 'text', 	editor: 'text', 		editable	: true				},
							{ mandatory: false, 	field	: 'gender', 	label:'_GENDER',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true,	 	field	: 'birthday', 	label:'_BIRTHDAY',		type: 'text',	editor: 'datetime',		editable	: true,	options : {yearNavigator: true, rangeYearNavigator: this.commons.getWorkableYears()}},							
						]
					},
					{
						title		: "_AVATAR",
						cols		: 4,
						showTitle	: false,
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ mandatory : false,	field	: 'avatar',		label : '_IMAGE',		type : 'img',	editor : 'img'}
						]
					},							
				],
				buttons	:	[
					{ id: 1, name: 'save', label: '_SAVE', action: 'save'	}
				]
			}
		};
	}

    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	async action($type,$item)	{
		switch($type){
			case "button"			:	switch($item.name){	
											case "list"			:	this.pageInfo.status = $item.name; 
																	await this.emitter.emit({action : 'reloadDrivers'});	break;
											case "stats"		:	this.pageInfo.status = $item.name;						break;
											case "api"			:	this.pageInfo.status = $item.name;						break;
										}
										break;
			case "tableDrivers"		:	switch($item.type){									
											case "new"			:	let tmpId	=	'_tmpRowExpanded'+this.commons.generateHash(+new Date());
																	this.drivers.unshift({id:tmpId, tmpId : tmpId});				
																	break;
											case "selectedRow"	:	//this.commons.generateToast("_INFO","Selected Item","info");
																	if(!$item.item.id){this.pageInfo.driverSelected = null; return;}

																	let findSelected				=	this.drivers.find(driver => driver.id == $item.item.id);
																	this.pageInfo.driverSelected	= 	findSelected;
																	break;
											case "saveRow"		:	
																	
																	if(this.fromWizard){
																		if(this.mode == 'edit'){
																			await this.saveUser($item.item);
																		}else{
																			this.emitter.emit({type:'saveDriver', data : $item.item});
																		}
																	}else{
																		await this.saveUser($item.item);
																	}
																	this.emitter.emit({action : 'reloadDrivers'});
																	break;
											case "createInvoice":	this.commons.generateToast("_INFO","Create invoice","info");
								
										break;											
										}
										break;
		}
	}	

	getFilteredEntity(type){
		switch(type){
			default			:	return;
			case 'drivers'	:	return this.drivers;
		}
	}

		/**
	 * Save user for login
	 */
	async saveUser(userInfo){
		this.pageInfo.loadings['creatingUser'] = true;
		
		let idDmc 			=	this.dmc 			?	this.dmc 			:	this.userInfo.idDmc;
		let idDestination 	=	this.destination	?	this.destination	:	this.userInfo.currentDestination.id; 
		
		await this.transportCtrl.saveDriver(userInfo,idDmc,idDestination);

		this.pageInfo.loadings['creatingUser'] = false;

	}
}
