import { Component, 
         Input, 
         Output, 
         EventEmitter      } from '@angular/core';

@Component({
  selector   	: 'app-list-items',
  styleUrls		: [ './list-items.scss' ],
  templateUrl  	: 'list-items.html'
})

export class ListItemsComponent 
{
	@Input("type")              type          	: string;
	@Input("more")              more          	: boolean;
	@Input('title')             title         	: string;
	@Input('subtitle')          subtitle      	: string;
	@Input('items')             items         	: any[];
	@Input("bgColor")           bgColor       	: string;
	@Input("circle")            circle        	: string;
	@Input("width")             width         	: number;
	@Input("height")            height        	: number;
	@Input('itemHeight')        itemHeight    	: number;
	@Input("shadow")            shadow        	: boolean;
	@Input("inner-margin")      innerMargin   	: string;
	@Input("inner-padding")     innerPadding	: string;
	@Input("reservations")      reservations	: any[] = [];

  
  	@Output()                   viewEmitter   	= new EventEmitter<any>();
  	@Output()                   moreEmitter   	= new EventEmitter<any>();

  	constructor(
    	// private commons : CommonsProvider, 		
  	){}

  	moreAction(detail?		){ this.moreEmitter.emit({ type: this.type }); 	            }
  	viewAction(detail 		){ this.viewEmitter.emit({ type: this.type, info: detail });}
  	doAction($type,detail 	){ this.viewEmitter.emit({ type: $type, 	info: detail });}
  	isReserved(item			){ return this.reservations.some(res => res.id === item.id);}
}
