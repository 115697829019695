import { HttpClient 		} from '@angular/common/http';
import { Component, 
		 OnInit, 
		 ViewEncapsulation	} from '@angular/core';
import { EntityService 		} from '../../../service/entity.service';
import { CommonsService 	} from '../../../service/commons.service';
import { MessageService 	} from 'primeng/components/common/messageservice';

@Component({
    templateUrl		: './drivers.component.html',
    styles			: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation	: ViewEncapsulation.None,
    providers		: [ MessageService ]
})

export class DriversComponent implements OnInit {
    drivers	            : any = { cols: [], filters: {} };
    results				: any[];
    rowGroupMetadata	: any;

    menu: any = {
        options: [
            { label: 'reload', icon: 'fa fa-plus' },
            { label: 'download', icon: 'fa fa-download' },
        ]
    };

    constructor(
        private http			: HttpClient,
        private commons			: CommonsService,
        private entityService	: EntityService,
        private messageService	: MessageService
    ) {
        this.loadEntities();
    }

    async loadEntities() {
        await this.load('drivers');
    }

    ngOnInit() {
        this.init();
    }

    async init() {

        this.drivers.cols = [
			{ field: 'name',     	header: 'Name', 		width: '200px', 	align: "center" 	},
			{ field: 'location',	header: 'Location',  	width: '100px',		align: "center" 	},
            { field: 'address',	    header: 'Address',  	width: '300px', 	align: "center" 	},
			{ field: 'idcard',	    header: 'Id card',  	width: '150px', 	align: "center" 	},
			{ field: 'phone',	    header: 'Phone',  		width: '150px', 	align: "center"		},
			{ field: 'email',	    header: 'Email',  	    width: '250px', 	align: "center" 	}            
        ];

        this.generateMenuCols('drivers');
        
        this.drivers.filters = [
			{ name: 'private', 	labelOn: 'Showing private', 	labelOff: 'No private', status: true },
			{ name: 'shared', 	labelOn: 'Showing shared', 	    labelOff: 'No shared', 	status: true }
        ];

        // this.loadEntities();

        // this.updateRowGroupMetaData();
    }

    generateMenuCols($entity) {
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
    }

    async load($entity) {
        switch ($entity) {
            case 'drivers':
				this[$entity].data 		= [];
				
				// Load faked data
				// this[$entity].data		= Array.from(Array(20).keys()).map(item=>(
				// 	{ 
				// 		name        : "Taxi Toni",
				// 		area		: "Palma",
				// 		vehicles	: 3,
				// 		places		: 10,
				// 		address		: "Avenidas Alexandre Rosselló, 15",
				// 		phone		: "+34 512567890",
				// 		email		: "taxitoni@gmail.com"
				// 	}
				// ));

				// Load data
                await this.entityService.loadEntity($entity);
				this[$entity].data 	= this.entityService.get($entity);
                break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
        this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
    }

    getEditor($col) {
        return $col.editor ? $col.editor : 'input';
    }

    search(event) {
        this.results = this.commons.getEntity('areasList').filter(item => item.includes(event.query));
    }

    checkEntity($entity, $value) {
        // alert('CHECKENTITY[' + $entity + ']->' + JSON.stringify(this.commons.getEntity($entity)));
        // return this.commons.getEntity($entity).some(item => item === $value);
        return true;
    }

    areaEditor($me, $type, $col, $items) {
        alert('AREA EDITOR');
    }

    getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
            case 'content'	: return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width ? $col.width : '',
            	'text-align'	: $col.align ? $col.align : '',
				'color'			: 'green',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
			};
            case 'content'	: return $items[$col.field]?'V':'';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    onSort() {
        // this.updateRowGroupMetaData();
    }

    // updateRowGroupMetaData() {
    //     this.rowGroupMetadata = {};
    //     if (this.cars) {
    //         for (let i = 0; i < this.cars.length; i++) {
    //             let rowData = this.cars[i];
    //             let brand = rowData.brand;
    //             if (i == 0) {
    //                 this.rowGroupMetadata[brand] = { index: 0, size: 1 };
    //             }
    //             else {
    //                 let previousRowData = this.cars[i - 1];
    //                 let previousRowGroup = previousRowData.brand;
    //                 if (brand === previousRowGroup)
    //                     this.rowGroupMetadata[brand].size++;
    //                 else
    //                     this.rowGroupMetadata[brand] = { index: i, size: 1 };
    //             }
    //         }
    //     }
    // }
}
