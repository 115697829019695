
export const service_filters=	[
	{
		active		:	true,
		name		:	"fail_filter",
		title		:	"_FAIL_FILTER",
		desc		:	"_FAIL_FILTER_DESC",
		error		:	"_FAIL_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	true,
		name		:	"basic_filter",
		title		:	"_BASIC_FILTER",
		desc		:	"_BASIC_FILTER_DESC",
		error		:	"_BASIC_FILTER_ERROR",
		forced		:	false,
		selected	:	true
	},
	{
		active		:	true,
		name		:	"inter_zone_distances",
		title		:	"_INTER_ZONE_DISTANCE",
		desc		:	"_INTER_ZONE_DISTANCE_DESC",
		error		:	"_INTER_ZONE_DISTANCE_ERROR",
		selected	:	true,
		forced		:	false
	},
	{
		active		:	true,
		name		:	"inter_zone_distances_2",
		title		:	"_INTER_ZONE_DISTANCE_2",
		desc		:	"_INTER_ZONE_DISTANCE_2_DESC",
		error		:	"_INTER_ZONE_DISTANCE_2_ERROR",
		selected	:	true,
		forced		:	false
	},
	{
		active		:	true,
		name		:	"vehicle_capacity",
		title		:	"_VEHICLE_CAPACITY",
		desc		:	"_VEHICLE_CAPACITY_DESC",
		error		:	"_TARGET_TRANSPORT_NOT_SEATS",
		selected	:	true,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"vehicle_turn",
		title		:	"_VEHICLE_TURN",
		desc		: 	"_VEHICLE_TURN_DESC",
		error		:	"_VEHICLE_TURN_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"vehicle_dynamic_turn",
		title		:	"_VEHICLE_DYNAMIC_TURN",
		desc		: 	"_VEHICLE_DYNAMIC_TURN_DESC",
		error		:	"_VEHICLE_DYNAMIC_TURN_ERROR",
		selected	:	true,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"vehicle_turn_2",
		title		:	"_VEHICLE_TURN_2",
		desc		: 	"_VEHICLE_TURN_2_DESC",
		error		:	"_VEHICLE_TURN_2_ERROR",
		selected	:	true,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"legacy_services",
		title		:	"_LEGACY_SERVICES",
		desc		: 	"_LEGACY_SERVICES_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"2_drivers_turns",
		title		:	"_2_DRIVERS_TURNS",
		desc		: 	"_2_DRIVERS_TURNS_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	true,
		name		: 	"fixed_distance",
		title		:	"_FIXED_DISTANCE",
		desc		: 	"_FIXED_DISTANCE_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"drag_services",
		title		:	"_DRAG_SERVICES",
		desc		:	"_DRAG_SERVICES_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},	
	{
		active		:	false,
		name		:	"vehicle,turns_per_journey",
		title		:	"_VEHICLE_TURNS_PER_JOURNEY",
		desc		:	"_VEHICLE_TURNS_PER_JOURNEY_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"google_check_distance",
		title		:	"_GOOGLE_CHECK_DISTANCE",
		desc		:	"_GOOGLE_CHECK_DISTANCE_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},	
	{
		active		:	false,
		name		:	"inter_areas_distances",
		title		:	"_INTER_AREAS_DISTANCES",
		desc		:	"_INTER_AREAS_DISTANCES_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"inter_zones",
		title		:	"_INTER_ZONES",
		desc		:	"_INTER_ZONES_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"init_journey_from_base",
		title		:	"_INIT_JOURNEY_FROM_BASE",
		desc		:	"_INIT_JOURNEY_FROM_BASE_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"return_to_base_after_service",
		title		:	"_RETURN_TO_BASE_AFTER_SERVICE",
		desc		:	"_RETURN_TO_BASE_AFTER_SERVICE_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"init_journey_checklist",
		title		:	"_INIT_JOURNEY_CHECKLIST",
		desc		:	"_INIT_JOURNEY_CHECKLIST_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	},
	{
		active		:	false,
		name		:	"driver_rest",
		title		:	"_DRIVER_REST",
		desc		:	"_DRIVER_REST_DESC",
		error		:	"_FILTER_ERROR",
		selected	:	false,
		forced		:	false
	}	
];