import { CommonsService } from './../commons.service';
import { Injectable 	} from '@angular/core';
import {tokenization	} from './data/tokenization'

@Injectable()
export class SuperformService {

    constructor(private commons: CommonsService){}
    /**
	 * VALIDATORS
	 */
    /**
     * 
     * @param form fields from formulary
     * @param info info from fields
     */
	public validate(form, info){
		const itemsNoValidated	=	[];

		form.forEach(col =>{
			if(col.disabled){ return; }
			col.items.filter(item => (item.mandatory || item.validators) && (!item.disabled)).filter(item =>{
				if(!info || Object.keys(info).length == 0){ return true; }
				let param	=	info.item[item.field];
				// let param	=	(info['item'] && Object.keys(info['item']).length > 0) ? info.item[item.field] : info.item[item.field];
				if(item.validators){
					return [{type : 'default'},...item.validators].some(validator => this.invalidInfo(param,validator,col));
				}else{
					return true ===	this.invalidInfo(param,{type : 'default'},col);
				}
			}).forEach(item =>{ item.invalidInfo = true; itemsNoValidated.push(item)});
		});
		console.log(itemsNoValidated);
		return itemsNoValidated.length == 0;
	}

	/**
	 * Check if the item match with the validator condition. If match, return error because it is the opposite behaviour it should has.
	 * @param item item to check
	 * @param validator validator to check
	 * @param colGroup the rest of the items which share the same column
	 */
	invalidInfo(item,validator,colGroup){
		if(item==undefined || item == null){ return true; }
		switch(validator.type){
			default			:	
			case 'default'			:	return (item == undefined ||( typeof item != 'number' && item == ''));
			case 'onlyIf'			:	return (colGroup.items.find(item => item.field == validator['field']) || {}).selected;	// check if depending property is selected
			case 'min'				:	return (item < validator['min']);
			case 'minmax'			:	return (item < validator['min'] || item > validator['max']);
			case 'minOneSelected'	:	return !Array.isArray(item) || item.length ==0;
			case 'positiveNumber'	:	return +(item) < 0;

		}
	}

	changeItemProperties(form,field,paramsToChange){
		let foundCol	=	form.find(col => col.items.find(el => el.field == field));
		if(!foundCol){ return; }
		let findItem	=	foundCol.items.find(el => el.field == field);
		Object.keys(paramsToChange).forEach(param => findItem[param] = paramsToChange[param]);

	}

	changeFooterProperties(form, action, paramsToChange){
		return false;
		let foundRowFooter = (form.buttons['items'] ? form.buttons['items'] : form.buttons).find(el => el.action == action);
		if(foundRowFooter){
			Object.keys(paramsToChange).forEach(key =>foundRowFooter[key]=paramsToChange[key])
		}
	}

	/**
	 * Delete the property 'invalidInfo' to restart the validators
	 * @param form Form 
	 */
	restartErrors(form){form.forEach(col => col.items.forEach(item => delete item.invalidInfo))}

	prepareToFrontend(data){
		if(!data.form){ return; }
		data.form.forEach(section =>{
			if(!section.items){ return; }
			section.items.forEach(item =>{
				if(null==data.item){ console.log("Empty item"); return; }
				switch(item.editor){
					case 'multiselect'	:
					case 'autocomplete'	:	if(!data.item[item.field]){ return }
											if(Array.isArray(data.item[item.field])){
												data.item[item.field] = data.item[item.field].map(el => {
													let label = this.commons.getTranslate(tokenization[el.label]) || this.commons.getTranslate(tokenization[el.name]) || this.commons.getTranslate(tokenization[el.value]) || this.commons.getTranslate(tokenization[el]) || el.value || el;
													return { label 	: 	label,
															value 	:	el.value ? el.value : el
															}
												}) 
											}else{
												data.item[item.field]	=	{	label	: data.item[item.field].label || data.item[item.field].name || data.item[item.field], 
																				value 	: data.item[item.field].value ? data.item[item.field].value : data.item[item.field]
																			};
											}
											break; 

					case 'datetime'		:	if(!data.item[item.field]){ return }
											data.item[item.field]	=	new Date(this.commons.formatTimestamp(data.item[item.field]));
											break; 
											case (item.editor == 'groupButton' ):
												if(!data.item[item.field]){
													let foundDefaults		=	item.items.filter(el => el.default == true);
													data.item[item.field]	=	foundDefaults.map(el => el.value);		
												}else{
													data.item[item.field].forEach(el => {let found = item.items.find(option => option.value == el); if(found){ found.selected = true}else{found.selected = false;}});
												}

											return;

					default				:	return;
				}
			})
			// section.items	.filter(item 	=> (item.editor == 'multiselect' || item.editor == 'autocomplete') && this.info.item[item.field])
			// 				.forEach(item	=>  this.info.item[item.field]  	=	Array.isArray(this.info.item[item.field])	
			// 																	?	this.info.item[item.field].map(el => { return el.value ? el : {name : this.commons.getTranslate(el.label) || this.commons.getTranslate(el.name) || el, value : el}})
			// 																	: 	this.info.item[item.field].value ? this.info.item[item.field] : {name : this.info.item[item.field].label || this.info.item[item.field].name || this.info.item[item.field], value : this.info.item[item.field]})
		});
		console.log(data);
	}

	prepareToBackend(data){
		console.group("prepareToBackend");
		if(null==data.item){
			console.log("No data");
			console.groupEnd();
			return false;
		}

		let formattedData	=	{};
		data.form.forEach(col =>{
			col.items.forEach(item =>{
				let infoUndefined	=	null == data.item[item.field];
				switch(item.editor){
					case 'autocomplete'	:	if(infoUndefined){return;									} formattedData[item.field] = data.item[item.field].value;									return;
					case 'multiselect'	:	if(infoUndefined){return;									} formattedData[item.field] = data.item[item.field].map(el => el.value ? el.value : el); 	return;
					case 'toggle'		:	if(infoUndefined){return formattedData[item.field] = false;	} formattedData[item.field] = data.item[item.field]; 										return;
					default				:	if(infoUndefined){return;									} formattedData[item.field] = data.item[item.field]; 										return;
				}
			})
		});

		let propertiesOriginalItem = Object.keys(data.item);
		propertiesOriginalItem.forEach(property => { // copy all items which are not in the form
			if(formattedData[property]){ return; }
			formattedData[property] = data.item[property];
		})

		console.groupEnd();
		return formattedData;
	}
}
