import { StorageService 		} from './../storageservice';
import { FirebaseService 		} from './firebase.service';
import { CommonsService 		} from './../commons.service';
import { Injectable				} from '@angular/core';
import { HttpClient         	} from '@angular/common/http';
import { AngularFirestore   	} from '@angular/fire/firestore';
import { AngularFireAuth     	} from '@angular/fire/auth';
import { auth               	} from 'firebase/app';
import { BehaviorSubject 		} from 'rxjs';
import { first 					} from 'rxjs/operators';
import { Router             	} from '@angular/router';
import { EntityService 			} from 'src/app/demo/service/entity.service';

@Injectable()
export class AuthFirebaseService {

    public authenticated = new BehaviorSubject(null);
    constructor(    
		private db              :   AngularFirestore, 
		private afAuth          :   AngularFireAuth,
		private firebaseCtrl    :   FirebaseService,
		private routeCtrl       :   Router,
		private commons         :   CommonsService,
		private storageCtrl     :   StorageService,
		private entityService	:	EntityService
	) {}

    // User Login
    async login(user,password){
        try{
			const response:any = await this.afAuth.auth.signInWithEmailAndPassword(user,password);
			if(response && response.user && response.user.emailVerified)	{	return { success : true, 	data: {}}															}
			else 															{	return { success : false, 	data : { code: '_UPDATE_PASSWORD', message: '_UPDATE_PASSWORD' }}	}
		} catch(error) {
            return { success : false, 	data : error  };
        }
    }

    async signIn(userInfo,method="backend"){
		switch(method){
			case "firebase"	:	return await this.signInDirectlyFirebase(userInfo);
			case "backend"	:
			default			:	return await this.signInFromBackend(userInfo);
		}
    }

    async signInFromBackend(userInfo){

		let message:any = { success: true };

        try {
            let response = 	await Promise.resolve(
								this.entityService.postJSON(	
									this.entityService.getUrl('signIn'),
									{ 	
										format	: 'json', 
										records	: [{
											email		: userInfo.email, 
											password	: 'tourinia2020',
											photoUrl	: 'http://www.example.com/12345678/photo.png',
										}] 
									}
								)
							);						
			
			
			if(!response["success"]){ throw new Error(response["error"]["message"]); }
			
			message	= response;

        } catch (error) {
			console.log(error);
			message["success"]	= false;
			message["error"]	= error;            
        }
		
		return message;
    }

    async signInDirectlyFirebase(userInfo)
    {
        let newUser = await this.afAuth.auth.createUserAndRetrieveDataWithEmailAndPassword(userInfo.user,userInfo.password)
        console.log('%c','border:1px solid tomato; color:red','User signed up:',newUser);
        
        if(!newUser.user.emailVerified){
            await this.afAuth.auth.sendPasswordResetEmail(newUser.user.email);
            return newUser;
        }        
    }

    async ressetPassword(email){	const reset = await this.afAuth.auth.sendPasswordResetEmail(email); return reset;	}
    subscribeAuthState()		{	return this.afAuth.authState;														}
    getAutenticated()			{	return this.afAuth.authState.pipe(first()).toPromise();								}
    async logOut()				{	this.storageCtrl.deleteItem('dmcSuite');
									await this.afAuth.auth.signOut();
    							}
	async removeUser(email)		{}
    async recoverPassword(email){	try{
							            await this.afAuth.auth.sendPasswordResetEmail(email);
							            return {
							                success : true,
							                data    : {
							                    code    : 	'email sent',
							                    message :	'Check your email to change the password'
							                }
							            }
							        } catch(error){
							            return { success: false, data: error }
							        }
	}
	async checkIfUserExists(email){	return false; } // TODO: call the backend
}
