import { FirebaseService 			} from '../database/firebase.service';
import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore			} from '@angular/fire/firestore';

@Injectable()
export class TransportFleetService {		
  	constructor(  
		private afs     	: AngularFirestore,
		private commons 	: CommonsService,
		private firebaseCtrl: FirebaseService
	){}
	
	/**
	 * Geet the types and vehicles from dmc given a destination 
	 * @param dmcId 
	 * @param destinationId 
	 */
	async getFleet(dmcId, destinationId){	
		try	{
				let fleetPath	=	this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('fleet')
				let fleetDocs	=	await fleetPath.ref.get();
				let fleetData	= 	fleetDocs.docs.map(item => item.data());
				return  { success : true, data: fleetData };
				
		} catch(e){ return { success : false} }	
	}																									
}