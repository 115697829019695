import { SuperformService } from './../../../demo/service/super-form/superform.service';
import { Component, ViewEncapsulation, 
		 EventEmitter, Output, Input   				} from '@angular/core';
import { entities								} from './data/items';
import { CommonsService 						} from 'src/app/demo/service/commons.service';
import { EntityService 							} from 'src/app/demo/service/entity.service';
import { StorageService 						} from 'src/app/demo/service/storageservice';

@Component({
	selector    		: 'app-transporter-fleet',
	styleUrls			: ['./component.scss'],
	encapsulation		: ViewEncapsulation.None,
	templateUrl 		: 'component.html'
})

export class TransporterFleetComponent {
  
	@Input()	types	: any = [];
	@Input()	fleet	: any = [];
	@Input()	form	: any = {};
	@Output()	emitter	= new EventEmitter<any>();

    locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService,
		private superFormCtrl	: SuperformService,
		private storage 		: StorageService,		
	){}
	ngOnInit() 	{ 	this.init();	}
    init()		{
		console.log('TRANSPORTER FLEET FROM TRANSPORTER IS', this.fleet);
		this.pageInfo	=	{
			typeTransportSelected : null,
			buttonsCrudType		:	[
				{
					label	:	'_CANCEL',
					icon	:	'fa fa-fw fa-remove',
					iconPos	:	'right',
					visible	:	true,
					disabled:	false,
					value	:	'cancelFormType'
				},
				{
					label	:	'_SAVE',
					iconPos	:	'right',
					icon	:	'fa fa-fw fa-save',
					value	:	'saveFormType',
					disabled:	false,
					visible	:	true,
				}
			],
		}
		this.unselectTypeTransports();
		this.selectFirstTransport();
		
	}

	selectFirstTransport(){
		this.selectTypeTransport(this.types[0]);
	}

	unselectTypeTransports(){
		this.pageInfo.typeTransportSelected = null;
		this.types.forEach(el => el.selected = false);
	}
	
	selectTypeTransport(item){
		if(this.pageInfo.typeTransportSelected && this.pageInfo.typeTransportSelected.typeId == item.id){ return; }
		this.form.ready = false;
		this.commons.toggleItem(item,this.types,'selected');
		if(!item.selected){ this.pageInfo.typeTransportSelected = null; return;}
		
		let findedTypeTransport	=	this.fleet.find(type => type.typeId == item.id);
		if(!findedTypeTransport)					{ 	this.pageInfo.typeTransportSelected = null; 
														this.form.item			=	{};
														this.form.item.name	=	item.name;
														this.superFormCtrl.prepareToFrontend(this.form);
														this.form.ready 		=	true;												
														return;
													}
		
		this.pageInfo.typeTransportSelected	=	findedTypeTransport;
		this.form.item						=	findedTypeTransport;
		this.superFormCtrl.prepareToFrontend(this.form);
		this.form.ready 					=	true;
	}

	getCountByType(typeVehicle){
		if(!typeVehicle || !this.fleet){ return 0};
		let findedTypeTransport	=	this.fleet.find(type => type.typeId == typeVehicle.id);
		if(!findedTypeTransport){return 0};
		return findedTypeTransport.qty || 0;
	}

	handleVehicleForm(action){
		switch(action){
			default						:	return this.commons.generateToast('_ERROR','_UNKNOWN_OPTION','error');
			case 	'saveFormType'		:	return this.saveTypeTransport();
			case 	'cancelFormType'	:	return this.restartTypeForm();
		}
	}

	saveTypeTransport(){
		if(!this.superFormCtrl.validate(this.form.form,this.form)){
			return this.commons.generateToast('_ERROR','_CHECK_PARAMS','error');
		}

		let typeSelected		= this.types.find(item => item.selected);
		let foundTypeTransport 	= this.fleet.find(item => typeSelected.id == item.typeId);
		let simplified 			= this.superFormCtrl.prepareToBackend(this.form);
		
		console.log(simplified);

		if(foundTypeTransport){
			foundTypeTransport	=	{typeId : foundTypeTransport.typeId,...simplified};
			this.pageInfo.typeTransportSelected = foundTypeTransport;
			this.commons.generateToast('_SUCCESS', '_UPDATED','success');
		}else{
			this.fleet.push({typeId : typeSelected.id, ...simplified});
			this.commons.generateToast('_SUCCESS', '_ADDED','success');
			this.pageInfo.typeTransportSelected = this.fleet[this.fleet.length -1]; 
		}
		this.emitter.emit({type : 'saveFleet', item : {type : this.pageInfo.typeTransportSelected}});
	}

	deleteTypeTransport(){
		let foundType	=	this.fleet.findIndex(type => type.typeId == this.pageInfo.typeTransportSelected.typeId);			
		if(foundType == -1){ return this.commons.generateToast('_ERROR','_COULD_NOT_REMOVE','error');}
		this.emitter.emit({type : 'deleteFleet', item : {type : this.pageInfo.typeTransportSelected}});
		this.fleet.splice(foundType,1);
		this.commons.generateToast('_SUCCESS','_TYPE_DELETED','success');
		this.restartTypeForm();
	}

	restartTypeForm(){
		this.form.item 						= {};
		this.pageInfo.typeTransportSelected = null;
	}
}