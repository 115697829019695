export const views	=	[
	{
		name	:	'Location',
		value	:	'location'
	},
	{
		name	:	'Areas',
		value	:	'areas'
	},
	{
		name	:	'Free',
		value	:	'free'
	}
];

export const interestPoints = [
    {
        label: 'Airports', 
        items: [
            {label: 'PMI', value : {name : 'PMI', coords   :   {lat: 39.5521152815252, lng: 2.736645344962426}}},
        ]
    },
    {
        label: 'Lodgings', 
        items: [
            {label: 'Ciudad Jardín',            value : {name : 'Ciudad Jardín',            coords :   {lat: 39.55231510567477, lng: 2.6908591358577496}}},
            {label: 'Melià Bellver',            value : {name : 'Melià Bellver',            coords :   {lat: 39.5684834791535, lng: 2.6310054612551994}}},
            {label: 'Catalonia Majorca',        value : {name : 'Catalonia Majorca',        coords :   {lat: 39.55626713924296, lng: 2.622402146240006}}},
            {label: 'JS Ca´n Picafort',         value : {name : 'JS Ca`n Picafort',         coords :   {lat: 39.76783667550054, lng: 3.152331168565979}}},
            {label: 'Hotel Still Picafort Park',value : {name : 'Hotel Still Picafort Park',coords :   {lat: 39.76065138377461,lng: 3.1646750803021177}}}
        ]
    },
    {
        label: 'Excursions', 
        items: [
            {label: 'Drach Caves',      value : {name : 'Drach Caves',      coords   :   {lat: 39.5357754056801, lng: 3.3302452153637887}}},
            {label: 'Capdepera Castle', value : {name :'Capdepera Castle',  coords   :   {lat: 39.70407380919209, lng: 3.4333689738355133}}},        ]
    }
];
