import { CommonsService } from './../../demo/service/commons.service';
import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-tree-destinations',
    templateUrl: './app-tree-destinations.component.html',  
    styleUrls   : ['./app-tree-destinations.component.scss']
  })
  export class AppTreeDestinationsComponent implements OnInit{
	
	@Input() countries		:	any = [];
	@Input() destinations	:	any = [];
	
	@Output()   emitter		:   EventEmitter<any>   = new EventEmitter();

	constructor(private commons : CommonsService){}
	ngOnInit(): void {
	}

	doAction($action, $item){
		switch($action){
			case 'toggleCountry'	:	this.commons.toggleItem($item,this.countries,'openned'); 
										this.emitter.emit({action : 'toggleCountry', item : $item.openned ? $item : null})								
			break;
			case 'toggleDestination':	this.emitter.emit({action : 'toggleDestination', item : $item}); break;
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			default					:	return;
			case "countries"		:	return this.countries;
			case "destinations"		:	let country = this.countries.find(item=>item.openned);
										return undefined==country? [] : this.destinations.filter(item=>item.country==country.name);
		}
	}

}