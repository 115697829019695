export const fomento_mapping = {
    "Alaró"   		            : "Alaró" ,
    "Alcudia"         			: "Alcúdia" ,
    "Algaida"                 	: "Algaida" ,
    "Andratx"        			: "Andratx" ,
    "Artà"                      : "Artà" ,
    "Banyalbufar"             	: "Banyalbufar" ,
    "Búger"                   	: "Búger" ,
    "Calvià"          			: "Calvià" ,
    "Campanet"        			: "Campanet" ,
    "Campos"     				: "Campos" ,
    "Capdepera"       			: "Capdepera" ,
    "Costitx"         			: "Costitx" ,
    "Estellencs"      			: "Estellencs" ,
    "Felanitx"        			: "Felanitx" ,
    "Fornalutx"       			: "Fornalutx" ,
    "Inca"          			: "Inca" ,
    "Llucmajor" 		      	: "Llucmajor" ,
    "Manacor"         			: "Manacor" ,
    "Muro"            			: "Muro" ,
    "Palma de Mallorca" 		: "Palma" ,
    "Pollença"        			: "Pollença" ,
    "Porreres"        			: "Porreres" ,
    "Puigpunyent"     			: "Puigpunyent" ,
    "Santa Margalida"   		: "Santa Margalida" ,
    "Santanyí"        			: "Santanyí" ,
    "Sant Llorenç des Cardassar": "Sant Llorenç des Cardassar" ,
    "Selva"           			: "Selva" ,
    "Sineu"           			: "Sineu" ,
    "Sóller"          			: "Sóller" ,
    "Son Servera"     			: "Son Servera" ,
    "Valldemossa"      			: "Valldemossa"
};