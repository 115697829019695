export let colFilters = [
	{
		label			: '_DESTINATION', 						
		children      	: [
			{
				label			: '_EUROPE',
				children		: [
					{ 	
						label			: '_SPAIN', 
						children		: [
							{ label: '_MALLORCA', 	_icon: 'fa fa-map-marker'  	},
							{ label: '_TENERIFE', 	_icon: 'fa fa-map-marker'	}
						]
					}
				]
			},
			{
				label			: '_AMERICA', 
				children		: [
					{ 	
						label			: '_MEXICO', 
						children		: [
							{ label: '_CANCUN', 	_icon: 'fa fa-map-marker'  	},
							{ label: '_ACAPULCO', 	_icon: 'fa fa-map-marker'	}
						]
					}
				]
			}
		],
		selected        : undefined
	},
	{
		label          	: "_CATEGORIES",
		children        : [
			{ 	
				label			: '_ATTRACTIONS_AND_SHOWS', 
				children		: [ 
					{ label		: "_THEME_PARKS"					},
					{ label		: "_EXHIBITS_AND_INSTALLATIONS"		},
					{ label		: "_FESTIVALS_AND_CONCERTS"			},
					{ label		: "_ZOO_AND_AQUARIUM"				},
					{ label		: "_SHOWS_AND_PERFORMANCES"			},
					{ label		: "_POPULAR_ATTRACTIONS"			},
					{ label		: "_MUSEUMS_AND_GALLERIES"			}
				]
			},
			{ 
				label			: '_TOURS_AND_SIGHTSEEING',
				children		: [ 			
					{ label		: "_GUIDED_FOOD_TOURS"				},
					{ label		: "_CHERRY_BLOSSOM"					},
					{ label		: "_WALKING_TOUR"					},
					{ label		: "_OVERNIGHT_TOURS_AND_EXPERIENCES"},
					{ label		: "_SUMMER_FUN"						},
					{ label		: "_SPRING_FLINGS"					},
					{ label		: "_AUTUMN_ADVENTURES"				},
					{ label		: "_WINTER_TOURS"					},
					{ label		: "_WINERY_AND_DISTILLERY_TOURS"	},
					{ label		: "_LOCAL_SIGHTSEEING"				},
					{ label		: "_NATURE_ESCAPES"					},
					{ label		: "_ISLAND_HOPPING"					},
					{ label		: "_ART_AND_CULTURE"				},
					{ label		: "_AIR_AND_CRUISE_TOURS"			},
					{ label		: "_BEYOND_THE_CITY"				}
				]
			},
			{ 
				label			: '_ACTIVITIES_AND_EXPERIENCES',
				children		: [ 							
					{ label		: "_HOTELS_AND_ACCOMODATIONS"		},
					{ label		: "_ADRENALINE_JUNKIES"				},
					{ label		: "_NATURE_AND_WILDLIFE"			},
					{ label		: "_SURVIVAL_SKILLS"				},
					{ label		: "_DIVING_EXPERIENCE"				},
					{ label		: "_HOT_SPRINGS"					},
					{ label		: "_FOR_LOCALS"						},
					{ label		: "_COMBO_EXPERIENCE"				},
					{ label		: "_SHOPPING_EXPERIENCE"			},
					{ label		: "_VIRTUAL_REALITY"				},
					{ label		: "_INDOOR_ACTIVITIES"				},
					{ label		: "_BIKING_AND_HIKING"				},
					{ label		: "_SPA_AND_WELLNESS"				},
					{ label		: "_FUN_WITH_KIDS"					},
					{ label		: "_OUTDOOR_ADVENTURE"				},
					{ label		: "_FOR_LOVERS"						},
					{ label		: "_EVENING_TODOS"					},
					{ label		: "_CRUISING"						},
					{ label		: "_WATER_ACTIVITIES"				},
					{ label		: "_DIVE_AND_SNORKEL"				},
					{ label		: "_SKIING_AND_SNOW_SPORTS"			},
					{ label		: "_CULTURAL_EXPERIENCE"			},
					{ label		: "_SEA_SNORKELING"					}					
				]
			},
			{ 
				label			: '_BEST_FOOD_AND_MUST_EATS',
				children		: [
					{ label		: "_MEAL_VOUCHERS"					},
					{ label		: "_COOKING_CLASS"					},
					{ label		: "_FOOD_TOURS"						},
					{ label		: "_DINING_EXPERIENCE"				},
					{ label		: "_SOUVENIRS"						},
					{ label		: "_DOWNTOWN"						},
					{ label		: "_OTHER_DISTRICTS"				},
					{ label		: "_BUFFER_AND_SPECIAL_MEALS"		},
					{ label		: "_UNIQUE_GOURMET_EXPERIENCE"		},
					{ label		: "_LOCAL_FAVORITES"				},
					{ label		: "_COZY_CAFES"						},
					{ label		: "_GOURMET_EXPERIENCE"				},
					{ label		: "_BARS_AND_NIGHTLIFE"				}
				]
			},
			{ 
				label			: '_TRANSPORT_AND_WIFI',
				children		: [
					{ label		: "_RAILWAY_AND_PUBLIC_TRANSPORT"	},
					{ label		: "_VEHICLE_RENTAL"					},
					{ label		: "_ATTRACTION_TRANSFERS"			},
					{ label		: "_LOUNGES_AND_AIRPORT_SERVICES"	},
					{ label		: "_EQUIPMENT_RENTAL"				},
					{ label		: "_SHUTTLE_BUS_TRANSFERS"			},
					{ label		: "_AIRPORT_AND_CITY_TRANSFERS"		},
					{ label		: "_CAR_CHARTER"					},
					{ label		: "_WIFI_AND_SIM_CARDS"				}					
				]
			}
		],
		selected        : undefined				
	}
];