
export const firebaseForm = [
	{	cols		: 12,
		title		: "_FIREBASE_CONSOLE",
		icon		: "fa fa-list",
		padding		: "1rem 1rem 4rem 1rem",
		items		: 	[
			{
				cols		: 12,
				offsetCols	: 0,
				items		: [ 			
					{	field			: 'path',					
						label			: '_FIREBASE_PATH',						
						editable		: true,
						type			: 'text',
						clean			: 'simple',
						flex			: 20
					}
					,{	field			: 'id',					
						label			: '_FIREBASE_PATH_ID',						
						editable		: true,
						type			: 'text',
						clean			: 'simple',
						flex			: 20
					}
                    ,{	mandatory		: false, 	
						field			: 'operation',
						label			: '_FIREBASE_OPERATION',
						editor			: 'radio',
						multiple		: false,
						inline			: true,					
						entityList		: 'firebaseOperationsList',
						type			: 'text',
						clean			: 'simple',
						flex			: 20
					}
				]
			},
			{
				cols		: 12,
				offsetCols	: 0,
				padding		: "2rem 1rem 1rem 1rem",
				items		: [ 
                    {	field		    : 'values',						
						label		    : '_FIREBASE_VALUES',
						editable		: true,
						noLabel			: true,
						type			: 'text',
						noEditorLabel	: true,						
						editor			: 'json_textarea',
						clean			: 'simple',
						flex			: 30,
						width			: '100%',
						height			: '20rem',	
						rows			: 20
					}
				]
			}
		]
	}
];