export const moreZones = {
    "Puerto Alcudia"			: "Norte",
    "Cala Mandia"				: "Levante",
    "Playa De Palma"			: "Palma",
    "Puerto De Pollensa"		: "Norte",
    "Santa Ponsa"				: "Palma",
    "Can Picafort"				: "Norte",
    "Camp De Mar"				: "Palma",
    "Portal Nous"				: "Palma",
    "El Arenal"					: "Palma",
    "Puerto De Soller"			: "Tramontana",
    "Palma City Center"			: "Palma",
    "Palma City Centre"			: "Palma",
    "Can Pastilla"				: "Palma",
    "Cala Dor"					: "Levante",
    "San Agustin"				: "Palma",
    "Bonanova"					: "Palma",
    "Alcudia"					: "Norte",
    "PAseo Maritimo"			: "Palma",
    "Paseo Maritimo - Palma"	: "Palma",
    "Puerto de Andratx"			: "Palma",
    "Ciudad Jardin"				: "Palma",
    "Cala Barca (OTB)"          : "Sur",
    "Sa Pobla"                  : "Norte",
    "Tolleric"                  : "Sur",
    "Lluchmajor"                : "Palma",
    "Maioris"                   : "Palma",
    "Sant Elm"                  : "Palma"
}

export const zones = [
    "Palma", "Norte", "Sur", "Levente", "Tramontana", "Centro"
];