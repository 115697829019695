import { FirebaseService 			} from '../database/firebase.service';
import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore			} from '@angular/fire/firestore';

@Injectable()
export class TransportTypeService {		
  	constructor(  
		private afs     	: AngularFirestore,
		private commons 	: CommonsService,
		private firebaseCtrl: FirebaseService
	){}
	
	/**
	 * 
	 * @param dmcId 
	 * @param destinationId 
	 * @param typeTransport 
	 */
	async saveTypeTransport(dmcId, destinationId, typeTransport)	{	
		let fleetPath = this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('fleet');
		let fleetInfo = await fleetPath.doc(String(typeTransport.id).toString()).ref.get();
		if(fleetInfo.exists){
			fleetPath.doc(String(typeTransport.id).toString()).update(typeTransport);																			
		}else{
			fleetPath.doc(String(typeTransport.id).toString()).set(typeTransport);
		}
	}

	/**
	 * 
	 * @param dmcId 
	 * @param destinationId 
	 * @param typeTransport 
	 * @returns 
	 */
	async deleteTypeTransport(dmcId, destinationId, typeTransport)	{	
		if(!typeTransport.id){ return this.commons.generateToast('_ERROR','_ERROR_ID_TYPE', 'error');}
		let fleetPath = this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('fleet');
		await fleetPath.doc(typeTransport.id).delete();
	}																									
}