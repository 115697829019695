import { CommonsService } from './../../demo/service/commons.service';
import { FirebaseService } from './../../demo/service/database/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
		 OnInit
		} from '@angular/core';

@Component({
  selector    : 'app-chat',
  templateUrl : './chat.component.html',
  styleUrls   : ['./chat.component.scss'] ,
})

export class ChatComponent implements OnInit {
	
	@Input()  	item			:	any;	// item with list messages as property 
	@Input()	type			:	any;	// type of messages
  	@Output()   emiterOption	:   EventEmitter<any>   = new EventEmitter();

	
	constructor(private commons	:	CommonsService){}

	ngOnInit(): void {}
  
  	sendNotification(){
		this.emiterOption.emit({type 	: 	this.type, 
								item	:	this.item,
								content	:	document.getElementById('chatbox')['value']
								})
  	}

	  /**
	 * 
	 * @param $event Event from scroll
	 */
  	onScroll($event){
		// console.log($event);
	}
	/**
	 * Check the difference time between two days. If is higher or equal than 1, display the date because its a new day
	 * @param indexMsg index of current message
	 */
	checkIntervalTime(indexMsg){
		if(indexMsg == 0) {return this.commons.formatCustomDate(this.item.messages[indexMsg].timestamp,'DD-MM-YYYY')}
		if(this.commons.getDiffDays(this.item.messages[indexMsg].timestamp, this.item.messages[indexMsg -1].timestamp, 'days') > 0){
			return this.commons.formatCustomDate(this.item.messages[indexMsg].timestamp, 'DD-MM-YYYY');
		}
		return null;
	}
}