import { CommonsService 		} 	from './../../service/commons.service';
import { Component, 
		 OnInit, 
		 ViewEncapsulation,		 
		 ViewChild 				} 	from '@angular/core';
import { AuthFirebaseService 	} 	from '../../service/database/authfirebase.service';
import { Router 				} 	from '@angular/router';
import { StorageService 		} 	from '../../service/storageservice';

interface user {
   email    : string;
   password : string;
}

@Component({
   selector		: 'app-landing',
   templateUrl	: './landing.component.html',
   styleUrls	: ['./landing.component.scss'],
   encapsulation: ViewEncapsulation.None,
})

export class LandingComponent implements OnInit {
   
   	user = {} as user;
   	@ViewChild('navBar')			navBar   		: any;
	@ViewChild('trafficSection') trafficSection 	: any;

	aosStyles = ["fade-up","fade-up","fade-right","fade-up-right","fade-up-left","fade-down-right","fade-down-left","flip-up"];
	pageInfo: any = {
		particles					:  20,
		computer					: 'assets/demo/images/landing/computer.png',
		verona						: 'assets/demo/images/landing/verona.png',
		tourinia					: 'assets/demo/images/brand/tourinia.png',
		winwin   					: 'assets/demo/images/landing/winwinwoman.png',
		mark    		 			: 'assets/demo/images/landing/mark.png',
		bus			      			: 'assets/demo/images/landing/fondo11.jpg',
		touriniaIncoming 			: 'assets/demo/images/landing/tourinia_incoming.png',
		headerLogo					: 'assets/layout/images/tourinia_logo_300.png',
		// mainLogo					: 'assets/layout/images/tourinia_logo_black.png',
		mainLogo					: 'assets/layout/images/tourinia_logo_black_2.png',
		image						: 'assets/layout/images/landing_fondo_1.png',
		sections					: [],
		screenLogin     			: false,
		forgotPassword    			: false,
		failedAccess            	: {},
		mailToRecover           	: null,
		messageRecoverPassword  	: {},
		showPassword            	: false,
		resultActionRecoverPassword	: false,
   }

   constructor(
	   public dbAuth 	: AuthFirebaseService,
	   public router 	: Router, 
	   public commons 	: CommonsService, 
	   public storage 	: StorageService
	){}

   	async ngOnInit() 		{}
   	navBarScrolled()		{ 	return window.scrollY >this.navBar.nativeElement.clientHeight; 					}
   	scrollToSection(item)	{	window.scrollTo(this[item].nativeElement.offsetTop)								}
	isMobile() 				{	return window.innerWidth < 1025;												}
	doAction($action)		{	switch($action){
									case "login"	: 	if( this.checkParams('login') ){ this.login(); }
														break;
								}
							}
	checkParams($type)		{	switch($type){
									case "login"	:	return 		undefined!=this.user.email 		&& this.user.email		!=""
																 && undefined!=this.user.password	&& this.user.password	!="";
								}
							}
   	async testLogin()		{	const user = await this.dbAuth.getAutenticated(); console.log(user);	}
	async loginDB(me)		{	//this.authenticated.next({   success : true,data : result   } );
								return await { success: true }										
							}
	async loginFirebase(me)	{	return await me.dbAuth.login( me.user.email, me.user.password);	}
	async login()			{	this.pageInfo.screenLogin 	= true;
								let type					= "firebase";
								let loginAction:any;
										
								// switch(type){
								// 		case "db"		:	loginAction	= this["loginDB"]; 			break;
								// 		case "firebase"	:	loginAction	= this["loginFirebase"];	break;
								// }
															
								loginAction	= await this.dbAuth.login( this.user.email, this.user.password	);

								if(!loginAction || !loginAction.success){   
									const message = await this.commons.getAuthErrorMessage(loginAction.data.code);
									this.commons.generateToast("ERROR",message ,"error");
								}
							}
	forgotPassword()		{	this.pageInfo.forgotPassword = true;											}
   	isTablet() 				{	const width = window.innerWidth;	return width <= 1024 && width > 640;		}
   	restartFormLogin()		{	this.pageInfo.forgotPassword              = false;
								this.pageInfo.resultActionRecoverPassword = false;
								this.pageInfo.failedAccess                = {};
								this.pageInfo.messageRecoverPassword      = {};
								this.user.password                        = null;
								this.pageInfo.mailToRecover               = null;
								this.pageInfo.showPassword                = false;
							}
  async recoverPassword()	{	let resultRecover                         = await this.dbAuth.recoverPassword(this.pageInfo.mailToRecover);
							    this.pageInfo.resultActionRecoverPassword = true;
							    this.pageInfo.messageRecoverPassword      = resultRecover;							
						   	}
   cancelRecoveryPassword()	{	this.pageInfo.forgotPassword = false;
						      	this.pageInfo.mailToRecover  = null;
						   	}
}