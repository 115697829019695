import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { EntityService } from '../../../service/entity.service';
import { CommonsService } from '../../../service/commons.service';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
    templateUrl: './hotels.component.html',
    styles: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation: ViewEncapsulation.None,
    providers: [ MessageService ]
})

export class HotelsComponent implements OnInit {
    hotels    			: any = { cols: [], filters: {} };
    areas             	: any = {};
    results     		: any[];
    rowGroupMetadata    : any;

    menu: any = {
        options: [
            { label: 'reload', 		icon: 'fa fa-plus'		},
            { label: 'download',	icon: 'fa fa-download' 	},
        ]
    };

    constructor(
        private http			: HttpClient,
        private commons			: CommonsService,
        private entityService	: EntityService,
        private messageService	: MessageService
    ){}

    ngOnInit() {
		this.init();
		this.loadEntities();
    }

	async loadEntities() {
        await this.load('areas');
        await this.load('hotels');
    }

	async init() 
	{
        this.areas.cols = [
            { field: 'destination', 	header: 'Destination', 	width: '100px', 	align: 'center' },
            { field: 'code', 			header: 'Code', 		width: '50px', 		align: 'center', editable: true },
            { field: 'name', 			header: 'Name', 		width: '100px', 	align: 'center', editable: true },
            { field: 'transfer_time', 	header: 'Transfer', 	width: '50px', 		align: 'center', editable: true },
            { field: 'alias', 			header: 'Name', 		width: '200px',		align: 'center', editable: true },
        ];

        // Listeners
        this.areas.nodeSelect = ($event) => {
            alert("Node selected:"+$event.node.label);
            this.messageService.add({severity: 'info', summary: 'Node Selected', detail: $event.node.label});       
        };

        this.areas.cm = {
            items    : [
                { label: 'open', icon: 	"fa fa-folder" }
            ]
        }

        this.hotels.cols = [
            { field: 'name', 		header: 'Name', 	width: '300px'	},
            { field: 'category',	header: 'Cat', 		width: '50px' 	},
            { field: 'rooms', 		header: 'Rooms', 	width: '50px' 	},
            { field: 'area', 		header: 'Area',		width: '100px' 	},
            { field: 'address', 	header: 'Address', 	width: '200px' 	},
            // { field: 'latitude', header: 'Lat', 		width: '100px' 	},
            // { field: 'longitude',header: 'Long', 	width: '100px' 	},
        ];

        // [ 'bookings', 'flights', 'areas' ]
        // .forEach( async (item) => {
        // 	this.generateMenuCols(item);
        // 	await this.load(item);
        // });

        this.generateMenuCols('areas');
        this.generateMenuCols('hotels');

        this.hotels.filters = [
            { name: 'pending', labelOn: 'Only pendings', labelOff: 'All states', status: false }
        ];

        // this.loadEntities();
        // this.updateRowGroupMetaData();
    }

    generateMenuCols($entity) {
        // this[$entity].selectedColumns = this[$entity].cols;
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);

        // this[$entity].menucols = this[$entity].cols.map(item => ({
        //     label: item.header,
        //     icon: !item.disabled ? 'pi pi-check' : 'pi',
        //     style: 'width:300px',
        //     command: ($event) => {
        //         item.disabled = item.disabled ? false : true; 			// Col status
        //         $event.item.icon = item.disabled ? 'pi' : 'pi pi-check';		// Menu col status
        //         // alert('INNER->'+JSON.stringify($event.item)+'. ITEM Disabled->'+item.disabled+'#');
        //     }
        // }));
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
    }

	async load($entity) 
	{
		let response;
		switch ($entity) 
		{
			case 'areas':			this[$entity].data 		= [];
					                this[$entity].tree 		= [];
					                this[$entity].loading 	= true;
									
									response			= await Promise.resolve(this.entityService.getRequest($entity,{ iddestination: 1, order: 'name' }));
					                this[$entity].data 	= response.data;                				
									this[$entity].tree	= [
										{
											"label"			: 	"Areas",
											"data"			: 	"Areas",
					                        "expandedIcon"	: 	"fa fa-folder-open",
											"collapsedIcon"	: 	"fa fa-folder",
											"expanded"		:	true,
											"children"		: 	[{ id: 1, label: 'test', icon: 'fa fa-folder'}]
										}
									];
									
									if(undefined!==this[$entity].data){
										this[$entity]["tree"][0]["children"] = this[$entity].data.map(item => ({
																					id    	: item.id,
																					label	: item.name,
																					icon 	: 'fa fa-folder'
																				}));					
									}
					
									// console.log("LOADING AREA 4");
									
									this[$entity].loading = false;
									
					                break;

            case 'hotels':			this[$entity].data = [];
									response			= await Promise.resolve(this.entityService.getRequest($entity,{ iddestination: 2, order: 'name' }));
					                this[$entity].data 	= response.data;
					                break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
        this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
    }

    getEditor($col) 					{	return $col.editor ? $col.editor : 'input';														}
    search(event) 						{	this.results = this.commons.getEntity('areasList').filter(item => item.includes(event.query));	}
    checkEntity($entity, $value) 		{	return true;																					}
	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR');																			}
	
    getRendererType($type) 				{
        switch ($type) {
            case 'euro'         : return (type, col, items) => this.priceRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header': return this.defaultStyleRenderer($col);
            case 'style': return this.defaultStyleRenderer($col);
            case 'content': return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width': $col.width ? $col.width : '',
            'text-align': $col.align ? $col.align : ''
        };
    }

    priceRenderer($me, $type, $col, $items) {
        switch ($type) {
            case 'header': return this.defaultStyleRenderer($col);
            case 'style': return this.defaultStyleRenderer($col);
            case 'content': return $me.floatRenderer($items[$col.field], 2) + ' €';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    onSort() {}

}
