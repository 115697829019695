import { CompanyService 				} from '../../service/database/company.service';
// import { AggregatocrsService 			} from '../../service/database/aggregator.service';
import { CommonsService 				} from '../../service/commons.service';
import { EntityService 					} from '../../service/entity.service';
import { MessageService 				} from 'primeng/components/common/messageservice';
import { OnInit,
		 Component,
		 ViewChild,
		 ViewEncapsulation,
		 ElementRef						} from '@angular/core';
import { filters, zonesFilter 			} from './data/filters';
import { toggleFullScreen 				} from './fn/fullscreen';
import { StorageService 				} from '../../service/storageservice';
import { help 							} from './data/help';
import { calendarInfo,
		 colorInfo,
		 heightInfo,					
		 mainView,					
		 servicesGrid,					} from './data/info';
import { servicesCols 					} from './columns/services.columns';
import { SimpleFirebaseService 			} from '../../service/database/simplefirebase.service';
import { ServiceFiltersService 			} from '../../service/serviceFilters/service-filters.service';
import { TransportService 				} from '../../service/transports/transports.service';
import { tabs							} from './data/tabs';
import { expandedArrivalForm,
		 expandedDepartureForm			} from './columns/expandedForm';
import { BookingService 				} from '../../service/bookings/booking.service';
import { isThisSecond } from 'date-fns';

interface marker {
	lat				: number;
	lng				: number;
	label?			: string;
	draggable		: boolean;
}

interface Calendar {
	value		: any,
	date		: string,
	last		: string,
	offset		: number,
	minOffset	: number,
	maxOffset	: number
};

export const serviceFilters = [
	{
		entity	: 'bookings',
		name	: 'shared',
		label	: '_VEHICLE_SERVICE',
		type	: 'multiple',
		field	: 'shared',
		icon	: 'share-alt',
		items	: [
			{ label: '_SHARED',		value: 'shuttle'	},
			{ label: '_PRIVATE',	value: 'private' 	}
		],
		selected: [ "shuttle", "private" ]
	}
];

@Component({
    styleUrls		: ['./externals.component.scss'],
	templateUrl		: './externals.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})
export class ExternalsComponent implements OnInit
{
	@ViewChild('calendarVehicles'	)	calendarVehicles: ElementRef;
	@ViewChild('calendarDrivers'	)	calendarDrivers	: ElementRef;
	@ViewChild('servicesGrid'		) 	servicesGrid	: any;
	
	pageInfo			: any 		= { fullScreen: false, export: [] };
	userInfo			: any		= {};
	zoom				: number 	= 10;
	lat					: number	= 39.638464;
	lng					: number	= 3.003831;
	providers			: any 		= {};
	markers				: marker[] 	= [
										{ 
											lat			: 39.575505, 
											lng			: 2.652774, 
											label		: 'Headquarters', 
											draggable	: true 	
										}
									  ];
	calendar			: Calendar 	= <Calendar>{ 
										last		: '', 
										value		: new Date(), 
										offset		: 30, 
										minOffset	: 0, 
										maxOffset	: 365 
									};
	calendarFullView	: boolean	= true;
	entities			: any[]		= [ "groups", "fleet", "drivers" ];
	groups      		: any 		= { cols: [], filters: {}};
	bookings			: any		= { cols: [], filters: {}, items: []};
	services			: any		= { cols: [], filters: {}, items: []};
	fleet				: any 		= { cols: [], filters: {}, draggableSet: [] };
	drivers				: any 		= { cols: [], filters: {}, draggableSet: [] };
	transporters		: any 		= { cols: [], filters: {}, draggableSet: [] };
	transportTypes		: any 		= { cols: [], filters: {}, draggableSet: [] };
	vehicleTypes		: any[]		= [];
	arrivals			: any		= { draggableSet: [] };
	departures			: any		= { draggableSet: [] };
	
	ownFleet			: any[]		= [];
	ownPlates			: any		= { draggableSet: [] };
	
	driversCalendar		: any 		= { rows: [{ empty: true, items: [] }]};
	transportersCalendar: any 		= { rows: [{ empty: true, items: [] }]};
	companies			: any		= { items: [], selected: [] };
	draggedItem			: any;
	chartData			: any;
	linkable			: any 		= {};

	plainServices		: any[]		= [];
	transportsCalendar	: any 		= { rows	: [{ empty: true, items: [] }],
										tmpRows	: [{ emtpy: true, items: []	}]
									};

	rowData				: any 		= { servicesGrid: {}};

    constructor(
		private commons					: CommonsService,
		private entityService			: EntityService,
		// private aggregatorCtrl			: AggregatorsService,
		private companyService			: CompanyService,
		private storageCtrl				: StorageService,
		private serviceFiltersService	: ServiceFiltersService,
		private firebaseService			: SimpleFirebaseService,
		private bookingService			: BookingService,
		private transportService		: TransportService
	){
		this.staticInit();
	}

	async ngOnInit(){ await this.init(); }

	async init()	{
		this.pageInfo.mappings				= [
			{	origin		: "reference",
				target		: "Referencia",
				selected	: true
			},
			{
				origin		: "customer",
				target		: "Cliente",
				selected	: false	
			}
		];
		this.pageInfo.mainView				=	mainView;
		this.pageInfo.serviceFilters		=	serviceFilters;
		this.pageInfo.subscriptions			=	[];
		this.pageInfo.tabs					=	tabs;
		this.pageInfo.table 				=	{ height: '65vh', border: '1px solid #f0f0f0', rowExpansionWidth: '85vw' };		
		this.pageInfo.dialogs				= {
			"splitGroup"	: { params: {}, openned	: false }
		};
		this.pageInfo.currencySign			= "€";
		this.pageInfo.filters 				= filters;
		this.pageInfo.elem 				= 	document.documentElement;

		this.generateMenuCols("groups");
		this.generateMenuCols("services");
	
		this.loadEntities();
	}

	async staticInit()	{
		this.pageInfo.show_controls			=	true;
		this.pageInfo.date_colspan			=	13;
		this.pageInfo.debug					=	true;
		this.pageInfo.wheel					= 	{ xFactor: 2, yFactor: 1, sliderFactor: 50 };
		this.pageInfo.noData				= 	{
			header	: {
				title	: "_WELCOME_COMPOSITOR_TITLE",
				message	: "_WELCOME_COMPOSITOR_DESC",
			},
			items	: [
				{ icon	: "bus",			message	: "_WELCOME_COMPOSITOR_ITEM_1" },
				{ icon	: "calendar",		message	: "_WELCOME_COMPOSITOR_ITEM_2" },
				{ icon	: "hand-o-right",	message	: "_WELCOME_COMPOSITOR_ITEM_3" },
				{ icon	: "floppy-o",		message	: "_WELCOME_COMPOSITOR_ITEM_4" }
			]
		};

		this.pageInfo.help					= 	help;
		this.pageInfo.height 				= 	heightInfo;
		this.pageInfo.colors				=	colorInfo;
		this.pageInfo.calendar				=	calendarInfo;
		this.pageInfo.servicesGrid			=	servicesGrid;
		this.pageInfo.servicesGrid.cols		=	await Promise.resolve(this.commons.translateRecursively(servicesCols, { label: 'header' }));

		this.pageInfo.rowData				= 	{};
		this.pageInfo.servicesVehicle		= 	{};

		this.pageInfo.export	= {
			items			: [
				{ label: 'CSV', icon: 'pi pi-refresh', command: () => { this.export('csv') }},
				{ label: 'PDF', icon: 'pi pi-refresh', command: () => { this.export('pdf') }},
			]
		}

		this.pageInfo.panels	= {		transporters	: false		};
	}

	export($format,$entity?,$info?)		{	
		if(undefined==$entity){
			this.commons.generateToast(
				"Export",
				"Generating "+$format+" for "+this.pageInfo.calendar.view.selected,
				"success"
			);
			return;
		}
		let calendar;
		switch($entity){
			case "services"		:	
				let header = [
					"_DIRECTION", 
					"_NAME", 
					"_VEHICLE",
					"_TYPE",
					"_DATE",
					"_TIME",
					"_AREA", 
					"_ZONE",
					"_LODGINGS",										
					"_BOOKINGS"										
				];
				let items = this.getFilteredEntity('services',{ type: 'mapped' })
								.map(item=>{
									let service = {};
									service["direction"	]	= item.direction;
									service["name"		]	= item.name;
									service["vehicle"	]	= item.vehicle;
									service["type"		]	= item.type;
									
									switch(item.direction){
										case "arrival":
											service["date"]	= item.date;
											service["time"] = item.pickupTime;
											break;
										case "departure":
											service["date"]	= item.date;
											service["time"] = item.pickupTime;
											break;
									}		
									
									service["area"		]	= item.area;
									service["zone"		]	= item.zone;
									service["bookings"	]	= (item.bookings||[]).join(',');
									service["hotels"	]	= (item.hotels	||[]).join(',');

									return service;														
								});

				items = [ header.map(h=>this.commons.getTranslate(h)), ...items ];
				
				switch($format){
					case "csv"	: this.commons.export("csv",items,"TMT_Services_"+this.commons.getToday('YYYYMMDD')); break;
				}
			break;
		}
	}

	async loadEntities() 				{
		this.calendar.date = this.commons.getToday('YYYY-MM-DD');
		this.loadBookings();
	}

	getProviders(){
		return {
			1: "HTX",
			2: "Hoppa"
		};
	}

	/**
	 * load bookings
	 *
	 * @param $entity
	 * @returns
	 */
	 async loadBookings()
	 {
		if(!this.calendar.date){ return false; }
		let $entity					=	"bookings";
		this.pageInfo.loadingData	=	true;


		// this.pageInfo.search.content=	"";

		// const controls				=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		// const execMode				=	controls.items.find(item=>item.name=="execMode"			);
		// const transferType			=	controls.items.find(item=>item.name=="transferType"		);
		// const transferMode			=	controls.items.find(item=>item.name=="transferMode"		);
		// const partitioned			=	controls.items.find(item=>item.name=="servicePartition"	);
		// const execCommand			=	controls.items.find(item=>item.name=="exec_command"		);
		// const showAssigneds			=	controls.items.find(item=>item.name=="show_assigneds"	);
		// const setEnvironment		=	controls.items.find(item=>item.name=="environment"		);
		// const onlyVerified			=	controls.items.find(item=>item.name=="only_verified"	);
		// const showCancelled			=	controls.items.find(item=>item.name=="cancelled"		);
		
		this.pageInfo.areas							= this.commons.userInfo.tourinia_resorts || [];
		this.pageInfo.provider_areas_to_tourinia 	= this.commons.userInfo.provider_mappings;
		this.pageInfo.providerAreasMapped			= this.commons.userInfo.provider_mappings;

		this[$entity].data			=	[];

		let params			= {
			offset			: this.calendar.offset
		};

		params["filters"]	= {
			transferType	: [ "private"	, "shuttle" 					],
			direction		: [ "arrivals"	, "departures" 					],
			verified		: [ "verified"	, "not_verified" 				],
			status			: [ "original"	, "rectified"	, "ammended"	],
			errors			: [ "errors"	, "not_errors" 					]
		};

		this.commons.userInfo.transporter = this.commons.userInfo.transporter || {
			transporter	: 4,
			name		: "santiago_bus",
			destination	: "QGBHKJzKHcGtoiVY5tVU"
		};

		params["filters"	]		= JSON.stringify(params["filters"]);
		params["transporter"] 		= this.commons.userInfo.transporter.id;
		params["destination"]		= this.commons.userInfo.transporter.destination,
		params["date"		]		= this.calendar.date;
		params["offset"		]		= this.calendar.offset;

		// REMOVE !
		// Forced params
		params["transporter"]		= 4;
		params["destination"]		= "QGBHKJzKHcGtoiVY5tVU";
		
		console.log("PARAMS",params);

		let response 				= await this.entityService.getRequest( "transporter_bookings", params );

		if(response["success"]!=true){
			throw new Error("_BOOKINGS_NOT_LOADED");
		}
		
		// Direction normalizing fields
		this[$entity].data			=	this.bookingService.normalizeBookings({
											data				: response["bookings"] || [],
											calendar_date		: this.calendar.date,
											nextDay				: this.commons.nextDay(this.calendar.date).format("YYYY-MM-DD"),
											providers			: this.getProviders(),
											only_verified		: false,
											show_cancelled		: true
										});

		this[$entity].count			=	(this[$entity].data||[]).length;											
		this[$entity].spinner		=	false;
		this.pageInfo.loadingData	=	false;

		this.commons.generateToast("Bookings","_BOOKINGS_LOADED","info");
	}

	generateTransportFromGroup(type)	{}
	clearContainer(type)				{}

	setInfo($entity, $params){
		switch($entity){
			case "group"		:	switch($params.action){
				case "toggle"	:	$params.item.open = $params.item.open?false:true; break;
			}
		}
	}

	getInfo($entity, $params){
		switch($entity){
			case "table"		:
				switch($params.type){
					case "datechanged":
						let new_date = false;
						// if($params.rowIndex==0){ new_date = true; }
						if(undefined==this.pageInfo.table_current_date){ new_date = true; }
						if(this.pageInfo.table_current_date!=$params.rowData.date){ new_date = true; }
						if(new_date){
							this.pageInfo.table_current_date = $params.rowData.date;
						} 	
						return new_date;
						break;
				}
				break;
			case "tab"			:
				switch($params.type){
					case "is_selected"	: return $params.panel.selected==$params.tab.name?'selected':'';
					case "expander"		:
						if(undefined==$params.rowData){ return		 }
						switch($params.rowData.direction){
							case "arrival"	: return $params.tab.name!="departure";
							case "departure": return $params.tab.name!="arrival";
						}
						return true;
				}
				break;

			case "expanderForm"	: 	
				if(undefined==$params.item || undefined==$params.item.direction){ return }
				switch($params.item.direction){
					case "arrival"	:	
						this.pageInfo.expanderForm 				= expandedArrivalForm; 	
						this.pageInfo.tabs.rowexpander.selected = "arrival";
						break;						
					case "departure":	
						this.pageInfo.expanderForm 				= expandedDepartureForm;	
						this.pageInfo.tabs.rowexpander.selected	= "departure";
						break;
				}
				return this.pageInfo.expanderForm;

			case "grid"			:	switch($params.type){
										case 'cols'	: return $params["items"];
									}
									break;

			case "time"			:	switch($params.type){
										case "min_in_time"			:	
											let time = $params.item;
											let hour = parseInt(time)/60;
											let min  = parseInt(time)%60;
											return hour.toString().padStart(2,'0')+":"+min.toString().padStart(2,'0');
									}
									break;

			case "importer"		:	switch($params.type){
										case "color"				:	return $params.item.forced?"crimson":"forestgreen";
										case "active"				:	return this.pageInfo.importerFilters.filter(filter=>filter.active);										
									}
									break;

			case "calendar"		:	
				switch($params.type){
					case "transportsCalendar"	:	switch($params.query){
														case "hasItems"		:	return this.transportsCalendar.rows.some(row=>(row.items||[]).length>0);
													}
													break;
					case "tabs"					: 	return this.pageInfo.calendar.view.items;
				}
				break;
			
			case "service"		:				
				switch($params.type){
					case "show_action":
						let acceptable = [ "_NONE" ];
						switch($params.button.name){
							case "accept_service"	: acceptable = [ "_SENT", "_PENDING",  "_REJECTED" 	]; break;
							case "reject_service"	: acceptable = [ "_SENT", "_PENDING",  "_ACCEPTED" 	]; break;
							case "show_service"		: acceptable = [ "_SENT", "_ACCEPTED", "_REJECTED" 	]; break;
						}
						return acceptable.some(item=>item==$params.rowData.transporter_status);

					case "external_service_status_color":	
						return this.serviceColor($params.item.transporter_status);
				}
				break;

			case "langs"		:	
				switch($params["type"])	{	
					case "icon"	:	
						return "/assets/layout/icons/flags/"+$params["lang"]+".png";	
				}	
				break;

			case "groups"		:	
				switch($params)			{	
					case "noItems"			: 	return ((this.groups||{}).count || 0 )==0;						
				}	
				break;

			case "group"		:	
				let group = $params["group"];
				if(undefined==group){
					console.log("NO GROUP");
				}
				switch($params["type"]){
					case "bookings"			:	
						switch(group.direction){
							case "arrival":
								return group.bookingsInfo.sort((a,b)=>a.arrival_Time>b.arrival_Time?1:-1);
							case "departure":
								return group.bookingsInfo.sort((a,b)=>a.departure_PickupTime>b.departure_PickupTime?1:-1);																		
						}
						break;

					case "is_empty"			:	return group.empty || (group.bookings||[]).length==0;
					case "flag"				:	return "/assets/layout/icons/flags/es.png";
					case "pax"				:	return group["bookings"]
													.filter(item=>{
														switch($params["assigned"]){
															default		:	return true;
															case true	:	return item.assgigned;
															case false	:	return !item.assigned;
														}
													})
													.reduce((acc,item)=>acc+parseInt(item.pax),0);
					case "firstTime"		:	return group["bookings"].reduce((min,item)=>min<item.time?min:item.time,100000);
					case "lastTime"			:	return group["bookings"].reduce((max,item)=>max>item.time?max:item.time,0);
					case "timeRange"		:	return group["bookings"].reduce((range,item)=>{
													range['min'] = range.min<item.init_timestamp?range.min:item.init_timestamp;
													range['max'] = range.max>item.init_timestamp?range.max:item.init_timestamp;									
													range["diff"]= (range.max-range.min)/60;																			
													return range;
												},{min:1000000000000,max:0})['diff'];									
				}
				break;

			case "formatRange"	:	
				return ($params["value"]<10?'0':'')+$params["value"]+":00";

			case "timeRange"	:	
				let value = this.pageInfo.timeRangeValues.values[$params];
				return (value<10?'0':'')+value+":00";

			case "driver"		:	
				switch($params["attribute"])	{	
					case "thumbnail"		:	return $params["driver"]["avatar"] || "/assets/layout/images/drivers/0.png";
					case "thumbnailFromId"	:	const emptyDriver	= "/assets/layout/images/drivers/0.png";
												if(undefined==$params["item"]){ return emptyDriver; }
												let driver 			= this.drivers.data.find(driver=>driver.id==$params["item"]["id"]);
												return driver && driver["avatar"]?driver["avatar"]:emptyDriver;
				}
				break;

			case "provider"		:	
				switch($params.type){
					case "serviceColor"		:	switch(parseInt($params.provider)){
													case 1	:	return "blue";
													case 2	:	return "forestgreen";
													default	:	return "orange";
												}
												break;

					case "color"			:	switch(parseInt($params.provider)){
													case 1	:	return "blue";
													case 2	:	return "forestgreen";
													default	:	return "orange";
												}
												break;

					case "restrictions"		:	let providerData = ( this["providers"].data || [] ).find(item=>item.selected) || {};
												return this.pageInfo.providerRestrictions.map(item=>{
													item.value = undefined==providerData["restrictions"]?item.default:providerData["restrictions"][item.name];
													return item;
												});

					case "selected"			:	return this["providers"].selected || {};

					case "selecteds"		:	return this["providers"].data.filter(item=>item.selected);

					case "_isSelect	ed"		:	if( null == this.providers.selected ){ return false; }
												return this.providers.selected.id== $params.provider.id;

					case "isSelected"		:	return $params.provider.selected;

				}
				break;

			case "transferCol"	:	
				switch($params){
					case "height"				:	
						const v = this.pageInfo.calendar; 
						return v.scaleOffset+(v.scaleFactorInitial*v.scaleRange*v.scaleFactor)+'px';

					case "serviceOnOverBorder"	:	
						return "10px solid red";
				}
				break;

			case "exec"			:	
				switch($params){
					case "params"			:	
						return this.pageInfo.execParams;
				}
				break;
		}
	}
 
	/**
	 * return color status
	 * 
	 */
	serviceColor($status){
		let colors = {
			"_NOT_SENT"	: 	"gray",
			"_SENT"		: 	"cadetblue",
			"_PENDING"	: 	"orange",
			"_ACCEPTED"	:	"forestgreen",
			"_REJECTED"	:	"crimson"
		}
		if(undefined==colors[$status]){ return "black"; }
		return colors[$status];
	}

	async initFilters()				{	// Zone filtering
										this.pageInfo.zonesFilter			= zonesFilter;
										// this.pageInfo.zonesFilter.items		= this.pageInfo.destinationInfo.pickupZones;
										this.pageInfo.zonesFilter.items		= ( this["providers"].selected || {}).zones;
										this.pageInfo.zonesFilter.selected	= "";
										// this.pageInfo.zonesFilter.selected	= (this.pageInfo.zonesFilter.items.find((item,index)=>index==0)||{})["name"];

									}


	getFilterButtonClass($filter,$item)	{	if ( $filter.multiple )	{	return $filter.selected==$item.name?'selected':'';		}
											else 					{	return $item.value?'selected':'';						}
										}

	remove($type,$info={},$event=null){

		if($event){ $event.cancelBubble=true; }
		
		switch($type){
			default					:	alert("[remove] type "+$type+" not allowed");
		}
	}

	getServicesByTime($slot="hour"):any{
		let plain_services = [], services = {}, factor;
		switch($slot){
			default:
			case "hour":	factor = 60; break;
		}

		this.getFilteredEntity('groups').forEach(group=>{
			// plain_services = [ ...plain_services, ...group.transports ];
			plain_services.push(group);
		});

		let services_by_key = plain_services.reduce((o,item)=>{
			let hour 			= Math.trunc(parseInt(item.init)/factor);
			let formatted_hour 	= (hour>9)?hour+":00":"0"+hour+":00";
			// o[formatted_hour]	= {
			// 	hour	: formatted_hour,
			// 	items	: [...((o[formatted_hour]||{}).items||[]), item]
			// };

			if(item.vehicle){
				item.assigned = true;
			}

			// Plain booking info into service
			switch(item.private){
				case true:	if((item.bookings||[]).length>0){
								let booking			= item.bookings[0];
								item.customer		= booking.customer;
								item.area			= booking.area;
								item.airport		= booking.airport;
								item.flight			= booking.flight;
								item.reference		= booking.reference;
								item.time			= booking.time;
							}
							break;
			}

			if(undefined==o[formatted_hour]){
				o[formatted_hour]	= {
					hour	: formatted_hour,
					items	: []
				}
			}
			o[formatted_hour].items.push(item);
			return o;
		},{});

		services = Object.keys(services_by_key).reduce((a,key)=>{
			a.push(services_by_key[key]);
			return a;
		},[])

		return services;
	}

	// -----------------------------------------------------------------------------------
	// RENDERING METHODS
	// -----------------------------------------------------------------------------------

	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR'); }
	getFieldEditor($col) 				{ 	return $col.editor ? $col.editor : 'input'; }
	
	getRenderer($type, $col, $items) 	{	
		return this.defaultRenderer($type, $col, $items);
	}

	getRendererNew($params)				{
		let $type 	= $params["type"	];
		let $col	= $params["col"		];
		let $items	= $params["items"	];
		let $name	= $params["name"	];
		return this.defaultRenderer($type, $col, $items);
	}

	defaultRenderer($type, $col, $items){	switch ($type) {
												case 'header'	: return this.defaultStyleRenderer($col);
												case 'style'	: return this.defaultStyleRenderer($col);
												case 'content'	: return $items[$col.field];
											}
										}

	defaultStyleRenderer($col) 			{	return {
												'width'		: $col.width ? $col.width : '',
												'text-align': $col.align ? $col.align : ''
											};
										}

	importedRenderer($type, $col, $items){	
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: 
				switch($items[$col.field]){
					case false	:	return "";
					case true	:	return "<i class='fa fa-times'></i>";
				}
		}
	}

	onSort(){}

	// -----------------------------------------------------------------------------------
	// END RENDERING METHODS
	// -----------------------------------------------------------------------------------

	/**
	 * Retrieve services from group depending on status
	 *
	 * @param item
	 * @param status
	 * @returns
	 */
	getGroupServices(group,status:any='all'){
		let services = group.transports
						.filter(item=>{
							switch(status){
								case "pending"	: return !item.assigned;
								default			: return true;
							}
						})
						.map(service=>{
							service.type		= group.type;
							service.zone 		= group.zone;
							service.firstTime	= group.firstTime;
							service.lastTime	= group.lastTime;
							service.pax			= group.pax>service.seats?service.seats:group.pax;
							// service.pax			= item.pax-service.seats>0?item.pax-service.seats:0;
							return service;
						});

		return services;
	}

	/**
	 * Retrieve ervicses by type and status
	 *
	 * @param status
	 * @returns
	 */
	setServices(){
		let services = [];
		// console.log("SERVICES VEHICLE",this.pageInfo.servicesVehicle);
		this.getFilteredEntity('groups').forEach(group=>{
			services =	[ 	...services, 
							...(group.transports||[])								
								.map(service=>{
									// "id", "vehicle", "time", "direction", "from", "to", "pax"
									// service.vehicle 	= group.name,
									service.type			= group.type;
									service.zone 			= group.zone;
									service.firstTime		= group.firstTime;
									service.lastTime		= group.lastTime;
									service.pax				= group.pax>service.seats?service.seats:group.pax;									
									service.time			= group.firstTime,
									service.direction 		= group.direction;
									service.area			= service.area || group.area;
									service.zone			= service.zone || group.zone;
									service.private			= group.private;

									if((group["bookings"]||[]).length>0){
										let booking				= group["bookings"][0];
										service.location 		= booking.accommodation;
										service.accommodation	= booking.accommodation;
									} 

									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else if(service.id){
										service.vehicle			= this.pageInfo.servicesVehicle[service.id];
									}
									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else {
										service.vehicle			= {};
										service.vehicle_plate	= null;
										service.vehicle_code	= null;
									}
									let booking 			= service.bookings[0];

									switch(group.private){
										case true		: 	// PRIVATE
															if((group["bookings"].length>0)){
																service.area = group["bookings"][0].area;																
															}
															break;
										case false		:	// SHUTTLES
									}
									
									switch(service.direction){
										case "arrival"	: 	service.from 		= booking.airport;
															service.to			= service.area;
															break;
										case "departure": 	service.from 		= service.area;
															service.to			= booking.airport;
															service.PickupTime	= group.PickupTime;
															break;
									}
									return service;
								})
						];
		});

		this.services.items					= services;
		this.pageInfo.servicesGrid.total 	= (services||[]).length;
		this.pageInfo.servicesGrid.paginate	= services;
	}

	/**
	 * get info related to transporter
	 * @param $data
	 * @returns
	 */
	getTransporterInfo($info){
		switch($info.type){
			case "color"		:
				switch($info.field){
					case "transporter_status"	: return "white";
				}
				break;
			case "background"	:	
				switch($info.field){
					default						:	return "transparent";
					case "transporter_status"	:
						switch($info.rowData["transporter_status"]){
							default				:	
							case "_NOT_SENT"	:	return "gray";
							case "_PENDING"		:	return "orange";
							case "_ACCEPTED"	:	return "forestgreen";
							case "_REJECTED"	:	return "crimson";
							case "_CANCELLED"	:	return "crimson";
							case "_DONE"		:	return "green";
						}
						break;
				}
				break;
		}
	}	

	getTabInfo($type,$tab,$panel,$params={})	{	
		switch($type){
			case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
			case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
								return this.isDisabled($tab["isDisabled"],$tab);
			case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
		}
	}

	toggle($type,$item=null,$status=undefined){
		switch($type){
			case "booking"				:	$item.expanded				= $item.expanded?false:true; break;
			case "provider"				:	this.providers.data 		= this.providers.data.map(item => { item.selected = item.id == $item.id; return item; }); break;
			case "transporters"			:	this.pageInfo.panels.transporters	= true; break;
			case "itemFleet"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "itemCalendar"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);
											$item.zindex				= $status!=undefined?status:($item.open?1:0);
											break;
			case "fullscreen"			:	toggleFullScreen(this); break;
			case "service"				:	// If every transport is assigned just close or not open
											// if(($item.transports||[]).every(item=>item.assigned))	{	$item.open = false;			}
											// else 													{	this.toggle("group",$item);	}
											this.toggle("group",$item);
											break;
			case "group"				:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "fleet"				:	this.fleet.open				= $status!=undefined?status:(this.fleet.open==1?0:1);
											this.fleet.draggableSet 	= this.fleet.draggableSet.map(item=>{ item.open = this.fleet.open; return item; });
											break;
			case "drivers"				:	this.drivers.open			= $status!=undefined?status:(this.drivers.open==true?false:true); 				break;
			case "vehicle_type_options"	:	$item.vehicle_type_options	= $status!=undefined?status:($item.vehicle_type_options==true?false:true); 		break;
			case "vehicle_plate_options":	$item.vehicle_plate_options	= $status!=undefined?status:($item.vehicle_plate_options==true?false:true); 	break;
		}
	}

	generateMenuCols($entity) {
		switch($entity){
			case "services"	: this[$entity].cols = servicesCols;	break;
		}
        this[$entity].selectedColumns = this[$entity].cols.filter(item=>{
			if(this.pageInfo.view_mode){ return true; }
			return !item.disabled;
		});
	}

	getLegends(){
		const legends = [
			{ 	
				name	: 'arrivals', 
				title	: '_LEGEND_ARRIVALS', 
				desc	: '_LEGEND_ARRIVALS_DESC', 
				icon	: 'plane',
				reverse : true,				
				color	: 'cadetblue'
			},
			{ 	
				name	: 'departures', 
				title	: '_LEGEND_DEPARTURES', 
				desc	: '_LEGEND_DEPARTURES_DESC', 
				icon	: 'plane',
				color	: 'forestgreen'
			},
			{ 	
				name	: 'privates', 
				title	: '_LEGEND_PRIVATES', 
				desc	: '_LEGEND_PRIVATES_DESC', 
				icon	: 'user',
				color	: 'forestgreen'
			},
			{ 	
				name	: 'shuttles', 
				title	: '_LEGEND_SHUTTLES', 
				desc	: '_LEGEND_SHUTTLES_DESC', 
				icon	: 'users',
				color	: 'cadetblue'
			}
		];
		return legends;
	}

	getFilteredEntity($entity,$info:any={}){
		switch($entity)	{
			case "legends"	:	return this.getLegends();						break;
			case "services"	:	return this.getFilteredServices($entity,$info);	break;
			case "mappings"	:	return this.getMappings();						break;
		}
	}

	private getMappings(){
		return this.pageInfo.mappings;
	}

	private getFilteredServices($entity,$info){
		let services = [];
		
		switch($info.type){
			default				:
			case "mapped"		:	
				services = this.bookings.data;									
				break;		
			case "date"			:
				services = this.bookings.data.filter(s=>s.date==$info.date);
				break;
		}
		
		this.pageInfo.services_qty = (services||[]).length;

		switch($info.mode){
			default				:	
				// this.pageInfo.table_current_date = undefined;		
				return services.sort((a,b)=>a.service_date>=b.service_date?1:-1);
		}
	}

	isDisabled($type,$item)				{	
		switch($type){
			case "transferBookings"	:	return !this.pageInfo.selectedRoutes.bookingPanelOpen; break;
		}
	}

	/**
	 * do autocomplete search
	 */
	doSearch($entity,$info){
		switch($entity){
			case "transporter"	: return [{id:1,name:"transporter_1",	value:"transporter_1_1"	}];	break;
			case "vehicle"		: return [{id:1,name:"vehicle_1",		value:"vehicle_1_1b"	}];	break;
			case "plate"		: return [{id:1,name:"plate_1",			value:"plate_1_1"		}]; break;
		}
	}

	toggleRow($item) 				{ this.toggleDT($item); 						}
	expandRow($item) 				{ this.toggleDT($item); 						}
	collapseRow($item) 				{ this.toggleDT($item); 						}

	/**
	 * actions to take once we open row with expander
	 * 
	 * @param $item 
	 * @returns 
	 */
	toggleDT($item) 				{
		console.log($item);
		if (undefined === $item) { return false; }
		if (undefined == this[$item.table].expandedRowKeys[$item.id]) {
			this[$item.table].expandedRowKeys 				= [];
			this[$item.table].expandedRowKeys[$item.id] 	= 1;
			this.rowData[$item.table] 						= $item;									
		} else {
			this[$item.table].expandedRowKeys 				= [];
		}
	}


	private updateExternalServiceStatus($info){
		$info.status = $info.status || "_NOT_SENT";
		switch($info.status){
			case "_NOT_SENT"	: $info.action = "_ACTION_SEND"; 	break;
			case "_PENDING"		: $info.action = "_ACTION_NONE"; 	break;
			case "_ACCEPTED"	: $info.action = "_ACTION_NONE"; 	break;
			case "_REJECTED"	: $info.action = "_ACTION_CLEAR"; 	break;
		}
		return $info;
	}

	private showService($info){
		let item = $info.item;
		this.pageInfo.rowData			= $info.item;
		this.pageInfo.overlay_service 	= true;
	}

	private async doServiceAction($info){
		let $item		= $info.item || {};
		let response;
		let status;
		let action_type;

		switch($info.action){
			case "show": return this.showService($info);
		}

		try {
			switch($info.action){
				case "accept"	:	
					action_type = "update_status"; 
					status 		= "_ACCEPTED"; 
					break;
				case "reject"	:
					action_type = "update_status";	
					status 		= "_REJECTED"; 
					break;
				case "notify_pickup":
					action_type	= "notify_pickup";
					break;
				default			:	
				throw new Error("action "+$info.action+" not allowed");
			}

			switch(action_type){
				case "update_status":		
					this.updateServiceStatus({ 
						reference	: $info.item.reference,
						direction	: $info.item.direction,
						status		: status,
					});
					break;
				case "notify_pickup":
					if($info.item.direction!="departure"){
						this.commons.generateToastError("_PICKUP_ONLY_FOR_DEPARTURES");
						return false;
					}
					this.updatePickupService({ 
						reference	: $info.item.reference,
						time		: $info.item.departure_PickupTime,
						location	: $info.item.departure_PickupLocation
					});
					break;
			}
			
		}catch(e){
			this.commons.generateToast("_INFO",e.message || "_SERVICE_ACTION_ERROR","info");
			return false;
		}
	}

	/**
	 * notify pickup service
	 * @param $params
	 */
	private async updatePickupService($params)
	{
		let message = { "success" : true };

		try {
			let response = 	await this.entityService.postJSON( 
								this.entityService.getUrl("update_transporter_booking_pickup"), 
								{
									reference	: $params["reference"	],
									time		: $params["time"		],
									location	: $params["location"	]									
								});

			message["status"]	= response["status"];
		} catch(e){
			message["success"] 	= false;
			message["message"] 	= e["message"];
		}
	}


	/**
	 * update service status
	 * @param $params
	 */
	 private async updateServiceStatus($params)
	 {
		 let message = { "success" : true };
 
		 try {
			 let response = 	await this.entityService.postJSON( 
								 this.entityService.getUrl("update_transporter_bookings_status"), 
								 {
									 reference	: $params["reference"	],
									 direction	: $params["direction"	],
									 status		: $params["status"		]									
								 });
 
			 message["status"]	= response["status"];
		 } catch(e){
			 message["success"] 	= false;
			 message["message"] 	= e["message"];
		 }
	 }

	private copyServicesToClipboard(){
		this.commons.generateToast("_INFO","copy services to clipboard","info");
	}

	private acceptService($params){
		this.doServiceAction({ item: $params.item, action: "accept" });
	}

	private rejectService($params){
		this.doServiceAction({ item: $params.item, action: "reject" });
	}

	private notifyPickupService($params){
		this.doServiceAction({ item: $params.item, action: "notify_pickup" });
	}

	private acceptAllServices(){
		(this.pageInfo.services||[])
			.filter(service	=>service.transporter_status=="_PENDING")
			.forEach(service => {
				this.acceptService({ item: service });
			});
	}

	private rejectAllServices(){
		// this.commons.generateToast("_INFO","reject all services","info");
		(this.pageInfo.services||[])
			.filter(service	=>service.transporter_status=="_PENDING")
			.forEach(service=>{
				this.rejectService({ item: service });
			});
	}

	async doSubscribe(){
		this.pageInfo.transporterRef	= "PlPMNb8Fwgh7GMkAqXdD";
		this.pageInfo.transporterPath	= "/transporters/"+this.pageInfo.transporterRef;
		let servicesPathRel				= this.pageInfo.transporterPath+"/requests/";
		this.calendar.date				= this.calendar.value.toISOString().split('T')[0];		
		this.pageInfo.servicesPath 		= servicesPathRel+this.calendar.date;
		// this.pageInfo.servicesPath 		= servicesPathRel+"2024-02-14";

		let transporterInfo:any	= await this.firebaseService.getDocData(this.pageInfo.transporterPath);
		if(transporterInfo.id){
			this.pageInfo.transporter = transporterInfo;
		}

		// Unsubscribe previous
		if(this.pageInfo.subscriptions["calendar"]){
			this.pageInfo.subscriptions["calendar"] = undefined;
		}

		// Subscribe current
		this.pageInfo.subscriptions["calendar"] = (await this.firebaseService.subscribeEntityDoc(this.pageInfo.servicesPath))
													.subscribe(info=>{
			if(info && info["items"]){														
				this.pageInfo.services = info["items"].map(item=>{
					item.customer = "TMT";
					return item;
				});
			}
		})
	}

	doCalendarChange(){
		this.calendar.date		= this.calendar.value.toISOString().split('T')[0];		
		this.pageInfo.services	= [];
		// this.doSubscribe();
		this.calendar.last 		= this.calendar.value;
		this.loadBookings();	
	}

	doActionFilter($action,$info){
		switch($action){
			case "open"	:	
				// this.pageInfo.overlay_filters 	= true; 
				this.pageInfo.show_controls	= this.pageInfo.show_controls?false:true;
				// Select filters option
				break;
		}
	}

	doActionFields($action,info){
		switch($action){
			case "open"	:	this.pageInfo.overlay_fields	= true; break;
		}
	}

	doActionViewMode($info){
		this.pageInfo.view_mode = this.pageInfo.view_mode?false:true;
	}

	doActionRow($action,$info){	
		switch($action){
			case "toggle":	
				if($info.table){ 
					$info.rowData.table = $info.table; 
				}
				this.toggleRow($info.rowData);
				break;		
			case "click":
				switch($info.col.field){
					case "reference"	: 
						this.commons.copyToClipboard({ value: $info.rowData[$info.col.field] }); 
						break;
				}
				break;									
		}	
	}

	/**
	 * toggle optional parts of view
	 */
	doActionSelector($params):void{
		switch($params["action"]){
			case "toggle":	this.pageInfo.show_controls = !this.pageInfo.show_controls; break;
		}
	}

	doActionCalendar($params):void {
		switch($params["action"])	{
			case "change"			:	this.doCalendarChange(); 					break;
			case "clear_selected"	: 	this.pageInfo.serviceSelected = undefined; 	break;				
		}
	}

	doActionButton($params):boolean {
		const $info 	= $params["info"];
		const $action	= $params["action"];

		switch($info["type"]){
			case "service":
				switch($action){
					case "accept_service"	: 
						this.acceptService({ item: $info.rowData 	}); break;								
					case "reject_service"	: 
						this.rejectService({ item: $info.rowData	}); break;
					case "show_service"		: 
						this.showService({ item: $info.rowData		}); break;
				}
				break;	
		}	

		switch($action){
			case "reload"			:	
				this.loadEntities();							
				break;

			case "zones"			:	
				this.pageInfo.zonesFilter.selected = this.pageInfo.zonesFilter.selected==$info["name"]?'':$info["name"];
				break;

			case "toggle"			:	
				if( undefined==$info["button"] || undefined==$info["item"] ){ return false; 		}
				if( $info["button"].multiple )	{
					// Check there is at least MIN_SELECTED_ITEMS
					if( 	$info["button"].minSelected
						&&	$info["item"].value
						&&	($info["button"].items.filter(item=>item.value)||[]).length<=$info["button"].minSelected
					){ return false;	}
					$info["item"].value 		= $info["item"].value?false:true;
				}
				else						{	
					$info["button"].selected 	= $info["item"].name;	
				}
				break;
		}

		return true;
	}

	/**
	 * execute action
	 * 
	 * @param $type 
	 * @param $action 
	 * @param $info 
	 * @returns 
	 */
	doAction($type,$action,$info){
		let proposed = {};
		switch($type){
			case "selector"	:	this.doActionSelector({ action: $action }); break;
			case "calendar"	:	this.doActionCalendar({ action: $action }); break;				
			case "control"	:	
				switch($action)	{
					case "doButton"				:	
						switch($info["item"].name){
							case "filters"						: this.doActionFilter("open", $info);	break;
							case "map_fields"					: this.doActionFields("open", $info);	break;
							case "accept_all_services"			: this.acceptAllServices();				break;
							case "reject_all_services"			: this.rejectAllServices();				break;
							case "export_services"				: this.export("csv","services");		break;
							case "copy_services_to_clipboard"	: this.copyServicesToClipboard(); 		break;						
							case "view_mode"					: this.doActionViewMode($info);			break;
						}
						break;
				}
				break;											
			
			case "button"				:	this.doActionButton({ action: $action, info: $info });	break;
			case "row"					:	this.doActionRow($action,$info);						break;
			case "table"				:	
				switch($action){
					case "click": 	switch($info.col.field){
						case "reference": 
							this.commons.copyToClipboard($info); 	
							break;													
					}
				}
				break;	
			case "tab"					:	
				switch($action)	{
					case "select"			: 	if(!$info["tab"].disabled){	this.pageInfo.calendar.view.selected=$info["tab"].name;}	break;
				}
				break;
			case "wheel"				:	
				switch($info["type"]){
					case "X":	
						if(undefined==$info["event"]){ return false; }
						$info["event"].currentTarget.scrollLeft += $info["event"].deltaY * this.pageInfo.wheel.xFactor;
						$info["event"].preventDefault();
						$info["event"].stopPropagation();
						break;

					case "Y":	
						if(undefined==$info["event"]){ return false; }
						$info["event"].stopPropagation(); 
						break;

					case "slider":	
						this.pageInfo.timeRangeValues.initTime = Math.max(	
							this.pageInfo.timeRangeValues.min,
							Math.min(	this.pageInfo.timeRangeValues.max,
										this.pageInfo.timeRangeValues.initTime + Math.ceil($info["event"].deltaY/this.pageInfo.wheel.sliderFactor)
									)
						);
						if(undefined==$info["event"]){ return false; }
						$info["event"].stopPropagation();
						break;
				}				
				break;

			case "panel"				:	switch($action){
												case "save"				:	// Set operative values
																			this["transporters"].data 			= this["transporters"].draggableSet.map(item=>item); break;
												case "close"			:	// Restore values
																			// this["transporters"].draggableSet	= this["transporters"].data.map(item=>item);
																			this.pageInfo.panels[$info] = false;
																			break;
												case "toggle_shared"	:	$info["shared"]  = !$info["shared"];	break;
												case "toggle_private"	:	$info["private"] = !$info["private"];	break;
											}
											break;

			case "transporter"			:	switch($action){
												case "filter":	switch($info.action){
													case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
																	else					{	$info.item.selected = $info.item.selected?false:true; 	}
																	break;
													}
													break;
											}
											break;

			case "service"				:	
				switch($action){
					case "accept_service"	: 
						this.acceptService({ item: $info.rowData 	}); break;								
					case "reject_service"	: 
						this.rejectService({ item: $info.rowData	}); break;							
					case "show_service":								
						this.showService({ item: $info.rowData }); 
						break;	
					case "update_service":
						this.notifyPickupService({ item: $info.rowData }); break;
						break;

					case "reload"			:
						this.loadBookings();
						break;

					case "toggle_row"		:
						// this.commons.generateToast("_INFO","_TOGGLE_ROW","info");
						break;

					case "change_external_service_status":	
						break;

					case "filter"			:	
						switch($info.action){
							case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
											else					{	$info.item.selected = $info.item.selected?false:true; 	}
											break;
						}
						break;

					case "remove"			:	
						this.remove('calendarTransport',{ item: $info.item, row: $info.row },$info.event);
						break;

					case "select"			:	
						if((this.pageInfo.selectedRoutes.items || []).length==0){
							this.pageInfo.selectedRoutes.zone	= undefined;
							this.pageInfo.selectedRoutes.type	= undefined;
						}
						this.pageInfo.selectedRoutes.zone	= undefined==this.pageInfo.selectedRoutes.zone	? $info.item.zone: this.pageInfo.selectedRoutes.zone;
						this.pageInfo.selectedRoutes.type	= undefined==this.pageInfo.selectedRoutes.type	? $info.item.type: this.pageInfo.selectedRoutes.type;

						if(this.pageInfo.selectedRoutes.zone!==	$info.item.zone	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_ZONE_ERROR",		"error");	return false; 	}
						if(this.pageInfo.selectedRoutes.type!==	$info.item.type	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_DIRECTION_ERROR",	"error");	return false;	}

						$info.item.selected = $info.item.selected?false:true;

						// Add items if selected, previous removing if already exists
						this.pageInfo.selectedRoutes.items	= ( this.pageInfo.selectedRoutes.items || [] ).filter(item=>item!=$info.item);
						this.pageInfo.selectedRoutes.items	= $info.item.selected?[ ...this.pageInfo.selectedRoutes.items, $info.item ]:this.pageInfo.selectedRoutes.items;

						this.doAction("service","check",{});
						break;

					case "clear"			:	
						this.pageInfo.selectedRoutes.items = [];
						this.doAction("service","check",{});
						break;

					case "check"			:	
						this.pageInfo.selectedRoutes.bookingPanelOpen = this.pageInfo.selectedRoutes.items.length>0;
						if(!this.pageInfo.selectedRoutes.bookingPanelOpen){ this.pageInfo.controlPanels.transfers.selected = "routes"; }
						break;
				}
				break;
			case "services"	: this.doActionServices($action,$info);
		}
	}

	doActionServices($action,$info){
		switch($action){
			case "toggle_services":
				this.pageInfo.toggleServices = this.pageInfo.toggleServices?false:true;
				this.bookings.data.forEach(service=>{ 
					service.selected = this.pageInfo.toggleServices; 
				});
				break;
		}
	}
}