import { CommonsService } from '../../service/commons.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { AppComponent } from 'src/app/app.component';

@Component({
    styleUrls		: ['./basicdata.component.scss'],
	templateUrl		: './basicdata.component.html',
	encapsulation	: ViewEncapsulation.None,
})
export class BasicDataComponent implements OnInit{

	pageInfo	:	any = {};
	constructor(private storage : StorageService,
				private app		: AppComponent,
				private commons : CommonsService){}

	ngOnInit(){
		this.pageInfo				=	{	messageInfo	:	{
												admin	:	{
													title		:	'_WELCOME_ADMIN',
													subtitle	:	'_SUBTITLE_WELCOME_ADMIN'
												},
												dmc		:	{
													title		:	'_SELECT_DESTINATION',
													subtitle	:	'_SUBTITLE_SELECT_DESTINATION',
												},
												reseller	:	{
													title		:	'_WELCOME_RESELLER',
													subtitle	:	'_SUBTITLE_WELCOME_RESELLER',
												}
											}
										};

		let userInfo				=	(this.storage.getItem('dmcSuite') || {}).userInfo;
		let entityProfile			=	this.commons.getProfileInfo(userInfo?userInfo.profile:'admin');
		this.pageInfo.displayInfo	=	this.pageInfo.messageInfo[entityProfile?entityProfile.entityType:'admin'];
		
		console.group("basicData");
		console.log('Entity profile"ENTITY PROFILE', 		entityProfile	);
		console.groupEnd();
	}
}