import { LandingComponent 					} from './demo/view/landing/landing.component';
import { Routes, RouterModule				} from '@angular/router';
import { ModuleWithProviders				} from '@angular/core';
import { AuthGuard 							} from './demo/service/auth/auth.guard';
import { ExternalsComponent 				} from './demo/view/externals/externals.component';

export const routes: Routes = [
	{	
		path		: 	'',			
		component	:	ExternalsComponent,
		canActivate	:	[ AuthGuard ],	
		data 		: 	{ 
			roles 	: 	[ 'ROLE_TRANSPORTER' ]
		}			
	},
	{	
		path		:	'landing',   
		component	:	LandingComponent 				
	},
    {	
		path		:	'login',     
		component	:	LandingComponent 				},
    {	
		path		:	'services',  
		component	:	ExternalsComponent,
		canActivate	:	[ AuthGuard ],	
		data 		: 	{ 
			roles 	: 	[ 'ROLE_TRANSPORTER' ]
		}			    
	}
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload' });
