

export const help	= {
	videos	: 	[
		{ thumbnail: "/assets/videos/1.jpg", title	: "_CREATE_CALENDAR_VIDEO_TITLE", info: "_CREATE_CALENDAR_VIDEO_INFO"	},
		{ thumbnail: "/assets/videos/1.jpg", title	: "_CREATE_CALENDAR_VIDEO_TITLE", info: "_CREATE_CALENDAR_VIDEO_INFO"	},
		{ thumbnail: "/assets/videos/1.jpg", title	: "_CREATE_CALENDAR_VIDEO_TITLE", info: "_CREATE_CALENDAR_VIDEO_INFO"	}
	],
	faqs	:	[
		{ question	: "_FAQ_QUESTION_1", answer: "_FAQ_ANSWER_1"	},
		{ question	: "_FAQ_QUESTION_2", answer: "_FAQ_ANSWER_2"	},
		{ question	: "_FAQ_QUESTION_3", answer: "_FAQ_ANSWER_3"	}
	]
}