export const tabs 	= {
	external_services : {
		items	: [ 
			{ 	name	: 'services', 		
				label	: '_SERVICES', 		
				icon	: 'bus'	 				
			}		
		], 
		cols		: 2,
		selected	: 'services'
	},
	rowexpander	: 	{
		items	: [ 
			{ name: 'arrival', 				label: '_BOOKING_ARRIVAL', 		icon: 'file'	},											
			{ name: 'departure', 			label: '_BOOKING_DEPARTURE', 	icon: 'file'	}
		],
		cols		: 2,
		selected	: 'arrival'
	},
};