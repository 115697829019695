export let providerCols = [
    {
        field		: 'id',
        header		: '_ID',
        width		: '50px',
        disabled	: false
    },    
    {
        field		: 'active',
        header		: '_ACTIVE',
        width		: '50px',
        disabled	: true
    },   
    {
        field		: 'name',
        header		: '_NAME',
        width		: '200px',
        disabled	: false        
    },
    {
        field		: 'location',
        header		: '_LOCATION',
        width		: '300px',
        disabled	: false
    },
    {
        field		: 'pax',
        header		: '_PAX',
        width		: '50px',
        disabled	: false
    },
    {
        field		: 'address',
        header		: '_ADDRESS',
        width		: '300px',
        disabled	: false
    },
    {
        field		: 'phone',
        header		: '_PHONE',
        width		: '100px',
        disabled	: false
    },
    {
        field		: 'email',
        header		: '_EMAIL',
        width		: '150px',
        disabled	: false
    },
];
