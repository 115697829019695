import { CommonsService 			} from './../../demo/service/commons.service';
import { Component, OnInit, Input 	} from '@angular/core';

@Component({
  selector		: 'app-list-countries',
  templateUrl	: './app-list-countries.component.html',  
  styleUrls   	: ['./app-list-countries.component.scss']
})

export class AppListCountriesComponent implements OnInit{
    
    @Input() rowData                :   any;
    @Input() destinationsCountries  :   any; 

    constructor(private commonsService : CommonsService){}
    ngOnInit(): void {
        console.log('row data',this.rowData);
        console.log('list countries', this.destinationsCountries);
    }

	toggleDestination(country,destination){
		console.log(country,destination);
		this.rowData.destinationsAssigneds = this.rowData.destinationsAssigneds || [];
		let foundCountry = this.rowData.destinationsAssigneds.findIndex(item => item.country.value == country.value);
		if(foundCountry > -1){
			let foundDestination	=	this.rowData.destinationsAssigneds[foundCountry].destinations.findIndex(destAssigned => destAssigned.id == destination.id);
			foundDestination > -1	?	this.rowData.destinationsAssigneds[foundCountry].destinations.splice(foundDestination,1)
									:	this.rowData.destinationsAssigneds[foundCountry].destinations.push({id : destination.id, name : destination.name});
		}else{
			this.rowData.destinationsAssigneds.push({
				country 		: {value : country.value},
				destinations	: [{id : destination.id, name : destination.name}]	
			});
		}
	}
	toggleAllDestinations($event,country,destinations){
		if($event){
			this.selectAllDestinations(country,destinations);
		}else{
			this.unselectAllDestinations(country);
		}
	}

	selectAllDestinations(country,destinations){
		this.rowData.destinationsAssigneds = this.rowData.destinationsAssigneds || [];
		let foundCountry = this.rowData.destinationsAssigneds.findIndex(item => item.country.value == country.value);
		if(foundCountry > -1){
			destinations.filter(destination => !this.rowData.destinationsAssigneds[foundCountry].destinations.some(item => item.id == destination.id))
						.forEach(destination => this.rowData.destinationsAssigneds[foundCountry].destinations.push({id : destination.id, name : destination.name}))
		}else{
			this.rowData.destinationsAssigneds.push({
				country			:	{value : country.value},
				destinations	:	destinations.map(destination => { return { id : destination.id, name : destination.name}})
			})
		}
	}
	unselectAllDestinations(country){
		this.rowData.destinationsAssigneds = this.rowData.destinationsAssigneds || [];
		let foundCountry = this.rowData.destinationsAssigneds.findIndex(item => item.country.value == country.value);
		if(foundCountry == -1){ return; }
		this.rowData.destinationsAssigneds.splice(foundCountry,1);
	}
	getCountDestinationsAssigned(country){
		if(!country || !this.rowData.destinationsAssigneds){ return; }
		let countryFound	=	this.rowData.destinationsAssigneds.find(countryAssigned => countryAssigned.country.value == country.value);
		if(!countryFound){ return false};
		return (countryFound.destinations|| []).length;
	}

	areAllDestinationAssigned(country,destinations){
		if(!country || !this.rowData.destinationsAssigneds){ return {active : false}; }
		let countryFound	=	this.rowData.destinationsAssigneds.find(countryAssigned => countryAssigned.country.value == country.value);
		if(!countryFound){ return {active : false}}
		return {active : countryFound.destinations.length == destinations.length}
	}
	isDestinationSelected(country,destination){
		if(!this.rowData.destinationsAssigneds){ return false;}
		let countryFound	=	this.rowData.destinationsAssigneds.find(countryAssigned => countryAssigned.country.value == country.value);
		if(!countryFound){ return false};
		return (countryFound.destinations|| []).some(destAssigned => destAssigned.id == destination.id);
	}

}