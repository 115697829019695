import { Router 			} from '@angular/router';
import { GoogleService 		} from '../../../demo/service/google/google.service';
import { MapService 		} from '../../../demo/service/mapservice';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { Component,
    	Input, 
		Output, 
		EventEmitter,      	
		ViewEncapsulation,		
		OnInit,
		ViewChild,
		OnChanges,			
		AfterViewInit		} from '@angular/core';
import { AgmMap 			} from '@agm/core';

@Component({
selector    		: 'app-external-transporters-prices',
styleUrls			: ['./prices.component.scss'],
encapsulation		: ViewEncapsulation.None,
templateUrl 		: 'prices.component.html'  
})
export class AppExternalTransportersPrices implements OnInit,OnChanges, AfterViewInit {
	
	@Input() 	types				:	any[]	=	[];
	@Input()	stations			:	any[]	=	[];
	@Input()	prices				:	any[]	= 	[];
	@Input()	fleet				:	any[]	=	[];
	@Input()	areas				:	any[]	=	[];
	@Input()	mode				:	any;
	@Input() 	fromWizard 			:	Boolean = 	false;
	@Output()	emitter				=	new EventEmitter<any>();	
	
    @ViewChild('map',{ read : AgmMap }	)	map				: AgmMap;
	@ViewChild('dropDownAreas'			)	dropDownAreas	: any;
	@ViewChild('listPricesTable'		) 	listPricesTable : any;
	@ViewChild('dt'						) 	table 			: any;

	pageInfo    :   any =   {};
	userInfo	:	any =	{};

	constructor(
		private commons 		: CommonsService,
		private googleService	: GoogleService,
		private mapCtrl			: MapService,
		private route			: Router
	){}

	ngOnChanges(){}

    async ngAfterViewInit() {
		this.pageInfo	=	{
			viewTable				: 'viewInfoTransport',
			linkedAllRows			: 	true,
			stationSelected 		: 	null,
			displayMarkers			: 	false,
			tabPanelIndex			: 	1,
			areas					:	[],
			tmpAggregatorsPrices	:	[],
			listPrices				:	[],
			displayMapPrices		:	true,
			displayMapLabel			:	false,
			workWithRatio			:	true,
			mapColors				:	{
				default		:	'cadetblue',
				selected	:	'salmon'	
			},
			tablePricing			:	{
				cols	:	[
					{	field	:	'destination', 		header	:	'_AREA', 		editable : false,	width	:	'25%'						},
					{	field	:	'price',			header	:	'_PRICE', 		editable : true, 	width	:	'20%', editor : 'number'	},
					{	field	:	'price_per_km',		header	:	'_PRICE_KM',	editable : true, 	width	:	'20%', editor : 'number'	},
					{	field	:	'distance',			header	:	'km',			editable : false,	width	:	'15%'						}
				]
			},
			tableInfoArea	:	{
				cols	:	[
					{	field	:	'typeId',	 		header	:	'_TRANSPORT', 	type : 'typeTransport',		width	:	'35%', editable : false		},
					{	field	:	'price',			header	:	'_PRICE', 		editable : true, 			width	:	'25%', editor : 'number' 	},
					{	field	:	'price_per_km',		header	:	'_PRICE_KM',	editable : true, 			width	:	'25%', editor : 'number'	},
					{	field	:	'distance',			header	:	'km',			editable : false,			width	:	'15%'						}
				]
			},
			items : [
				{	label: this.commons.getTranslate('_EXPORT_ALL'), 			icon: 'pi pi-fw pi-file'	},
				{	label: this.commons.getTranslate('_EXPORT_CURRENT_TYPE'),	icon: 'pi pi-fw pi-file'	}
			]
		}
		await this.googleService.loadApi();
	}

	ngOnInit()			{}
	mapReady(map)		{	this.loadMapStations(map);
							if(this.fromWizard){document.querySelector("agm-map")["style"].height	=	"72vh";}
						}
	getColFields()		{	return ['destination','price.auto','price.manual','price_per_km.auto','price_per_km.manual','distance']};
	focusCellTable()	{	setTimeout(()=>{
								document.getElementById('cell'+'price'+this.pageInfo.currentListPricing['areas'][0]['destination']).focus();
								document.getElementById('cell'+'price'+this.pageInfo.currentListPricing['areas'][0]['destination']).click();
							},500);
						}

	getInfo($type)					{
		switch($type){
			case "areas"	: 	if(undefined==this.pageInfo.currentListPricing){ return []; }
									return this.pageInfo.currentListPricing.areas;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			case "stations"				:	return this.stations
													.filter(station=> station.items.length>0)
													.map(el => { return {	
														label : el.type, 
														value : el.type, 
														items : el.items.map(station => { return { label : station.name, value :station }})} 
													});		
			default						:	return;
		}
	}

	getInfoTransport(transportId)	{ return this.types.find(el => el.id == transportId) || {}}
	
	goToFleet(){
		if(this.fromWizard)	{	this.emitter.emit({action : 'goToFleet'});			}
		else				{	this.route.navigate(['/company_fleet'])				}
	}

	setActiveView(view){
		this.pageInfo.viewTable = view;
		switch(this.pageInfo.viewTable){
			case 'viewInfoArea'			:	let findPolySelected	=	this.pageInfo.polygonsDestination.findIndex(el =>el.selected);
											this.pageInfo.polygonsDestination.forEach(el => {el.selected = false; el.color = this.pageInfo.mapColors['default']})
											this.selectPolygon(this.pageInfo.polygonsDestination[findPolySelected  == -1 ? 0 : findPolySelected]);
											break;
			case 'viewInfoTransport'	:	break;
			default						:	break;
		}
	}

	loadListPrices(){
		let tmpAggregator	=	this.pageInfo.tmpAggregatorsPrices.find(el => el.aggregatorId == (this.pageInfo.currentAggregator.id || this.pageInfo.currentAggregator.providerId));
		if(this.fromWizard){
			this.pageInfo.listPrices	=	tmpAggregator
											?(tmpAggregator && tmpAggregator.listPrices) ? tmpAggregator.listPrices : []
											:this.pageInfo.currentAggregator.listPrices || [];			
		}
		else{
			this.pageInfo.listPrices	=	this.pageInfo.currentAggregator.listPrices || [];
		}	
	}

	updateTmpListPrices(){
		if(this.fromWizard){
			if(this.pageInfo.currentAggregator){
				let foundAggregator		=	this.pageInfo.tmpAggregatorsPrices.findIndex(el => el.aggregatorId == (this.pageInfo.currentAggregator.id || this.pageInfo.currentAggregator.providerId));
				foundAggregator == -1 	? 	this.pageInfo.tmpAggregatorsPrices.push({
												aggregatorId: ( this.pageInfo.currentAggregator.id || this.pageInfo.currentAggregator.aggregatorId ), 
												listPrices 	: this.pageInfo.listPrices && this.pageInfo.listPrices.length > 0 ? this.pageInfo.listPrices : []
											})
										:	this.pageInfo.tmpAggregatorsPrices[foundAggregator].listPrices = this.pageInfo.listPrices;	}
		}
		
			// this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.pageInfo.this.simplifyPrices()}});				
		console.log('LIST PRICES BY AGGREGATOR', this.pageInfo.tmpAggregatorsPrices);
		console.log('********************', this.table)
	}
	
	setUpAreas(){
		if(!this.areas){ return [];}
		let removedDuplicateds = this.removeDuplicatedAreas();
		this.pageInfo.areas					=	removedDuplicateds.sort((a,b)=> a.destination > b.destination ? 1 : -1);
		this.pageInfo.listAreas				=	this.pageInfo.areas.map(item => { return { label : item.destination, value : item.destination }});
		this.pageInfo.polygonsDestination	=	this.pageInfo.areas.map(item => { return { color : this.pageInfo.mapColors['default'], ...item }});	
	
	}

	getListAreas(){return (this.pageInfo.areas || []).map(item => { return {label : item.destination, value : item.destination}});}

	removeDuplicatedAreas(){
		return this.areas.map(area => {
			let occurrences			=	this.areas.filter(item => item.properties.name == area.properties.name);
			if(occurrences.length == 1){ return { destination : area.properties.name, coords : area.coords}}
			let areaMostPolygons	=	Math.max.apply(Math, occurrences.map(item => item.coords.length));
			let areaFullInfo		=	occurrences.find(item => item.coords.length == areaMostPolygons);
			return { destination : areaFullInfo.properties.name, coords : areaFullInfo.coords}
		}).filter((item,index,array)=>array.findIndex(t=>(t.destination === item.destination))===index);
	}

	toggleTypeVehicle(item){
		if(this.pageInfo.typeVehicleSelected && this.pageInfo.typeVehicleSelected.id == item.id){ return; } // avoid none selected
		this.commons.toggleItem(item, this.types, 'selected');
		this.pageInfo.typeVehicleSelected 			= item;

		this.mountTypeVehicleList(item);
		this.updateTmpListPrices();
	
		let listPricing	=	this.getCurrentListPricing();
		if(listPricing.every(item => item.price.auto == null)){
			this.focusCellTable();
		}
	}

	setTypeVehicleAsMain($event,item, firstTime?){
		$event ? $event.stopPropagation() : null;
		this.commons.toggleItem(item,this.types,'isMain');
		if(item.isMain){
			let foundType	=	this.pageInfo.listPrices.find(el => el.typeId == item.id);
			if(!foundType){
				this.pageInfo.listPrices.forEach(element => {
					element.isMain = false;
					element.stations.forEach(station => {
						station.ratio = null;
					})
				})
			}
			this.pageInfo.listPrices.forEach(element => element.isMain = element.typeId == item.id);
			this.generateRatios(true);
			this.updateTmpListPrices();

			if(firstTime){return;}
			this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.simplifyPrices()}});	

		}
	}

	onChangeStation($event)				{ this.mountTypeVehicleList(this.pageInfo.typeVehicleSelected)}

	onChangeTemplateDestination(rowData){
		this.pageInfo.currentListPricing.diggest.destination 	= 	rowData['destination'];
		this.pageInfo.currentListPricing.diggest.distance		= 	this.pageInfo.currentListPricing.areas.find(item => item.destination == this.pageInfo.currentListPricing.diggest.destination).distance;
		console.log(this.pageInfo.polygonsDestination);
		// this.pageInfo.polygonsDestination.find
	}

	getCurrentListPricing(){
		return this.pageInfo.currentListPricing && this.pageInfo.currentListPricing['areas']  ? this.pageInfo.currentListPricing['areas'] : [];
	}

	getInfoTransportFromArea(){
		let findSelected	=	this.pageInfo.polygonsDestination.find(el => el.selected);
		if(!findSelected){ return [];}
		return this.pageInfo.infoAreaTransports;
	}

	generateInfoAreaTransports(){
		//fer tot el typeId i matchear, no només listPrice
		this.pageInfo.infoAreaTransports = this.types.map(typeVehicle =>{
			let foundVehicle = this.pageInfo.listPrices.find(el => el.typeId == typeVehicle.id);
			if(foundVehicle){
				let defaultData		=	{typeId : foundVehicle.typedId, linked : true, price : {auto : null, manual : null}, price_per_km : {auto : null, manual : null}}
				let currentStation	=	foundVehicle.stations.find(el => el.station == this.pageInfo.stationSelected.name);
				if(!currentStation){ return defaultData};
				let findDestination	=	currentStation.areas.find(el => el.destination == this.pageInfo.polygonSelected.destination);
				if(!findDestination){ return defaultData};
				return {
					typeId 			:	foundVehicle.typeId,
					linked			:	findDestination.linked,
					distance		:	findDestination.distance,
					price			:	findDestination.price,
					price_per_km	:	findDestination.price_per_km		
				}
			}else{
				let distance = this.calculateDistanceStationArea();
				return {typeId : typeVehicle.id, linked : true, distance : distance, price : {auto : null, manual : null}, price_per_km : {auto : null, manual : null}}

			}
		})
	}

	calculateDistanceStationArea(){
		let centerArea	=	this.mapCtrl.get_polygon_centroid(this.pageInfo.polygonSelected.coords);
		return (this.mapCtrl.checkDistance(this.pageInfo.stationSelected.location,centerArea)/1000).toFixed(0);

	}

	/**
	 * Generate the structure of destinations from the selected vehicle or just assign the value to it
	 * @param item typeVehicle selected
	 */
	mountTypeVehicleList(item){
		let foundType		=	this.pageInfo.listPrices.find(typeVehicle => typeVehicle.typeId == item.id);
		if(foundType){
			let foundListPrices	=	foundType.stations.find(item => item.station == this.pageInfo.stationSelected.name);
			if(foundListPrices){
				this.pageInfo.currentListPricing	=	foundListPrices;	
			}else{
				foundType.stations.push({	station		: 	this.pageInfo.stationSelected.name, 
											ratio		: 	null,
											areas		:	this.mountDistancesInfo()});
				foundType.stations[foundType.stations.length-1].diggest	=	this.setDefaultDiggest(foundType.stations[foundType.stations.length-1].areas);
				this.pageInfo.currentListPricing	=	foundType.stations[foundType.stations.length-1];
				this.updateTmpListPrices();
			}
		}else{
			this.pageInfo.listPrices.push({
				typeId		:	item.id,
				stations	:	[{	
					station		:	this.pageInfo.stationSelected.name,
					areas	:	this.mountDistancesInfo(),
					ratio		:	null
				}]
			});
			this.pageInfo.listPrices[this.pageInfo.listPrices.length-1].stations[0].diggest	=	this.setDefaultDiggest(this.pageInfo.listPrices[this.pageInfo.listPrices.length-1].stations[0].areas);
			this.pageInfo.currentListPricing								=	this.pageInfo.listPrices[this.pageInfo.listPrices.length-1].stations[0];
			this.updateTmpListPrices();
		}
		this.pageInfo.destinationTemplate									=	this.pageInfo.currentListPricing.diggest.destination;

		console.log('LIST PRICES',this.pageInfo.listPrices);
	}
	
	setDefaultDiggest(areas){
		let templateDest	=	areas.find(area => area.destination == this.pageInfo.destinationTemplate);
		return {
			destination		:	templateDest ? templateDest.destination 	: areas[0].destination,
			distance		:	templateDest ? templateDest.distance 		: areas[0].distance,
			price_per_km	:	{	auto	:	templateDest ? templateDest.price_per_km.auto	: areas[0].price_per_km.auto, 
									manual	:	templateDest ? templateDest.price_per_km.manual	: areas[0].price_per_km.manual
								},
			price			:	{	auto	:	templateDest ? templateDest.price.auto	: areas[0].price.auto, 
									manual	:	templateDest ? templateDest.price.manual: areas[0].price.manual
								}
		}
		
	}
	/**
	 * Mount the object of 'listPrice'
	 */
	mountDistancesInfo(){
		return this.pageInfo.areas.map(infoArea => {
			let centerPoint	=	this.mapCtrl.get_polygon_centroid(infoArea.coords);
			return {	origin				:	this.pageInfo.stationSelected.name, 
						coordsOrigin		:	this.pageInfo.stationSelected.location,	
						destination			:	infoArea.destination,
						coords				:	infoArea.coords,
						coordsDestination	:	centerPoint,	
						linked				:	true,
						price				:	{auto : null, manual : null},
						price_per_km		:	{auto : null, manual : null},
						distance			:	(this.mapCtrl.checkDistance(this.pageInfo.stationSelected.location,centerPoint)/1000).toFixed(0)
					}			
		})
	}
	loadMapStations(map?){
		let centerArea			=	this.pageInfo.areas[Math.ceil(this.pageInfo.areas.length / 2)];
		let centerCoords		=	centerArea.coords[Math.ceil(centerArea.coords.length / 2)];
		let centerCoordsBounds	=	this.googleService.getCenterPolygons(this.pageInfo.areas.map(el => el.coords).flat());
		this.pageInfo.mapConfig = {
			lat		: centerCoords.lat,
			lng		: centerCoords.lng,
			zoom	: 9,
			markerConfig	:	{
				url			:	null,
				scaledSize	: {
					width: 32,
					height: 32
				}
			}
		}
		map ? map.fitBounds(centerCoordsBounds.bounds) : null;
	}

	formatTypeVehicle(vehicle){
		if(!vehicle){ return; }
		return String(vehicle).toLowerCase();
	}

	generateListPrices(){
		//Update all prices from current typeVehicle
		this.pageInfo.currentListPricing.areas.forEach(area =>{
			let autoPrice		=	Math.round(+(area.distance * this.pageInfo.currentListPricing.diggest.price_per_km.auto).toFixed(2));
			let manualValue		=	Math.round(+(area.price.auto == area.price.manual && area.linked===true) ? autoPrice : area.price.manual); 
			area.price = {
				manual	:	manualValue,
				auto	:	autoPrice
			};
			area.price_per_km	=	{
				manual	:	(+(area.price.manual / area.distance).toFixed(2)),
				auto	:	(+(area.price.auto / area.distance).toFixed(2))
			}

		});

		this.generateRatios()
		// this.setRatio();
		console.log(this.pageInfo.currentListPricing.areas);
		this.updateTmpListPrices();
	}
	
	alertUnableClickTransport(){
		return this.commons.generateToast('_INFO','_CHANGE_VIEW_TO_SEE_INFO_TRANSPORT', 'info');
	}

	/**
	 * Return all the destinations from the vehicle main and stationSelected
	 */
	getMainRatio(){
		let mainType		=	this.types.find(item => item.isMain);
		if(!mainType)		{	this.pageInfo.currentListPricing.ratio = '-';	return;} 
		let foundRatioMain	=	this.pageInfo.listPrices.find(item => item.typeId == mainType.id);
		if(!foundRatioMain)	{	this.pageInfo.currentListPricing.ratio = '-';	return;}
		let stationRatio	=	foundRatioMain.stations.find(item => item.station == this.pageInfo.stationSelected.name);
		// return	stationRatio.areas.find(area => area.destination == this.pageInfo.currentListPricing.diggest.destination).price.auto;
		return stationRatio.areas;
	}

	getRatioByType(type){
		if(!this.pageInfo.stationSelected){ return '-'}
		let foundType	=	this.pageInfo.listPrices.find(item => item.typeId == type.id);
		if(!foundType){ return '-'}
		let foundStation	=	foundType.stations.find(item => item.station == this.pageInfo.stationSelected.name);
		return !foundStation || !foundStation.ratio ? '-' : 'x'+foundStation.ratio;
	}

	generateRatios(fromChangeMain?){
		let foundMain	=	this.pageInfo.listPrices.find(item => item.isMain);
		if(!foundMain){
			this.pageInfo.listPrices.forEach(item =>{
				let foundItemStation = item.stations.find(el => el.station == this.pageInfo.stationSelected.name);
				if(!foundItemStation){ return; }
				foundItemStation.areas.forEach(area => area.ratio = null);
			});
			// return;
		}else{
			let mainItemStation = foundMain.stations.find(el => el.station == this.pageInfo.stationSelected.name);
			if(mainItemStation){
				mainItemStation.ratio = 1;
			}
		}
		
		let allWithRatio	=	this.pageInfo.listPrices.filter(el => el.typeId != this.pageInfo.typeVehicleSelected.id)
														.every(item => {
															let foundItemStation = item.stations.find(el => el.station == this.pageInfo.stationSelected.name);
															if(!foundItemStation){ return false}
															return foundItemStation.ratio != null;
														});
		
		//If condition, dont update ratios, just prices
		if(this.pageInfo.workWithRatio && this.pageInfo.typeVehicleSelected.isMain && allWithRatio && !fromChangeMain){return;} // avoid to update ratios because it has to be updated with the ratio it has
		
		//update all ratios
		this.pageInfo.listPrices.forEach(item =>{
			let foundItemStation = item.stations.find(el => el.station == this.pageInfo.stationSelected.name);
			if(!foundItemStation){ return;}
			let listPricesMain		=	(this.getMainRatio() || []).filter(el => el.linked === true);
			let match : any = {};
			listPricesMain.find(destMain => {
				let foundMatch = foundItemStation.areas.find(el => el.destination == destMain.destination && el.linked == true)
				if(foundMatch){ 
					match.priceDestMain = destMain.price.auto;
					match.priceDest		= foundMatch.price.auto;
					return true;
				}
			})
			if(match['priceDestMain'] && match['priceDest']){
				foundItemStation.ratio	=	+(match.priceDest / +match.priceDestMain).toFixed(1);
				this.updateTmpListPrices();
			}else{
				foundItemStation.ratio = null;
			}
		})
	}

	onFocusField($event,value,rowData){
		this.pageInfo.tmpFocusedField	=	JSON.parse(JSON.stringify(rowData[value.field]));	
		console.log('TMP SAVED', this.pageInfo.tmpFocusedField);
	}

	onBlurField($event,$value, col,rowData){
		console.log($event);
		console.log($value);
		console.log(col);
		console.log(rowData);

	}

	checkInputDiggest($event){
		if($event.keyCode == 27)						{	return;						}
		if($event.keyCode == 9 || $event.keyCode == 13)	{	this.generateListPrices();	}
	}

	checkTableField($event,col,rowData){
		
		if($event.keyCode == 27){ // ESC code
			rowData[col.field]				=	this.pageInfo.tmpFocusedField;
			this.pageInfo.tmpFocusedField	=	null;
			rowData.selected				=	false;
			let currentPolygon				=	this.pageInfo.polygonsDestination.find(el => el.selected);
			this.selectPolygon(currentPolygon);
			return;
		}
		
		if($event.keyCode ==9 || $event.keyCode==13 ){
			let validate	=	this.validateNumber(rowData[col.field][rowData.linked ? 'auto' : 'manual']);
			if(!validate.success){ 
				this.commons.generateToast('_ERROR',validate.msg,'error');
				rowData[col.field]				=	this.pageInfo.tmpFocusedField;
				this.pageInfo.tmpFocusedField	=	null;
				return;
			}
	
			this.pageInfo.tmpFocusedField	=	null;
			this.updateRowData(col,rowData);
			if(this.pageInfo.workWithRatio && this.pageInfo.typeVehicleSelected.isMain){ //If is linked and the current transport is the main one, update all auto prices
				let mainDestinations	=	this.getMainRatio() || [];
				this.pageInfo.listPrices.forEach(item =>{
					let foundItemStation = item.stations.find(el => el.station == this.pageInfo.stationSelected.name);
					if(!foundItemStation){ return; }
					foundItemStation.areas.forEach(area => {
						area.price.auto 		=	Math.round(+(mainDestinations.find(el => el.destination == area.destination).price.auto * foundItemStation.ratio));
						area.price_per_km.auto	=	(+mainDestinations.find(el => el.destination == area.destination).price_per_km.auto * foundItemStation.ratio).toFixed(2);
					});
				})
			};
			this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.simplifyPrices()}});	
		}
	}

	toggleAllRows(){
		this.pageInfo.linkedAllRows = !this.pageInfo.linkedAllRows;	
		
		switch(this.pageInfo.viewTable){
			case 'viewInfoArea'			:	this.getInfoTransportFromArea().forEach(el => el.linked = this.pageInfo.linkedAllRows);	break;
			case 'viewInfoTransport'	:	this.getCurrentListPricing().forEach(el => el.linked = this.pageInfo.linkedAllRows);  	break;
			default						:	return;
		}
	}

	/**
	 * Update the info from the rowData depending on the modified field. Then set the rowData as main and if it is linked update the whole table
	 * @param rowData RowData from table
	 */
	updateRowData(col,rowData){
		if(col.field == 'price'){
			rowData.linked	?	rowData.price.manual	=	rowData.price.auto 
							:	rowData.price.auto		=	rowData.price.manual;
			rowData.price_per_km	=	{	auto 	: 	(+(rowData.price.auto / rowData.distance)),
											manual	:	(+(rowData.price.manual / rowData.distance))
				}
		}else{ // price_per_km
			rowData.linked	?	rowData.price_per_km.manual	=	rowData.price_per_km.auto
							:	rowData.price_per_km.auto	=	rowData.price_per_km.manual;
			rowData.price	=	{	auto	:	(+(rowData.price_per_km.auto * rowData.distance)),
									manual	:	(+(rowData.price_per_km.manual * rowData.distance))}
		}

		if(rowData.linked){
			this.pageInfo.currentListPricing.diggest	=	{
				price 			:	rowData.price,
				price_per_km	:	rowData.price_per_km,
				distance		:	rowData.distance,
				destination		:	rowData.destination,
			}
			this.generateListPrices();
		}
		// this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.simplifyPrices()}});
	}

	removeManuallyValue(rowData){
		delete rowData['manuallyIntroduced'];
		this.generateListPrices();
	}

	validateNumber(number){
		let result : any	=	{success : true}
		switch(true){
			case Number.isNaN(number)	:	result.msg = '_NOT_A_NUMBER'; result.success 	= 	false; break;
			case 0>=number				:	result.msg = '_INVALID_AMOUNT'; result.success	=	false; break;
			default						:	break;
		}
		return result;
	}

	selectPolygon(polygon){
		this.commons.toggleItem(polygon, this.pageInfo.polygonsDestination,'selected');
		this.pageInfo.polygonsDestination.forEach(item => item['color'] = this.pageInfo.mapColors['default']);
		polygon.color = polygon.selected ? this.pageInfo.mapColors['selected'] : this.pageInfo.mapColors['default'];
		this.pageInfo.polygonSelected	=	polygon.selected ? polygon : null;
		if(this.pageInfo.polygonSelected && this.pageInfo.viewTable == 'viewInfoArea'){
			this.generateInfoAreaTransports();
		}
	}

	checkIfDestSelected(rowData){
		if(!this.pageInfo.polygonSelected){ return false; }
		return rowData.destination	== this.pageInfo.polygonSelected.destination;
	}

	uploadPrices()	{	alert("Upload");	}
	downloadPrices(){	alert("Download");	}
	savePrices()	{	this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.simplifyPrices()}});	}
	
	deletePrices()	{	
						console.log(this.listPricesTable);
						let foundType	=	this.pageInfo.listPrices.findIndex(item => item.typeId == (this.types.find(el => el.selected) || {}).id);
						if(foundType == -1)	{	return this.commons.generateToast('_ERROR','_ERROR','error')};
						this.pageInfo.listPrices.splice(foundType,1);
						this.mountTypeVehicleList(this.pageInfo.typeVehicleSelected);
						this.updateTmpListPrices();
						this.emitter.emit({action : 'savePrices', data : { aggregator : this.pageInfo.currentAggregator, listPrices : this.simplifyPrices()}});
					}

	/**
	 * Simplify the data that is want to be stored
	 */				
	simplifyPrices(){
		let simplifiedListPrices	=	JSON.parse(JSON.stringify(this.pageInfo.listPrices));
		simplifiedListPrices.forEach(item => item.stations.forEach(station => station.areas.forEach(area => delete area.coords)));
		return simplifiedListPrices;
	}

	/**
	 * With the col selected, search into polygons the rowData and paint it to indicate which area is being modified
	 * @param col col clicked
	 * @param rowData rowData info
	 */
	onClickCol(col,rowData){
		if(!col.editable){
			let polygonSelected =  this.pageInfo.polygonsDestination.find(el => el.destination == rowData.destination);
			this.selectPolygon(polygonSelected);
			return;
		}
		switch(this.pageInfo.viewTable){
			case 'viewInfoArea'			:	let typeVehicle	=	this.types.find(el => el.id == rowData.typeId);
											this.toggleTypeVehicle(typeVehicle);
											return;
			case 'viewInfoTransport'	:	let currentPrices	=	this.getCurrentListPricing();
											currentPrices.forEach(prices => prices.selected = false);
											rowData.selected = true;
											let polygonSelected =  this.pageInfo.polygonsDestination.find(el => el.destination == rowData.destination);
											this.selectPolygon(polygonSelected);
											// this.pageInfo.polygonsDestination.forEach(polygon => polygon.color = this.pageInfo.mapColors['default']);
											// this.pageInfo.polygonsDestination.find(el => el.destination == rowData.destination).color = this.pageInfo.mapColors['selected'];	
											return;
			}
		
	}


	getCountByType(typeVehicle){
		if(!typeVehicle || !this.fleet){ return 0};
		let findedTypeTransport	= this.fleet.find(type => type.typeId == typeVehicle.id);
		if(!findedTypeTransport){return 0};
		return findedTypeTransport.qty || 0;
	}
}
