import { EntityService } from 'src/app/demo/service/entity.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { Component, OnInit, ViewEncapsulation, NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { airports							} from './data/data'
import { fullAreasMallorca					} from './data/data'
import { ToastrService                          } from 'ngx-toastr';
import { MapsAPILoader 							} from '@agm/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
    styleUrls		: ['./generatorpricing.component.scss'],
	templateUrl		: './generatorpricing.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class GeneratorPricingComponent implements OnInit{

	pageInfo			:	any		= {
		pricePerKm				:	0.01,
		listElements			:	[],
		originAreaSelected		:	null,
		destinationAreaSelected	:	null,
		markers					:	[],
		currentFleet			:	[],
		airportIcon : {
			url: 'https://cdn0.iconfinder.com/data/icons/airplane-safety/512/xxx042-2-512.png',
			scaledSize: {
			  width: 40,
			  height: 48
			}
}
		};
	zoom                :   number  = 10;
    lat                 :   number  = 39.518464;
	lng                 :   number  = 3.003831 ;

	listAreas		: 	any 	= {};
	mapAreas		:	any		= {};
	tablePricing	:	any		= {};
	fleet			:	any		= {};
	@ViewChild('destinationDropDown') 	destinationDropDown 	: any;
	@ViewChild('originDropDown') 		originDropdown			: any;
    constructor(private storage	:	StorageService, private entityService	:	EntityService){

    }
    async ngOnInit(){
		this.initTables();
		await this.load('mapAreas');  
		await this.load('listAreas');
		await this.load('fleet');
		// this.init();
	}

	initTables(){
		this.listAreas.columns = [
			// { field: 'zone_origin',			header: 'Zone Origin',		width: '200px', 	align: "center",    editor : null,  options : null,	mandatory : true 	},
            { field: 'area_origin_name',		header: 'Area Origin', 		width: '100px',		align: "center",    editor : null,  options : null,	mandatory : false 	},
            // { field: 'zone_destination',	header: 'Zone Destination', width: '75px',     	align: "center",    editor : null,  options : null,	mandatory : false 	},
            { field: 'area_destination_name',	header: 'Area Destination',	width: '300px', 	align: "center",    editor : null, 	options : null,	mandatory : false 	},
			{ field: 'duration_text',  			header: 'Time',  			width: '150px', 	align: "center",    editor : null,  options : null,	mandatory : false	},
			{ field: 'distance_text',    		header: 'Distance',  	    width: '250px', 	align: "center",    editor : null,  options : null,	mandatory : true 	}           
		];      
		
		this.fleet.columns	=	[
			{field	:	'name', header: 'Vehicle'}
		];

		this.tablePricing.columns = [
			{ field	:	'type_transport', 			header: 'Vehicle', 			editable:false},
			{ field :	'unitary_value', 			header: 'Value', 			editable:false},
			{ field :	'original_price', 			header:	'Original Price', 	editable:false},
			{ field :	'custom_price',				header: 'Custom Price',  	editable:true,	editor : 'input', options : {type : 'text'}}
		]
	}

	getListAreas(){
		this.pageInfo.zones				=	this.storage.getItem('zones') || [];
		this.pageInfo.listElements		=	airports;
		let areas						=	{label : 'Areas', items	: this.mapAreas.data.map(el => { return {label : el.name, value	:	{type : 'area', ...el}}})};
		this.pageInfo.listElements.push(areas);
		
		this.pageInfo.fullCombinedAreas	=	this.storage.getItem('calculedAreas') || fullAreasMallorca;
		console.log('FULL AREAS', this.pageInfo.fullCombinedAreas);
		this.pageInfo.fullCombinedAreas.forEach((element : any) => { { 	element.origin.zone 		= this.findZoneAssigned(element.origin.id); 
																		element.destination.zone 	= this.findZoneAssigned(element.destination.id)}});
		
		this.pageInfo.fullCombinedAreas	=	this.pageInfo.fullCombinedAreas.filter(area => (area.origin.name == 'PMI Airport' || area.destination.name == 'PMI Airport'));
		this.pageInfo.fullCombinedAreas =	this.pageInfo.fullCombinedAreas.map(area => {return {	area_origin_name		:	area.destination.name,
																								 	area_origin_id			:	area.destination.id,
																								 	area_destination_name	:	area.origin.name,
																								 	area_destination_id		:	area.origin.id,
																									duration_text			:	area.data.duration.text,
																									distance_value			:	area.data.duration.value,
																									distance_text			:	area.data.distance.text,
																									duration_value			:	area.data.distance.value,
																									tablePrices				:	[],
																									fleet					:	null,
																									composed_id				:	""+area.destination.id+area.origin.id}})
		console.log(this.pageInfo.fullCombinedAreas);
		return this.pageInfo.fullCombinedAreas;
	}

	async load($entity){
		console.log('GOING TO CHECK', $entity);
		switch($entity){
			case 'listAreas':
				this[$entity].data = [];
				this[$entity].data = this.getListAreas();
				console.log(this[$entity].data);
			break;
			case 'mapAreas':
				await this.entityService.loadEntity('areasmallorca');
				this[$entity].data = this.entityService.get('areasmallorca');
				console.log(this[$entity].data);
				console.log('puting blue')
				this[$entity].data.map(el => el.color = '#6495ED' );
			break;
			case 'fleet':
				this[$entity].data 		= [];
				// Load data
                await this.entityService.loadEntity($entity,);
                this[$entity].data 	= this.entityService.get($entity);
                console.log('VEHICLES LOAD', this[$entity].data);
            break;
				
		};
		this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
        this.paginate($entity, null);
	}
	
	
    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
			);
		console.log(this[$type].paginate);
    }

	onSort() {
        // this.updateRowGroupMetaData();
    }
	findZoneAssigned(nameZone){
		let findZoneAssigned	=	this.pageInfo.zones.find(zone => zone.areas.find(area => area.id == nameZone));
		return (findZoneAssigned || {}).name || '_NO_ZONE';
	}
	
	getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {

        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
            case 'content'	: return $items[$col.field];
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
	}

	//Methods from the main table. The user can check multiple rows at the same time, as difference from the view map. 
	selectRowMainTable($event){

	}
	getCurrentCombination(rowData, property){
		if(!rowData) return [];	
		let findCombination = this.pageInfo.fullCombinedAreas.find(combination => combination.composed_id == rowData.composed_id);
		if(findCombination){
			// findCombination.fleet = findCombination.fleet || this.fleet.data.map(el => {return {activated : false,...el}}); 
			// return findCombination.fleet;
			switch(property){
				case 'fleet':
					if(findCombination.fleet != null){
						return findCombination.fleet;
					}else{
						findCombination.fleet = JSON.parse(JSON.stringify(this.fleet.data));
						findCombination.fleet.forEach(el => el.activated = false);
						return findCombination.fleet;	
					}
				case 'tablePrices':
					if(!findCombination.fleet) return;
					if(findCombination.tablePrices  && (Array.isArray(findCombination.tablePrices) && findCombination.tablePrices.length != 0)){
						return findCombination.tablePrices;
					}else{
						findCombination.tablePrices = [];
						findCombination.fleet.filter(transport => transport.activated).forEach(transport =>{
							findCombination.tablePrices.push({
								type_transport		:	transport.name,
								type_transport_id	:	transport.id,
								unitary_value		:	1,
								original_price		:	(rowData.distance_value * this.pageInfo.pricePerKm) * 1 ,
								custom_price		:	(rowData.distance_value * this.pageInfo.pricePerKm) * 1,	
							});
						})
						return findCombination.tablePrices;
					}
			}
			
		}else{
			return [];
		}
	}
	getCurrentCombinationSelected(rowData){
		if(!rowData) 				return [];	

		let findCombination = this.pageInfo.fullCombinedAreas.find(combination => combination.composed_id == rowData.composed_id);
		if(!findCombination) 		return [];
		if(!findCombination.fleet) 	return [];

		return findCombination.fleet.filter(el => el.activated);
			
	}
	toggleTransportOnMainTable($event,rowData,action){
		let findedTransport = rowData.fleet.find(transport => transport.id == $event.data.id);
		if(findedTransport){
			findedTransport.activated = action == 'activate' ? true : false;
			findedTransport.activated 	? this.addTransportMainTablePrice(rowData, findedTransport) 
										: this.removeTransportMainTablePrice(rowData, findedTransport);
		}
		console.log(rowData.tablePrices);

		// findedTransport.tablePrices	=	[];
		// console.log(findedTransport);
	}

	addTransportMainTablePrice(rowData, transport){
		let transportFinded = rowData.tablePrices.find(el => el.type_transport_id == transport.id);
		if(transportFinded) return;
		rowData.tablePrices.push({
			type_transport		:	transport.name,
			type_transport_id	:	transport.id,
			unitary_value		:	1,
			original_price		:	(rowData.distance_value * this.pageInfo.pricePerKm) * 1 ,
			custom_price		:	(rowData.distance_value * this.pageInfo.pricePerKm) * 1,
		});
		
		this.updateTransportsTableMapView(rowData);
	}
	removeTransportMainTablePrice(rowData, transport){
		let findedElement = rowData.tablePrices.findIndex(rowPrice => rowPrice.type_transport_id == transport.id);
		if(findedElement > -1){
			rowData.tablePrices.splice(findedElement,1);
		}
		this.updateTransportsTableMapView(rowData);
	}

	toggleFullTransportMainTable($event,rowData){
		rowData.fleet.forEach(transport => {
								transport.activated = $event.checked;
								transport.activated ? this.addTransportMainTablePrice(rowData,transport) 
													: this.removeTransportMainTablePrice(rowData, transport)});
		
		this.updateTransportsTableMapView(rowData)	
	}

	updateTransportsTableMapView(rowData){
		if(this.pageInfo.concreteCombination && (this.pageInfo.concreteCombination.composed_id == rowData.composed_id)){
			this.pageInfo.currentFleet = rowData.fleet.filter(el => el.activated);
		}
	}
	
	getCurrentCombinationTablePrice(rowData){

	}



	onClickDropdown($event, element){
		if(element == 'origin'){ // Check if the value comes from the dropdown
			if( this.pageInfo.originAreaSelected && this.pageInfo.originAreaSelected.type == 'airport') { this.removeMarkers(); }
			
			this.restartArea(this.pageInfo.originAreaSelected);
			this.pageInfo.originAreaSelected			=	$event.value;
		}else{
			if( this.pageInfo.destinationAreaSelected && this.pageInfo.destinationAreaSelected.type == 'airport') { this.removeMarkers(); }
			
			this.restartArea(this.pageInfo.destinationAreaSelected);
			this.pageInfo.destinationAreaSelected		=	$event.value;
		}

		if($event.value.type == 'airport'){ // Check if the has to paint a marker or a polygon
			this.addMarkerToMap($event.value);
		}else{
			this.toggleArea($event.value, element);
		}
	}

	addMarkerToMap(marker){
		console.log(marker);
		this.pageInfo.markers.push(marker.coords);
		if(this.pageInfo.originAreaSelected && this.pageInfo.destinationAreaSelected){
			this.initFleet();
		}
	}
	removeMarkers(){
		this.pageInfo.markers = [];
	}
	toggleArea(area, type?){
		
		if(type){
			this.markArea(area);
		}else{ // if both selected. Always change destination. If none selected, always change origin
			if(this.pageInfo.originAreaSelected || (this.pageInfo.originAreaSelected && this.pageInfo.destinationAreaSelected)){
				if(this.pageInfo.destinationAreaSelected){
					this.restartArea(this.pageInfo.destinationAreaSelected);
					this.pageInfo.destinationAreaSelected 		= this.pageInfo.destinationAreaSelected.id == area.id ? null : area;
					this.destinationDropDown.selectedOption		= this.assignCurrentValueToDropDown('destination'); 
					if(this.pageInfo.destinationAreaSelected != null){ this.markArea(area)};
				}else{
					this.pageInfo.destinationAreaSelected		= area;
					this.destinationDropDown.selectedOption		= this.assignCurrentValueToDropDown('destination');
					this.markArea(area);
				}
				
			}else{ // If origin has some value, update it. Else, add it. 
				if(this.pageInfo.originAreaSelected){
					this.restartArea(this.pageInfo.originAreaSelected);
					this.pageInfo.originAreaSelected 			= this.pageInfo.originAreaSelected.id == area.id ? null : area;
					this.originDropdown.selectedOption			= this.assignCurrentValueToDropDown('origin'); 
					if(this.pageInfo.originAreaSelected != null){ this.markArea(area)};
				}else{
					this.pageInfo.originAreaSelected = area;
					this.originDropdown.selectedOption			= this.assignCurrentValueToDropDown('origin');;
					this.markArea(area);
				}
				
			}
		}
		if(this.pageInfo.originAreaSelected && this.pageInfo.destinationAreaSelected){
			this.initFleet();
		}
	}

	initFleet(){
		this.pageInfo.concreteCombination	=	this.pageInfo.fullCombinedAreas.find(combination => {
			if((this.pageInfo.originAreaSelected.id	== combination.area_origin_id ) || (this.pageInfo.destinationAreaSelected.id == combination.area_origin_id)){
				if((this.pageInfo.destinationAreaSelected.id == combination.area_destination_id) || (this.pageInfo.originAreaSelected.id == combination.area_destination_id)){
					return true;
				}
			}});
			if(!this.pageInfo.concreteCombination) return;
			this.pageInfo.concreteCombination.fleet			=	this.pageInfo.concreteCombination.fleet || this.fleet.data.map(el => {return {activated : false,...el}});
			this.pageInfo.testInfo							=	this.pageInfo.concreteCombination.fleet.map(el => el.activated);		
			this.pageInfo.currentFleet						=	this.pageInfo.concreteCombination.fleet.filter(el => el.activated);
			// this.pageInfo.concreteCombination.tablePrices	=	null;
			if(!this.pageInfo.concreteCombination.tablePrices) { this.initTablePrices(); } 
			
	}

	toggleTransport($event, type){
		const findTransport	=	this.pageInfo.concreteCombination.fleet.find(transport => transport.id == $event.data.id);
		if(findTransport){
			findTransport.activated = type == 'activate' ? true : false;
			type == 'activate' ? this.addTransportTablePrices(findTransport) : this.removeTransportTablePrices(findTransport.id);
		}
		this.pageInfo.currentFleet	=	this.pageInfo.concreteCombination.fleet.filter(el => el.activated);
	}

	toggleFullTransport($event){
		this.pageInfo.concreteCombination.fleet.map(transport => transport.activated = $event.checked);
		this.pageInfo.currentFleet						=	this.pageInfo.concreteCombination.fleet;
		$event.checked ?  this.initTablePrices() : this.removeAllTransportsTablePrices(); 
	}

	assignCurrentValueToDropDown(type){

		let mode	=	type == 'origin' ? this.pageInfo.originAreaSelected : this.pageInfo.destinationAreaSelected;
		return this.pageInfo.listElements	.find(el => el.label == 'Areas' ).items
											.find(el => el.label == (mode || {}).name);
	}

	restartArea(area){
		if(area == null || area.type == 'airport') return;
		this.mapAreas.data.find(el => el.id == area.id).color 	=	'#6495ED';
	}
	markArea(area){
		if(area == null) return;
		this.mapAreas.data.find(el => el.id == area.id).color	=	'red';
	}


	/************ METHODS FROM TABLE PRICES ************/
	initTablePrices(){
		const activatedTransports	=	this.pageInfo.concreteCombination.fleet.filter(transport => transport.activated);
		const tablePrices		=	activatedTransports.map(transport => { return {
			type_transport		:	transport.name,
			type_transport_id	:	transport.id,
			unitary_value		:	1,
			original_price		:	(this.pageInfo.concreteCombination.distance_value * this.pageInfo.pricePerKm) * 1 ,
			custom_price		:	(this.pageInfo.concreteCombination.distance_value * this.pageInfo.pricePerKm) * 1,			
		}});
		console.log(tablePrices);
		this.pageInfo.concreteCombination.tablePrices = tablePrices;
	}

	removeTransportTablePrices(transportId){
		this.pageInfo.concreteCombination.tablePrices = this.pageInfo.concreteCombination.tablePrices.filter(dataRow => dataRow.type_transport_id != transportId);
	}
	removeAllTransportsTablePrices(){
		this.pageInfo.concreteCombination.tablePrices = [];
	}
	addTransportTablePrices(transport){
		this.pageInfo.concreteCombination.tablePrices.push({
			type_transport		:	transport.name,
			type_transport_id	:	transport.id,
			unitary_value		:	1,
			original_price		:	(this.pageInfo.concreteCombination.distance_value * this.pageInfo.pricePerKm) * 1 ,
			custom_price		:	(this.pageInfo.concreteCombination.distance_value * this.pageInfo.pricePerKm) * 1,
		})
	}
}