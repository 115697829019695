import { Component, 
		Input, 
		Output, 
		EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';

@Component({
	selector    	: 'app-stepper',
	styleUrls		: ['./app-stepper.component.scss'],
	templateUrl 	: 'app-stepper.component.html'  
})
export class AppStepperComponent {

	@Input() steps	:	any  			= [];
	@Output()			emitter			= new EventEmitter<any>();	
	pageInfo		:	any  = {
		colors	:	{
			border	:	{
				active	:	'crimson',
				past	:	'gray',
				pending	:	'#4ebfd0'
			},
			background	:	{
				active	:	'backgroundActive',
				past	:	'backgroundPast',
				pending	:	'backgroundPending'
			}
			
		}
	}

    constructor( public commons: CommonsService ){ this.init(); }

	init(){}
	
	getColorStep(step,type){
		if(step.active)		{ 	return this.pageInfo.colors[type].active; }
		let currentActive	=	this.steps.find(el => el.active);
		if(!currentActive)	{	return this.pageInfo.colors[type].pending	};
		
		return step.id > currentActive.id ? this.pageInfo.colors[type].pending : this.pageInfo.colors[type].past;	
	}

	clickStep(step){
		this.emitter.emit({ step : step});
	}
}