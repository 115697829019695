import { CompanyService } from './../database/company.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { CommonsService } from './../commons.service';
import { FirebaseService } from './../database/firebase.service';
import { AuthFirebaseService } from './../database/authfirebase.service';
import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate{


	constructor(	private router		: Router,
					private firebaseCtrl: FirebaseService,
					private commons		: CommonsService, 
					private companyCtrl	: CompanyService,
					private storageCtrl	: StorageService) {
}


   async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<any> {

		return true;

		const currentUser		=	( this.storageCtrl.getItem('dmcSuite') || {} ).userInfo;
		
		if(!currentUser || !currentUser.profile) return this.commons.generateToast('_NO_PERMISSION', '_NO_PERMISSION_GRANT','error');
		const findedProfile		=	this.commons.getProfileInfo(currentUser.profile);
		
		if(!findedProfile){ return false };

		if(findedProfile.activators.length == 0){ return true} // is admin or do not need any validation
		
		let checkAllValidators	=	true;
		for await (let activator of findedProfile.activators){
			switch(activator){
				default					:	return false;
				case	'permission'	:	const roles				=	await this.firebaseCtrl.getRolesFromProfile(currentUser.profile);
											if(this.hasPermission(route.routeConfig.path,roles) == false){
												checkAllValidators = false;
											};
											break;
				case	'modules'		:	const modulesCompany	=	await this.companyCtrl.getModulesFromCompany(currentUser.idDmc);
											if(this.hasModule(route.routeConfig.path,modulesCompany) == false){
												checkAllValidators = false;
											}
											break;
			}
		};
		if(!checkAllValidators){this.commons.generateToast('_NO_PERMISSION', '_NO_PERMISSION_GRANT','error');return false; }
		return true;	
	}  
	
	hasPermission(path,roles){
		return true;
		
		let pathsWithData	=	this.router.config.filter(element => element.data);
		if(pathsWithData.length == 0){ return true; }
		let findPath		=	pathsWithData.find(route => route.path == path);
		if(!findPath || !findPath.data.roles) {return false;}
		return findPath.data.roles.some(grantedRol => roles.findIndex(role => role == grantedRol) > -1)
	}
	
	hasModule(path,module){

		if(!module){ return true;}	

		return true // check if user has modules hired 
	}
}