import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class MapService{

    colors: string[] = [    "#e1d276",
                            "#006699",
                            "#009933",
                            "purple",
                            "#0066ff",
                            "#ff9900",
                            "#9900ff",
                            "blue"
    ];

    constructor(private http: HttpClient) {}

    decideColorPolygon(index){
        // let randomNumber = Math.floor(Math.random() * 7)+1;
        // return randomNumber > this.colors.length ? this.colors[2] : this.colors[randomNumber];
        return this.colors[index] || this.generateRandomColor();
    }
    
    getColor(i : number){
        return this.colors[i] || 'black';
    }

    generateRandomColor(){
        return "#"+((1<<24)*Math.random()|0).toString(16);
    }

    get_polygon_centroid(pts) {
        if(pts.length == 0) return {lng:0, lat:0};
        var first = pts[0], last = pts[pts.length-1];
        if (first.lng != last.lng || first.lat != last.lat) pts.push(first);
        var twicearea=0,
        x=0, y=0,
        nPts = pts.length,
        p1, p2, f;
        for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
           p1 = pts[i]; p2 = pts[j];
           f = (p1.lat - first.lat) * (p2.lng - first.lng) - (p2.lat - first.lat) * (p1.lng - first.lng);
           twicearea += f;
           x += (p1.lng + p2.lng - 2 * first.lng) * f;
           y += (p1.lat + p2.lat - 2 * first.lat) * f;
        }
        f = twicearea * 3;
        return { lng:x/f + first.lng, lat:y/f + first.lat };
     }

     isPointInsidePolygon(latLngs, lat, lng){
        let vertices_y		= new Array();
        let vertices_x 		= new Array();
        let longitude_x 	= lng;
        let latitude_y 		= lat;
        // latLngs 			= JSON.parse(latLngs);
        var r 				= 0;
        var i 				= 0;
        var j 				= 0;
        let c :any 			= 0;
        var point 			= 0;

        for(r=0; r<latLngs.length; r++){
        	vertices_y.push(latLngs[r].lat);
        	vertices_x.push(latLngs[r].lng);
        }
        let points_polygon = vertices_x.length;
		
		for(i = 0, j = points_polygon; i < points_polygon; j = i++){
        	point = i;
        	if(point == points_polygon)
            	point = 0;
        	if ( ((vertices_y[point]  >  latitude_y != (vertices_y[j] > latitude_y)) && (longitude_x < (vertices_x[j] - vertices_x[point]) * (latitude_y - vertices_y[point]) / (vertices_y[j] - vertices_y[point]) + vertices_x[point]) ) )
            	c = !c;
		   }
        return c;
     }

     isPointInsideCircle(latCenter,lngCenter, latPoint, lngPoint, radius){

        return Math.sqrt((latPoint-latCenter)*(latPoint-latCenter) + (lngPoint-lngCenter)*(lngPoint-lngCenter)) < radius*radius;
     }
    
    somePointInsidePolygon(points, polygon){    return points.some(point => this.isPointInsidePolygon(polygon, point.lat, point.lng));}

    	/**
	 * Check distance between two geopoints in meters
	 * @param me 
	 * @param p 
	 */
	checkDistance(me,p)
	{  
		var R 		= 6378.137; // Radius of earth in KM
		var dLat 	= p.lat * Math.PI / 180 - me.lat * Math.PI / 180;
		var dLng 	= p.lng * Math.PI / 180 - me.lng * Math.PI / 180;
		var a 		= Math.sin(dLat/2) * Math.sin(dLat/2)
					    + Math.cos(me.lat * Math.PI / 180) 
						* Math.cos(p.lat * Math.PI / 180) 
						* Math.sin(dLng/2) 
						* Math.sin(dLng/2);

		var c 		= 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		var d 		= R * c;

		// Return value in meters
		return d * 1000;
    }

    findPolygonByPoint(point, listZones){
        if(!point || !listZones){ return null; } 
        let zoneFound   =   listZones.find(zone =>{
            let findedArea  =    zone.areas.find(el => this.isPointInsidePolygon(el.coords,point.lat, point.lng))
            if(findedArea){
                console.log('findedArea is', findedArea);
            }
            return findedArea;
        })
        return zoneFound && zoneFound.name ? zoneFound.name : null;   
    }



}