import { Component, Input, OnInit } from '@angular/core';

/**
 * Generated class for the BookingVoucherComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-profile-destination',
  templateUrl: './app-profile-destination.component.html',  
  styleUrls   : ['./app-profile-destination.component.scss']
})
export class AppProfileDestinationComponent implements OnInit{
   
    
    @Input() destinations   :   any;
    @Input() profiles       :   any;
	@Input() rowData        :   any;
	
    constructor() {}
    ngOnInit(): void {
        console.log(this.destinations,this.profiles, this.rowData);
	}
	addDestination(){
		this.rowData.destinations	=	this.rowData.destinations || [];
		this.rowData.destinations.push({destination : {}, profile : {}});
		console.log(this.rowData);
	}
	
	getFilteredDestinations(item){
		if(item.destination && item.destination.name){ return this.destinations};
		return this.destinations.filter(el => (this.rowData.destinations || []).filter(el => el.destination.name != item.destination.name).findIndex(dest => dest.destination.name == el.label) == -1);
	}

	onChange($event,){

	}
	removeDestination(item){
		this.rowData.destinations.splice(this.rowData.destinations.findIndex(el => el.destination.name == item.destination.name),1);
	}
}