import { CommonsService		} from './../../../demo/service/commons.service';
import { GoogleService 		} from './../../../demo/service/google/google.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
         ViewEncapsulation,		
         OnInit				} from '@angular/core';

@Component({
	selector    		: 'app-stations',
	styleUrls			: ['./app-stations.component.scss'],
	encapsulation		: ViewEncapsulation.None,
	templateUrl 		: 'app-stations.component.html'  
})
export class StationsComponent implements OnInit {

    @Input() infoAreas          : any;
	@Input() workingStations    : any;
	@Input() mode				: any;
	@Output()emitter			= new EventEmitter<any>();	

	pageInfo    : any 		=   {};
	panel		: any		= 	{ 	items	: 	[
													{ name: 'stations', 	label: '_STATIONS', 	icon: 'plane',		isDisabled: ()=>( false								)},
													{ name: 'help', 		label: '_HELP',			icon: 'question',	isDisabled: ()=>( false								)}
												],
												selected: 'stations'								
								};

	constructor(private googleCtrl	:	GoogleService,
				private commons		:	CommonsService){

    }

	// CONTROL PANEL --------------------------------------------------------------------------------------------------

	getInfo($type,$info,$params={})				{	switch($type)	{	case "flags"	:	return 'https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/'+$info+'.png';
																	}		
												}
	getTabInfo($type,$tab,$panel,$params={})	{	switch($type)	{	case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
																		case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
																							return this.isDisabled($tab["isDisabled"],$tab);
																		case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
																	}
												}
	isDisabled($type,$item)						{	switch($type){	default: break; }}

	// END CONTROL PANEL ----------------------------------------------------------------------------------------------

   async ngOnInit(){
        this.pageInfo = {
			addingMeetingPoint	:	false,
            imgs		:	{
                noImgDest						:	'https://icon-library.net/images/polygon-icon/polygon-icon-28.jpg',
                noImgComp						:	'https://img.icons8.com/bubbles/2x/company.png',
				stations						:	'assets/demo/images/general/collageStation.png',
				selectedMeetingPoint			:	'assets/demo/images/maps/selectedMeetingPoint.png',
				unselectedMeetingPoint			:	'assets/demo/images/maps/unselectedMeetingPoint.png',
                airport							:	{	selected 	:	'assets/demo/images/maps/airportSelected.png',
                                    					unselected	:	'assets/demo/images/maps/airportUnselected.png'
													},
                port							:	{	selected 	:	'assets/demo/images/maps/portSelected.png',
                            					        unselected	:	'assets/demo/images/maps/portUnselected.png'
													},
                train							:	{	selected 	:	'assets/demo/images/maps/trainSelected.png',
                					                    unselected	:	'assets/demo/images/maps/trainUnselected.png'
													}
				},
			meetingPointIcon	:	{
				url			:	'assets/demo/images/maps/selectedMeetingPoint.png',
				scaledSize	: {
					width: 32,
					height: 32
				}
			},
            currentStationTab	:	0,
            stationsTypes		:	[	{type : 'airport', 	label : '_AIRPORT', 	noItemsLabel : '_NO_AIRPORTS_ON_THIS_AREAS', 	img :	'https://icons.iconarchive.com/icons/sonya/swarm/256/Airport-icon.png'							},
                                        {type : 'port', 	label : '_PORT', 		noItemsLabel : '_NO_PORTS_ON_THIS_AREAS',		img	:	'https://i.ya-webdesign.com/images/anchor-logos-png-1.png'										},
                                        {type : 'train', 	label : '_TRAIN', 		noItemsLabel : '_NO_TRAINS_ON_THIS_AREAS',		img :	'https://as2.ftcdn.net/v2/jpg/01/01/41/99/1000_F_101419995_v8EYd2Z5PSLRSvY6ufOcNdcoEyrIyH8k.jpg'}
									]
        }
		this.pageInfo.loading = {
			stations	:	true
		}		
		
		await this.googleCtrl.loadApi();

        if(this.workingStations){
            this.setupStations();
		}
		this.toggleTypeStation(this.pageInfo.stationsTypes[0]);
		this.pageInfo.loading.stations = false;
    }
	
	mapReady(map){
		this.loadMapStations(map);
	}

	/**
	 * Assign the current stations from existing destination to active
	 */
	setupStations(){	
		this.workingStations.forEach(typeStation => {
			let listCurrentType	=	this.infoAreas.stations.find(el => el.type == typeStation.type);
			if(!listCurrentType){	return;	}
			typeStation.items.forEach(station => {
				let findedStation	=	listCurrentType.items.find(el => el.name == station.name); // MIRAR AQUI SI TENIM MEETING POINTS!!!
				if(!findedStation){
					this.commons.generateToast("_ERROR","_NO_STATION_FOUND","error");
					return null;
				}
				station.meetingPoints	?	findedStation.meetingPoints 	= station.meetingPoints 	: null; 
				findedStation 			?	findedStation.selected	 		= station.selected			: null;
			});
		})
	}
	
	loadMapStations(map?){
		let centerArea				=	this.infoAreas.areas[ Math.ceil(this.infoAreas.areas.length / 2) ];
		let centerCoords			=	centerArea.coords[ Math.ceil(centerArea.coords.length / 2) ];
		let centerCoordsBounds		=	this.googleCtrl.getCenterPolygons(this.infoAreas.areas.map(el => el.coords).flat());

		this.pageInfo.mapConfig 	= 	{
			lat		: centerCoords.lat,
			lng		: centerCoords.lng,
			zoom	: 10,
			markerConfig	:	{
				url			:	null,
				scaledSize	: 	{ width: 32, height: 32 }
			}
		};

		this.pageInfo.map			=	map || null;

		if(map){	map.fitBounds(centerCoordsBounds.bounds) }
	}

	displayStationsFromDestination()		{	return this.infoAreas.stations;	}
	
	onTabChange($event)						{
												console.log(this.infoAreas.stations);
												this.pageInfo.currentStationSelected	=	null;
												this.pageInfo.markerMeetingPoint		=	null;
												this.pageInfo.currentStationTab 		=	$event.index;
												this.pageInfo.addingMeetingPoint		=	false;
												this.displayCurrentStations();
												this.loadMapStations(this.pageInfo.map);
											}

	getCountStation(type)					{
												let total 	= ((this.infoAreas.stations.find(el => el.type == type) || {}).items || []);
												if(total.length == 0){ return {total : 0}}
												let active	= total.filter(el => el.selected == true);
												return { active : active.length, total : total.length}
											}

	getStationsByType(type)					{
												let stations = ((this.infoAreas.stations.find(el => el.type == type) || {}).items || []);
												if(!stations){ return []}
												stations.forEach(station =>{
														station.iconUrl	=	this.mountIconStation(station);
													if(station.terminals.length > 1){
														station.terminals.forEach(terminal =>{
																terminal.iconUrl	=	this.mountIconStation(terminal,station.type);
														})
													}
												});
												return stations;
											}

	toggleTypeStation(type)					{
												this.commons.toggleItem(type,this.pageInfo.stationsTypes,'toggled');
												if(type.toggled){
													this.displayCurrentStations();
													this.loadMapStations(this.pageInfo.map);
												}
											}

	displayCurrentStations()				{
												let currentType						=	this.pageInfo.stationsTypes.find(el => el.toggled);
												if(!currentType){ return; }
												
												this.pageInfo.listCurrentStations	=	((this.infoAreas.stations.find(el => el.type == currentType.type) || {}).items || []);
												console.log('List current stations',this.pageInfo.listCurrentStations);
												
												this.pageInfo.currentStationType	=	currentType.type;
												this.pageInfo.listCurrentStations.forEach(station =>{
													station.iconUrl	=	this.mountIconStation(station);
													if(station.terminals.length > 1){
														station.terminals.forEach(terminal =>{
															terminal.iconUrl	=	this.mountIconStation(terminal,station.type);
														})
													}
												});
											}

		/**
	 * Click on text. If it has more than one terminal, it expands, otherwise it uses as a checkbox
	 * @param station Station selected
	 */
	onClickStation(station){
		if(station.terminals && station.terminals.length <	2){
			station.selected	=	!station.selected;
			console.log(station);
			console.log(this.infoAreas);
			station.iconUrl		=	this.mountIconStation(station);
			if(station.terminals.length > 0){
				station.terminals.forEach(terminal =>{
					terminal.iconUrl	=	this.mountIconStation(terminal,station.type);
					terminal.selected	=	station.selected;
				})
			}
			this.clickMeetingPoint(station);
			this.saveStations();
		}else{
			station.displayMore 				= 	!station.displayMore;
		}
	}

	selectStation(station){
		if(this.pageInfo.currentStationSelected && this.pageInfo.currentStationSelected.name == station.name){
			station.displayMore = false;
			this.pageInfo.currentStationSelected = null;
		}else{
			this.pageInfo.currentStationSelected = station;
			if(station.terminals && station.terminals.length >=2){
				station.displayMore = !station.displayMore;
			}else{
				this.pageInfo.map.panTo({lat : +station.location.lat, lng : +station.location.lng});
				this.pageInfo.map.setZoom(18);
			}
		}
	}

	selectTerminal(terminal){
		if(this.pageInfo.currentTerminalSelected && this.pageInfo.currentTerminalSelected.name == terminal.name){
			this.pageInfo.currentTerminalSelected = null;
		}else{
			this.pageInfo.currentTerminalSelected = terminal;
			this.pageInfo.map.panTo({lat : +terminal.location.lat, lng : +terminal.location.lng});
			this.pageInfo.map.setZoom(18);
		}
	}
	checkIfStationSelected(station)		{	if(!this.pageInfo.currentStationSelected){ return false}
											return this.pageInfo.currentStationSelected.name == station.name;
										}
	checkIfTerminalSelected(terminal)	{	if(!this.pageInfo.currentTerminalSelected || !this.pageInfo.currentTerminalSelected.name){return false;}
											return this.pageInfo.currentTerminalSelected.name == terminal.name;
										}

	getCurrentListMeetingPoint()		{	if(this.pageInfo.currentTerminalSelected){return this.pageInfo.currentTerminalSelected.meetingPoints || []}
											if(this.pageInfo.currentStationSelected){return this.pageInfo.currentStationSelected.meetingPoints || []}
											return [];
										}
	deleteMeetingPoint(meetingPoint, list){
		let foundMeetingPoint	=	list.findIndex(el => el.id == meetingPoint.id);
		foundMeetingPoint 		> 	-1 	? list.splice(foundMeetingPoint,1) 
										: this.commons.generateToast('_ERROR','_COULD_NOT_REMOVE','error');
	}

	clickMeetingPoint(station){
		this.pageInfo.currentStationSelected	=	station;
		station.meetingPoint					=	station.meetingPoint || null;
		this.pageInfo.markerMeetingPoint		=	station.meetingPoint ? {coords : station.meetingPoint} : null;
		this.pageInfo.addingMeetingPoint		=	true; 
		this.pageInfo.map.panTo({lat : +station.location.lat, lng : +station.location.lng});
		this.pageInfo.map.setZoom(18);
		// this.pageInfo.mapConfig['lat']		=	station.location.lat;
		// this.pageInfo.mapConfig['lng']		=	station.location.lng;	
		// this.pageInfo.mapConfig['zoom']		=	18;
	}

	checkInputMeetingPoint($event, meetingPoint){
		if($event.keyCode == 27 || $event.keyCode == 13 || $event.keyCode == 9){
			if((meetingPoint.name == null || meetingPoint.name == "")){
				meetingPoint.hasErrors	=	true;
				return this.commons.generateToast('_ERROR','_NOT_EMPTY','error');
			}else{
				meetingPoint.editing	=	false;
				meetingPoint.hasErrors	=	false;
				this.commons.generateToast('_SUCCESS','_MEETING_POINT_UPDATED','success');
				this.saveStations();
			}
		}
	}

	getCountMeetingStation(station){

		if(station.terminals && station.terminals.length <	2){
			return station.meetingPoints ? station.meetingPoints.length : 0;
		}else{
			return station.terminals.reduce((acc, item) => acc + (item.meetingPoints ? item.meetingPoints.length : 0), 0) || 0;
		}
	
	}

	getImgMeetingPoint(station){
		return (station.meetingPoints && station.meetingPoints.length > 0) ? this.pageInfo.imgs['selectedMeetingPoint'] :  this.pageInfo.imgs['unselectedMeetingPoint'];
	}

	mapClickMeetingPoint($event){
		console.log($event);
		if(this.pageInfo.currentTerminalSelected){
			this.pageInfo.currentTerminalSelected.meetingPoints	=	this.pageInfo.currentTerminalSelected.meetingPoints || [];
			if(this.pageInfo.currentTerminalSelected.meetingPoints.some(el => el.name == null || el.name == "")){return this.commons.generateToast('_ERROR','_FINISH_MEETING_POINT');}
			this.pageInfo.currentTerminalSelected.meetingPoints.push({coords : $event.coords, name : null, editing : true, hasErrors : false, id : this.commons.generateHash(+new Date())});
		}else{
			this.pageInfo.currentStationSelected.meetingPoints	=	this.pageInfo.currentStationSelected.meetingPoints || [];
			if(this.pageInfo.currentStationSelected.meetingPoints.some(el => el.name == null || el.name == "")){return this.commons.generateToast('_ERROR','_FINISH_MEETING_POINT');}
			this.pageInfo.currentStationSelected.meetingPoints.push({coords : $event.coords, name : null, editing : true, hasErrors : false,  id : this.commons.generateHash(+new Date())});
		}
	}

	dragEndMeetingPoint($event,meetingPoint){
		meetingPoint.coords = $event.coords;
	}



	/**
	 * Idem from the station method, and if all the terminals are selected or one unselected, it changes the status from their station
	 * @param $event True / False
	 * @param terminal Terminal from the station
	 * @param station Station
	 */
	onCheckTerminal($event,terminal,station){
		terminal.selected 	= 	$event;
		terminal.iconUrl	=	this.mountIconStation(terminal,station.type);	
		if(station.terminals.length > 1){
			station.selected	=	station.terminals.every(terminal => terminal.selected);
			station.iconUrl		=	this.mountIconStation(station);
		}
		this.saveStations();
	}
	mountIconStation(station, type?){
		if(!station){ return null	};
		return  {
			url			:	station.selected ?	this.pageInfo.imgs[station.type || type].selected :	this.pageInfo.imgs[station.type || type].unselected,
			scaledSize	: {
				width: 32,
				height: 32
			}
		};
	}
	getCurrentStationType(mode){	return this.pageInfo.currentStationType ? this.pageInfo.imgs[this.pageInfo.currentStationType][mode] : null;	}
	getCurrentTextStationType(){	return this.pageInfo.stationsTypes[this.pageInfo.currentStationTab].noItemsLabel 
												? this.pageInfo.stationsTypes[this.pageInfo.currentStationTab].noItemsLabel :
												 null;	
								}

	saveStations(){
		this.emitter.emit({type : 'save', data : this.infoAreas.stations});
	}

	getFilteredEntity($entity){
		switch($entity){
			case "stations"		:	return this.pageInfo.listCurrentStations;
		}
	}
}