import { Injectable             } from '@angular/core';
import { CommonsService 		} from '../commons.service';
import { EntityService 			} from '../entity.service';
import { fomento_areas 			} from './fomento_areas';
import { fomento_mapping		} from './fomento_mapping';

const headers = [
	"CODIGO",	
	"MATRÍCULA",
	"CIF TITULAR",
	"INTERMEDIARIO",
	"FECHA Y HORA CONTRATO",
	"CODIGO PROVINCIA CONTRATO",
	"CODIGO MUNICIPIO CONTRATO",
	"CODIGO PROVINCIA ORIGEN",
	"CODIGO MUNICIPIO ORIGEN",
	"DIRECCION ORIGEN",
	"FECHA Y HORA PREVISTA INICIO",
	"CODIGO PROVINCIA DESTINO",
	"CODIGO MUNICIPIO DESTINO",
	"DIRECCIÓN DESTINO",
	"FECHA FIN",
	"PROVINCIA MAS LEJANA",
	"MUNICIPIO MAS LEJANO",
	"DIRECCIÓN MÁS LEJANO",
	"VERACIDAD"
];

// CODIGO;MATR�CULA;CIF TITULAR;INTERMEDIARIO;FECHA Y HORA CONTRATO;CODIGO PROVINCIA CONTRATO ;CODIGO MUNICIPIO CONTRATO;CODIGO PROVINCIA ORIGEN;CODIGO MUNICIPIO ORIGEN;DIRECCION ORIGEN;FECHA Y HORA PREVISTA INICIO;CODIGO PROVINCIA DESTINO;CODIGO MUNICIPIO DESTINO;DIRECCI�N DESTINO;FECHA FIN;PROVINCIA MAS LEJANA;MUNICIPIO MAS LEJANO;DIRECCI�N M�S LEJANO;VERACIDAD

const plantilla = [
	"CODIGO",
	"MATR�CULA",
	"CIF TITULAR",
	"INTERMEDIARIO",
	"FECHA Y HORA CONTRATO",
	"CODIGO PROVINCIA CONTRATO ",
	"CODIGO MUNICIPIO CONTRATO",
	"CODIGO PROVINCIA ORIGEN",
	"CODIGO MUNICIPIO ORIGEN",
	"DIRECCION ORIGEN",
	"FECHA Y HORA PREVISTA INICIO",
	"CODIGO PROVINCIA DESTINO",
	"CODIGO MUNICIPIO DESTINO",
	"DIRECCI�N DESTINO",
	"FECHA FIN",
	"PROVINCIA MAS LEJANA",
	"MUNICIPIO MAS LEJANO",
	"DIRECCI�N M�S LEJANO",
	"VERACIDAD"
];


const moreAreas = {
	"Puerto Alcudia"			: "Alcudia",
	"Cala Mandia"				: "Manacor",
	"Playa De Palma"			: "Palma de Mallorca",
	"Puerto De Pollensa"		: "Pollença",
	"Santa Ponsa"				: "Calvià",
	"Can Picafort"				: "Santa Margalida",
	"Camp De Mar"				: "Andratx",
	"Portal Nous"				: "Calvià",
	"El Arenal"					: "Palma de Mallorca",
	"Puerto De Soller"			: "Sóller",
	"Palma City Center"			: "Palma de Mallorca",
	"Can Pastilla"				: "Palma de Mallorca",
	"Cala Dor"					: "Santanyí",
	"San Agustin"				: "Palma de Mallorca",
	"Bonanova"					: "Palma de Mallorca",
	"Alcudia"					: "Alcudia",
	"PAseo Maritimo"			: "Palma de Mallorca",
	"Paseo Maritimo - Palma"	: "Palma de Mallorca",
	"Puerto de Andratx"			: "Palma de Mallorca",
	"Palmanova"					: "Calvià",
	"Sa Pobla"					: "Pobla, Sa",
	"Tolleric"					: "Llucmajor"
};

/**
 *		Fomento		moreAreas  		Areas de Provider a Touirinia
					fomento_mapping	De Touinia a Fomento
		Commons		moreZones		Areas de Provider a Tourinia
 */

@Injectable({
  providedIn: 'root'
})
export class FomentoService {
	constructor(
		private commons					: CommonsService,
		private entityService			: EntityService
  	) { 
	}	

	private checkIfVehicleNameFits($service){
		if(undefined == this.commons.userInfo.destination							){ return true; }
		if(undefined == this.commons.userInfo.destination.fomento					){ return true; }
		if(undefined == this.commons.userInfo.destination.fomento.vehicleNameFormats){ return true; }

		let formats = this.commons.userInfo.destination.fomento.vehicleNameFormats;
		return formats.some(item=>$service.vehicle.match(item));
	}

  	async sendServices($params)		{
		let data					= $params["data"];
		let filter					= $params["filter"] || false;
		let fomento_services 		= [];
		let province				= "baleares";
		let providers_areas_mapped	= this.commons.getEntity("provider_areas_to_tourinia");
		let moreZones				= this.commons.pageInfo.moreZones;

		console.log("provider areas mapped"	, providers_areas_mapped);
		console.log("fomento_mapping"		, fomento_mapping);
	
		// let providers_with_more_zones = [2,15]
		// Object.keys(moreZones).forEach(zone=>{
		// 	providers_with_more_zones.forEach(provider=>{
		// 		providers_areas_mapped[provider][zone] = moreZones[zone];
		// 	});
		// });

		let errors					= [];
		let serviceId				= 1;

		data.forEach(row=> {
			if(row.empty){ return false; }

			row.items.forEach(service => {		

				if(undefined==service.vehicle){
					service.vehicle	= row.name;
					service.plate	= row.plate;
				}
				if(!this.checkIfVehicleNameFits(service)){
					return false;
				}

				const areaTourinia	= providers_areas_mapped[service.provider][service.area] || moreAreas[service.area];
				const areaFomento	= fomento_mapping[areaTourinia] || "not_found";
				const areaInfo		= fomento_areas[province].find(item=>areaFomento==item[3]);	
								
				if(null==areaTourinia || null==areaInfo){
					console.log("providers_areas_mapped", providers_areas_mapped);
					console.log("areaTourinia"			, areaTourinia);				
					console.log("fomento_mapping"		, fomento_mapping);
					console.log("service"				, service);
				
					errors.push({
						"código"		: service.transportId,
						"matrícula"		: row.plate,
						"area_booking"	: service.area,
						"provider"		: service.provider,
						"area_tourinia"	: areaTourinia,
						"area_fomento"	: areaFomento,
						"reason"		: "area not found",
					});
					return false;
				}

				// Get only NEW OR UPODATED services
				switch(service.fomento_status){
					case "created"	:
					case "added"	:
					case "init"		:
					case "finished"	:	return;
				}

				// let formattedDate	= this.commons.getFormattedDate(service.date,"DD/MM/YYYY");
				let formattedDate	= this.commons.getFormattedDate(service.date,"YYYY-MM-DD");
				switch(service.direction){
					case "arrival"	:	service.pickupTime = service.pickupTime || service.arrival_PickupTime; 		break;
					case "departure":	service.pickupTime = service.pickupTime || service.departure_PickupTime;	break;
				}
				
				let serviceInit 	= formattedDate+"T"+service.pickupTime+":00";
				let comparableDate	= service.date +" "+service.pickupTime;
				let airportArea		= "040";		

				// TODO !!!
				// CIF TITULAR E INTERMEDIARIO pasar a Firebase
				
				let item 			= {
					// "CODIGO"						: service.transportId,	
					// "CODIGO"						: row.name,
					// "CODIGO"						: serviceId++,
					"GROUP"							: service.name,
					"CODIGO"						: row.name,
					"MATRÍCULA"						: row.plate,	
					"CIF TITULAR"					: "B87920484",
					"INTERMEDIARIO"					: "B16553869",
					"FECHA Y HORA CONTRATO"			: serviceInit,
					"CODIGO PROVINCIA CONTRATO"		: areaInfo[0],
					"CODIGO MUNICIPIO CONTRATO"		: areaInfo[1],
					"CODIGO PROVINCIA ORIGEN"		: areaInfo[0],
					"CODIGO MUNICIPIO ORIGEN"		: service.type=="arrival"?airportArea:areaInfo[1],
					"DIRECCION ORIGEN"				: service.type=="arrival"?"Aeropuerto PMI":service.area,
					"FECHA Y HORA PREVISTA INICIO"	: serviceInit,
					"CODIGO PROVINCIA DESTINO"		: areaInfo[0],
					"CODIGO MUNICIPIO DESTINO"		: service.type=="arrival"?areaInfo[1]:airportArea,
					"DIRECCIÓN DESTINO"				: service.type=="arrival"?areaInfo[1]:airportArea,
					// "FECHA FIN"						: this.commons.getFormattedDate(service.date,"DD/MM/YYYY",'YYYY-MM-DD'),
					"FECHA FIN"						: service.date,
					"PROVINCIA MAS LEJANA"			: areaInfo[0],
					"MUNICIPIO MAS LEJANO"			: areaInfo[1],
					"DIRECCIÓN MÁS LEJANO"			: "Aeropuerto PMI",
					"VERACIDAD"						: "S",
					"COMPARABLE"					: comparableDate
				}
				
				fomento_services.push(item);
			});
		});

		fomento_services = fomento_services
							.sort((a,b)=>{
								let field = "FECHA Y HORA PREVISTA INICIO";
								return a[field]>b[field]?1:-1;
							});
		
		if(filter){
			fomento_services = fomento_services.filter(item=>{				
				let field = "COMPARABLE";
				return item[field]>this.commons.getToday("YYYY-MM-DD HH:mm");
			}).map(item=>{
				delete item["COMPARABLE"];
				return item;
			})
		}

		// Insert header before data
		// let sheet = [
		// 	headers.reduce((o,header)=>{ o[header] = header; return o},{}),
		// 	...fomento_services.sort((a,b)=>{
		// 		let field = "FECHA Y HORA PREVISTA INICIO";
		// 		return a[field]>b[field]?1:-1;
		// 	})
		// ];
		console.log("errors",	errors);
		console.log("services",	fomento_services);

		this.export({ mode: 'proxy', items: fomento_services });
	}

	private export($params){
		switch($params["mode"]){
			case "csv"	: this.commons.export("csv",$params["items"],"TMT_Services_Fomento_"+this.commons.getToday('YYYYMMDD')); break;
			case "proxy": this.proxy($params); break;
			default		: alert("Fomento export mode "+$params["mode"]+" not allowed");
		}
	}

	/**
	 * send througth proxy
	 * 
	 * @param $params
	 */
	private async proxy($params){
		let items = $params["items"] || [];

		console.log(items);
		// return true;

		return await this.entityService.postJSON(
			this.entityService.getUrl("fomento_proxy"),
			{	
				// mode		: 'test',
				mode		: 'real',
				dmc			: this.commons.userInfo.dmc.id,
				destination	: this.commons.userInfo.destination.id,
				action		: "create", 
				items		: JSON.parse(JSON.stringify(items))
			}
		)
		.then(response => {
			console.log("[saveServicesIntoBookikngs] response success",response);
			this.commons.generateToast("_FOMENTO","_FOMENTO_SERVICES_SENT","info");
			return true;
		}).catch(response => {
			console.log("[saveServicesIntoBookikngs] response error",response);	
			this.commons.generateToast("_FOMENTO","_FOMENTO_SERVICES_ERROR","error");	
			return false;
		})
	}
}
