import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { Injectable 			} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  	constructor(private commons: CommonsService){ }

	generateCharts($params){
	
		let $entity			= $params.entity;
		$entity.charts 		= undefined===$entity.charts?{}:$entity.charts;
		$entity.charts.days	= Array.from(Array(30).keys()).map(item=>"2019-09-"+(item+1));

		$entity.charts[$params.chartName] = this.generateChartByType({
			provider	: $entity,
			title		: $params.title,
			labels		: $entity.charts.days.map(item=>item),
			datasets	: $params.datasets
		});		
		return $entity.charts;
	}
	
	generateChartByType($params){
		return { 
			line : {
				type	: "line",
				options	: {
					title: {
						display	: false,
						text	: $params.title,
						fontSize: $params.titleSize || 20
					},
					legend: {	
						position: $params.legendPosition || 'bottom'
					}			
				},
				data 	: {
					labels	: $params["labels"],
					datasets: $params.datasets.map(dataset => ({
						label			: dataset.label,
						data			: dataset.data,
						steppedLine		: dataset.steppedLine 	|| false,
						tension			: dataset.tension 		|| 0,
						_backgroundColor: dataset.color,
						backgroundColor	: 'transparent',
						_borderColor	: dataset.borderColor 	|| '#505050',
						borderColor		: dataset.color 	|| '#505050'
					}))		
				}
			}
		};
	}
}
